import { ILead } from "@/customTypings/Lead";
import { ErrorMessage, Field, Formik } from "formik";
import { useState } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { IAddress } from "../../customTypings/Address";
import { IContact } from "../../customTypings/Contact";
import { ILeadFull } from "../../customTypings/LeadFull";
import {
    addressService,
    contactService,
    leadService,
    postcodeService
} from "../../services";
function LeadAddForm() {
    const [alertVariant, setAlertVariant] = useState("danger");
    const navigate = useNavigate();

    const initialValues: any = {
        leadNumber: "",
        name: "",
        contactDepartment: "",
        phone: "",
        email: "",
        line1: "",
        line2: "",
        line3: "",
        town: "",
        country: "",
        postCode: "",
    };

    const generateValidationSchema = () => {
        const schemaObject: {
            [key: string]: Yup.StringSchema<string | undefined>;
        } = {};

        Object.keys(initialValues).forEach((field) => {
            let fieldSchema;
            if (field === "email") {
                fieldSchema = Yup.string()
                    .required(`email is required`)
                    .email("must be a valid email");
            } else if (field === "leadNumber") {
                fieldSchema = Yup.string().matches(/^\d+$/, "lead Number must contain only numbers");
            } else if (field === "phone") {
                fieldSchema = Yup.string()
                    .required("Phone Number is required")
                    .matches(
                        /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
                        "Invalid phone number"
                    );
            } else if (field === "line1" || field === "line2") {
                fieldSchema = Yup.string();
            } else {
                fieldSchema = Yup.string().required(`${field} is required`);
            }

            schemaObject[field] = fieldSchema;
        });
        return Yup.object().shape(schemaObject);
    };

    const save = async (
        values: any,
        setStatus: any,
        setSubmitting: any,
        setFieldError: any,
        resetForm: any
    ) => {
        const updatedContact: IContact = {
            id: undefined,
            name: values.name,
            emailAddress: values.email,
            phoneNumber: values.phone,
        };
        const updatedAddress: IAddress = {
            id: undefined,
            line1: values.line1,
            line2: values.line2,
            line3: values.line3,
            town: values.town,
            county: values.country,
            postcode: values.postCode,
            what3words: "null",
            latitude: "",
            longitude: "",
        };
        const contact = await contactService.add(updatedContact);
        const address = await addressService.add(updatedAddress);
        const updatedLead: ILead = {
            id: undefined,
            leadNumber: `${values.leadNumber}`,
            name: values.name,
            isBusiness: true,
            dateOfInquiry: new Date(),
            notes: "",
            contactId: contact.id,
            addressId: address.id,
            department: values.contactDepartment,
        };

        leadService.add(updatedLead).then(
            (response) => {
                setSubmitting(false);
                if (response.status === "Success") {
                    resetForm({ values: "" });
                    setAlertVariant("success");
                    setStatus("Lead has been created successfully");
                    navigate("/leads")
                } else {
                    setAlertVariant("danger");
                    setStatus(response.message);
                }
            },
            (error) => {
                setAlertVariant("danger");
                setStatus(error.title);
            }
        );
    };
    const lookUpPostCode = (postcode: string, values: any, setStatus: any) => {
        postcodeService.lookup(postcode).then((res) => {
            if (res.status === "OK") {
                setAlertVariant("success");
                setStatus("Postcode is valid");
            } else {
                setAlertVariant("danger");
                setStatus(res.error);
            }
        });
    };

    const fieldDisplayNames: any = {
        "ContactDepartment": "Contact Department",
        "Line1": "Address Line 1",
        "Line2": "Address Line 2",
        "Line3": "Address Line 3"
    };
    return (
        <>
            <h1 className="my-3">Add Lead</h1>
            <Formik
                initialValues={{ ...initialValues }}
                validationSchema={generateValidationSchema}
                onSubmit={(
                    values,
                    { setStatus, setSubmitting, setFieldError, resetForm }
                ) => {
                    save(values, setStatus, setSubmitting, setFieldError, resetForm);
                }}
            >
                {({
                    errors,
                    status,
                    touched,
                    isSubmitting,
                    handleSubmit,
                    values,
                    setStatus,
                    handleChange,
                }) => (
                    <Form onSubmit={handleSubmit}>
                        {Object.keys(initialValues)
                            .filter(
                                (field) =>
                                    field !== "leadNumber" && field !== "postCode" && field !== "id"
                            )
                            .map((field) => (
                                <Form.Group
                                    as={Row}
                                    key={field}
                                    className="mb-3"
                                    controlId={field}
                                >
                                    <Form.Label column sm={3}>
                                        {fieldDisplayNames[(field.charAt(0).toUpperCase() + field.slice(1))] || (field.charAt(0).toUpperCase() + field.slice(1))}
                                    </Form.Label>
                                    <Col sm={9}>
                                        <Field
                                            name={field}
                                            onChange={handleChange}
                                            type="text"
                                            value={values[field as keyof ILeadFull]}
                                            className={`form-control ${errors[field as keyof ILeadFull] &&
                                                touched[field as keyof ILeadFull]
                                                ? "is-invalid"
                                                : ""
                                                }`}
                                        />
                                        <ErrorMessage
                                            name={field}
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </Col>
                                </Form.Group>
                            ))}
                        <Form.Group
                            as={Row}
                            key="postCode"
                            className="mb-3"
                            controlId="postCode"
                        >
                            <Form.Label column sm={3}>
                                Post Code
                            </Form.Label>
                            <Col sm={4}>
                                <Row>
                                    <Col>
                                        <Field
                                            name="postCode"
                                            onChange={handleChange}
                                            type="text"
                                            value={values["postCode"] as keyof ILeadFull}
                                            className={`form-control ${errors["postCode" as keyof ILeadFull] &&
                                                touched["postCode" as keyof ILeadFull]
                                                ? "is-invalid"
                                                : ""
                                                }`}
                                        />
                                        <ErrorMessage
                                            name="postCode"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </Col>
                                    <Col>
                                        <Button
                                            variant="primary"
                                            onClick={() =>
                                                lookUpPostCode(values.postCode, values, setStatus)
                                            }
                                        >
                                            Lookup
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Form.Group>

                        <Button
                            variant="primary"
                            disabled={isSubmitting}
                            type="submit"
                            className="me-2 d-flex align-items-end ms-auto"
                        >
                            {isSubmitting ? <LoadingSpinner text="Saving..." /> : "Save"}
                        </Button>

                        {status && (
                            <Alert variant={alertVariant} className="mt-3">
                                {status}
                            </Alert>
                        )}
                    </Form>
                )}
            </Formik>
        </>
    );
}

export default LeadAddForm;