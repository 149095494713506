import { useAtom } from "@dbeining/react-atom";
import { faBars, faCaretDown, faKey, faPowerOff, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Badge, Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useNavigate } from "react-router-dom";
import { userAtom } from "../atoms/userAtom";
import { useViewport } from "../contexts/ViewportContext";
import { accountService, authenticationService, invitationService } from "../services";
import { Avatar } from "./Avatar";
import { NavigationBar } from "./NavigationBar/NavigationBar";
import { SwitchOrganisation } from "./Models/SwitchOrganisation";
import { UserPasswordEdit } from "./Models/UserPasswordEdit";

export const HeaderBar = () => {
    let navigate = useNavigate();
    const [showUserMenu, setShowUserMenu] = useState(false);
    const [userInvitationsAmount, setUserInvitationsAmount] = useState(0);
    const [showSwitchOrganisation, setShowSwitchOrganisation] = useState(false);
    const [userIdentityAmount, setUserIdentityAmount] = useState(0);
    const [showEditPassword, setShowEditPassword] = useState(false);

    const [roleNames, setRoleNames] = useState("");

    const currentUser = useAtom(userAtom);

    const [showMenu, setShowMenu] = useState(false);
    const { width } = useViewport();
    const breakpoint = 768;

    useEffect(() => {
        if (currentUser !== undefined) {
            invitationService.amountByEmail(currentUser.identity.email).then((amount) => {
                setUserInvitationsAmount(amount);
            });
            accountService.getIdentitiesAmount().then((amount) => {
                setUserIdentityAmount(amount);
            });

            setRoleNames(
                Array.from(currentUser.identity.roles.values(), (role) => role.name)
                    .sort()
                    .join(", ")
            );
        }
    }, [currentUser]);

    function logout() {
        authenticationService.logout().then(() => {
            navigate("/signin", { state: { message: "Ok" } });
            setShowUserMenu(false);
        });
    }

    function changePassword() {
        setShowUserMenu(false);
        setShowEditPassword(true);
    }

    return (
        <>
            {currentUser && (
                <>
                    <Navbar expand={true} expanded={true} bg="light" variant="light" sticky="top" id="top-nav" className="p-0">
                        <Container fluid className="p-0">
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Nav className="me-auto">
                                    {width < breakpoint && (
                                        <Button variant="outline-secondary" onClick={() => setShowMenu(true)} className="border-0 rounded-0 py-2">
                                            <FontAwesomeIcon icon={faBars} />
                                        </Button>
                                    )}
                                </Nav>
                                <Nav>
                                    <Button variant="outline-secondary" onClick={() => setShowUserMenu(true)} className="border-0 rounded-0 py-2">
                                        <Avatar identity={currentUser.identity} />
                                        {userInvitationsAmount > 0 && (
                                            <Badge pill bg="secondary">
                                                {userInvitationsAmount}
                                            </Badge>
                                        )}
                                        <span className="visually-hidden">unread messages</span>
                                        <FontAwesomeIcon icon={faCaretDown} className="ms-2" />
                                    </Button>
                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                    <Offcanvas show={showUserMenu} onHide={() => setShowUserMenu(false)} key="offcanvasNavbar-user-menu" restoreFocus={false} placement="end" name="end">
                        <Offcanvas.Header closeButton className="pb-1">
                            <Offcanvas.Title>
                                <Avatar identity={currentUser.identity} />
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body className="pt-0">
                            <div>{currentUser.identity.email}</div>
                            <div className="my-3 small">{roleNames}</div>
                            <Nav className="flex-column">
                                <Button className="mt-2 text-start" variant="outline-primary" onClick={changePassword}>
                                    <FontAwesomeIcon icon={faKey} fixedWidth /> Change Password
                                </Button>
                                <Button className="mt-2 text-start" variant="outline-primary" onClick={logout}>
                                    <FontAwesomeIcon icon={faPowerOff} fixedWidth /> Sign Out
                                </Button>
                                {userIdentityAmount > 1 && (
                                    <Button className="mt-5 text-start" variant="outline-primary" onClick={() => setShowSwitchOrganisation(true)}>
                                        <FontAwesomeIcon icon={faUserCircle} fixedWidth /> Switch Organisation
                                    </Button>
                                )}
                                <SwitchOrganisation show={showSwitchOrganisation} setShow={setShowSwitchOrganisation} />
                            </Nav>
                        </Offcanvas.Body>
                    </Offcanvas>
                    <Offcanvas show={showMenu} onHide={() => setShowMenu(false)}>
                        <Offcanvas.Body className="p-0">

                            <div className="d-flex justify-content-end">
                                <Button
                                    variant="outline-secondary"
                                    onClick={() => setShowMenu(false)}
                                    className="border-0 rounded-0 py-2"
                                >
                                    <FontAwesomeIcon icon={faBars} />
                                </Button>
                            </div>

                            <NavigationBar setShowMenu={setShowMenu} />
                        </Offcanvas.Body>
                    </Offcanvas>
                    <UserPasswordEdit show={showEditPassword} setShow={setShowEditPassword} user={currentUser.identity} />
                </>
            )}
        </>
    );
};
