import { authHeader, handleResponse } from '../helpers';

export const rolesService = {
    getAll,
    getAllForGroup
};

function getAll() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`api/roles`, requestOptions).then(handleResponse);
}

function getAllForGroup(group: number) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`api/roles/forgroup?group=${group}`, requestOptions).then(handleResponse);
}