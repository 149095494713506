import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import { Helmet } from "react-helmet";
import Select from "react-select";
import { EventCalendar } from "../../components/EventCalendar/EventCalendar";
import { IValueAndLabel } from "../../customTypings/ValueAndLabel";
import { userService } from "../../services";
const CalendarPage = () => {
  const [selectedColors, setSelectedColors] = useState<Record<string,string>>({});

  
  const styles = {
    multiValue: (base:any, item:any) =>
    {
    return({
        ...base,
        backgroundColor: selectedColors[item.data.value] || "#3788d8"
      })
    }
  };

  const [filters, setFilters] = useState<string[]>([]);
  const [allIdentities, setAllIdentities] = useState<IValueAndLabel[]>();
  
  const setColors = (event:any) => {
    setSelectedColors({...selectedColors, [event.identityId]: event?.color } )
  }

  useEffect(() => {
    userService
      .getAllListItems()
      .then((identities) => setAllIdentities(identities));
  }, []);
  return (
    <Container fluid>
      <Helmet>
        <title>Company Diary</title>
      </Helmet>
      <Row>
        <Col>
          <h1 className="my-3">Company Diary</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <Select
            isMulti
            closeMenuOnSelect={false}
            placeholder={
              <>
                <FontAwesomeIcon icon={faUsers} /> Select users...
              </>
            }
            styles={styles}
            className="stateManagedSelect z-2 mb-2"
            onChange={(value) => {
              var identityIds: string[] = [];
              for(var i=0; i<value.length;i++){
                identityIds.push(value[i].value);
              };
              setFilters(identityIds);
            }}
            isClearable
            options={allIdentities}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EventCalendar
            setColors={setColors}
            identityIds={filters}
            newEventAllowed={true}
            updateEventAllowed={true}
          />
        </Col>
      </Row>
    </Container>
  );
};

export { CalendarPage };
