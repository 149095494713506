import { useAtom } from '@dbeining/react-atom';
import { Button, Stack } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import { userAtom } from '../../atoms/userAtom';
import { Avatar } from "../../components/Avatar";
import { AvatarSkeleton } from "../../components/AvatarSkeleton";
import { JobCancelled, JobComplete, JobOpen, JobType, SummaryCritical, SummaryNoTasks, SummaryOK, SummaryOverdue, TaskDueToday, TaskOpen, TaskOverdue, WaitTime } from "../../components/Badges";
import { LoadingSpinner } from "../../components/LoadingSpinner";

const ComponentDemoPage = () => {

  const currentUser = useAtom(userAtom)

  return (
    <>
      <Helmet>
        <title>Component Demo</title>
      </Helmet>
      <Stack gap={1}>
        <h1>Component Demo</h1>
        <h3>Loading Spinner</h3>
        <div>
        <Button>
          <LoadingSpinner text="Updating..."/>
        </Button>
        </div>      
        <>
          <h3 className="mt-4">Avatar</h3>
          {currentUser && 
          <>
          Avatar
          <div>
            <Avatar identity={currentUser.identity}/>
          </div>
          </>}
          AvatarSkeleton
          <div>
            <AvatarSkeleton/>
          </div>
        </>
        
        <h3 className="mt-4">Badges</h3>
        Task Summary
        <Stack direction="horizontal" gap={2}>
          <SummaryCritical/>
          <SummaryOverdue/>
          <SummaryNoTasks/>
          <SummaryOK/>
        </Stack>
        Job status
        <Stack direction="horizontal" gap={2}>
          <JobOpen/>
          <JobCancelled/>
          <JobComplete/>
        </Stack>
        Task status
        <Stack direction="horizontal" gap={2}>
          <TaskOverdue/>
          <TaskDueToday/>
          <TaskOpen/>
        </Stack>
        Job types
        <Stack direction="horizontal" gap={2}>
          <JobType text="PV Solar"/>
          <JobType text="EV Charger"/>
          <JobType text="Ground Heat Pump"/>
        </Stack>
        Wait Time
        <Stack direction="horizontal" gap={2}>
          <WaitTime days={1} />
          <WaitTime days={2} />
          <WaitTime days={3} />
          <WaitTime days={4} />
          <WaitTime days={5} />
          <WaitTime days={6} />
        </Stack>

        <h3 className="mt-4">Headings</h3>
        <h1>Heading 1</h1>
        <h2>Heading 2</h2>
        <h3>Heading 3</h3>
        <h4>Heading 4</h4>
        <h5>Heading 5</h5>
        <h6>Heading 6</h6>
      </Stack>
    </>
  )
}

export { ComponentDemoPage };
