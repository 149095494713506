import { ErrorMessage, Field, Formik } from "formik";
import React, { useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import * as Yup from "yup";
import { IChecklistDefinition } from "../../customTypings/ChecklistDefinition";
import { checklistDefinitionService } from "../../services";
import { LoadingSpinner } from "../LoadingSpinner";

type Props = {
    show: boolean;
    onClose: (added: boolean) => void;
};

export const AddNewChecklist: React.FC<Props> = ({ show, onClose }) => {
    const [alertVariant, setAlertVariant] = useState("danger");

    const handleClose = () => onClose(false);

    return (
        <Modal centered show={show} keyboard={false} onHide={handleClose}>
            <Formik
                initialValues={{
                    name: "",
                    type: "",
                }}
                validationSchema={Yup.object().shape({
                    name: Yup.string().required("Name is required"),
                })}
                onSubmit={(
                    values,
                    { setStatus, setSubmitting, setFieldError }
                ) => {
                    setStatus();
                    setSubmitting(false);
                    const newChecklist: IChecklistDefinition = {
                        id: undefined,
                        name: values.name,
                        type: values.type,
                        isDeleted: false,
                    };
                    checklistDefinitionService.add(newChecklist).then(
                        (response) => {
                            setSubmitting(false);
                            if (response.status !== "Failure") {
                                onClose(true);
                            } else {
                                setAlertVariant("danger");
                                setStatus(response.message);
                            }
                        },
                        (error) => {
                            setAlertVariant("danger");
                            if (error.status === 400) {
                                setStatus(error.title);
                                setFieldError("name", error.errors.Name);
                                setFieldError("type", error.errors.type);
                            } else {
                                setStatus(error);
                            }
                            setSubmitting(false);
                        }
                    );
                }}
            >
                {({
                    errors,
                    status,
                    touched,
                    isSubmitting,
                    handleSubmit,
                    values,
                    handleChange,
                    setFieldValue,
                }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>New Checklist</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="name"
                            >
                                <Form.Label column sm={3}>
                                    Name
                                </Form.Label>
                                <Col sm={9}>
                                    <Field
                                        name="name"
                                        type="text"
                                        className={
                                            "form-control" +
                                            (errors.name && touched.name
                                                ? " is-invalid"
                                                : "")
                                        }
                                    />
                                    <ErrorMessage
                                        name="name"
                                        component="div"
                                        className="invalid-feedback"
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="type"
                            >
                                <Form.Label column sm={3}>
                                    Type
                                </Form.Label>
                                <Col sm={9}>
                                    <Field
                                        name="type"
                                        type="text"
                                        className={
                                            "form-control" +
                                            (errors.type && touched.type
                                                ? " is-invalid"
                                                : "")
                                        }
                                    />
                                    <ErrorMessage
                                        name="type"
                                        component="div"
                                        className="invalid-feedback"
                                    />
                                </Col>
                            </Form.Group>
                            {status && (
                                <Alert variant={alertVariant} className="mt-3">
                                    {status}
                                </Alert>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="form-group">
                                <Button
                                    variant="primary"
                                    disabled={isSubmitting}
                                    type="submit"
                                    className="me-2"
                                >
                                    {isSubmitting ? (
                                        <LoadingSpinner text="Creating checklist..." />
                                    ) : (
                                        "Create checklist"
                                    )}
                                </Button>
                                <Button
                                    variant="secondary"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};
