import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";

import { Alert, Button, Modal } from "react-bootstrap";
import { organisationService } from "../../services";
import { LoadingSpinner } from "../LoadingSpinner";

type Props = {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<any>>;
    organisationId: string;
    displayName?: string;
    setUserInteraction?: React.Dispatch<React.SetStateAction<any>>;
};

const OrganisationDisplayNameEdit: React.FC<Props> = ({
    show,
    setShow,
    organisationId,
    displayName,
    setUserInteraction,
}) => {
    const [alertVariant, setAlertVariant] = useState("danger");

    const handleClose = () => setShow(false);

    return (
        <Modal centered show={show} keyboard={false} onHide={handleClose}>
            <Formik
                initialValues={{
                    displayName: displayName ?? "",
                }}
                validationSchema={Yup.object().shape({
                    displayName: Yup.string().required("Username is required"),
                })}
                onSubmit={(values, { setStatus, setSubmitting }) => {
                    setStatus();
                    organisationService
                        .updateDisplayName(organisationId, values.displayName)
                        .then(
                            (response) => {
                                setSubmitting(false);
                                if (response.status !== "Failure") {
                                    if (setUserInteraction !== undefined) {
                                        setUserInteraction(true);
                                    }
                                    handleClose();
                                } else {
                                    setAlertVariant("danger");
                                    setStatus(response.message);
                                }
                            },
                            (error) => {
                                setAlertVariant("danger");
                                if (error.status === 400) {
                                    setStatus(error.title);
                                } else {
                                    setStatus(error);
                                }
                                setSubmitting(false);
                            }
                        );
                }}
            >
                {({ errors, touched, status, isSubmitting }) => (
                    <Form>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Display Name</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="form-group">
                                <label htmlFor="displayName">
                                    Display Name
                                </label>
                                <Field
                                    name="displayName"
                                    type="text"
                                    autoFocus
                                    className={
                                        "form-control" +
                                        (errors.displayName &&
                                        touched.displayName
                                            ? " is-invalid"
                                            : "")
                                    }
                                />
                                <ErrorMessage
                                    name="displayName"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </div>
                            {status && (
                                <Alert variant={alertVariant} className="mt-3">
                                    {status}
                                </Alert>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="form-group">
                                <Button
                                    variant="primary"
                                    disabled={isSubmitting}
                                    type="submit"
                                >
                                    {isSubmitting ? (
                                        <LoadingSpinner text="Updating..." />
                                    ) : (
                                        "Update"
                                    )}
                                </Button>
                            </div>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export { OrganisationDisplayNameEdit };
