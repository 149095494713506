import { IWebsiteSetting } from '@/customTypings/WebsiteSetting';
import { authHeader, handleResponse } from '../helpers';

export const websiteSettingService = {
    add,
    remove,
    restore,
    get,
    edit,
    getAll,
};

function add(websiteSetting: IWebsiteSetting) {
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(websiteSetting),
    };
    return fetch(`api/websitesetting/add`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function remove(websiteSettingId: string) {
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: authHeader(),
    };
    return fetch(`api/websitesetting/delete?id=${websiteSettingId}`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function restore(websiteSettingId: string) {
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: authHeader(),
    };
    return fetch(`api/websitesetting/restore?id=${websiteSettingId}`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function get(id: string) {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(`api/websitesetting/get?id=${id}`, requestOptions)
        .then(handleResponse)
        .then(response => {
           return response;
        });
}

function edit(websiteSetting: IWebsiteSetting) {
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(websiteSetting),
    };
    return fetch(`api/websitesetting/edit`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function getAll() {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: authHeader(),
        credentials: "include"
    };
    return fetch(`api/websitesetting/getall`, requestOptions)
        .then(handleResponse);
}