import { ITimesheet } from "@/customTypings/Timesheet";
import { IValueAndLabel } from "@/customTypings/ValueAndLabel";
import { ErrorMessage, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Modal, Row, Stack } from "react-bootstrap";
import Select from "react-select";
import * as Yup from "yup";
import { allTimesheetTypes, TimesheetType } from "../../constants/TimesheetType";
import { jobService } from "../../services";
import { timesheetService } from "../../services/timesheet.service";
import { ReactSelectBootstrapStyle } from "../../styles/ReactSelectBootstrapStyle";
import { LoadingSpinner } from "../LoadingSpinner";

type Props = {
    show: boolean;
    onClose: (added: boolean) => void;
    jobId?: string;
};

const AddTimesheetModel: React.FC<Props> = ({ show, onClose, jobId }) => {
    const [alertVariant, setAlertVariant] = useState("danger");
    const [allJobs, setAllJobs] = useState<IValueAndLabel[]>();

    const handleClose = () => onClose(false);

    useEffect(() => {
        if (show) {
            jobService.getAllListItems().then((jobs) => {
                setAllJobs(jobs);
            });
        }
    }, [jobId, show]);

    return (
        <>
            {allJobs && (
                <Modal centered show={show} keyboard={false} onHide={handleClose}>
                    <Formik
                        initialValues={{
                            type: TimesheetType.Travel,
                            showSelectedJobId: false,
                            selectedJobId: "",
                        }}
                        validationSchema={Yup.object().shape({
                            type: Yup.number()
                                .oneOf(Object.values(TimesheetType) as number[], "Some custom error message here")
                                .required(),
                            showSelectedJobId: Yup.boolean(),
                            selectedJobId: Yup.string().when("showSelectedJobId", {
                                is: true,
                                then: Yup.string().required("Field is required"),
                            }),
                        })}
                        onSubmit={(values, { setStatus, setSubmitting, setFieldError }) => {
                            setStatus();
                            const newTimesheet: ITimesheet = {
                                type: values.type,
                                jobId: TimesheetType[values.type] === TimesheetType[TimesheetType.Work] ? values.selectedJobId : undefined,
                            };
                            timesheetService.add(newTimesheet).then(
                                (response) => {
                                    if (response.status !== "Failure") {
                                        onClose(true);
                                    } else {
                                        setAlertVariant("danger");
                                        setStatus(response.message);
                                    }
                                },
                                (error) => {
                                    setAlertVariant("danger");
                                    if (error.status === 400) {
                                        setStatus(error.title);
                                        setFieldError("name", error.errors.Name);
                                    } else {
                                        setStatus(error);
                                    }
                                    setSubmitting(false);
                                }
                            );
                        }}
                    >
                        {({ values, errors, status, touched, isSubmitting, handleSubmit, setFieldValue }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Modal.Header closeButton>
                                    <Modal.Title>New Task</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Stack gap={3}>
                                        <Form.Group as={Row} className="mb-3" controlId="costInput">
                                            <Form.Label column sm={3}>
                                                Type
                                            </Form.Label>
                                            <Col sm={9}>
                                                <Select
                                                    styles={ReactSelectBootstrapStyle}
                                                    className={"stateManagedSelect" + (errors.type && touched.type ? " is-invalid" : "")}
                                                    onChange={(value) => {
                                                        setFieldValue("type", value == null ? "" : value.value);
                                                        setFieldValue("showSelectedJobId", TimesheetType[value.value] === TimesheetType[TimesheetType.Work]);
                                                    }}
                                                    defaultValue={allTimesheetTypes.find((obj) => {
                                                        return obj.value.localeCompare(TimesheetType[values.type]) === 0;
                                                    })}
                                                    options={allTimesheetTypes.sort((a, b) => a.label.localeCompare(b.label))}
                                                />
                                                <ErrorMessage name="type" component="div" className="invalid-feedback" />
                                            </Col>
                                        </Form.Group>
                                        {TimesheetType[values.type] === TimesheetType[TimesheetType.Work] && (
                                            <Form.Group as={Row} className="mb-3" controlId="costInput">
                                                <Form.Label column sm={3}>
                                                    Job
                                                </Form.Label>
                                                <Col sm={9}>
                                                    <Select
                                                        styles={ReactSelectBootstrapStyle}
                                                        className={"stateManagedSelect" + (errors.selectedJobId && touched.selectedJobId ? " is-invalid" : "")}
                                                        onChange={(value) => setFieldValue("selectedJobId", value == null ? "" : value.value)}
                                                        isClearable
                                                        options={allJobs}
                                                    />
                                                    <ErrorMessage name="selectedJobId" component="div" className="invalid-feedback" />
                                                </Col>
                                            </Form.Group>
                                        )}
                                        {status && (
                                            <Alert variant={alertVariant} className="mt-3">
                                                {status}
                                            </Alert>
                                        )}
                                    </Stack>
                                </Modal.Body>
                                <Modal.Footer>
                                    <div className="form-group">
                                        <Button variant="primary" disabled={isSubmitting} type="submit" className="me-2">
                                            {isSubmitting ? <LoadingSpinner text="Adding..." /> : "Add Timesheet"}
                                        </Button>
                                        <Button variant="secondary" onClick={handleClose}>
                                            Cancel
                                        </Button>
                                    </div>
                                </Modal.Footer>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            )}
        </>
    );
};

export { AddTimesheetModel };
