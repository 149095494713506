import { IAlert } from "@/customTypings/Alert";
import { faCheck, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Alert, Button, Col, Container, Row, Stack, Tab, Tabs } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { EditLeadModel } from "../../components/Models/EditLeadModel";
import { customerService, leadService } from "../../services";
import HHTRow from "../../shared/HHTRow";
import { CustomerProductsPage } from "../CustomerDashboard/CustomerProductsPage";
import { CustomerTasksPage } from "../CustomerDashboard/CustomerTasksPage";


const LeadDetailPage = () => {
  let params = useParams();

  const [lead, setLead] = useState<any>();
  const [showEditLead, setShowEditLead] = useState(false);
  const [notes, setNotes] = useState<string>();
  const [status, setStatus] = useState<IAlert>();

  useEffect(() => {
    getFullLead();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFullLead = () => {
    if (params.id) {
      leadService.fullLead(params.id).then((lead) => {
        setLead(lead);
        setNotes(lead.notes);
      });
    }
  };
  const convertToCustomer = async () => {
    const response = window.confirm(
      "Do you want to convert lead into customer? "
    );
    if (response) {
      try {
        const customer = await customerService.add({
          ...lead,
          leadId: lead.id,
        });
        const customerContact = await leadService.addCustomerContact({
          ...lead,
          customerId: customer.id,
          isPrimaryContact: true,
        });
        if (
          customer.status === "Success" &&
          customerContact.status === "Success"
        ) {
            setStatus({
                text: "Lead has been converted to Customer",
                variant:"success"
            })
            setLead({...lead, isCustomer: true})
        }
         else {
            setStatus({
                text: customer.statusText || customerContact.statusText,
                variant:"danger"
            })
         }   
     
      } catch (error:any) {
        setStatus({
            text: error.toString(),
            variant:"danger"
        })
      }
    }
  };

  const saveNotes = () => {
    leadService.edit({ ...lead, notes: notes }).then((response)=>{
      if(response.status!=="Failure")
      {
        setStatus({
          variant:"success",
          text: "Notes updated"
        })
      }
      else {
        setStatus({
          variant:"danger",
          text: response.message
        })
      }
    })
  };

  return (
    <Container fluid>
      <Helmet>
        <title>Lead Information</title>
      </Helmet>
      {lead && (
        <>
          <h1 className="my-3">
            {lead.name ? `${lead.name}` : "N/A"}
            <span className="text-primary">
              {lead.leadNumber ? `${lead.leadNumber}` : "N/A"}
            </span>
            <FontAwesomeIcon
              icon={faPenToSquare}
              className="onclick-hover"
              onClick={() => {
                setShowEditLead(true);
              }}
            />
          </h1>
          <Stack direction="horizontal" gap={3}>
            <h3 className="my-3">Lead Information</h3>
            <Button
              disabled={lead.isCustomer}
              variant="primary"
              className="ms-auto"
              onClick={convertToCustomer}
            >
              <FontAwesomeIcon
                fixedWidth={true}
                icon={faCheck}
                className="me-2"
              />
              Convert to customer
            </Button>
          </Stack>
          <HHTRow label="Name" value={lead.name} />
          <HHTRow label="Department" value={lead.department} />
          <HHTRow label="Email" value={lead.email} />
          <HHTRow label="Phone" value={lead.phone} />
          <HHTRow label="Address" value={<a href={`https://maps.google.com/?q=${lead.address}`} target="_blank" rel="noreferrer" > {lead.address}</a>} />
          <HHTRow label="Date Of Inquiry" value={dayjs(lead.dateOfInquiry).format("ddd DD MMM YYYY")} />
          <Row className="mt-5">
            <Col md={3}>Notes</Col>
            <Col md={9}>
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Control
                    as="textarea"
                    rows={2}
                    value={notes}
                    onChange={(event) => setNotes(event.target.value)}
                  />
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <Stack direction="horizontal" gap={3}>
            <div className="ms-auto">
              <Button type="submit" variant="primary" onClick={saveNotes}>
                Save notes
              </Button>
            </div>
          </Stack>

          {status && (
            <Alert variant={status?.variant} className="mt-3">
              {status?.text}
            </Alert>
          )}
            <Row>
                <Col>
                    <Tabs defaultActiveKey="required" className="mb-3" mountOnEnter>
                        <Tab eventKey="required" title="Tasks">
                            <CustomerTasksPage customerNumber={lead.customerNumber ? lead.customerNumber: "######" } customerId={lead.customerId} />
                        </Tab>
                        <Tab eventKey="due" title="Products">
                            <CustomerProductsPage
                                customerId={lead.customerId}
                                customerNumber={lead.customerNumber ? lead.customerNumber : "######"}
                            />
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
          <EditLeadModel
            show={showEditLead}
            leadId={lead.id ?? ""}
            onClose={() => {
              getFullLead();
              setShowEditLead(false);
            }}
          />
        </>
      )}
    </Container>
  );
};

export { LeadDetailPage };
