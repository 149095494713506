import {
  authHeader,
  authHeaderNoContentType,
  handleResponse,
} from "../helpers";

export const documentService = {
  getFolders,
  getDocuments,
  getFolderByPath,
  createFolder,
  uploadFile,
  removeDocument,
  removeFolder,
  getDocumentsInFolderRecursive,
};

function getFolders(taskId?: string, parentFolderId?: string) {
  const data = {
    filter: { taskId, parentFolderId },
    offset: 0,
    count: 999999,
  };
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`api/folder/all`, requestOptions).then(handleResponse);
}

function getDocuments(folderId?: string) {
  const data = {
    filter: { folderId },
    offset: 0,
    count: 999999,
  };
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`api/document/all`, requestOptions).then(handleResponse);
}

function getFolderByPath(taskId?: string, fullPath?: string) {
  const requestOptions = { method: "GET", headers: authHeader() };
  var optionalParameters = "";
  if (taskId) optionalParameters += `taskId=${taskId}`;
  if (fullPath && fullPath.length) {
    if (optionalParameters.length > 0) {
      optionalParameters += `&`;
    }
    optionalParameters += `path=${encodeURIComponent(fullPath)}`;
  }

  return fetch(
    `api/folder/getbypath` +
      (optionalParameters.length > 0 ? "?" + optionalParameters : ""),
    requestOptions
  ).then(handleResponse);
}

function createFolder(parentFolderId: string | null | undefined, name: string,taskId: string) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ name, parentFolderId,taskId }),
  };
  return fetch(`api/folder/add`, requestOptions).then(handleResponse);
}

function uploadFile(folderId: string, files: File[], entityId: string) {
  var promises = files.map((file) => {
    var data = new FormData();
    data.append("FileContent", file);
    data.append("FolderId", folderId);
    data.append("EntityId", entityId);

    const requestOptions = {
      method: "POST",
      headers: authHeaderNoContentType(), // Important if sending form data - standard authHeader adds application/json content type which breaks multipart form data
      body: data,
    };

    return fetch(`api/document/add`, requestOptions).then(handleResponse);
  });

  return Promise.all(promises);
}

function removeDocument(id: string) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };
  return fetch(`api/document/delete?id=${id}`, requestOptions).then(
    handleResponse
  );
}

function removeFolder(id: string) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };
  return fetch(`api/folder/delete?id=${id}`, requestOptions).then(
    handleResponse
  );
}

function getDocumentsInFolderRecursive(folderId: string) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `api/document/allinfolderrecursive?folderId=${folderId}`,
    requestOptions
  ).then(handleResponse);
}
