import { ILead } from '@/customTypings/Lead';
import { authHeader, handleResponse } from '../helpers';
import { ICustomerContact } from '../customTypings/CustomerContact';

export const leadService = {
    add,
    edit,
    get,
    getAll,
    getLast,
    fullLead,
    addCustomerContact,
};

function add(lead: ILead) {
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(lead),
    };
    return fetch(`api/lead/add`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function edit(lead: ILead) {
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(lead),
    };
    return fetch(`api/lead/edit`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function get(id: string) {
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: authHeader(),
    };
    return fetch(`api/lead/get?id=${id}`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function getLast() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch('api/lead/getLast', requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function fullLead(id: string) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(`api/lead/fulllead?id=${id}`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function addCustomerContact(customerContact: ICustomerContact) {
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: authHeader(),
        credentials: "include",
        body: JSON.stringify(customerContact),
    };
    return fetch(`/api/customercontact/add`, requestOptions)
        .then(handleResponse);
}

function getAll() {
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: authHeader(),
        credentials: "include"
    };
    return fetch(`api/lead/all`, requestOptions)
        .then(handleResponse);
}
