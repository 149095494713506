import { authHeader, handleResponse } from '../helpers';

export const accountService = {
    get,
    getAllIdentities,
    getIdentitiesAmount,
    updateDisplayName,
    updatePassword,
    passwordComplexityCheck
};

function get() {
    const requestOptions:RequestInit = { method: 'GET', credentials: 'include', headers: authHeader(), };
    return fetch(`api/account`, requestOptions).then(handleResponse);
}

function getAllIdentities() {
    const requestOptions:RequestInit = { method: 'GET', credentials: 'include', headers: authHeader(), };
    return fetch(`api/account/getallidentities`, requestOptions).then(handleResponse);
}

function getIdentitiesAmount() {
    const requestOptions:RequestInit = { method: 'GET', credentials: 'include', headers: authHeader(), };
    return fetch(`api/account/getidentitiesamount`, requestOptions).then(handleResponse);
}

function updateDisplayName(id: string, displayName: string){
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader()
    };
    return fetch(`api/account/updatedisplayname?displayname=${displayName}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function updatePassword(currentPassword: string, newPassword: string, confirmPassword: string){
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader(),
        body: JSON.stringify({ currentPassword, newPassword, confirmPassword })
    };
    return fetch(`api/account/updatepassword`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function passwordComplexityCheck(email: string | null, password: string){
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader(),
        body: JSON.stringify({ email, password })
    };
    return fetch(`api/account/passwordcomplexitycheck`, requestOptions).then(handleResponse);
}