import { Formik } from "formik";
import React, { useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import * as Yup from "yup";
import { LoadingSpinner } from "../components/LoadingSpinner";
import HHTDropDown from "./HHTDropDown";
import HHTField from "./HHTField";

type Field = {
  name: string;
  type: string;
  value?: string | undefined
};

type Props = {
  show: boolean;
  onClose: (updated: boolean) => void;
  fields: Field[];
  title: string;
  callback: (values: any) => Promise<any>;
  dropdown?: { placeholders: string[]; title: string, name:string };
};

const HHTModal: React.FC<Props> = ({
  show,
  onClose,
  fields,
  title,
  callback,
  dropdown,
}) => {
  const handleClose = () => onClose(false);
  const [alertVariant, setAlertVariant] = useState("danger");
  const initialFormValues: { [key: string]: string } = {};
  fields.forEach((field) => {
    initialFormValues[field.name] = field.value || "";
  });

  const generateValidationSchema = () => {
    const schemaObject: {
      [key: string]: Yup.StringSchema<string | undefined>;
    } = {};

    fields.forEach((field) => {
      schemaObject[field.name] = Yup.string().required(
        `${field.name} is required`
      );
    });

    return Yup.object().shape(schemaObject);
  };

  return (
    <Modal centered show={show} keyboard={false} onHide={handleClose}>
      <Formik
        initialValues={{
          ...initialFormValues,
        }}
        validationSchema={generateValidationSchema()}
        onSubmit={(values, { setStatus, setSubmitting, setFieldError }) => {
          setStatus();
          setSubmitting(false);
          const res = callback(values);
          res.then((response) => {
            if (response.status !== "Failure") {
              onClose(true);
            } else {
              setAlertVariant("danger");
              setStatus(response.message);
            }
          });
        }}
      >
        {({
          errors,
          status,
          setFieldValue,
          touched,
          isSubmitting,
          handleSubmit,
          values,
          handleChange,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {fields.map((field, index) => (
                <Row key={index} className="mt-3">
                  <Col md={3}>{field.name}</Col>
                  <Col md={9}>
                    <HHTField
                      type={field.type}
                      name={field.name}
                      errors={errors}
                      touched={touched}
                      values={values}
                      handleChange={handleChange}
                    />
                  </Col>
                </Row>
              ))}
              {dropdown && (
                <Row  className="mt-3">
                  <Col md={3}>{dropdown.name}</ Col>
                  <Col md={9}>
                    <HHTDropDown
                      placeholders={dropdown.placeholders}
                      setFieldValue={setFieldValue}
                      values={values}
                      clickHandler={(value: string) => {
                        values[dropdown.name] = value
                      }}
                      visibleTitle={true}
                      title={dropdown.title}
                      selected= {dropdown.title}
                    />
                  </Col>
                </Row>
              )}

              {status && (
                <Alert variant={alertVariant} className="mt-3">
                  {status}
                </Alert>
              )}
            </Modal.Body>
            <Modal.Footer>
              <div className="form-group">
                <Button
                  variant="primary"
                  disabled={isSubmitting}
                  type="submit"
                  className="me-2"
                >
                  {isSubmitting ? (
                    <LoadingSpinner text="Creating Category..." />
                  ) : (
                    "Save"
                  )}
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                  Cancel
                </Button>
              </div>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default HHTModal;
