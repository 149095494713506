import CustomerSignature from "../../components/Forms/CustomerSignature";
import { ErrorMessage, Field, Formik } from "formik";
import { Alert, Col, Container, Form, FormCheck, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";

type props = {
    currentForm: any
}

const AnnualServiceReportFromWithData = ({currentForm}:props) => {

    const serviceChecks = ['Visual plumbing check', 'Clean and buff spa shel',
        'Test jet functions', 'Clean headrests(if fitted)',
        'Test topside diverter/air valves(if fitted)', 'Clean and refit filter(s)',
        'Test ozone output(if fitted)', 'Clean outside of cabinet & steps',
        'Visual check of pump and heater seals', 'Vacuum inside cabinet(where access allows)',
        'Test all electrical components', 'Clean cover lifter(if fitted)',
        'Visually check circuit board', 'Clean cover and treat with UV protection',
        'Test RCD & isolator(if fitted)', 'Refill hot tub to correct level',
        'Complete bio film pipe clean', 'Add sanitizer',
        'Drain rinse and wet vacuum spa and jets', 'Balance pH level']
    const selectedServiceChecks = currentForm.serviceChecks ? currentForm.serviceChecks.split(',') : [];
    return (
        <>
            <Container fluid>
                <Row>
                    <Col>
                        <Helmet>
                            <title>Annual Service Report Form Data</title>
                        </Helmet>

                        <h1 className="my-3">Annual Service Report Form Data</h1>
                        <Formik
                            initialValues={currentForm}
                            enableReinitialize
                            onSubmit={() => { }}
                        >
                            {({
                                errors,
                                status,
                                touched,
                                isSubmitting,
                                handleSubmit,
                                values,
                                setStatus,
                                handleChange,
                                setFieldValue,
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group as={Row} className="mb-3" controlId="customerName">
                                        <Form.Label column sm={3}>
                                            Name of the customer
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="customerName" type="text" className={"form-control" + (errors.customerAddress && touched.customerAddress ? " is-invalid" : "")} />
                                            <ErrorMessage name="customerName" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="customerContactPostcode">
                                        <Form.Label column sm={3}>
                                            Postcode
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="customerContactPostcode" type="text" className={"form-control" + (errors.customerContactPostcode && touched.customerContactPostcode ? " is-invalid" : "")} />
                                            <ErrorMessage name="customerContactPostcode" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>


                                    <Form.Group as={Row} className="mb-3" controlId="sanitiser">
                                        <Form.Label column sm={3}>
                                            Sanitizer
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="sanitiser" type="text" className={"form-control" + (errors.sanitiser && touched.sanitiser ? " is-invalid" : "")} />
                                            <ErrorMessage name="sanitiser" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="phLevel">
                                        <Form.Label column sm={3}>
                                            pH Level
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="phLevel" type="text" className={"form-control" + (errors.phLevel && touched.phLevel ? " is-invalid" : "")} />
                                            <ErrorMessage name="phLevel" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="waterAppearance">
                                        <Form.Label column sm={3}>
                                            Water appearance
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="waterAppearance" type="text" className={"form-control" + (errors.waterAppearance && touched.waterAppearance ? " is-invalid" : "")} />
                                            <ErrorMessage name="waterAppearance" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="chemicalRecommendation">
                                        <Form.Label column sm={3}>
                                            Chemical recommendation
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="chemicalRecommendation" type="text" className={"form-control" + (errors.chemicalRecommendation && touched.chemicalRecommendation ? " is-invalid" : "")} />
                                            <ErrorMessage name="chemicalRecommendation" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <h4>Service Checks</h4>
                                    <Form.Group as={Row} className="mb-3" controlId="serviceChecks">
                                        <Form.Label column sm={3}></Form.Label>
                                        <Col sm={12} className="pt-2">
                                            <Row>
                                                {serviceChecks?.map((acc, index) => {
                                                    const isChecked = selectedServiceChecks.includes(acc);
                                                    return (
                                                        <Col key={index} sm={6} className="mb-2">
                                                            <label className="d-block">
                                                                <Field type="checkbox" name="serviceChecks" value={acc} className="me-2" disabled={!isChecked} checked={isChecked} />
                                                                {acc}
                                                            </label>
                                                        </Col>
                                                    );
                                                })}
                                            </Row>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="serviceRecommendation">
                                        <Form.Label column sm={3}>
                                            Service recommendation
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="serviceRecommendation" type="text" className={"form-control" + (errors.serviceRecommendation && touched.serviceRecommendation ? " is-invalid" : "")} />
                                            <ErrorMessage name="serviceRecommendation" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="filterConditon">
                                        <Form.Label column sm={3}>
                                            Filter conditon
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="filterConditon" type="text" className={"form-control" + (errors.filterConditon && touched.filterConditon ? " is-invalid" : "")} />
                                            <ErrorMessage name="filterConditon" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="filterRecommendation">
                                        <Form.Label column sm={3}>
                                            Filter recommendation
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="filterRecommendation" type="text" className={"form-control" + (errors.filterRecommendation && touched.filterRecommendation ? " is-invalid" : "")} />
                                            <ErrorMessage name="filterRecommendation" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="coverConditon">
                                        <Form.Label column sm={3}>
                                            Cover conditon
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="coverConditon" type="text" className={"form-control" + (errors.coverConditon && touched.coverConditon ? " is-invalid" : "")} />
                                            <ErrorMessage name="coverConditon" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="coverRecommendation">
                                        <Form.Label column sm={3}>
                                            Cover recommendation
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="coverRecommendation" type="text" className={"form-control" + (errors.coverRecommendation && touched.coverRecommendation ? " is-invalid" : "")} />
                                            <ErrorMessage name="coverRecommendation" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="coverLifterConditon">
                                        <Form.Label column sm={3}>
                                            Cover Lifter conditon
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="coverLifterConditon" type="text" className={"form-control" + (errors.coverLifterConditon && touched.coverLifterConditon ? " is-invalid" : "")} />
                                            <ErrorMessage name="coverLifterConditon" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="coverLifterRecommendation">
                                        <Form.Label column sm={3}>
                                            Cover Lifter recommendation
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="coverLifterRecommendation" type="text" className={"form-control" + (errors.coverLifterRecommendation && touched.coverLifterRecommendation ? " is-invalid" : "")} />
                                            <ErrorMessage name="coverLifterRecommendation" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>


                                    <Form.Group as={Row} className="mb-3" controlId="headrestConditon">
                                        <Form.Label column sm={3}>
                                            Hardest conditon
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="headrestConditon" type="text" className={"form-control" + (errors.headrestConditon && touched.headrestConditon ? " is-invalid" : "")} />
                                            <ErrorMessage name="headrestConditon" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="headrestRecommendation">
                                        <Form.Label column sm={3}>
                                            Hardest recommendation
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="headrestRecommendation" type="text" className={"form-control" + (errors.headrestRecommendation && touched.headrestRecommendation ? " is-invalid" : "")} />
                                            <ErrorMessage name="headrestRecommendation" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="visitEngineerName">
                                        <Form.Label column sm={3}>
                                            Engineer name
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="visitEngineerName" type="text" className={"form-control" + (errors.visitEngineerName && touched.visitEngineerName ? " is-invalid" : "")} />
                                            <ErrorMessage name="visitEngineerName" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="visitEngineerSigned">
                                        <Form.Label column sm={3}>
                                            Engieer Signed?
                                        </Form.Label>
                                        <Col sm={9}>
                                            <FormCheck
                                                type="switch"
                                                id="custom-switch"
                                                label=""
                                                checked={values.visitEngineerSigned}
                                                onChange={handleChange}
                                                name="visitEngineerSigned"
                                                style={{ pointerEvents: 'none' }}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="visitDate">
                                        <Form.Label column sm={3}>
                                            Date of visit
                                        </Form.Label>
                                        <Col sm={4}>
                                            <Field readOnly name="visitDate" type="datetime-local" className={"form-control" + (errors.visitDate && touched.visitDate ? " is-invalid" : "")} />
                                            <ErrorMessage name="visitDate" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="qualifyForLastingCarePlan">
                                        <Form.Label column sm={3}>
                                            Qualify for lasting care plan?
                                        </Form.Label>
                                        <Col sm={9}>
                                            <FormCheck
                                                type="switch"
                                                id="custom-switch"
                                                label=""
                                                checked={values.qualifyForLastingCarePlan}
                                                onChange={handleChange}
                                                name="qualifyForLastingCarePlan"
                                                style={{ pointerEvents: 'none' }}
                                            />
                                        </Col>
                                    </Form.Group>

                                    {status && (
                                        <Alert variant="danger" className="mt-3">
                                            {status}
                                        </Alert>
                                    )}
                                </Form>
                            )}
                        </Formik>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default AnnualServiceReportFromWithData;