import { ErrorMessage, Field, FieldAttributes } from "formik";
import React from "react";

interface FieldProps extends FieldAttributes<any> {}

const HTTField: React.FC<FieldProps> = ({
  type,
  name,
  errors,
  touched,
  values,
  handleChange,
  ...rest
}) => {
  return (
    <div>
      <Field
        name={name}
        type={type}
        className={`form-control ${
          errors[name] && touched[name] ? "is-invalid" : ""
        }`}
        value={values[name]}
        onChange={handleChange}
        {...rest}
      />
      <ErrorMessage name={name} component="div" className="invalid-feedback" />
    </div>
  );
};

export default HTTField;
