import { useEffect, useMemo, useState } from 'react';

const useFormikSubmitEffect = () => {
    const [hasSubmitted, setHasSubmitted] = useState(false);
    
    const FormikSubmitEffect = useMemo(() => {
        const FormikSubmitComponent = (props: {submitCount: number}) => {
            useEffect(() => {
                setHasSubmitted(props.submitCount !== 0)
            }, [props.submitCount]);
            return null;
        };
        return FormikSubmitComponent;
    }, [setHasSubmitted])

    return { FormikSubmitEffect, hasSubmitted };
};

export { useFormikSubmitEffect };
