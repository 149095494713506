import dayjs from "dayjs";
import { Card, Col, Container, Row } from "react-bootstrap";
import { usePDF } from "react-to-pdf";
import "../../styles/form.css";

type Props = {
    currentForm: any;
};

const DeliveryOrInstallationPdf: React.FC<Props> = ({ currentForm }) => {
    const { toPDF, targetRef } = usePDF({ filename: "page.pdf" });
    return (
        <div>
            <button onClick={() => toPDF()}>Download PDF</button>
            <div>
                <div>
                    <Container fluid="lg">
                        <Row ref={targetRef} className="download-pdf p-2 m-auto">
                            <Card className="p-5">
                                <Row className="m-0 py-2 border-dark border-bottom">
                                    <Col className="p-0" xs={4}>
                                        <img className="logo" src="/happy-hot-tubs-logo.svg" alt="" />
                                    </Col>
                                    <Col xs={8} className="p-0">
                                        <strong><h2 style={{ color: "#4A9CE8", fontFamily: "Arial, sans-serif", fontWeight: "bold" }} >You Deserve Happy</h2></strong>
                                        <div style={{ fontFamily: "Arial, sans-serif" }}>Showrooms across the South & Midlands</div>
                                        <div>Tel: 02380 812769 | web: www.happyhottubs.co.uk | email: admin@happyhottubs.co.uk</div>
                                    </Col>
                                </Row>
                                <Row className="m-0 py-2 border-dark border-bottom">
                                    <Col xs={4} className="p-0">
                                        <h4 className="text-decoration-underline" style={{ fontFamily: "Arial, sans-serif", fontWeight: "bold" }}>Billing Address</h4>
                                        <div><p>{currentForm?.customerContactTitle || "Mr."}&emsp;{currentForm?.customerContactName}</p></div>
                                        <div>{currentForm?.customerAddress}</div>
                                        <div></div>
                                    </Col>
                                    <Col xs={4} className="p-0">
                                        <h4 className="text-decoration-underline" style={{ fontFamily: "Arial, sans-serif", fontWeight: "bold", whiteSpace: "nowrap" }}>Delivery Address</h4>
                                        <strong>(if different)</strong>
                                        <div></div>
                                    </Col>
                                    <Col xs={4} className="p-0">
                                        <h4 className="text-decoration-underline" style={{ fontFamily: "Arial, sans-serif", fontWeight: "bold" }}>Contact details</h4>
                                        <Row className="m-0">
                                            <Col xs={6} className="p-0">
                                                <strong>Home Tel</strong>
                                            </Col>
                                            <Col xs={6} className="p-0">
                                                {currentForm?.customerHomeTel}
                                            </Col>
                                        </Row>
                                        <Row className="m-0">
                                            <Col xs={6} className="p-0">
                                                <strong>Mobile</strong>
                                            </Col>
                                            <Col xs={6} className="p-0">
                                                {currentForm?.customerMobileTel}
                                            </Col>
                                        </Row>
                                        <Row className="m-0">
                                            <Col xs={6} className="p-0">
                                                <strong>Work Tel</strong>
                                            </Col>
                                            <Col xs={6} className="p-0">
                                                {currentForm?.customerWorkTel}
                                            </Col>
                                        </Row>
                                        <Row className="m-0">
                                            <Col xs={6} className="p-0">
                                                <strong>Other Tel</strong>
                                            </Col>
                                            <Col xs={6} className="p-0">
                                                {currentForm?.customerOtherTel}
                                            </Col>
                                        </Row>
                                        <Row className="m-0">
                                            <Col xs={6} className="p-0">
                                                <strong>Email</strong>
                                            </Col>
                                            <Col xs={6} className="p-0">
                                                {currentForm?.customerEmail}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="m-0 py-2 border-dark border-bottom">
                                    <Col xs={4} className="p-0">
                                        <h5 className="text-decoration-underline">Spa Details</h5>
                                        <Row className="m-0">
                                            <Col xs={6} className="p-0">
                                                <strong>Order No</strong>
                                            </Col>
                                            <Col xs={6} className="p-0">
                                                {currentForm?.orderNumber}
                                            </Col>
                                        </Row>
                                        <Row className="m-0">
                                            <Col xs={6} className="p-0">
                                                <strong>Sales Person</strong>
                                            </Col>
                                            <Col xs={6} className="p-0">
                                                {currentForm?.salesPersonName}
                                            </Col>
                                        </Row>
                                        <Row className="m-0">
                                            <Col xs={6} className="p-0">
                                                <strong>Spa Model</strong>
                                            </Col>
                                            <Col xs={6} className="p-0">
                                                {currentForm?.productModel}
                                            </Col>
                                        </Row>
                                        <Row className="m-0">
                                            <Col xs={6} className="p-0">
                                                <strong>Colour</strong>
                                            </Col>
                                            <Col xs={6} className="p-0">
                                                {currentForm?.productColour}
                                            </Col>
                                        </Row>
                                        <Row className="m-0">
                                            <Col xs={6} className="p-0">
                                                <strong>Cabinet</strong>
                                            </Col>
                                            <Col xs={6} className="p-0">
                                                {currentForm?.productCabinet}
                                            </Col>
                                        </Row>
                                        <Row className="m-0">
                                            <Col xs={6} className="p-0">
                                                <strong>Condition</strong>
                                            </Col>
                                            <Col xs={6} className="p-0">
                                                {currentForm?.productCondition}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={4} className="p-0">
                                        <h5 className="text-decoration-underline">Other Items</h5>

                                    </Col>
                                    <Col
                                        xs={4}
                                        className="p-0 py-3 d-flex flex-column justify-content-between"
                                    >
                                        <div>
                                            <div><strong>Comments: </strong>{currentForm?.productComments}</div>
                                        </div>
                                        <div>
                                            <div><strong>Access Notes: </strong>{currentForm?.accessNotes}</div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="m-0 py-2 border-dark border-bottom">
                                    <Col xs={6} className="p-0">
                                        <h5 className="text-decoration-underline">Payment Details</h5>
                                        <Row className="m-0">
                                            <Col xs={6} className="p-0">
                                                <strong>Balance</strong>
                                            </Col>
                                            <Col xs={6} className="p-0">
                                                {currentForm?.balance}
                                            </Col>
                                        </Row>
                                        <Row className="m-0">
                                            <Col xs={6} className="p-0">
                                                <strong>Payment Method</strong>
                                            </Col>
                                            <Col xs={6} className="p-0">
                                                {currentForm?.paymentMethod}
                                            </Col>
                                        </Row>
                                        <Row className="m-0">
                                            <Col xs={6} className="p-0">
                                                <strong>Date Balance Paid</strong>
                                            </Col>
                                            <Col xs={6} className="p-0">
                                                {dayjs(currentForm?.dateBalancePaid).format("ddd DD MMM YYYY")}
                                            </Col>
                                        </Row>
                                        <Row className="m-0">
                                            <Col xs={6} className="p-0">
                                                <strong>Finance Agreement Name</strong>
                                            </Col>
                                            <Col xs={6} className="p-0">
                                                {currentForm?.financeAgreementName}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={6} className="p-0">
                                        <div className="py-2 px-5 fw-bold">
                                            Retention of Title - All goods remain the property of Hot Tub
                                            Care Ltd until paid for in full
                                        </div>
                                        <Row className="m-0">
                                            <Col xs={3} className="p-0"></Col>
                                            <Col xs={9} className="p-0 fw-bold">
                                                If finance sale a Hitachi Satisfaction note must be signed.
                                                Please note Hitachi will only accept a signature from the
                                                person named on the finance agreement.
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="m-0 py-2 border-dark border-bottom">
                                    <Col xs={10} className="p-0">
                                        <h5>Comments</h5>
                                        <div>{currentForm?.visitComments}</div>
                                    </Col>
                                    <Col xs={2} className="p-0">
                                        <div className="d-flex justify-content-between">
                                            <div>HPW O-Care:</div>
                                            <div>{currentForm?.servicePlanHPWOCare ? "Yes" : "No"}</div>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <div>HPW Silk:</div>
                                            <div>{currentForm?.servicePlanHPWSilk ? "Yes" : "No"}</div>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <div>Lasting Care:</div>
                                            <div>{currentForm?.servicePlanLC ? "Yes" : "No"}</div>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <div>No Pump O/SB:</div>
                                            <div>{currentForm?.servicePlanNoPumpOSB ? "Yes" : "No"}</div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="m-0 py-2 border-dark border-bottom">
                                    <h5 className="text-decoration-underline">Engineer Section</h5>
                                    <Row className="m-0">
                                        <Col xs={8} className="p-0">
                                            <div className="d-flex gap-2">
                                                <div>Engineer Name(s):</div>
                                                <div>{currentForm?.engineerNameList}</div>
                                            </div>
                                        </Col>
                                        <Col xs={4} className="p-0">
                                            <div className="d-flex gap-2 justify-content-around">
                                                <div>Date:</div>
                                                <div>{dayjs(currentForm?.engineerDate).format("ddd DD MMM YYYY")}</div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="d-flex gap-2">
                                        <strong>Serial Number (if applicable):</strong>
                                        <div>{currentForm?.productSerialNumber}</div>
                                    </div>
                                    <div className="d-flex gap-2">
                                        <strong>Access Clear?</strong>
                                        <div>{currentForm?.accessClear ? "Yes" : "(add details below, continue on separate sheet if necessary)"}</div>
                                    </div>
                                    <div className="d-flex gap-2">
                                        <div>Comments</div>
                                        <div>{currentForm?.accessComments}</div>
                                    </div>
                                </Row>
                                <Row className="m-0 py-2 border-dark border-bottom">
                                    <h5 className="text-decoration-underline">Customer Section</h5>
                                    <strong>
                                        **Please read important notes overleaf before signing**
                                    </strong>
                                    <div>
                                        I, the customer, confirm my delivery has been completed to my
                                        satisfaction & all items are in good order, unless stated
                                        otherwise
                                    </div>
                                    <Row className="m-0 py-2">
                                        <Col xs={4} className="p-0">
                                            <Row className="m-0">
                                                <Col xs={6} className="p-0">
                                                    <div>Customer Name:</div>
                                                </Col>
                                                <Col xs={6} className="p-0">
                                                    <div>{currentForm?.customerFullName}</div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={4}>
                                            <Row>
                                                <Col xs={6}>
                                                    <p className="m-0" >Customer signature:</p>
                                                </Col>
                                                <Col xs={6}>
                                                    <p className="m-0" ><img height={35} width={120} src={currentForm?.customerSignature} alt="signature" /></p>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={4} className="p-0">
                                            <Row className="m-0">
                                                <Col xs={4} className="text-end p-0">
                                                    <p>Date:</p>
                                                    <p></p>
                                                </Col>
                                                <Col xs={8} className="p-0">
                                                    <p>{currentForm?.completedTime ? dayjs(currentForm?.completedTime).format("ddd DD MMM YYYY") : ""}</p>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <div>
                                        <p>Comments:.................................................................................................................................................................................................................
                                            ...................................................................................................................................................................................................................................................................
                                            ..............................................................................................................................................................................</p>
                                        <p></p>
                                    </div>
                                </Row>
                                <div className="heading" style={{ width: "100%" }}>
                                    <p>1 copy to be retained by the customer, and 1 copy to be retained by the engineers</p>
                                </div>
                            </Card>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    );
}

export default DeliveryOrInstallationPdf;