import { IAlert } from "@/customTypings/Alert";
import { IContactFull } from "@/customTypings/ContactFull";
import { ICustomerFull } from "@/customTypings/CustomerFull";
import { faPenToSquare, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Alert, Button, Col, Container, Row, Stack, Tab, Tabs } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { AddCustomerContactModel } from "../../components/Models/AddCustomerContactModel";
import { EditCustomerModel } from "../../components/Models/EditCustomerModel";
import { customerService } from "../../services";
import { CustomerContactDetails } from "./CustomerContactDetails";
import { CustomerProductsPage } from "./CustomerProductsPage";
import { CustomerTasksPage } from "./CustomerTasksPage";

const CustomerDashboard = () => {
  let params = useParams();

  const [customer, setCustomer] = useState<ICustomerFull>();
  const [customerContacts, setCustomerContacts] = useState<IContactFull[]>();
  const [showEditCustomer, setShowEditCustomer] = useState(false);
  const [showAddContact, setShowAddContact] = useState(false);
  const [notes, setNotes] = useState<string>();
  const [status, setStatus] = useState<IAlert>();

  useEffect(() => {
    getCustomer();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  useEffect(() => {
    if (customer && params.id) {
      customerService.getAllContacts(params.id).then((customerContacts) => {
        setCustomerContacts(customerContacts);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  function getCustomer() {
    if (params.id) {
      customerService.getFull(params.id).then((customer) => {
        setCustomer(customer);
        setNotes(customer.notes);
      });
    }
  }

  const saveNotes = () => {
    customerService.edit({ ...customer, notes: notes }).then((response) => {
      if (response.status !== "Failure") {
        setStatus({
          variant: "success",
          text: "Notes updated",
        });
      } else {
        setStatus({
          variant: "danger",
          text: response.message,
        });
      }
    });
  };

  return (
    <Container fluid>
      <Helmet>
        <title>Customer Dashboard</title>
      </Helmet>
      {customer && (
        <>
          <h1 className="my-3">
            {customer.name ? `${customer.name}` : "N/A"}{" "}
            <span className="text-primary">
              {customer.customerNumber ? `${customer.customerNumber}` : "N/A"}
            </span>{" "}
            <FontAwesomeIcon
              icon={faPenToSquare}
              className="onclick-hover"
              onClick={() => {
                setShowEditCustomer(true);
              }}
            />
          </h1>

          <Stack direction="horizontal" gap={3}>
            <h3 className="my-3">Contacts</h3>
            <Button
              variant="secondary"
              className="ms-auto"
              onClick={() => setShowAddContact(true)}
            >
              <FontAwesomeIcon
                fixedWidth={true}
                icon={faPlus}
                className="me-2"
              />
              Add contact
            </Button>
          </Stack>
          <Row xs={1} md={1} lg={2} xl={3} className="g-3">
            {customerContacts?.map((contact) => (
              <Col key={contact.customerContactId}>
                <CustomerContactDetails
                  key={contact.customerContactId}
                  customerId={customer.id ?? ""}
                  contact={contact}
                  isBusiness={customer.isBusiness}
                  onUpdate={() => getCustomer()}
                />
              </Col>
            ))}
          </Row>
          <Row className="mt-5">
            <Col md={3}>Notes</Col>
            <Col md={9}>
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Control
                    as="textarea"
                    rows={2}
                    value={notes}
                    onChange={(event) => setNotes(event.target.value)}
                  />
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <Stack direction="horizontal" gap={3}>
            <div className="ms-auto">
              <Button type="submit" variant="primary" onClick={saveNotes}>
                Save notes{" "}
              </Button>
            </div>
          </Stack>
          {status && (
            <Alert variant={status?.variant} className="mt-3">
              {status?.text}
            </Alert>
          )}
          <Row>
            <Col>
              <Tabs defaultActiveKey="required" className="mb-3" mountOnEnter>
                <Tab eventKey="required" title="Tasks">
                  <CustomerTasksPage customerNumber={customer.customerNumber} customerId={customer.id} />
                </Tab>
                <Tab eventKey="due" title="Products">
                  <CustomerProductsPage
                    customerId={customer.id}
                    customerNumber={customer.customerNumber}
                  />
                </Tab>
              </Tabs>
            </Col>
          </Row>
          <EditCustomerModel
            show={showEditCustomer}
            customerId={customer.id ?? ""}
            onClose={() => {
              getCustomer();
              setShowEditCustomer(false);
            }}
          />
          <AddCustomerContactModel
            show={showAddContact}
            customer={customer}
            onClose={() => {
              getCustomer();
              setShowAddContact(false);
            }}
          />
        </>
      )}
       
    </Container>
  );
};

export { CustomerDashboard };
