import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { ErrorMessage, Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { taskService } from "../../services";
dayjs.extend(utc);


type Props = {
    show: boolean;
    onClose: (added: boolean) => void;
    taskId?: string;
};

const ChangeTaskDates: React.FC<Props> = ({ show, onClose, taskId }) => {
    const [alertVariant, setAlertVariant] = useState("danger");
    const [currentTask, setCurrentTask] = useState<any>();

    useEffect(() => {
        getTask();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getTask = () => {
        if (taskId) {
            taskService.get(taskId).then((task) => {
                setCurrentTask(task);
            });
        }
    }
    const handleClose = () => onClose(false);


    return (
        <Modal centered show={show} keyboard={false} onHide={handleClose}>
            <Formik
                initialValues={{
                    creationDate: "",
                    dueDate: "",
                    startTime: "",
                    endTime: ""
                }}
                onSubmit={(values, { setStatus, setSubmitting, setFieldError }) => {
                    setStatus();
                    setSubmitting(false);
                    taskService.edit({
                        ...currentTask,
                        creationDate: dayjs.utc(values.creationDate + " " + values.startTime).toDate(),
                        dueDate: dayjs.utc(values.dueDate + " " + values.endTime).toDate()
                    }).then(
                        (response) => {
                            setSubmitting(false);
                            if (response.status !== "Failure") {
                                onClose(true);
                            } else {
                                setAlertVariant("danger");
                                setStatus(response.message);
                            }
                        },
                        (error) => {
                            setAlertVariant("danger");
                            if (error.status === 400) {
                                setStatus(error.title);
                                setFieldError("productId", error.errors.ProductId);
                                setFieldError("customerId", error.errors.CustomerId);
                            } else {
                                setStatus(error);
                            }
                            setSubmitting(false);
                        }
                    );

                }}
            >
                {
                    ({ errors, status, touched, isSubmitting, handleSubmit, values, handleChange, setFieldValue }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Header closeButton>
                                <Modal.Title>Change Dates</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form.Group as={Row} className="mb-3" controlId="taskCreationDate">
                                    <Form.Label column sm={3}>
                                        Start Time
                                    </Form.Label>
                                    <Col sm={4}>
                                        <Field name="creationDate" type="date" className={"form-control" + (errors.creationDate && touched.creationDate ? " is-invalid" : "")} />
                                        <ErrorMessage name="creationDate" component="div" className="invalid-feedback" />
                                    </Col>
                                    <Col sm={4}>
                                        <Field name="startTime" type="time" className={"form-control" + (errors.startTime && touched.startTime ? " is-invalid" : "")} />
                                        <ErrorMessage name="startTime" component="div" className="invalid-feedback" />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="taskDueDate">
                                    <Form.Label column sm={3}>
                                        Due/End Time
                                    </Form.Label>
                                    <Col sm={4}>
                                        <Field name="dueDate" type="date" className={"form-control" + (errors.dueDate && touched.dueDate ? " is-invalid" : "")} />
                                        <ErrorMessage name="dueDate" component="div" className="invalid-feedback" />
                                    </Col>
                                    <Col sm={4}>
                                        <Field name="endTime" type="time" className={"form-control" + (errors.endTime && touched.endTime ? " is-invalid" : "")} />
                                        <ErrorMessage name="endTime" component="div" className="invalid-feedback" />
                                    </Col>
                                </Form.Group>

                                {status && (
                                    <Alert variant={alertVariant} className="mt-3">
                                        {status}
                                    </Alert>
                                )}
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="form-group">
                                    <Button variant="primary" disabled={isSubmitting} type="submit" className="me-2">
                                        {isSubmitting ? <LoadingSpinner text="Changing Dates..." /> : "Change Dates"}
                                    </Button>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Cancel
                                    </Button>
                                </div>
                            </Modal.Footer>
                        </Form>
                    )
                }
            </Formik>
        </Modal>
    );
};

export { ChangeTaskDates };
