import { ErrorMessage, Field, Formik } from "formik";
import React, { useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { IAccessories } from "../../customTypings/Accessories";
import { accessoriesService } from "../../services";

type Props = {
    show: boolean;
    onClose: (added: boolean) => void;
};

const AddNewAccessory: React.FC<Props> = ({ show, onClose }) => {
    const [alertVariant, setAlertVariant] = useState("danger");

    const handleClose = () => onClose(false);

    return (
        <Modal centered show={show} keyboard={false} onHide={handleClose}>
            <Formik
                initialValues={{
                    name: ""
                }}
                onSubmit={(values, { setStatus, setSubmitting, setFieldError }) => {
                    setStatus();
                    setSubmitting(false);
                    const newAccessory: IAccessories = {
                        id: undefined,
                        name: values.name
                    };
                    accessoriesService.add(newAccessory).then(
                        (response) => {
                            setSubmitting(false);
                            if (response.status !== "Failure") {
                                onClose(true);
                            } else {
                                setAlertVariant("danger");
                                setStatus(response.message);
                            }
                        },
                        (error) => {
                            setAlertVariant("danger");
                            if (error.status === 400) {
                                setStatus(error.title);
                            } else {
                                setStatus(error);
                            }
                            setSubmitting(false);
                        }
                    );
                }}
            >
                {({ errors, status, touched, isSubmitting, handleSubmit, values, handleChange, setFieldValue }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>New Accessory</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group as={Row} className="mb-3" controlId="accessoryName">
                                <Form.Label column md={3}>
                                    Accessory Name
                                </Form.Label>
                                <Col md={9}>
                                    <Field name="name" type="text" className={"form-control" + (errors.name && touched.name ? " is-invalid" : "")} value={values.name} />
                                    <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                </Col>
                            </Form.Group>
                            {status && (
                                <Alert variant={alertVariant} className="mt-3">
                                    {status}
                                </Alert>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="form-group">
                                <Button variant="primary" disabled={isSubmitting} type="submit" className="me-2">
                                    {isSubmitting ? <LoadingSpinner text="Creating accessory..." /> : "Create Accessory"}
                                </Button>
                                <Button variant="secondary" onClick={handleClose}>
                                    Cancel
                                </Button>
                            </div>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export { AddNewAccessory };
