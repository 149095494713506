import Spinner from 'react-bootstrap/esm/Spinner';
import Fade from 'react-bootstrap/Fade';

type Props = {
    children: React.ReactNode
    active: boolean,
}

const LoadingOverlay: React.FC<Props> = ({ children, active }) => {
    return (
        <div className='position-relative'>
            <>
                {children}
                <Fade in={active} mountOnEnter={true} unmountOnExit={true}>
                    <div>
                        <div className='position-absolute top-0 left-0 w-100 h-100 bg-secondary opacity-25' style={{zIndex: "1050"}}/>
                        <div className='position-absolute top-0 left-0 w-100 h-100 d-flex align-items-center justify-content-center text-center' style={{zIndex: "1050"}}>
                            <Spinner animation="border" variant="primary" />
                        </div>
                    </div>
                </Fade>
            </>
        </div>
    );
}

export { LoadingOverlay };
