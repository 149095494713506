import dayjs from "dayjs";
import { calendarEventService, taskService, userService } from "../../services";

type MappingProps = {
    body?: string;
    id?: string;
    actioner: string;
    oldStartTime?: Date | undefined;
    oldEndTime?: Date | undefined;
};

const mapEmailBody = async ({ body, id, actioner, oldStartTime, oldEndTime }: MappingProps): Promise<string> => {
    let currentEvent: any = {};
    let currentTask: any = {};
    let labelsOfIdentities: any = [];

    if (id) {
        currentEvent = await calendarEventService.get(id);
    }
    const appointmentTypes = await calendarEventService.getAllAppointmentTypeListItems();
    const eventType = appointmentTypes.find((item: any) => item.value === currentEvent.appointmentTypeId);
    if (currentEvent.taskId) {
        currentTask = await taskService.fullTask(currentEvent.taskId);
    }
    const allIdentityIds = await calendarEventService.getAllCalendarIdentitys(currentEvent.id);
    const allUsers = await userService.getAllListItems();
    allUsers.forEach((user: any) => {
        if (allIdentityIds?.includes(user.value)) {
            labelsOfIdentities.push(user.label);
        }
    });

    if (!body) {
        return "";
    }

    let replacements: Record<string, string | boolean> = {};
        replacements = {
            "{EventName}": currentEvent.title,
            "{EventType}": eventType.label,
            "{PersonnelList}": labelsOfIdentities ? labelsOfIdentities.map((label: string, index: number) => `${index + 1}. ${label}`).join(', ') : "No Personnel found",
            "{StartTime}": currentEvent.startDate,
            "{EndTime}": currentEvent.endDate,
            "{Actioner}": actioner,
            "{CreationDate}": currentEvent.startDate.split('T')[0],
            "{Location}": currentTask.location || "No located allocated",
            "{RecursiveInfoIfRecursive}": currentTask.recursiveInterval ? `Recurring on ${currentTask.days} every ${currentTask.recursiveInterval} weeks` : "Not Recursive",
            "{TaskNameIfRelated}": currentTask.name || "No task related",
            "{TaskURLIfRelated}": currentTask.taskURL || "No task related",
            "{NewStartTime}": currentEvent.startDate,
            "{NewEndTime}": currentEvent.endDate,
            "{OldStartTime}": oldStartTime ? oldStartTime.toString() : dayjs(new Date()).format("ddd DD MMM YYYY - HH:mm").toString(),
            "{OldEndTime}": oldEndTime ? oldEndTime.toString() : dayjs(new Date()).format("ddd DD MMM YYYY - HH:mm").toString(),
            "{ReScheduleDate}": dayjs(new Date()).format("ddd DD MMM YYYY - HH:mm").toString(),
            "{SingleEventOrSeriesIfRecursive}": currentTask.recursiveInterval ? `Recurring on ${currentTask.days} every ${currentTask.recursiveInterval} weeks` : "Single Event",
            "{OldRecursiveInfoIfRecursive}": currentTask.recursiveInterval ? `Recurring on ${currentTask.days} every ${currentTask.recursiveInterval} weeks` : "Not Recursive",
            "{NewRecursiveInfoIfRecursive}": currentTask.recursiveInterval ? `Recurring on ${currentTask.days} every ${currentTask.recursiveInterval} weeks` : "Not Recursive",
            "{CancellationDate}": dayjs(new Date()).format("ddd DD MMM YYYY - HH:mm").toString(),
        }


    return body.replace(/{EventName}|{EventType}|{PersonnelList}|{StartTime}|{EndTime}|{Actioner}|{CreationDate}|{Location}|{RecursiveInfoIfRecursive}|{TaskNameIfRelated}|{TaskURLIfRelated}|{OldStartTime}|{NewStartTime}|{OldEndTime}|{NewEndTime}|{ReScheduleDate}|{SingleEventOrSeriesIfRecursive}|{OldRecursiveInfoIfRecursive}|{NewRecursiveInfoIfRecursive}|{CancellationDate}/g, (match: any) => replacements[match] || match);
};
export default mapEmailBody;
