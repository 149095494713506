import { ICalendarEvent } from "@/customTypings/CalendarEvent";
import { IValueAndLabel } from "@/customTypings/ValueAndLabel";
import { useAtom } from "@dbeining/react-atom";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { ErrorMessage, Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import * as Yup from "yup";
import { userAtom } from "../../atoms/userAtom";
import { IRole } from "../../customTypings/role";
import { Role } from "../../helpers";
import { calendarEventService, emailTemplateService, userService } from "../../services";
import { ReactSelectBootstrapStyle } from "../../styles/ReactSelectBootstrapStyle";
import { LoadingSpinner } from "../LoadingSpinner";
import { IEvent } from "./EventCalendar";
import mapEmailBody from "./Mapping";
dayjs.extend(utc);

type Props = {
  show: boolean;
  onClose: (updated: boolean, removed: boolean) => void;
  eventToBeUpdated?: IEvent;
  identityIds?: string[];
};

const EventUpdate: React.FC<Props> = ({
  show,
  onClose,
  eventToBeUpdated,
  identityIds,
}) => {
  const [alertVariant, setAlertVariant] = useState("danger");
  const [allAppointmentTypes, setAllAppointmentTypes] =
    useState<IValueAndLabel[]>();
  const currentUser = useAtom(userAtom);
  const actioner = `${currentUser?.identity.displayName}: ${currentUser?.identity.email}`;

  const [emailTemplate, setEmailTemplate] = useState<any>({});
  const [eventTemplate, setEventTemplate] = useState<any>({});
  const [allIdentities, setAllIdentities] = useState<IValueAndLabel[]>();
  
  const handleClose = () => onClose(false, false);

  let getRoundedDate = (minutes: number, d = new Date()) => {
    let ms = 1000 * 60 * minutes; // convert minutes to ms
    let roundedDate = new Date(Math.round(d.getTime() / ms) * ms);

    return roundedDate;
    };
    useEffect(() => {
    calendarEventService
      .getAllAppointmentTypeListItems()
      .then((appointmentTypes) => setAllAppointmentTypes(appointmentTypes));
        userService
      .getAllListItems()
      .then((identities) => setAllIdentities(identities));
    }, []);

    useEffect(() => {
        emailTemplateService.getAll().then(async (response) => {
            const eventReScheduleTemplate = response.find((template: any) => template.name === "Event Reschedule");
            if (eventReScheduleTemplate) {
                setEmailTemplate(eventReScheduleTemplate);
                setEventTemplate(await emailTemplateService.getById(eventReScheduleTemplate.id));
            } else {
                console.error("Event Reschedule template not found");
            }
        });
    }, []);
    async function createEmailArray(eventTemplate: any, personals: any) {
        let emails = [];

        if (eventTemplate.eventCreator) {
            emails.push(currentUser?.identity.email);
        }
        if (eventTemplate.adminEmail) {
            emails.push("");
        }
        if (eventTemplate.personnel) {
            if (personals) {
                personals.map((obj: any) => emails.push(obj.email));
            };
        }
        return emails;
    }

    const emailBody = emailTemplate.body;
    const emailSubject = emailTemplate.subject;

  return (
    <Modal centered show={show} keyboard={false} onHide={handleClose} size="lg">
      <Formik
        initialValues={{
          title: eventToBeUpdated?.title ?? "",
          description: eventToBeUpdated?.description ?? "",
          appointmentTypeId: eventToBeUpdated?.appointmentTypeId ?? "",
          selectedIdentityIds: identityIds as string[],
          startDate: dayjs(
            eventToBeUpdated?.start ? eventToBeUpdated.start : new Date()
          ).format("YYYY-MM-DD"),
          startTime: dayjs(
            eventToBeUpdated?.start
              ? eventToBeUpdated.start
              : getRoundedDate(30, new Date())
          ).format("HH:mm"),
          endDate: dayjs(
            eventToBeUpdated?.end ? eventToBeUpdated.end : new Date()
          ).format("YYYY-MM-DD"),
          endTime: dayjs(
            eventToBeUpdated?.end
              ? eventToBeUpdated.end
              : getRoundedDate(30, new Date())
          ).format("HH:mm"),
          notes: "",
          isAllDay: eventToBeUpdated?.allDay ?? false,
          color: eventToBeUpdated?.color,
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string().required("Title is required"),
          description: Yup.string().required("Description is required"),
          appointmentTypeId: Yup.string().required(
            "Appointment Type is required"
          ),
          selectedIdentityIds: Yup.array()
            .min(1, "Minimum of 1 user is required")
            .required("required"),
        })}
        onSubmit={(values, { setStatus, setSubmitting, setFieldError }) => {
          setStatus();
          setSubmitting(true);
          const calendarEntry: ICalendarEvent = {
            id: eventToBeUpdated?.groupId,
            taskId:
              eventToBeUpdated?.taskId ?? (undefined as string | undefined), //values.taskId,
            title: values.title,
            description: values.description,
            appointmentTypeId: values.appointmentTypeId,
            startDate: dayjs
              .utc(values.startDate + " " + values.startTime)
              .toDate(),
            endDate: dayjs.utc(values.endDate + " " + values.endTime).toDate(),
            isAllDay: values.isAllDay,
            identityIds: values.selectedIdentityIds,
            jobId: eventToBeUpdated?.jobId ?? (undefined as string | undefined),
            color: values.color,
          };
          calendarEventService.update(calendarEntry).then(
            async (response) => {
              setSubmitting(false);
              if (response.status !== "Failure") {
                  const personals = allIdentities?.filter(user => calendarEntry.identityIds?.includes(user.value)).map((item: any) => ({
                      email: item?.label.split(": ")[1],
                  }));
                  const receiverEmails = await createEmailArray(eventTemplate, personals) as string[];
                  const updatedBody = await mapEmailBody({ body: emailBody, id: eventToBeUpdated?.groupId, actioner: actioner, oldStartTime: eventToBeUpdated?.start, oldEndTime: eventToBeUpdated?.end });
                  const updatedSubject = await mapEmailBody({ body: emailSubject, id: eventToBeUpdated?.groupId, actioner: actioner, oldStartTime: eventToBeUpdated?.start, oldEndTime: eventToBeUpdated?.end });
                  emailTemplateService.sendAutoEmail(updatedSubject, updatedBody, receiverEmails).then(
                      (response) => {
                          setStatus(response.message);
                      });
                onClose(true, false);
              } else {
                setAlertVariant("danger");
                setStatus(response.message);
              }
            },
            (error) => {
              setAlertVariant("danger");
              if (error.status === 400) {
                setStatus(error.title);
              } else {
                setStatus(error);
              }
              setSubmitting(false);
            }
          );
        }}
      >
        {({
          errors,
          status,
          touched,
          isSubmitting,
          handleSubmit,
          values,
          handleChange,
          setFieldValue,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Update Event</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group as={Row} className="mb-3" controlId="eventName">
                <Form.Label column sm={3}>
                  Event Name
                </Form.Label>
                <Col sm={9}>
                  <Field
                    name="title"
                    type="text"
                    className={
                      "form-control" +
                      (errors.title && touched.title ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="title"
                    component="div"
                    className="invalid-feedback"
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="eventDescription"
              >
                <Form.Label column sm={3}>
                  Event description
                </Form.Label>
                <Col sm={9}>
                  <Field
                    name="description"
                    type="text"
                    className={
                      "form-control" +
                      (errors.description && touched.description
                        ? " is-invalid"
                        : "")
                    }
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="invalid-feedback"
                  />
                </Col>
              </Form.Group>

              {currentUser?.identity.roles.some(
                (role: IRole) => role.name === Role.SystemAdmin
              ) && (
                <Form.Group as={Row} className="mb-3" controlId="eventColor">
                  <Form.Label column sm={3}>
                    Event Color
                  </Form.Label>
                  <Col sm={9}>
                    <Field
                      name="color"
                      type="color"
                      className={
                        "form-control" +
                        (errors.description && touched.description
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="color"
                      component="div"
                      className="invalid-feedback"
                    />
                  </Col>
                </Form.Group>
              )}

              {!eventToBeUpdated?.taskId && allAppointmentTypes && (
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="appointmentType"
                >
                  <Form.Label column sm={3}>
                    Appointment Type
                  </Form.Label>
                  <Col sm={9}>
                    <Select
                      styles={ReactSelectBootstrapStyle}
                      className="stateManagedSelect z-2 mb-2"
                      onChange={(value) => {
                        setFieldValue(
                          "appointmentTypeId",
                          value == null ? "" : value.value
                        );
                      }}
                      defaultValue={allAppointmentTypes.find(
                        (obj) =>
                          eventToBeUpdated?.appointmentTypeId === obj.value
                      )}
                      isClearable
                      options={allAppointmentTypes}
                    />
                    <ErrorMessage
                      name="appointmentTypeId"
                      component="div"
                      className="invalid-feedback"
                    />
                  </Col>
                </Form.Group>
              )}
              {allIdentities && (
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="selectedIdentityIds"
                >
                  <Form.Label column sm={3}>
                    Users
                  </Form.Label>
                  <Col sm={9}>
                    <Select
                      isMulti
                      closeMenuOnSelect={false}
                      placeholder={
                        <>
                          <FontAwesomeIcon icon={faUsers} /> Select users...
                        </>
                      }
                      styles={ReactSelectBootstrapStyle}
                      className="stateManagedSelect mb-2"
                      onChange={(value) => {
                        var identityIds: string[] = [];
                        for(let i=0;i<value.length;i++){
                          identityIds.push(value[i].value);
                        };
                        setFieldValue("selectedIdentityIds", identityIds);
                      }}
                      defaultValue={allIdentities.filter((obj) =>
                        identityIds?.includes(obj.value)
                      )}
                      isClearable
                      options={allIdentities}
                    />
                    <ErrorMessage
                      name="selectedIdentityIds"
                      component="div"
                      className="invalid-feedback"
                    />
                  </Col>
                </Form.Group>
              )}
              <Form.Group as={Row} className="mb-3" controlId="startDate">
                <Form.Label column sm={3}>
                  Start Date
                </Form.Label>
                <Col sm={9}>
                  <Field
                    name="startDate"
                    type="date"
                    className={
                      "form-control" +
                      (errors.startDate && touched.startDate
                        ? " is-invalid"
                        : "")
                    }
                  />
                  <ErrorMessage
                    name="startDate"
                    component="div"
                    className="invalid-feedback"
                  />
                </Col>
              </Form.Group>
              {!values.isAllDay && (
                <Form.Group as={Row} className="mb-3" controlId="startTime">
                  <Form.Label column sm={3}>
                    Start Time
                  </Form.Label>
                  <Col sm={9}>
                    <Field
                      name="startTime"
                      type="time"
                      className={
                        "form-control" +
                        (errors.startTime && touched.startTime
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="startTime"
                      component="div"
                      className="invalid-feedback"
                    />
                  </Col>
                </Form.Group>
              )}
              <Form.Group as={Row} className="mb-3" controlId="endDate">
                <Form.Label column sm={3}>
                  End Date
                </Form.Label>
                <Col sm={9}>
                  <Field
                    name="endDate"
                    type="date"
                    className={
                      "form-control" +
                      (errors.endDate && touched.endDate ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="endDate"
                    component="div"
                    className="invalid-feedback"
                  />
                </Col>
              </Form.Group>
              {!values.isAllDay && (
                <Form.Group as={Row} className="mb-3" controlId="endTime">
                  <Form.Label column sm={3}>
                    End Time
                  </Form.Label>
                  <Col sm={9}>
                    <Field
                      name="endTime"
                      type="time"
                      step="1800"
                      className={
                        "form-control" +
                        (errors.endTime && touched.endTime ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="endTime"
                      component="div"
                      className="invalid-feedback"
                    />
                  </Col>
                </Form.Group>
              )}
              <Form.Group as={Row} className="mb-3" controlId="updateCheckbox">
                <Col sm={{ span: 9, offset: 3 }}>
                  <Form.Check
                    type="checkbox"
                    name="isAllDay"
                    className={
                      "mt-1 form-check" +
                      (errors.isAllDay && touched.isAllDay ? " is-invalid" : "")
                    }
                    defaultChecked={values.isAllDay.valueOf()}
                    onChange={(e) => {
                      setFieldValue(
                        "isAllDay",
                        e.target.checked == null ? false : e.target.checked
                      );
                      if (e.target.checked.valueOf()) {
                        setFieldValue("startTime", "00:00");
                        setFieldValue("endTime", "00:00");
                      }
                    }}
                    label="All day event?"
                  />
                  <ErrorMessage
                    name="isAllDay"
                    component="div"
                    className="invalid-feedback"
                  />
                </Col>
              </Form.Group>
              {status && (
                <Alert variant={alertVariant} className="mt-3">
                  {status}
                </Alert>
              )}
            </Modal.Body>
            <Modal.Footer>
              <div className="form-group">
                <Button
                  variant="primary"
                  disabled={isSubmitting}
                  type="submit"
                  className="me-2"
                >
                  {isSubmitting ? (
                    <LoadingSpinner text="Updating task..." />
                  ) : (
                    "Update Event"
                  )}
                </Button>
                <Button
                  variant="danger"
                  onClick={() => {
                    onClose(false, true);
                  }}
                  className="me-2"
                >
                  Remove
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                  Cancel
                </Button>
              </div>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export { EventUpdate };
