import { faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { ColumnRender, FilterType, IColumn, IFilter, IPagedListAction } from "../../components/PagedList/Declarations";
import { PagedList } from "../../components/PagedList/PagedList";
import { accessoriesService } from "../../services";
import { AddNewAccessory } from "./AccessoriesAddPage";
import { EditAccessoryModel } from "./AccessoriesEditPage";

const AccessoriesListPage = () => {
    const [accessory, setAccessory] = useState<any>();
    const [showEditAccessory, setShowEditAccessory] = useState(false);
    const [showAddAccessory, setShowAddAccessory] = useState(false);
    const [refreshNumber, setRefeshNumber] = useState(0);

    const getAccessory = async (id: string) => {
        if (id) {
            const response = await accessoriesService.get(id);
            setAccessory(response);
        }
    }

    const handleAddAccessory = (added: boolean) => {
        if (added) {
            setRefeshNumber((refreshNumber) => refreshNumber + 1)
        }
        setShowAddAccessory(false);
    };
    const handleOpenAddAccessory = () => {
        setShowAddAccessory(true);
    };

    return (
        <>
            <Container fluid>

                <Row>
                    <Col>
                        <Helmet>
                            <title>Accessories</title>
                        </Helmet>

                        <Breadcrumb>
                            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/settings" }}>
                                Settings
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>Accessories</Breadcrumb.Item>
                        </Breadcrumb>

                        <h1 className="my-3">Accessories</h1>
                        <PagedList
                            columns={[
                                {
                                    member: "name",
                                    renderAs: ColumnRender.text,
                                    title: "Name",
                                    width: 200,
                                } as IColumn,
                            ]}
                            itemActions={[
                                {
                                    icon: faEdit,
                                    text: "Edit",
                                    onClick: (item) => {
                                        getAccessory(item.id);
                                        setShowEditAccessory(true);
                                    },
                                    primary: true,
                                } as IPagedListAction,
                            ]}
                            headActions={[

                                {
                                    icon: faPlus,
                                    text: "Add Accessory",
                                    onClick: handleOpenAddAccessory,
                                } as IPagedListAction,
                            ]}

                            filters={[
                                {
                                    title: "Name",
                                    member: "name",
                                    type: FilterType.text,
                                } as IFilter,
                            ]}
                            defaultSortBy="name"
                            getUrl="api/accessories/all"
                            rowFormatter={(item) => {
                                return null;
                            }}
                            persistanceKey="AccessoriesListPage"
                            refreshNumber={refreshNumber + 1}
                        />
                    </Col>
                </Row>
                <EditAccessoryModel
                    show={showEditAccessory}
                    currentAccessory={accessory}
                    onClose={() => {
                        getAccessory(accessory.id);
                        setRefeshNumber((refreshNumber) => refreshNumber + 1)
                        setShowEditAccessory(false);
                    }}
                />
            </Container>
            <AddNewAccessory show={showAddAccessory} onClose={handleAddAccessory} />
        </>
    );
};

export { AccessoriesListPage };

