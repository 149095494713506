import { useEffect, useState } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import CustomerSignature from '../../components/Forms/CustomerSignature';
import { formService } from '../../services';
import AnnualServiceReportFromWithData from './AnnualServiceReportFormWithData';
import DeliveryOrInstallationFormWithData from './DeliveryOrInstallationFormWithData';
import EndlessPoolsOrderFormWithData from './EndlessPoolsOrderFormWithData';
import HotTubOrderFormWithData from './HotTubOrderFormWithData';
import SiteVisitFormWithData from './SiteVisitFormWithData';
import SpaFreshReportFormWithData from './SpaFreshReportFormWithData';

type props = {
    isPublic: boolean,
}

function SigningForm({isPublic}:props) {
    const [alertVariant, setAlertVariant] = useState("danger");
    const [currentForm, setCurrentForm] = useState<any>({}); 
    const [sign, setSign] = useState<any>();
    const [url, setUrl] = useState<any>();

    const params = useParams();
    const formId = params.id;

    const navigate = useNavigate();

    useEffect(() => {
        if (!formId)
            return;
        
        if (isPublic){
            formService.getSignableById(formId).then(setCurrentForm);
        } else {
            formService.getById(formId).then(setCurrentForm);
        }
    }, [formId, isPublic]);

    const initialValues: any = {
        customerFullName: "",
        customerSignature: "",
        customerDeclaration: false,
        gdprConsent: false,
        newsletter: false,
        completedTime: null,
        signed: false,
    }

    const save = async (
        values: any,
        setStatus: any,
        setSubmitting: any,
        setFieldError: any,
        resetForm: any
    ) => {
        if(!isPublic || sign === undefined || sign === null || sign.isEmpty()){
            setSubmitting(false);
            setAlertVariant("danger");
            setStatus("Cannot submit form");
            return;
        }

        formService.signForm({
            ...currentForm,
            customerFullName: values.customerFullName,
            customerSignature: url ?? "",
            customerDeclaration: values.customerDeclaration,
            gdprConsent: values.gdprConsent,
            newsletter: values.newsletter,
            completedTime: new Date(),
            signed: true
        }).then(
            async (response) => {
                setSubmitting(false);
                if (response.status === "Failure") {
                    setAlertVariant("danger");
                    setStatus(response.message);
                } else {
                    navigate("/Portal/Form/"+formId, { state: { message: "Ok" } });
                }
            },
            (error) => {
                setAlertVariant("danger");
                if (error.status === 400) {
                    setStatus(error.title);
                } else {
                    setStatus(error);
                }
                setSubmitting(false);
            }
        );
    };
    return (
        <>
            <Container fluid>
                <Row>
                    <Col>
                        {currentForm.name === "Site Visit Form" && (<SiteVisitFormWithData currentForm={currentForm} />)}
                        {currentForm.name === "Delivery/Installation Form" && (<DeliveryOrInstallationFormWithData currentForm={currentForm} />)}
                        {currentForm.name === "Hot Tub Order Form" && (<HotTubOrderFormWithData currentForm={currentForm} />)}
                        {currentForm.name === "Endless Pools Order Form" && (<EndlessPoolsOrderFormWithData currentForm={currentForm} />)}
                        {currentForm.name === "Spa Fresh Service Report Form" && (<SpaFreshReportFormWithData currentForm={currentForm} />)}
                        {currentForm.name === "Annual Service Report Form" && (<AnnualServiceReportFromWithData currentForm={currentForm} />)}
                        {currentForm.name && <CustomerSignature
                            isPublic={isPublic}
                            initialValues={isPublic ? initialValues : currentForm}
                            save={save}
                            sign={sign}
                            setSign={setSign}
                            setUrl={setUrl}
                            alertVariant={alertVariant}
                        />}
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default SigningForm;
