import { ErrorMessage, Field, Formik } from "formik";
import { useEffect, useState } from "react";
import { Alert, Breadcrumb, Col, Container, Form, FormCheck, Row } from "react-bootstrap";

import CustomerSignature from "../../components/Forms/CustomerSignature";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
    accessoriesService
} from "../../services";

type props = {
    currentForm: any
}

const EndlessPoolsOrderFormWithData = ({currentForm}:props) => {
    const [allAccessories, setAllAcessories] = useState<any[]>();


    useEffect(() => {
        Promise.all([accessoriesService.getAll()]).then((response) => {
            setAllAcessories(response[0]);
        });
    }, []);

    const paymentMethods = ['Cash', 'Cheque', 'Card', 'BACS']
    const fundingChoices = ['Full payment prior to delivery', 'Finance terms']
    const customerReferences = ["Google reviews/internet search", "Happyhottubs.co.uk", "Recommendation", "Radio", "Social media", "Local adverts / Vans", "Show", "Other"]
    const features = ["Bluetooth speaker system", "Treadmill"]
    const coverOptions = ["Hard top cover (Black)  £1249", "Uprite lifter x2  £1198", "Cover Cradle x2  £1198", "End 2 end roll on cover  £4999", "Own Brand Roll on cover  £1999", "Cavana  (depending on size) £13995-£21995"]
    const accessories = allAccessories?.map(accessory => accessory.name);
    const servicePlans = ["EP Wellness Plan", "EP Wellness PLUS Plan"]
    const deliveryMethods = ["Manual delivery & commision (FREE)", "Crane/hiab delivery and commission - POA"]
    const electricalSupplies = ["32 amp", "24 amp + 32 amp", "2x 32 amp"]

    const selectedValues = currentForm.customerReferredBy ? currentForm.customerReferredBy.split(',') : [];
    const selectedPaymentMethods = currentForm.depositMethods ? currentForm.depositMethods.split(',') : [];
    const selectedFundingChoices = currentForm.fundingChoice ? currentForm.fundingChoice.split(',') : [];
    const selectedAccessories = currentForm.accessoryList ? currentForm.accessoryList.split(',') : [];
    const selectedDeliveryMethods = currentForm.deliveryMethod ? currentForm.deliveryMethod.split(',') : [];
    const addressComponents = currentForm.customerAddress ? currentForm.customerAddress.split(',') : [];
    const selectedFeatures = currentForm.features ? currentForm.features.split(',') : [];
    const selectedCoverOptions = currentForm.coverOptions ? currentForm.coverOptions.split(',') : [];
    const selectedServicePlans = currentForm.servicePlan ? currentForm.servicePlan.split(',') : [];
    return (
        <>
            <Container fluid>
                <Row>
                    <Col>
                        <Helmet>
                            <title>Endless Pools Order Form Data</title>
                        </Helmet>

                        <Breadcrumb>
                            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/formListPage" }}>
                                Forms
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>Endless Pools Order Form Data</Breadcrumb.Item>
                        </Breadcrumb>

                        <h1 className="my-3">Endless Pools Order Form Data</h1>
                        <Formik
                            initialValues={currentForm}
                            enableReinitialize
                            onSubmit={() => { }}
                        >
                            {({
                                errors,
                                status,
                                touched,
                                isSubmitting,
                                handleSubmit,
                                values,
                                setStatus,
                                handleChange,
                                setFieldValue,
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group as={Row} className="mb-3" controlId="orderDate">
                                        <Form.Label column sm={3}>
                                            Date the order is placed
                                        </Form.Label>
                                        <Col sm={4}>
                                            <Field readOnly name="orderDate" type="datetime-local" className={"form-control" + (errors.orderDate && touched.orderDate ? " is-invalid" : "")} />
                                            <ErrorMessage name="orderDate" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="orderNumber">
                                        <Form.Label column sm={3}>
                                            Order Number
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="orderNumber" type="text" className={"form-control" + (errors.orderNumber && touched.orderNumber ? " is-invalid" : "")} />
                                            <ErrorMessage name="orderNumber" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="taskId">
                                        <Form.Label column sm={3}>
                                            Associated Task
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="taskId" type="text" className={"form-control" + (errors.taskId && touched.taskId ? " is-invalid" : "")} />
                                            <ErrorMessage name="taskId" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="orderLocation">
                                        <Form.Label column sm={3}>
                                            Order Location
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="orderLocation" type="text" className={"form-control" + (errors.orderLocation && touched.orderLocation ? " is-invalid" : "")} />
                                            <ErrorMessage name="orderLocation" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="cofsent">
                                        <Form.Label column sm={3}>
                                            COF Sent?
                                        </Form.Label>
                                        <Col sm={9}>
                                            <FormCheck
                                                type="switch"
                                                id="custom-switch"
                                                label=""
                                                checked={values.cofSent}
                                                onChange={handleChange}
                                                name="cofsent"
                                                style={{ pointerEvents: 'none' }}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="cofLink">
                                        <Form.Label column sm={3}>
                                            COF Link
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="cofLink" type="text" className={"form-control" + (errors.cofLink && touched.cofLink ? " is-invalid" : "")} />
                                            <ErrorMessage name="cofLink" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="subtotal">
                                        <Form.Label column sm={3}>
                                            Sub Total
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="subtotal" placeholder="£" type="number" className={"form-control" + (errors.subtotal && touched.subtotal ? " is-invalid" : "")} />
                                            <ErrorMessage name="subtotal" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="orderTotal">
                                        <Form.Label column sm={3}>
                                            Order Total
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="orderTotal" placeholder="£" type="number" className={"form-control" + (errors.orderTotal && touched.orderTotal ? " is-invalid" : "")} />
                                            <ErrorMessage name="orderTotal" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="depositAmount">
                                        <Form.Label column sm={3}>
                                            Deposit Amount
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="depositAmount" placeholder="£" type="number" className={"form-control" + (errors.depositAmount && touched.depositAmount ? " is-invalid" : "")} />
                                            <ErrorMessage name="depositAmount" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="depositMethods">
                                        <Form.Label column sm={3}>Deposit Methods(s)</Form.Label>
                                        <Col sm={7} className="pt-2">
                                            {paymentMethods.map((ref, index) => {
                                                const isChecked = selectedPaymentMethods.includes(ref);
                                                return (
                                                    <label key={index} className="d-inline me-3">
                                                        <Field type="checkbox" name="depositMethods" value={ref} className="me-2" disabled={!isChecked} checked={isChecked} />
                                                        {ref}
                                                    </label>
                                                );
                                            })}
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="balance">
                                        <Form.Label column sm={3}>
                                            Balance after deposit
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="balance" placeholder="£" type="number" className={"form-control" + (errors.balance && touched.balance ? " is-invalid" : "")} />
                                            <ErrorMessage name="balance" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="fundingChoice">
                                        <Form.Label column sm={3}>Funding choice</Form.Label>
                                        <Col sm={9} className="pt-2">
                                            {fundingChoices.map((choice, index) => {
                                                const isChecked = selectedFundingChoices.includes(choice);
                                                return (
                                                    <label key={index} className="d-inline me-3">
                                                        <Field type="radio" name="fundingChoice" value={choice} className="me-2" disabled={!isChecked} checked={isChecked}
                                                        />
                                                        {choice}
                                                    </label>
                                                );
                                            })}
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="partExchangeValueToBeDeducted">
                                        <Form.Label column sm={3}>
                                            Part exchange value to be deducted
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="partExchangeValueToBeDeducted" placeholder="£" type="number" className={"form-control" + (errors.partExchangeValueToBeDeducted && touched.partExchangeValueToBeDeducted ? " is-invalid" : "")} />
                                            <ErrorMessage name="partExchangeValueToBeDeducted" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="salesPersonName">
                                        <Form.Label column sm={3}>
                                            Sales person Name
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="salesPersonName" type="text" className={"form-control" + (errors.salesPersonName && touched.salesPersonName ? " is-invalid" : "")} />
                                            <ErrorMessage name="salesPersonName" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="salesPersonEmail">
                                        <Form.Label column sm={3}>
                                            Sales person Email
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="salesPersonEmail" type="text" className={"form-control" + (errors.salesPersonEmail && touched.salesPersonEmail ? " is-invalid" : "")} />
                                            <ErrorMessage name="salesPersonEmail" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="comments">
                                        <Form.Label column sm={3}>
                                            General comments about the sale
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="comments" type="text" className={"form-control" + (errors.comments && touched.comments ? " is-invalid" : "")} />
                                            <ErrorMessage name="comments" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <h3>CUSTOMER DETAILS</h3>

                                    <Form.Group as={Row} className="mb-3" controlId="customerContactTitle">
                                        <Form.Label column sm={3}>
                                            Title
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="customerContactTitle" type="text" className={"form-control" + (errors.customerContactTitle && touched.customerContactTitle ? " is-invalid" : "")} />
                                            <ErrorMessage name="customerContactTitle" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="firstName">
                                        <Form.Label column sm={3}>
                                            First Name
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="firstName" type="text" className={"form-control" + (errors.firstName && touched.firstName ? " is-invalid" : "")} />
                                            <ErrorMessage name="firstName" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="lastName">
                                        <Form.Label column sm={3}>
                                            Last Name
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="lastName" type="text" className={"form-control" + (errors.lastName && touched.lastName ? " is-invalid" : "")} />
                                            <ErrorMessage name="lastName" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="postalCode">
                                        <Form.Label column sm={3}>
                                            Post Code
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="postalCode" type="text" value={addressComponents[5]} className={"form-control" + (errors.postalCode && touched.postalCode ? " is-invalid" : "")} />
                                            <ErrorMessage name="postalCode" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="addressLine1">
                                        <Form.Label column sm={3}>
                                            Address Line 1
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="addressLine1" type="text" value={addressComponents[0]} className={"form-control" + (errors.addressLine1 && touched.addressLine1 ? " is-invalid" : "")} />
                                            <ErrorMessage name="addressLine1" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="addressLine2">
                                        <Form.Label column sm={3}>
                                            Address Line 2
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Col sm={9}>
                                                <Field readOnly name="addressLine2" type="text" value={addressComponents[1]} className={"form-control" + (errors.addressLine2 && touched.addressLine2 ? " is-invalid" : "")} />
                                                <ErrorMessage name="addressLine2" component="div" className="invalid-feedback" />
                                            </Col>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="town">
                                        <Form.Label column sm={3}>
                                            Town/City
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="town" type="text" value={addressComponents[2]} className={"form-control" + (errors.town && touched.town ? " is-invalid" : "")} />
                                            <ErrorMessage name="town" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="county">
                                        <Form.Label column sm={3}>
                                            County
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="county" type="text" value={addressComponents[3]} className={"form-control" + (errors.salesPersonEmail && touched.salesPersonEmail ? " is-invalid" : "")} />
                                            <ErrorMessage name="county" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="country">
                                        <Form.Label column sm={3}>
                                            Country
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="country" type="text" value={addressComponents[4]} className={"form-control" + (errors.country && touched.country ? " is-invalid" : "")} />
                                            <ErrorMessage name="country" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="emailAddress">
                                        <Form.Label column sm={3}>
                                            Email Address
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="customerEmail" type="text" className={"form-control" + (errors.emailAddress && touched.emailAddress ? " is-invalid" : "")} />
                                            <ErrorMessage name="emailAddress" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="homeTelephone">
                                        <Form.Label column sm={3}>
                                            Home Telephone
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="customerHomeTel" type="text" className={"form-control" + (errors.homeTelephone && touched.homeTelephone ? " is-invalid" : "")} />
                                            <ErrorMessage name="homeTelephone" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="mobileTelephone">
                                        <Form.Label column sm={3}>
                                            Mobile Telephone
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="customerMobileTel" type="text" className={"form-control" + (errors.mobileTelephone && touched.mobileTelephone ? " is-invalid" : "")} />
                                            <ErrorMessage name="mobileTelephone" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="customerReferredBy">
                                        <Form.Label column sm={3}>How did the customer hear about us?</Form.Label>
                                        <Col sm={9} className="pt-2">
                                            <Row>
                                                {customerReferences.map((acc, index) => {
                                                    const isChecked = selectedValues.includes(acc);
                                                    return (
                                                        <Col key={index} sm={6} className="mb-2">
                                                            <label className="d-block">
                                                                <Field type="checkbox" name="customerReferredBy" value={acc} className="me-2" disabled={!isChecked} checked={isChecked} />
                                                                {acc}
                                                            </label>
                                                        </Col>
                                                    );
                                                })}
                                            </Row>
                                        </Col>
                                    </Form.Group>

                                    <h3>PRODUCT</h3>
                                    <Form.Group as={Row} className="mb-3" controlId="productModel">
                                        <Form.Label column sm={3}>
                                            Product Model
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="productModel" type="text" className={"form-control" + (errors.productModel && touched.productModel ? " is-invalid" : "")} />
                                            <ErrorMessage name="productModel" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="productShellColour">
                                        <Form.Label column sm={3}>
                                            Product Shell Colour
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="productShellColour" type="text" className={"form-control" + (errors.productShellColour && touched.productShellColour ? " is-invalid" : "")} />
                                            <ErrorMessage name="productShellColour" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="productCabinet">
                                        <Form.Label column sm={3}>
                                            Product Cabinet
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="productCabinet" type="text" className={"form-control" + (errors.productCabinet && touched.productCabinet ? " is-invalid" : "")} />
                                            <ErrorMessage name="productCabinet" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="productPrice">
                                        <Form.Label column sm={3}>
                                            Product Price
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="productPrice" type="text" className={"form-control" + (errors.productPrice && touched.productPrice ? " is-invalid" : "")} />
                                            <ErrorMessage name="productPrice" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="modelToBePartExchanged">
                                        <Form.Label column sm={3}>
                                            Model To Be Part Exchanged
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="modelToBePartExchanged" type="text" className={"form-control" + (errors.modelToBePartExchanged && touched.modelToBePartExchanged ? " is-invalid" : "")} />
                                            <ErrorMessage name="modelToBePartExchanged" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>

                                    <h4>FEATURES</h4>
                                    <Form.Group as={Row} className="mb-3" controlId="features">
                                        <Form.Label column sm={3}></Form.Label>
                                        <Col sm={12} className="pt-2">
                                            {features.map((pkg, index) => {
                                                const isChecked = selectedFeatures.includes(pkg);
                                                return (
                                                    <label key={index} className="d-block">
                                                        <Field type="checkbox" name="features" value={pkg} className="me-2" disabled={!isChecked} checked={isChecked}
                                                        />
                                                        {pkg}
                                                    </label>
                                                );
                                            })}
                                        </Col>
                                    </Form.Group>
                                    <h4>COVER OPTIONS</h4>
                                    <Form.Group as={Row} className="mb-3" controlId="coverOptions">
                                        <Form.Label column sm={3}></Form.Label>
                                        <Col sm={12} className="pt-2">
                                            {coverOptions.map((pkg, index) => {
                                                const isChecked = selectedCoverOptions.includes(pkg);
                                                return (
                                                    <label key={index} className="d-block">
                                                        <Field type="checkbox" name="coverOptions" value={pkg} className="me-2" disabled={!isChecked} checked={isChecked}
                                                        />
                                                        {pkg}
                                                    </label>
                                                );
                                            })}
                                        </Col>
                                    </Form.Group>
                                    <h4>ACCESSORIES</h4>
                                    <Form.Group as={Row} className="mb-3" controlId="accessoryList">
                                        <Form.Label column sm={3}></Form.Label>
                                        <Col sm={12} className="pt-2">
                                            {accessories?.map((acc, index) => {
                                                const isChecked = selectedAccessories.includes(acc);
                                                return (
                                                    <label key={index} className="d-block">
                                                        <Field type="checkbox" name="accessoryList" value={acc} className="me-2" disabled={!isChecked} checked={isChecked}
                                                        />
                                                        {acc}
                                                    </label>
                                                );
                                            })}
                                        </Col>
                                    </Form.Group>
                                    <h4>SERVICING OPTIONS</h4>
                                    <Form.Group as={Row} className="mb-3" controlId="servicePlan">
                                        <Form.Label column sm={3}></Form.Label>
                                        <Col sm={12} className="pt-2">
                                            {servicePlans.map((pkg, index) => {
                                                const isChecked = selectedServicePlans.includes(pkg);
                                                return (
                                                    <label key={index} className="d-block">
                                                        <Field type="checkbox" name="servicePlan" value={pkg} className="me-2" disabled={!isChecked} checked={isChecked}
                                                        />
                                                        {pkg}
                                                    </label>
                                                );
                                            })}
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="deliveryMethod">
                                        <Form.Label column sm={3}>Delivery Method</Form.Label>
                                        <Col sm={9} className="pt-2">
                                            {deliveryMethods.map((choice, index) => {
                                                const isChecked = selectedDeliveryMethods.includes(choice);
                                                return (
                                                    <label key={index} className="d-inline me-3">
                                                        <Field type="radio" name="deliveryMethod" value={choice} className="me-2" disabled={!isChecked} checked={isChecked}
                                                        />
                                                        {choice}
                                                    </label>
                                                )
                                            })}
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="electricalSupply">
                                        <Form.Label column sm={3}>Electrical Supply</Form.Label>
                                        <Col sm={9} className="pt-2">
                                            {electricalSupplies.map((choice, index) => {
                                                return (
                                                    <label key={index} className="d-inline me-3">
                                                        <Field type="radio" name="electricalSupply" value={choice} className="me-2"
                                                        />
                                                        {choice}
                                                    </label>
                                                );
                                            })}
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="electricianRequired">
                                        <Form.Label column sm={3}>
                                            Electrician required?
                                        </Form.Label>
                                        <Col sm={9}>
                                            <FormCheck
                                                type="switch"
                                                id="custom-switch"
                                                label=""
                                                checked={values.electricianRequired}
                                                onChange={handleChange}
                                                name="electricianRequired"
                                                style={{ pointerEvents: 'none' }}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="preferredDeliveryDate">
                                        <Form.Label column sm={3}>
                                            Preferred Delivery Date
                                        </Form.Label>
                                        <Col sm={4}>
                                            <Field readOnly name="preferredDeliveryDate" type="datetime-local" className={"form-control" + (errors.preferredDeliveryDate && touched.preferredDeliveryDate ? " is-invalid" : "")} />
                                            <ErrorMessage name="preferredDeliveryDate" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="accessDescription">
                                        <Form.Label column sm={3}>
                                            Description of access to the site
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="accessDescription" type="text" className={"form-control" + (errors.accessDescription && touched.accessDescription ? " is-invalid" : "")} />
                                            <ErrorMessage name="accessDescription" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="siteVisitFormSent">
                                        <Form.Label column sm={3}>
                                            Site Visit Form Sent?
                                        </Form.Label>
                                        <Col sm={9}>
                                            <FormCheck
                                                type="switch"
                                                id="custom-switch"
                                                label=""
                                                checked={values.siteVisitFormSent}
                                                onChange={handleChange}
                                                name="siteVisitFormSent"
                                                style={{ pointerEvents: 'none' }}
                                            />
                                        </Col>
                                    </Form.Group>
                                    
                                    {status && (
                                        <Alert variant="danger" className="mt-3">
                                            {status}
                                        </Alert>
                                    )}
                                </Form>
                            )}
                        </Formik>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default EndlessPoolsOrderFormWithData;