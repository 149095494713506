import { faPlus, faRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Col, Container, Row, Stack } from 'react-bootstrap';
import Button from 'react-bootstrap/esm/Button';
import { IChecklistDefinitionItem } from '../customTypings/ChecklistDefinitionItem';
import { clone } from '../helpers/utils';
import { EditChecklistDefitionItem } from './EditChecklistDefinitionItem';

type Props = {
    checklistDefinitionItemOptions: IChecklistDefinitionItem[],
    onChange: (checklistDefinitionItemOptions: IChecklistDefinitionItem[]) => void,
    onReset: ()=> void,
}

export const EditChecklistDefitionItems: React.FC<Props> = ({ checklistDefinitionItemOptions, onChange, onReset }) => {
    const [checklistDefinitionItems, setChecklistDefinitionItems] = useState<IChecklistDefinitionItem[]>(clone(checklistDefinitionItemOptions));
    
    useEffect(()=> { 
        setChecklistDefinitionItems(clone(checklistDefinitionItemOptions));
    }, [checklistDefinitionItemOptions]);

    const handleReset = () => {
        onReset();
    };
    
    const handleOnAdd = () => {
        const updatedColumns = clone(checklistDefinitionItems)
        const newItem: IChecklistDefinitionItem = {
            id: undefined,
            name: "new",
            displayOrder: checklistDefinitionItems.length,
            checklistDefinitionId: undefined
        }
        updatedColumns.push(newItem)
        setChecklistDefinitionItems(updatedColumns);
        onChange(updatedColumns);
    };
    
    const handleOnChange = (checklistDefinitionItem: IChecklistDefinitionItem) => {
        const updatedColumns = clone(checklistDefinitionItems);
        updatedColumns.splice(checklistDefinitionItem.displayOrder, 1, checklistDefinitionItem);
    
        setChecklistDefinitionItems(updatedColumns);
        onChange(updatedColumns);
    };
    
    const handleOnDelete = (index: number) => {
        const updatedColumns = clone(checklistDefinitionItems);
        updatedColumns.splice(index, 1);

        for(let i=0;i<updatedColumns.length;i++) {
            updatedColumns[i].displayOrder = i;
        }
    
        setChecklistDefinitionItems(updatedColumns);
        onChange(updatedColumns);
    };

    const handleOnDragEnd = (result: any) => {
        if (!result.destination) return;

        const updatedColumns = clone(checklistDefinitionItems);
        const [reorderedItem] = updatedColumns.splice(result.source.index, 1);
        updatedColumns.splice(result.destination.index, 0, reorderedItem);

        for(let i=0;i<updatedColumns.length;i++){
            updatedColumns[i].displayOrder = i
        }
    
        setChecklistDefinitionItems(updatedColumns);
        onChange(updatedColumns);
    };

    return (
        <Container>
            <Stack gap={2} >
                <span>
                    You can add, edit or remove items in this list by selecting them below. To change the order, drag and drop a field.
                </span>
                <Row>
                    <Col>
                        <Button variant="outline-dark" onClick={() => handleReset()}>
                            <FontAwesomeIcon icon={faRotateLeft} /> Reset items
                        </Button>
                    </Col>
                    <Col xs="auto">
                        <Button variant="primary" className="me-2" onClick={() => handleOnAdd()}>
                            <FontAwesomeIcon icon={faPlus} /> Add item
                        </Button>
                    </Col>
                </Row>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="members-list">
                        {(provided) => (
                            <Stack gap={2} {...provided.droppableProps} ref={provided.innerRef}>
                                { checklistDefinitionItems.map((checklistDefinitionItem, index) =>
                                    <Draggable key={`${checklistDefinitionItem.displayOrder}-${checklistDefinitionItem.name}`} draggableId={`${checklistDefinitionItem.displayOrder}-${checklistDefinitionItem.name}`} index={index}>
                                        {(provided) => (
                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                <EditChecklistDefitionItem checklistDefinitionItemOption={checklistDefinitionItem} onChange={(updatedItem)=> {handleOnChange(updatedItem)}} onDelete={() => {handleOnDelete(index)}} />
                                            </div>
                                        )}
                                    </Draggable>
                                )}
                                {provided.placeholder}
                            </Stack>
                        )}
                    </Droppable>
                </DragDropContext>
            </Stack>
        </Container>
    );
}