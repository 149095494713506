import { GroupBase, StylesConfig } from 'react-select';

const ReactSelectBootstrapStyle: StylesConfig<any, boolean, GroupBase<any>> = {
    control: (provided, state)=> {
        const base = { ...provided, borderRadius: "0.25rem", borderWidth: "1px" };
        if(state.isFocused )
            return {
                ...base,
                borderColor: "#86b7fe",
                boxShadow: "0 0 0 0.25rem rgb(13 110 253 / 25%)"
            };
        return base;
    },
};

export { ReactSelectBootstrapStyle };
