import { AppointmentType } from "../constants/AppointmentTypeIds";
import { calendarEventService, taskService } from "../services";
import { ICalendarEvent } from "../customTypings/CalendarEvent";
export function addTaskToCalender(id: string) {
    return taskService.get(id).then((res) => {
        const newCalendarEntry: ICalendarEvent = {
            id: undefined,
            color: undefined,
            taskId: res.id ?? (undefined as string | undefined),
            title: res.name,
            description: res.notes ?? undefined,
            appointmentTypeId: AppointmentType.task,
            startDate: res.creationDate,
            endDate: res.dueDate,
            isAllDay: false,
            identityIds: typeof res.userId === 'string' ? [res.userId] : res.userId,
            jobId: (undefined as string | undefined),
        };
       return calendarEventService.add(newCalendarEntry).then( response => response)
    })

    
    
}
