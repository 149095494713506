import React from 'react';
import { Spinner } from 'react-bootstrap';


type Props = {
  text?: string
  }

const LoadingSpinner: React.FC<Props> = ({text}) => {
  return (
    <>
      <Spinner 
          animation="border" 
          role="status"
          size="sm"
          className={text ? "me-2" : ""}
          >
          <span className="visually-hidden">{text}</span>
      </Spinner>
      {text}
  </>
  );
}

export { LoadingSpinner };
