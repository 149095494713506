import { authHeader, handleResponse } from '../helpers';

export const emailTemplateService = {
    remove,
    getPlaceholders,
    getById,
    edit,
    getAll,
    getAllActive,
    sendAutoEmail
};



async function remove(emailTemplateId: string){
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader()
    };
    const response = await fetch(`api/emailtemplate/delete?id=${emailTemplateId}`, requestOptions);
    const response_1 = await handleResponse(response);
    return response_1;
}


async function getPlaceholders() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    const response = await fetch(`api/emailtemplate/getplaceholders`, requestOptions);
    return handleResponse(response);
}

async function getById(id: string) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    const response = await fetch(`api/emailtemplate/GetById?id=${id}`, requestOptions);
    return handleResponse(response);
}

async function edit(emailTemplate:any){
    const requestOptions:RequestInit = {
        method: 'PATCH',
        credentials: 'include', 
        headers: authHeader(),
        body: JSON.stringify(emailTemplate)
    };
    const response = await fetch(`api/emailtemplate/edit`, requestOptions);
    const response_1 = await handleResponse(response);
    return response_1;
}

async function getAll() {
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader(),
    };
    const response = await fetch(`api/emailtemplate/getall`, requestOptions);
    const response_1 = await handleResponse(response);
    return response_1;
}

async function getAllActive(proposalId: string) {
    const requestOptions:RequestInit = {
        method: 'GET',
        credentials: 'include', 
        headers: authHeader(),
    };
    const response = await fetch(`api/emailtemplate/getallactive?proposalId=${proposalId}`, requestOptions);
    const response_1 = await handleResponse(response);
    return response_1;
}
async function sendAutoEmail(subject: string, body: string, emails: string[]) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({subject, body, emails})
    };
    const response = await fetch(`api/emailtemplate/sendautoemail?subject=${subject}&body=${body}&emails=${emails}`, requestOptions);
    const response_1 = await handleResponse(response);
    return response_1;
}