import { Breadcrumb } from "react-bootstrap";
import Container from "react-bootstrap/esm/Container";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import WebsiteSettingsForm from "./WebsiteSettingForm";

const WebsiteSettingListPage = () => {
    return (
        <Container fluid>
            <Helmet>
                <title>Website Settings</title>
            </Helmet>

            <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/settings" }}>
                    Settings
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Website Settings</Breadcrumb.Item>
            </Breadcrumb>

            <h1 className="my-3">Website Settings</h1>
            <WebsiteSettingsForm />
        </Container>
    );
};

export { WebsiteSettingListPage };

