import { authHeader, handleResponse } from '../helpers';

export const postcodeService = {
    lookup
};

function lookup(postcode: string) {
    const requestOptions:RequestInit = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(postcode) 
    };
    return fetch(`api/postcodelookup/lookup`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}