import { authHeader, handleResponse } from '../helpers';

import { IInviteAcceptance } from '../customTypings/InviteAcceptance'

export const invitationService = {
    getAll,
    getById,
    getByEmail,
    amountByEmail,
    exists,
    checkIsForEmail,
    getEmailIfAccountExists,
    accept,
    remove,
    updateRoles,
    response
};

function getAll() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`api/invitations`, requestOptions).then(handleResponse);
}

function getById(id: string) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`api/invitations/${id}`, requestOptions).then(handleResponse);
}

function getByEmail(email: string) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`api/invitations/foremail?email=${email}`, requestOptions).then(handleResponse);
}

function amountByEmail(email: string) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`api/invitations/amount?email=${email}`, requestOptions).then(handleResponse);
}

function exists(id: string) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`api/invitations/exists?id=${id}`, requestOptions).then(handleResponse);
}

function checkIsForEmail(id: string, email: string) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`api/invitations/checkinvitaionisforemail?id=${id}&email=${email}`, requestOptions).then(handleResponse);
}

function getEmailIfAccountExists(id: string) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`api/invitations/getemailifaccountexists?id=${id}`, requestOptions).then(handleResponse);
}

function accept(invite: IInviteAcceptance){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify( invite )
    };
    return fetch(`api/invitations/accept`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function remove(id: string){
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`api/invitations/remove?id=${id}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function updateRoles(tokenId: string, roleIds: string[]){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ tokenId, roleIds })
    };
    return fetch(`api/invitations/updateroles`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function response(id: string, accepted: Boolean){
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`api/invitations/response?id=${id}&accepted=${accepted}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}