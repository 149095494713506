import { useAtom } from "@dbeining/react-atom";
import { useEffect, useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars-2";
import { NavLink } from "react-router-dom";
import { userAtom } from "../../atoms/userAtom";
import { IRole } from "../../customTypings/role";
import { Role } from "../../helpers";
import "../../styles/navbar.css";
import CustomNavDropdown from "./CustomNavDropdown";

interface DropdownStates {
    user: boolean;
    admin: boolean;
    sales: boolean;
    setting: boolean;
}

type Props = {
    setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NavigationBar:React.FC<Props> = ({setShowMenu}) => {
    const currentUser = useAtom(userAtom);
    const isSystemAdmin = currentUser?.identity.roles.some((role: IRole) => [Role.SystemAdmin].includes(role.name))
    const isAdmin = currentUser?.identity.roles.some((role: IRole) => [Role.Admin].includes(role.name))
    const isSales = currentUser?.identity.roles.some((role: IRole) => [Role.Sales].includes(role.name))
    const isUser = currentUser?.identity.roles.some((role: IRole) => [Role.User].includes(role.name))

    const dropdownStates = {
        user: true,
        admin: true,
        sales: true,
        setting: true,
    };
    const settingsLinks = {
        "General Settings": "/settings",
        "Company Info": "/companyinfo",
        "Communication Templates": "/EmailTemplates",
        "Task Types": "/tasktype",
        "Task Categories": "/taskcategory",
        Forms: "/formListPage",
        Products: "/products",
        Accessories: "/accessories",
    };

    const adminLinks = {
        Diary: "/calendar",
        Customers: "/customers",
        Tasks: "/tasks",
        Communication: "/admincommunicationlog",
        Products: "/orders",
    };

    const salesLinks = {
        Leads: "/leads",
    };
    const userLinks = {
        "My Tasks": "/mytasks",
        "My Diary": "/mycalendar",
        "My Communication": "/communicationlog",
    };

    useEffect(() => {
        let pathname = getDropdownKeyFromPathname(window.location.pathname);

        if (pathname) {
            const validKey: keyof DropdownStates = pathname;
            dropdownStates[validKey] = true;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDropdownKeyFromPathname = (
        pathname: string
    ): keyof DropdownStates | null => {
        if (Object.values(userLinks).includes(pathname)) {
            return "user";
        } else if (Object.values(adminLinks).includes(pathname)) {
            return "admin";
        } else if (Object.values(settingsLinks).includes(pathname)) {
            return "setting";
        } else if (Object.values(salesLinks).includes(pathname)) {
            return "sales";
        }

        return null;
    };

    const [openDropdown, setOpenDropdown] =
        useState<DropdownStates>(dropdownStates);

    const handleDropdownToggle = (key: keyof DropdownStates) => {
        let selectedItem: string = getDropdownKeyFromPathname(
            window.location.pathname
        ) as string;

        setOpenDropdown((prevState) => ({
            ...prevState,
            [key]: !prevState[key],
            [selectedItem]: true,
        }));

        setShowMenu(false);
    };
    return (
        <Navbar
            bg="light"
            expand={false}
            expanded={true}
            sticky="top"
            id="side-nav"
        >
            <Navbar.Brand className="p-0" as={NavLink} to="/">
                <img
                    alt="Happy Hot Tubs"
                    src="/happy-hot-tubs-logo.svg"
                    style={{ height: "5rem" }}
                    className="d-inline-block align-top m-3"
                />
            </Navbar.Brand>
            <Navbar.Collapse>
                <Scrollbars autoHide id="navbar-nav-scroll">
                    <Nav className="px-3 pb-5">
                        {currentUser && (
                            <div>
                                {(isUser || isSystemAdmin) && (
                                    <CustomNavDropdown
                                        title="Me"
                                        links={userLinks}
                                        show={openDropdown["user"]}
                                        onClick={() => handleDropdownToggle("user")}
                                    />
                                )}
                                {(isAdmin || isSystemAdmin) && (
                                    <CustomNavDropdown
                                        title="Admin"
                                        links={adminLinks}
                                        show={openDropdown["admin"]}
                                        onClick={() => handleDropdownToggle("admin")}
                                    />
                                )}

                                {(isSales || isSystemAdmin) && (
                                    <CustomNavDropdown
                                        title="Sales"
                                        links={salesLinks}
                                        show={openDropdown["sales"]}
                                        onClick={() => handleDropdownToggle("sales")}
                                    />
                                )}

                                {(isAdmin || isSystemAdmin) && (
                                    <CustomNavDropdown
                                        title="Settings"
                                        links={settingsLinks}
                                        show={openDropdown["setting"]}
                                        onClick={() => handleDropdownToggle("setting")}
                                    />
                                )}
                            </div>
                        )}
                    </Nav>
                </Scrollbars>
            </Navbar.Collapse>
        </Navbar>
    );
};
