import { IEmailTemplate } from "@/customTypings/EmailTemplate";
import {
  faPen
} from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb } from "react-bootstrap";
import Container from "react-bootstrap/esm/Container";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import {
  ColumnRender, FilterType, IColumn, IFilter, IPagedListAction
} from "../../components/PagedList/Declarations";
import { PagedList } from "../../components/PagedList/PagedList";
import { removeHTMLTagsString } from "../../helpers/utils";
import { emailTemplateService } from "../../services";

const EmailTemplateListPage = () => {
  const navigate = useNavigate();

  const navigateToTemplate = (item: any) => {
    emailTemplateService
      .getById(item.id)
        .then((template) => {
        navigate(`/EmailTemplates/edit`, {
          state: { item: { ...item,...template }, templateAttributes: Object.keys(template)  },
        });
      })
      .catch((e) => { 
        console.log(e);
      });
  };

  return (
    <Container fluid>
      <Helmet>
        <title>Automatic Emails</title>
      </Helmet>

      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/settings" }}>
          Settings
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Automatic Emails</Breadcrumb.Item>
      </Breadcrumb>

      <h1 className="my-3">Automatic Emails</h1>
      <PagedList
        columns={[
          {
            member: "name",
            renderAs: ColumnRender.text,
            title: "Name",
            width: 100,
          } as IColumn,
          {
            member: "subject",
            renderAs: ColumnRender.text,
            title: "Subject",
            width: 200,
          } as IColumn,
          {
            member: "body",
            renderAs: ColumnRender.customRenderer,
            title: "Body",
            width: 300,
            customRenderer: (item: IEmailTemplate) => {
              return (
                <td>
                  <p className="truncate-to-450">
                    {removeHTMLTagsString(item.body)}
                  </p>
                </td>
              );
            },
          } as IColumn,
          {
            member: "enabled", // Updated column name
            renderAs: ColumnRender.checkCross,
            title: "Enabled",
            width: 100,
          } as IColumn,
          {
            member: "lastModifiedTime", // Updated column name
            renderAs: ColumnRender.text,
            title: "Last Modified Time",
            width: 200,
          } as IColumn,
          {
            member: "lastModifiedBy", // Updated column name
            title: "Last Modified By",
            width: 200,
            renderAs: ColumnRender.text,
          } as IColumn,
          {
            member: "customerSignature",
            title: "Requires Customer Signature",
            width: 300,
            renderAs: ColumnRender.checkCross,
          } as IColumn,
        ]}
        itemActions={[
          {
            icon: faPen,
            text: "Edit",
            onClick: navigateToTemplate,
          } as IPagedListAction,
          //{
          //  icon: faTrash,
          //  text: "Delete",
          //  onClick: (item) => {
          //    handleDeleted(item);
          //  },
          //} as IPagedListAction,
        ]}
        filters={[
          {
            title: "Name",
            member: "name",
            type: FilterType.text,
          } as IFilter,
          {
            title: "Subject",
            member: "subject",
            type: FilterType.text,
          } as IFilter,
          {
            title: "Body",
            member: "body",
            type: FilterType.text,
          } as IFilter,
        ]}
        defaultSortBy="Name"
        getUrl="api/emailtemplate/all"
        avatarUrl="api/avatar"
        rowFormatter={(item: any) => {
          return null;
        }}
        persistanceKey="EmailTemplateListPage"
        refreshNumber={0}
      />
    </Container>
  );
};

export { EmailTemplateListPage };

