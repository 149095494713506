import { IAddress } from '@/customTypings/Address';
import { authHeader, handleResponse } from '../helpers';

export const addressService = {
    add
};


function add(address: IAddress) {
    const requestOptions: RequestInit = {
        method: 'POST',
        credentials: 'include',
        headers: authHeader(),
        body: JSON.stringify(address)
    };
    return fetch(`api/address/add`, requestOptions).then(handleResponse)
        .then(response => {
            return response;
        });
}