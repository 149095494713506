import { useEffect, useState } from "react";
import {
    Navigate, Route, Routes, useNavigate, useSearchParams
} from "react-router-dom";

import CookieConsent from "react-cookie-consent";
import { HeaderBar, NavigationBar } from "./components";
import PrivateRoute from "./components/PrivateRoute";
import { Role } from "./helpers";

import { useAtom } from "@dbeining/react-atom";
import { Container, Image, Spinner, Stack } from "react-bootstrap";
import { accessDeniedAtom } from "./atoms/accessDeniedAtom";
import { updateUserStateAtomToLocalStorage, userAtom } from "./atoms/userAtom";
import AddTaskCategory from "./components/AddTaskCategory";
import AddTaskType from "./components/AddTaskType";
import AddCommunication from "./components/CommunicationLog/AddCommunicationLog";
import CommunicationLog from "./components/CommunicationLog/CommunicationLog";
import CompanyInfo from "./components/CompanyInfo";
import { useViewport } from "./contexts/ViewportContext";
import { AcceptInvitationPage } from "./pages/AcceptInvitationPage";
import AccessDeniedPage from "./pages/AccessDeniedPage/AccessDeniedPage";
import { AccessoriesListPage } from "./pages/Accessories/AccessoriesListPage";
import { AdminPage } from "./pages/AdminPage/AdminPage";
import { AllPendingCallbacksPage } from "./pages/AllPendingCallbacks/AllPendingCallbacksPage";
import { AllTasksPage } from "./pages/AllTasksPage/AllTasksPage";
import { CalendarPage } from "./pages/CalendarPage/CalendarPage";
import ChecklistSettingsPage from "./pages/ChecklistSettingsPage/ChecklistSettingsPage";
import { ComponentDemoPage } from "./pages/ComponentDemo/ComponentDemoPage";
import { CustomerListPage } from "./pages/Customer/CustomerListPage";
import { CustomerDashboard } from "./pages/CustomerDashboard/CustomerDashboard";
import { DocumentLibraryPage } from "./pages/DocumentLibraryPage/DocumentLibraryPage";
import { default as EmailTemplateAddPage, default as EmailTemplateEditPage } from "./pages/EmailTemplate/EmailTemplateEditPage";
import { EmailTemplateListPage } from "./pages/EmailTemplate/EmailTemplateListPage";
import { ForgotPasswordPage } from "./pages/ForgotPasswordPage/ForgotPasswordPage";
import AnnualServiceReportForm from "./pages/Form/AnnualServiceReportForm";
import DeliveryOrInstallationForm from "./pages/Form/DeliveryOrInstallationForm";
import EndlessPoolsOrderForm from "./pages/Form/EndlessPoolsOrderForm";
import { FormListPage } from "./pages/Form/FormListPage";
import FormPdf from "./pages/Form/FormPdf";
import HotTubOrderForm from "./pages/Form/HotTubOrderForm";
import SigningForm from "./pages/Form/SigningForm";
import SiteVisitForm from "./pages/Form/SiteVisitForm";
import SpaFreshReportForm from "./pages/Form/SpaFreshReportForm";
import { InvitationsPage } from "./pages/InvitationsPage/InvitationsPage";
import LeadAddForm from "./pages/Lead/LeadAddForm";
import { LeadDetailPage } from "./pages/Lead/LeadDetailPage";
import { LeadListPage } from "./pages/Lead/LeadListPage";
import { MyCalendarPage } from "./pages/MyCalendarPage/MyCalendarPage";
import { MyPendingCallbacksPage } from "./pages/MyPendingCallbacksPage/MyPendingCallbacksPage";
import { MyTasksPage } from "./pages/MyTasksPage/MyTasksPage";
import { MyTimesheet } from "./pages/MyTimesheet/MyTimesheet";
import { OrderDetailPage } from "./pages/Order/OrderDetailPage";
import { OrderListPage } from "./pages/Order/OrderListPage";
import { OrganisationsPage } from "./pages/OrganisationsPage/OrganisationsPage";
import { ProductListPage } from "./pages/Product/ProductListPage";
import { ResetPasswordPage } from "./pages/ResetPasswordPage/ResetPasswordPage";
import { SessionsPage } from "./pages/SessionsPage/SessionsPage";
import { SettingsPage } from "./pages/SettingsPage/SettingsPage";
import { SignInPage } from "./pages/SignInPage/SignInPage";
import { TaskDetailPage } from "./pages/Task/TaskDetailPage";
import { TimesheetPage } from "./pages/TimesheetPage/TimesheetPage";
import { TwoFASetupPage } from "./pages/TwoFASetupPage/TwoFASetupPage";
import UnderConstructionPage from "./pages/UnderConstructionPage/UnderConstructionPage";
import { UserPage } from "./pages/UserPage/UserPage";
import { UsersPage } from "./pages/UsersPage/UsersPage";
import { WebsiteSettingListPage } from "./pages/WebsiteSetting/WebsiteSettingListPage";
import { authenticationService } from "./services/authentication.service";
import "./styles/App.css";

const App = () => {
    let navigate = useNavigate();
    let [searchParams] = useSearchParams();
    const id: string = searchParams.get("id") || "";
    const currentActionAccessDenied = useAtom(accessDeniedAtom);
    const [loadingUser, setLoadingUser] = useState(true);
    const currentUser = useAtom(userAtom);

    const { width } = useViewport();
    const breakpoint = 768;

    useEffect(() => {
        updateUserStateAtomToLocalStorage();

        authenticationService.checkAuthorised().then(() => {
            setLoadingUser(false);
        });
    }, []);

    useEffect(() => {
        if (currentActionAccessDenied) {
            navigate("/accessdenied", { replace: true });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentActionAccessDenied]);

    if (loadingUser) {
        return (
            <div className="d-flex align-content-center justify-content-center flex-wrap vh-100">
                <Stack className="justify-content-center mb-5" gap={3}>
                    <Image
                        src="/happy-hot-tubs-logo.svg"
                        className="mx-auto"
                        style={{ height: "5rem" }}
                    />
                    <Spinner animation={"border"} variant="info" className="mx-auto" />
                </Stack>
            </div>
        );
    }

    const contentMaxWidth =
        currentUser && width >= breakpoint ? "calc(100vw - 15rem)" : "100vw";

    return (
        <>
            <div className="d-flex flex-nowrap" style={{ minHeight: "100vh" }}>
                {currentUser && width >= breakpoint && (
                    <div className="d-flex flex-column" style={{ width: "15rem" }}>
                        <NavigationBar setShowMenu={() => {}} />
                    </div>
                )}
                <div
                    className="flex-column flex-grow-1"
                    style={{ maxWidth: contentMaxWidth }}
                >
                    <HeaderBar />
                    <Container fluid className="py-3">
                        <Routes>
                            <Route path="/" element={<Navigate to="/mytasks" />} />
                            <Route
                                path="/mytasks"
                                element={
                                    <PrivateRoute>
                                        <MyTasksPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/mytasks/:id"
                                element={
                                    <PrivateRoute>
                                        <TaskDetailPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/orders"
                                element={
                                    <PrivateRoute roles={[Role.Admin, Role.SystemAdmin]}>
                                        <OrderListPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/orders/:id"
                                element={
                                    <PrivateRoute>
                                        <OrderDetailPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/tasktype"
                                element={
                                    <PrivateRoute>
                                        <AddTaskType />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/taskcategory"
                                element={
                                    <PrivateRoute>
                                        <AddTaskCategory />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/formListPage"
                                element={
                                    <PrivateRoute>
                                        <FormListPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/siteVisitForm"
                                element={
                                    <PrivateRoute>
                                        <SiteVisitForm />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/deliveryOrInstallationForm"
                                element={
                                    <PrivateRoute>
                                        <DeliveryOrInstallationForm />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/hotTubOrderForm"
                                element={
                                    <PrivateRoute>
                                        <HotTubOrderForm />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/endlessPoolsOrderForm"
                                element={
                                    <PrivateRoute>
                                        <EndlessPoolsOrderForm />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/spaFreshReportForm"
                                element={
                                    <PrivateRoute>
                                        <SpaFreshReportForm />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/annualServiceReportForm"
                                element={
                                    <PrivateRoute>
                                        <AnnualServiceReportForm />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/portal/signingForm/:id"
                                element={
                                        <SigningForm isPublic={true} />
                                }
                            />
                            <Route
                                path="/portal/form/:id"
                                
                                element={
                                    <FormPdf isPublic={true} />
                                }
                            />
                            <Route
                                path="/completedForm/:id"
                                element={
                                        <SigningForm isPublic={false} />
                                }
                            />
                            <Route
                                path="/Form/:id"
                                element={
                                    <PrivateRoute>
                                        <FormPdf isPublic={false} />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/mypendingcallbacks"
                                element={
                                    <PrivateRoute>
                                        <MyPendingCallbacksPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/mycalendar"
                                element={
                                    <PrivateRoute>
                                        <MyCalendarPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/mytimesheet"
                                element={
                                    <PrivateRoute>
                                        <MyTimesheet />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/pendingcallbacks"
                                element={
                                    <PrivateRoute>
                                        <AllPendingCallbacksPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/timesheet"
                                element={
                                    <PrivateRoute>
                                        <TimesheetPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/customers"
                                element={
                                    <PrivateRoute roles={[Role.Admin, Role.SystemAdmin]}>
                                        <CustomerListPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/customers/:id"
                                element={
                                    <PrivateRoute>
                                        <CustomerDashboard />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/tasks"
                                element={
                                    <PrivateRoute roles={[Role.Admin, Role.SystemAdmin]}>
                                        <AllTasksPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/calendar"
                                element={
                                    <PrivateRoute roles={[Role.Admin, Role.SystemAdmin]}>
                                        <CalendarPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/documentlibrary"
                                element={<DocumentLibraryPage />}
                            />
                            <Route
                                path="/export"
                                element={
                                    <PrivateRoute>
                                        <UnderConstructionPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/companyinfo"
                                element={
                                    <PrivateRoute roles={[Role.Admin, Role.SystemAdmin]}>
                                        <CompanyInfo />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/settings"
                                element={
                                    <PrivateRoute
                                        roles={[
                                            Role.Admin,
                                            Role.SystemAdmin,
                                        ]}
                                    >
                                        <SettingsPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/admin"
                                element={
                                    <PrivateRoute roles={[Role.Admin, Role.SystemAdmin]}>
                                        <AdminPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/users"
                                element={
                                    <PrivateRoute
                                        roles={[Role.Admin, Role.SystemAdmin]}
                                    >
                                        <UsersPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/users/:userId"
                                element={
                                    <PrivateRoute
                                        roles={[Role.Admin, Role.SystemAdmin]}
                                    >
                                        <UserPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/checklists"
                                element={
                                    <PrivateRoute>
                                        <ChecklistSettingsPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/EmailTemplates"
                                element={
                                    <PrivateRoute>
                                        <EmailTemplateListPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/EmailTemplates/add"
                                element={
                                    <PrivateRoute>
                                        <EmailTemplateAddPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/EmailTemplates/:id"
                                element={
                                    <PrivateRoute>
                                        <EmailTemplateEditPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/invitations"
                                element={
                                    <PrivateRoute>
                                        <InvitationsPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/organisations"
                                element={
                                    <PrivateRoute>
                                        <OrganisationsPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/twofactorSetup"
                                element={
                                    <PrivateRoute>
                                        <TwoFASetupPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/sessions"
                                element={
                                    <PrivateRoute>
                                        <SessionsPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route path="/signin" element={<SignInPage />} />
                            <Route
                                path="/acceptinvitation"
                                element={<AcceptInvitationPage id={id} />}
                            />
                            <Route
                                path="/resetpassword"
                                element={<ResetPasswordPage id={id} />}
                            />
                            <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
                            <Route path="/accessdenied" element={<AccessDeniedPage />} />
                            <Route path="/componentdemo" element={<ComponentDemoPage />} />
                            <Route
                                path="/websiteSettings"
                                element={<WebsiteSettingListPage />}
                            />
                            <Route path="/leads" element={<LeadListPage />} />
                            <Route path="/addlead" element={<LeadAddForm />} />
                            <Route path="/leads/:id" element={<LeadDetailPage />} />
                            <Route path="/products" element={<ProductListPage />} />
                            <Route path="/accessories/" element={<AccessoriesListPage />} />
                            <Route
                                path="/communicationlog"
                                element={<CommunicationLog myCommunicationLogs={true} />}
                            />
                            <Route
                                path="/admincommunicationlog"
                                element={
                                    <PrivateRoute roles={[Role.Admin, Role.SystemAdmin]}>
                                        <CommunicationLog myCommunicationLogs={false} />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/addcommunicationlog"
                                element={<AddCommunication />}
                            />
                            <Route path="*" element={<Navigate to="/" replace />} />
                        </Routes>
                    </Container>
                </div>
            </div>
            <CookieConsent
                location="bottom"
                buttonText="Accept"
                cookieName="HappyHotTubsCookieConsent"
                style={{ zIndex: 2000 }}
            >
                <h3>This website uses cookies</h3>
                Cookies help us deliver the best experience on our website. By using our
                website, you agree to the use of cookies.
            </CookieConsent>
        </>
    );
};
export { App };

