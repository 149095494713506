import { ErrorMessage, Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import { LoadingSpinner } from "../LoadingSpinner";
import { IOrder } from "../../customTypings/Order";
import { customerService, orderService, productService } from "../../services";
import { ReactSelectBootstrapStyle } from "../../styles/ReactSelectBootstrapStyle";
import { IValueAndLabel } from "@/customTypings/ValueAndLabel";

type Props = {
    show: boolean;
    onClose: (added: boolean) => void;
    productId?: string;
    customerId: string | undefined | null;
};

const AddOrderModel: React.FC<Props> = ({ show, onClose, productId, customerId }) => {
    const [alertVariant, setAlertVariant] = useState("danger");
    const [allProducts, setAllProducts] = useState<any[]>();
    const [allCustomers, setAllCustomers] = useState<any[]>();
    const [customerField, setCustomerField] = useState<IValueAndLabel>();

    const handleClose = () => onClose(false);

    useEffect(() => {
        Promise.all([productService.getAllListItems(), customerService.getAllListItems()]).then((response) => {
            setAllProducts(response[0]);
            setAllCustomers(response[1]);
            const customer=response[1].find((customer:IValueAndLabel) => customer.value === customerId)
            setCustomerField(customer ?? null);
        });
    }, [customerId]);

    if (customerField === undefined)
        return <></>;

    return (
        <Modal centered show={show} keyboard={false} onHide={handleClose}>
            <Formik
                initialValues={{
                    serialNumber: "",
                    deliveryDate: new Date(),
                    productId: productId ? productId : "",
                    customerId: customerId ? customerId : "",
                }}
                onSubmit={(values, { setStatus, setSubmitting, setFieldError }) => {
                    setStatus();
                    setSubmitting(false);
                    const newOrder: IOrder = {
                        id: undefined,
                        serialNumber: values.serialNumber,
                        deliveryDate: values.deliveryDate,
                        productId: values.productId,
                        customerId: values.customerId,
                    };
                    orderService.add(newOrder).then(
                        (response) => {
                            setSubmitting(false);
                            if (response.status !== "Failure") {
                                onClose(true);
                            } else {
                                setAlertVariant("danger");
                                setStatus(response.message);
                            }
                        },
                        (error) => {
                            setAlertVariant("danger");
                            if (error.status === 400) {
                                setStatus(error.title);
                                setFieldError("serialNumber", error.errors.SerialNumber);
                                setFieldError("productId", error.errors.ProductId);
                                setFieldError("customerId", error.errors.CustomerId);
                            } else {
                                setStatus(error);
                            }
                            setSubmitting(false);
                        }
                    );
                }}
            >
                {({ errors, status, touched, isSubmitting, handleSubmit, values, handleChange, setFieldValue }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>New Order</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group as={Row} className="mb-3" controlId="orderSerialNumber">
                                <Form.Label column sm={3}>
                                    Serial Number
                                </Form.Label>
                                <Col sm={9}>
                                    <Field name="serialNumber" type="text" className={"form-control" + (errors.serialNumber && touched.serialNumber ? " is-invalid" : "")} />
                                    <ErrorMessage name="serialNumber" component="div" className="invalid-feedback" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="orderDeliveryDate">
                                <Form.Label column sm={3}>
                                    Delivery Date
                                </Form.Label>
                                <Col sm={9}>
                                    <Field name="deliveryDate" type="date" className={"form-control" + (errors.deliveryDate && touched.deliveryDate ? " is-invalid" : "")} />
                                    <ErrorMessage name="deliveryDate" component="div" className="invalid-feedback" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="orderProductId">
                                <Form.Label column sm={3}>
                                    Product
                                </Form.Label>
                                <Col sm={9}>
                                    <Select
                                        styles={ReactSelectBootstrapStyle}
                                        className="stateManagedSelect"
                                        onChange={(value) => setFieldValue("productId", value == null ? "" : value.value)}
                                        isClearable
                                        options={allProducts}
                                    />
                                    <ErrorMessage name="productId" component="div" className="invalid-feedback" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="orderCustomerId">
                                <Form.Label column sm={3}>
                                    Customer
                                </Form.Label>
                                <Col sm={9}>
                                    <Select
                                        styles={ReactSelectBootstrapStyle}
                                        className="stateManagedSelect"
                                        onChange={(value) => setFieldValue("customerId", value == null ? "" : value.value)}
                                        defaultInputValue={customerField?.label ?? undefined}
                                        isClearable
                                        options={allCustomers}
                                    />
                                    <ErrorMessage name="customerId" component="div" className="invalid-feedback" />
                                </Col>
                            </Form.Group>
                            {status && (
                                <Alert variant={alertVariant} className="mt-3">
                                    {status}
                                </Alert>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="form-group">
                                <Button variant="primary" disabled={isSubmitting} type="submit" className="me-2">
                                    {isSubmitting ? <LoadingSpinner text="Creating order..." /> : "Create order"}
                                </Button>
                                <Button variant="secondary" onClick={handleClose}>
                                    Cancel
                                </Button>
                            </div>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export { AddOrderModel };
