import { FieldAttributes } from "formik";
import React from "react";

interface FieldProps extends FieldAttributes<any> {}

const HHTRadioButton: React.FC<FieldProps> = ({
  name,
  values,
  handleChange,
  ...rest
}) => {
  return (
    <div className="form-check form-switch">
      <input
        name={name}
        onChange={handleChange}
        checked={values[name]}
        className="form-check-input"
        type="checkbox"
        role="switch"
      />
    </div>
  );
};

export default HHTRadioButton;
