import { IIdentity } from '@/customTypings/Identity';
import { IIdentityAvatar } from '@/customTypings/IdentityAvatar';
import React, { useEffect, useState } from 'react';
import Image from 'react-bootstrap/Image';

type AvatarProps = {
    identity: IIdentity | IIdentityAvatar;
    size?: number;
    showName? : boolean
}

const Avatar: React.FC<AvatarProps> = ({ identity, size = 2, showName = true }) => {
    const fallbackImageUrl = "/GenericAvatar.png";

    const getImageSrc = (identity: IIdentity | IIdentityAvatar) => {
        if(identity === null || identity === undefined) 
            return fallbackImageUrl;

        const isIIdentity = (identity as IIdentity).avatarRevision !== undefined;

        if(identity.avatarContent)
            return `data:${identity.avatarContentType};base64,${identity.avatarContent}`;
        if(isIIdentity)
            return `api/avatar/${identity.id}/${(identity as IIdentity).avatarRevision}`;
        return fallbackImageUrl;
    };

    const [src, setSrc] = useState(getImageSrc(identity));

    useEffect(()=> {
        setSrc(getImageSrc(identity));
    }, [identity])
    
    const handleImageNotFound = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        event.currentTarget.src = fallbackImageUrl;
    }
    
    return (
        identity && 
            <>
                <Image
                    roundedCircle
                    src={src} 
                    onError={handleImageNotFound}
                    style={{width: `${size}em`, height: `${size}em`}} 
                    title={identity.displayName}
                    alt={`Avatar image for user ${identity.displayName}`}
                    className={showName ? "me-2" : ""}
                    />
                {showName && identity.displayName}
            </>
    );
}

export { Avatar };
