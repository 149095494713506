import { Breadcrumb, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { UserInvitationsPage } from "../UserInvitationsPage/UserInvitationsPage";
import { UsersListPage } from "../UsersListPage/UsersListPage";

const UsersPage = () => {
    return (
        <Container fluid>
            <Helmet>
                <title>Users</title>
            </Helmet>

            <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/settings" }}>
                    Settings
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Users</Breadcrumb.Item>
            </Breadcrumb>
            <Row>
                <Col>
                    <h1>Users</h1>
                    <Tabs defaultActiveKey="required" className="mb-3" mountOnEnter>
                        <Tab eventKey="required" title="Active Users">
                            <UsersListPage />
                        </Tab>
                        <Tab eventKey="due" title="Invitations">
                            <UserInvitationsPage />
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </Container>
    );
};

export { UsersPage };
