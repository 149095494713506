import { faPersonDigging } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Stack } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

export default function UnderConstructionPage() {

  return (
    <Container fluid className="d-flex align-content-center justify-content-center flex-wrap vh-100">
        <Helmet>
            <title>Under Construction</title>
        </Helmet>

        <Stack gap={3}>
            <FontAwesomeIcon icon={faPersonDigging} size="10x"/>
            <div className="d-flex justify-content-center">Under Construction</div>
        </Stack>
    </Container>
  )
}
