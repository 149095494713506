import { IRole } from "@/customTypings/role";
import { useAtom } from "@dbeining/react-atom";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import { useNavigate } from "react-router-dom";
import { userAtom } from "../../atoms/userAtom";
import { ColumnRender, FilterType, IColumn, IFilter, IPagedListAction } from "../../components/PagedList/Declarations";
import { PagedList } from "../../components/PagedList/PagedList";
import { Role } from "../../helpers";

type Props = {
    taskId: string;
};

const TaskFormsPage: React.FC<Props> = ({ taskId }) => {
    const navigate = useNavigate();
    const currentUser = useAtom(userAtom);

    return (
        <>
            <Container fluid>
                <Row>
                    <Col>
                        <PagedList
                            columns={[
                                {
                                    member: "name",
                                    renderAs: ColumnRender.text,
                                    title: "Form",
                                    width: 150,
                                } as IColumn,
                                {
                                    member: "email",
                                    renderAs: ColumnRender.text,
                                    title: "Email",
                                    width: 250,
                                } as IColumn,
                                {
                                    member: "sentTime",
                                    renderAs: ColumnRender.dateTime,
                                    title: "Time Sent",
                                    width: 200,
                                } as IColumn,
                                {
                                    member: "completedTime",
                                    renderAs: ColumnRender.dateTime,
                                    title: "Time Completed",
                                    width: 200,
                                } as IColumn
                            ]}
                            itemActions={[
                                {
                                    text: "View Data",
                                    onClick: (item) => {
                                        navigate(`/completedForm/${item.id}`);
                                    },
                                    primary: true,
                                    secondaryBtn: true
                                } as IPagedListAction,
                                {
                                    text: "View Pdf",
                                    onClick: (item) => {
                                        navigate(`/Form/${item.id}`);
                                    },
                                    primary: true,
                                } as IPagedListAction
                            ]}
                            filters={[
                                {
                                    title: "Form",
                                    member: "name",
                                    type: FilterType.text,
                                } as IFilter,
                                {
                                    title: "Email",
                                    member: "email",
                                    type: FilterType.text,
                                } as IFilter,
                                {
                                    title: "Time sent",
                                    member: "sentTime",
                                    type: FilterType.text,
                                } as IFilter,
                                {
                                    title: "Time Completed",
                                    member: "completedTime",
                                    type: FilterType.text,
                                } as IFilter
                            ]}
                            defaultSortBy="name"
                            getUrl="api/form/all"
                            avatarUrl="api/avatar/get"
                            rowFormatter={(item: any) => {
                                return null;
                            }}
                            persistanceKey="TaskFormsPage"
                            refreshNumber={0}
                            exportUrl={currentUser?.identity.roles.some((role: IRole) => [Role.Admin].indexOf(role.name) >= 0) ? "api/order/download" : undefined}
                            exportIcon={faFileExport}
                            staticFilterValues={{ taskId }}
                        />
                    </Col>
                </Row>

            </Container>
        </>
    );
};

export { TaskFormsPage };

