import { ICompany } from "@/customTypings/Company";
import { authHeader, handleResponse } from "../helpers";

function create(companyInfo: ICompany) {
  const requestOptions: RequestInit = {
    method: "POST",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify(companyInfo),
  };
  return fetch("api/company/create", requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function getCompany() {
  const requestOptions: RequestInit = {
    method: "GET",
    credentials: "include",
    headers: authHeader(),
  };
  return fetch("api/company/getcompany", requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function update(companyInfo: ICompany) {
  const requestOptions: RequestInit = {
    method: "PATCH",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify(companyInfo),
  };
  return fetch("api/company/update", requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}
export const companyService = {
  create,
  getCompany,
  update,
};
