import CustomerSignature from "../../components/Forms/CustomerSignature";
import { ErrorMessage, Field, Formik } from "formik";
import { Alert, Breadcrumb, Col, Container, Form, FormCheck, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

type props = {
    currentForm: any
}

function DeliveryOrInstallationFormWithData({currentForm}:props) {
    return (
        <>
            <Container fluid>
                <Row>
                    <Col>
                        <Helmet>
                            <title>Delivery/Installation Form Data</title>
                        </Helmet>

                        <Breadcrumb>
                            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/formListPage" }}>
                                Forms
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>Delivery/Installation Form Data</Breadcrumb.Item>
                        </Breadcrumb>

                        <h1 className="my-3">Delivery/Installation Form Data</h1>
                        <Formik
                            initialValues={currentForm}
                            enableReinitialize
                            onSubmit={() => { }}
                        >
                            {({
                                errors,
                                status,
                                touched,
                                isSubmitting,
                                handleSubmit,
                                values,
                                setStatus,
                                handleChange,
                                setFieldValue,
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group as={Row} className="mb-3" controlId="customerName">
                                        <Form.Label column sm={3}>
                                            Name of the customer
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="customerName" type="text" className={"form-control" + (errors.customerAddress && touched.customerAddress ? " is-invalid" : "")} />
                                            <ErrorMessage name="customerName" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="taskId">
                                        <Form.Label column sm={3}>
                                            Associated Task
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="taskId" type="text" className={"form-control" + (errors.taskId && touched.taskId ? " is-invalid" : "")} />
                                            <ErrorMessage name="taskId" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="customerContactName">
                                        <Form.Label column sm={3}>
                                            Name of the main customer contact
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="customerContactName" type="text" className={"form-control" + (errors.customerContactName && touched.customerContactName ? " is-invalid" : "")} />
                                            <ErrorMessage name="customerContactName" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="customerContactTitle">
                                        <Form.Label column sm={3}>
                                            Title of the main customer contact
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="customerContactTitle" type="text" className={"form-control" + (errors.customerContactTitle && touched.customerContactTitle ? " is-invalid" : "")} />
                                            <ErrorMessage name="customerContactTitle" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="customerAddress">
                                        <Form.Label column sm={3}>
                                            Inline address summary
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="customerAddress" type="text" className={"form-control" + (errors.customerAddress && touched.customerAddress ? " is-invalid" : "")} />
                                            <ErrorMessage name="customerAddress" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="customerHomeTel">
                                        <Form.Label column sm={3}>
                                            Home telephone
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="customerHomeTel" type="text" className={"form-control" + (errors.customerAddress && touched.customerAddress ? " is-invalid" : "")} />
                                            <ErrorMessage name="customerHomeTel" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="customerMobileTel">
                                        <Form.Label column sm={3}>
                                            Mobile telephone
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="customerMobileTel" type="text" className={"form-control" + (errors.customerAddress && touched.customerAddress ? " is-invalid" : "")} />
                                            <ErrorMessage name="customerMobileTel" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="customerWorkTel">
                                        <Form.Label column sm={3}>
                                            Work telephone
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="customerWorkTel" type="text" className={"form-control" + (errors.customerAddress && touched.customerAddress ? " is-invalid" : "")} />
                                            <ErrorMessage name="customerWorkTel" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="customerOtherTel">
                                        <Form.Label column sm={3}>
                                            Other telephone
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="customerOtherTel" type="text" className={"form-control" + (errors.customerAddress && touched.customerAddress ? " is-invalid" : "")} />
                                            <ErrorMessage name="customerOtherTel" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="customerEmail">
                                        <Form.Label column sm={3}>
                                            Email Address
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="customerEmail" type="text" className={"form-control" + (errors.customerAddress && touched.customerAddress ? " is-invalid" : "")} />
                                            <ErrorMessage name="customerEmail" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="accessNotes">
                                        <Form.Label column sm={3}>
                                            Notes about accessing the customer site
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="accessNotes" type="text" className={"form-control" + (errors.accessNotes && touched.accessNotes ? " is-invalid" : "")} />
                                            <ErrorMessage name="accessNotes" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="productMake">
                                        <Form.Label column sm={3}>
                                            Customer Product
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="productMake" type="text" className={"form-control" + (errors.productMake && touched.productMake ? " is-invalid" : "")}
                                                value={`${currentForm.productMake || ""} ${currentForm.productModel || ""} ${currentForm.productColour || ""}`}
                                            />
                                            <ErrorMessage name="productMake" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="productComments">
                                        <Form.Label column sm={3}>
                                            Comments about the product
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="productComments" type="text" className={"form-control" + (errors.productComments && touched.productComments ? " is-invalid" : "")} />
                                            <ErrorMessage name="productComments" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="orderNumber">
                                        <Form.Label column sm={3}>
                                            Order number for the product sale
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="orderNumber" type="text" className={"form-control" + (errors.orderNumber && touched.orderNumber ? " is-invalid" : "")} />
                                            <ErrorMessage name="orderNumber" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="salesPersonName">
                                        <Form.Label column sm={3}>
                                            Name of sales person
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="salesPersonName" type="text" className={"form-control" + (errors.salesPersonName && touched.salesPersonName ? " is-invalid" : "")} />
                                            <ErrorMessage name="salesPersonName" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="balance">
                                        <Form.Label column sm={3}>
                                            Amount paid/to pay
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="balance" placeholder="£" type="text" className={"form-control" + (errors.balance && touched.balance ? " is-invalid" : "")} />
                                            <ErrorMessage name="balance" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="paymentMethod">
                                        <Form.Label column sm={3}>
                                            Payment method
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="paymentMethod" type="text" className={"form-control" + (errors.paymentMethod && touched.paymentMethod ? " is-invalid" : "")} />
                                            <ErrorMessage name="paymentMethod" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="dateBalancePaid">
                                        <Form.Label column sm={3}>
                                            Date paid (if any)
                                        </Form.Label>
                                        <Col sm={4}>
                                            <Field readOnly name="dateBalancePaid" type="datetime-local" className={"form-control" + (errors.dateBalancePaid && touched.dateBalancePaid ? " is-invalid" : "")} />
                                            <ErrorMessage name="dateBalancePaid" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="financeAgreementName">
                                        <Form.Label column sm={3}>
                                            Name on finance agreement (if required)
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="financeAgreementName" type="text" className={"form-control" + (errors.financeAgreementName && touched.financeAgreementName ? " is-invalid" : "")} />
                                            <ErrorMessage name="financeAgreementName" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>

                                    <div className="row">
                                        {['HPWOCare', 'HPWSilk', 'NoPumpOSB', 'LC', 'LCLite', 'LPC', 'Gold', 'Ultimate', 'EPWellness', 'EPWellnessPlus'].map((index) => (
                                            <Form.Group className="col-4 d-flex justify-content-start align-items-center gap-2" controlId={`servicePlan${index}`} key={`servicePlan${index}`}>
                                                <Form.Check
                                                    type="switch"
                                                    id="custom-switch"
                                                    label=""
                                                    checked={values[`servicePlan${index}`]}
                                                    onChange={handleChange}
                                                    name={`servicePlan${index}`}
                                                    style={{ pointerEvents: 'none' }}
                                                />
                                                <Form.Label>
                                                    ServicePlan{index}
                                                </Form.Label>
                                            </Form.Group>
                                        ))}
                                    </div>
                                    <Form.Group as={Row} className="mb-3" controlId="engineerNameList">
                                        <Form.Label column sm={3}>
                                            Inline CSV list of engineer names
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="engineerNameList" type="text" className={"form-control" + (errors.engineerNameList && touched.engineerNameList ? " is-invalid" : "")} />
                                            <ErrorMessage name="engineerNameList" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    {/*Need to add somwthing here*/}
                                    <Form.Group as={Row} className="mb-3" controlId="engineerDate">
                                        <Form.Label column sm={3}>
                                            Date completed by engineer
                                        </Form.Label>
                                        <Col sm={4}>
                                            <Field readOnly name="engineerDate" type="datetime-local" className={"form-control" + (errors.engineerDate && touched.engineerDate ? " is-invalid" : "")} />
                                            <ErrorMessage name="engineerDate" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="accessClear">
                                        <Form.Label column sm={3}>
                                            Clear Access?
                                        </Form.Label>
                                        <Col sm={9}>
                                            <FormCheck
                                                type="switch"
                                                id="custom-switch"
                                                label=""
                                                checked={values.accessClear}
                                                onChange={handleChange}
                                                name="accessClear"
                                                style={{ pointerEvents: 'none' }}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="accessComments">
                                        <Form.Label column sm={3}>
                                            Comments about access
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="accessComments" type="text" className={"form-control" + (errors.accessComments && touched.accessComments ? " is-invalid" : "")} />
                                            <ErrorMessage name="accessComments" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="visitComments">
                                        <Form.Label column sm={3}>
                                            Comments about delivery/Installation
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="visitComments" type="text" className={"form-control" + (errors.visitComments && touched.visitComments ? " is-invalid" : "")} />
                                            <ErrorMessage name="visitComments" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>

                                    {status && (
                                        <Alert variant="danger" className="mt-3">
                                            {status}
                                        </Alert>
                                    )}
                                </Form>
                            )}
                        </Formik>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default DeliveryOrInstallationFormWithData;