import { IWebsiteSetting } from "@/customTypings/WebsiteSetting";
import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { websiteSettingService } from "../../services";

const WebsiteSettingsForm = () => {
    const [alertVariant, setAlertVariant] = useState('');
    const [message, setMessage] = useState("");
    const [formData, setFormData] = useState({
        id: "",
        domain: "",
        invitationExpiryDays: 0,
    });

    const [submitting, setSubmitting] = useState(false)


    useEffect(() => {
        if (!submitting) {
            websiteSettingService.getAll().then(res => {
                if (res.length) {
                    setFormData({
                        id: res[0]?.id || "",
                        domain: res[0]?.domain || "",
                        invitationExpiryDays: res[0]?.invitationExpiryDays || "",
                    });
                }
            });
        }
    }, [submitting]);

    const handlealerts = () => {
        setAlertVariant("success");
        setMessage("Website setting details have been added successfully");
        setTimeout(() => {
            setAlertVariant('');
            setMessage('');
        }, 2000);
    }

    const handleSubmit = async (values: IWebsiteSetting) => {
        setSubmitting(true)
        const data: IWebsiteSetting = {
            domain: values.domain,
            invitationExpiryDays: values.invitationExpiryDays,
        };

        if (!values.id) {
            data.isDeleted = false
            await websiteSettingService.add(data);
            handlealerts();
        } else {
            data.id = values.id;
            await websiteSettingService.edit(data);
            handlealerts();
        }

        setSubmitting(false);
    };

    if (!submitting) {
        return (<Formik
            enableReinitialize={true}
            initialValues={{
                id: formData.id ? formData.id : '',
                domain: formData.domain ? formData.domain : '',
                invitationExpiryDays: formData.invitationExpiryDays ? formData.invitationExpiryDays : 0,
                isDeleted: false,
            }}
            onSubmit={handleSubmit}
        >
            {(formikProps) => (
                <Form>
                    <div className="mb-3">
                        <label htmlFor="domain" className="form-label">
                            Domain
                        </label>
                        <Field
                            type="text"
                            className="form-control"
                            id="domain"
                            name="domain"
                            placeholder="Enter domain"
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="invitationExpiryDays" className="form-label">
                            Invitation Expiry Days
                        </label>
                        <Field
                            type="number"
                            className="form-control"
                            id="invitationExpiryDays"
                            name="invitationExpiryDays"
                            placeholder="Enter invitation expiry days"
                        />
                    </div>

                    <div className="d-flex justify-content-end">
                        <Button variant="primary" type="submit">
                            Save
                        </Button>
                    </div>
                    <Alert variant={alertVariant} className="mt-3">
                        {message}
                    </Alert>
                </Form>
            )}
        </Formik>
        );
    } else {
        return (
            <div className="spinner-border text-success" role="status">
                <span className="sr-only">Submitting...</span>
            </div>
        )
    }
};

export default WebsiteSettingsForm;
