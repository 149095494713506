import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import { Helmet } from 'react-helmet';
import { TaskListPage } from "../Task/TaskListPage";

const MyTasksPage = () => {
    return (
        <Container fluid>
            <Helmet>
                <title>My Tasks</title>
            </Helmet>
            <Row>
                <Col>
                    <h1 className="my-3">My Tasks</h1>
                    <TaskListPage/>
                </Col>
            </Row>
        </Container>
    )
}

export { MyTasksPage };

