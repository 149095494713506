import {
  authHeader,
  authHeaderNoContentType,
  handleResponse,
} from "../helpers";
import { ICommunicationLog } from "../customTypings/CommunicationLog";

function create(communicationLog: ICommunicationLog) {
  const requestOptions: RequestInit = {
    method: "POST",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify(communicationLog),
  };
  return fetch(`api/communicationlog/create`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

const update = (communicationLog: ICommunicationLog): Promise<any> => {
  const requestOptions: RequestInit = {
    method: "PATCH",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify(communicationLog),
  };
  return fetch(`api/communicationlog/update`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}


function uploadFile(files: File[], id: string) {
  var promises = Array.from(files).map((file) => {
    var data = new FormData();
    data.append("formFile", file);
    data.append("filename", file.name);
    data.append("CommunicationLogId", id)
    const requestOptions = {
      method: "POST",
      headers: authHeaderNoContentType(), // Important if sending form data - standard authHeader adds application/json content type which breaks multipart form data
      body: data,
    };

    return fetch(`api/communicationlog/upload`, requestOptions).then(
      handleResponse
    );
  });

  return Promise.all(promises);
}

export const communicationLogService = {
  create,
  uploadFile,
  update
};

