import { ICalendarEvent } from '../customTypings/CalendarEvent';
import { authHeader, handleResponse } from '../helpers';

export const calendarEventService = {
    getAllEntries,
    add,
    remove,
    update,
    getAllAppointmentTypeListItems,
    get,
    getAllCalendarIdentitys
};

function getAllEntries(filter: Record<string, any>) {
    const requestOptions = { 
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(filter) 
    };
    return fetch(`api/calendarevent/allentries`, requestOptions).then(handleResponse);
}

function add(calendarEntry: ICalendarEvent){
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader(),
        body: JSON.stringify(calendarEntry)
    };
    return fetch(`api/calendarevent/add`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function remove(calendarId: string, identityId?: string){
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader()
    };
    return fetch(`api/calendarevent/remove/${calendarId}`+(identityId ? `/${identityId}` : ``), requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function update(calendarEntry: ICalendarEvent){
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader(),
        body: JSON.stringify(calendarEntry)
    };
    return fetch(`api/calendarevent/edit`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function getAllAppointmentTypeListItems() {
    const requestOptions = { method: 'POST', headers: authHeader() };
    return fetch(`api/appointmenttype/alllistitems`, requestOptions).then(handleResponse);
}
function get(id: string) {
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(`api/calendarevent/get?id=${id}`, requestOptions)
        .then(handleResponse);
}
function getAllCalendarIdentitys(calendarId: string) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(calendarId)
    };
    return fetch(`api/calendarevent/getallcalendaridentitys?calendarId=${calendarId}`, requestOptions).then(handleResponse);
}