import { IAddress } from "@/customTypings/Address";
import { IContact } from "@/customTypings/Contact";
import { ICustomer } from "@/customTypings/Customer";
import { ICustomerContactNew } from "@/customTypings/CustomerContactNew";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, Formik } from "formik";
import React, { useState } from "react";
import {
    Alert,
    Button, Col, Form, InputGroup, Modal, Row
} from "react-bootstrap";
import * as Yup from "yup";
import { phoneRegExp } from "../../helpers/utils";
import { customerService } from "../../services";
import { LoadingSpinner } from "../LoadingSpinner";

type Props = {
  show: boolean;
  onClose: (updated: boolean) => void;
  customer: ICustomer;
};

const AddCustomerContactModel: React.FC<Props> = ({
  show,
  onClose,
  customer,
}) => {
  const [alertVariant, setAlertVariant] = useState("danger");
  const handleClose = () => onClose(false);
  const labelSpan = 3;
  const fieldSpan = 9;

  return (
    <Modal centered show={show} keyboard={false} onHide={handleClose} size="lg">
      <Formik
        initialValues={{
          contactName: "",
          contactPhone: "",
          contactEmail: "",
          showContactDepartment: customer?.isBusiness,
          contactDepartment: "",
          contactAddressLine1: "",
          contactAddressLine2: "",
          contactAddressLine3: "",
          contactAddressTown: "",
          contactAddressCounty: "",
          contactAddressPostcode: "",
          contactAddressWhat3Words: "",
        }}
        validationSchema={Yup.object().shape({
          showContactDepartment: Yup.boolean(),
          contactName: Yup.string().required("contactName is required"),
          contactPhone: Yup.string()
            .matches(phoneRegExp, "Phone number is not valid")
            .required("Field is required"),
          contactEmail: Yup.string().required("contactEmail is required"),
          contactDepartment: Yup.string().when("showContactDepartment", {
            is: true,
            then: Yup.string().required("showContactDepartment is required"),
          }),
          contactAddressLine1: Yup.string().required(
            "contactAddressLine1 is required"
          ),
          contactAddressTown: Yup.string().required(
            "contactAddressTown is required"
          ),
          contactAddressCounty: Yup.string().required(
            "contactAddressCounty is required"
          ),
          contactAddressPostcode: Yup.string().required(
            "contactAddressPostcode is required"
          ),
          contactAddressWhat3Words: Yup.string().required(
            "contactAddressWhat3Words is required"
          ),
        })}
        onSubmit={(values, { setStatus, setSubmitting, setFieldError }) => {
          setStatus();
          setSubmitting(true);
          const newContact: IContact = {
            name: values.contactName,
            emailAddress: values.contactEmail,
            phoneNumber: values.contactPhone,
          };
          var newAddress: IAddress | undefined;

          newAddress = {
            line1: values.contactAddressLine1,
            line2: values.contactAddressLine2,
            line3: values.contactAddressLine3,
            town: values.contactAddressTown,
            county: values.contactAddressCounty,
            postcode: values.contactAddressPostcode,
            what3words: values.contactAddressWhat3Words,
            latitude: undefined,
            longitude: undefined,
          };

          const newCustomerContact: ICustomerContactNew = {
            id: undefined,
            customerId: customer.id as string,
            contact: newContact,
            address: newAddress,
            department: values.contactDepartment,
            isPrimaryContact: false,
          };
          customerService.addContact(newCustomerContact).then(
            (response) => {
              setSubmitting(false);
              if (response.status !== "Failure") {
                onClose(true);
              } else {
                setAlertVariant("danger");
                setStatus(response.message);
              }
            },
            (error) => {
              setAlertVariant("danger");
              if (error.status === 400) {
                setStatus(error.title);
              } else {
                setStatus(error);
              }
              setSubmitting(false);
            }
          );
        }}
      >
        {({
          errors,
          status,
          touched,
          isSubmitting,
          handleSubmit,
          values,
          handleChange,
          setFieldValue,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Add Contact</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group as={Row} className="mb-3" controlId="contactName">
                <Form.Label column sm={labelSpan}>
                  Name
                </Form.Label>
                <Col sm={fieldSpan}>
                  <Field
                    name="contactName"
                    type="text"
                    className={
                      "form-control" +
                      (errors.contactName && touched.contactName
                        ? " is-invalid"
                        : "")
                    }
                  />
                  <ErrorMessage
                    name="contactName"
                    component="div"
                    className="invalid-feedback"
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="contactPhone">
                <Form.Label column sm={labelSpan}>
                  Phone
                </Form.Label>
                <Col sm={fieldSpan}>
                  <Field
                    name="contactPhone"
                    type="text"
                    className={
                      "form-control" +
                      (errors.contactPhone && touched.contactPhone
                        ? " is-invalid"
                        : "")
                    }
                  />
                  <ErrorMessage
                    name="contactPhone"
                    component="div"
                    className="invalid-feedback"
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="contactEmail">
                <Form.Label column sm={labelSpan}>
                  Email
                </Form.Label>
                <Col sm={fieldSpan}>
                  <Field
                    name="contactEmail"
                    type="text"
                    className={
                      "form-control" +
                      (errors.contactEmail && touched.contactEmail
                        ? " is-invalid"
                        : "")
                    }
                  />
                  <ErrorMessage
                    name="contactEmail"
                    component="div"
                    className="invalid-feedback"
                  />
                </Col>
              </Form.Group>
              {values.showContactDepartment && (
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="contactDepartment"
                >
                  <Form.Label column sm={labelSpan}>
                    Department
                  </Form.Label>
                  <Col sm={fieldSpan}>
                    <Field
                      name="contactDepartment"
                      type="text"
                      className={
                        "form-control" +
                        (errors.contactDepartment && touched.contactDepartment
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="contactDepartment"
                      component="div"
                      className="invalid-feedback"
                    />
                  </Col>
                </Form.Group>
              )}
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="contactAddressLine1"
              >
                <Form.Label column sm={labelSpan}>
                  Address Line 1
                </Form.Label>
                <Col sm={fieldSpan}>
                  <Field
                    name="contactAddressLine1"
                    type="text"
                    className={
                      "form-control" +
                      (errors.contactAddressLine1 && touched.contactAddressLine1
                        ? " is-invalid"
                        : "")
                    }
                  />
                  <ErrorMessage
                    name="contactAddressLine1"
                    component="div"
                    className="invalid-feedback"
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="contactAddressLine2"
              >
                <Form.Label column sm={labelSpan}>
                  Address Line 2
                </Form.Label>
                <Col sm={fieldSpan}>
                  <Field
                    name="contactAddressLine2"
                    type="text"
                    className={
                      "form-control" +
                      (errors.contactAddressLine2 && touched.contactAddressLine2
                        ? " is-invalid"
                        : "")
                    }
                  />
                  <ErrorMessage
                    name="contactAddressLine2"
                    component="div"
                    className="invalid-feedback"
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="contactAddressLine3"
              >
                <Form.Label column sm={labelSpan}>
                  Address Line 3
                </Form.Label>
                <Col sm={fieldSpan}>
                  <Field
                    name="contactAddressLine3"
                    type="text"
                    className={
                      "form-control" +
                      (errors.contactAddressLine3 && touched.contactAddressLine3
                        ? " is-invalid"
                        : "")
                    }
                  />
                  <ErrorMessage
                    name="contactAddressLine3"
                    component="div"
                    className="invalid-feedback"
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="contactAddressTown"
              >
                <Form.Label column sm={labelSpan}>
                  Town
                </Form.Label>
                <Col sm={fieldSpan}>
                  <Field
                    name="contactAddressTown"
                    type="text"
                    className={
                      "form-control" +
                      (errors.contactAddressTown && touched.contactAddressTown
                        ? " is-invalid"
                        : "")
                    }
                  />
                  <ErrorMessage
                    name="contactAddressTown"
                    component="div"
                    className="invalid-feedback"
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="contactAddressCounty"
              >
                <Form.Label column sm={labelSpan}>
                  County
                </Form.Label>
                <Col sm={fieldSpan}>
                  <Field
                    name="contactAddressCounty"
                    type="text"
                    className={
                      "form-control" +
                      (errors.contactAddressCounty &&
                      touched.contactAddressCounty
                        ? " is-invalid"
                        : "")
                    }
                  />
                  <ErrorMessage
                    name="contactAddressCounty"
                    component="div"
                    className="invalid-feedback"
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="contactAddressPostcode"
              >
                <Form.Label column sm={labelSpan}>
                  Postcode
                </Form.Label>
                <Col sm={labelSpan}>
                  <Field
                    name="contactAddressPostcode"
                    type="text"
                    className={
                      "form-control" +
                      (errors.contactAddressPostcode &&
                      touched.contactAddressPostcode
                        ? " is-invalid"
                        : "")
                    }
                  />
                  <ErrorMessage
                    name="contactAddressPostcode"
                    component="div"
                    className="invalid-feedback"
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="contactAddressWhat3Words"
              >
                <Form.Label column sm={labelSpan}>
                  What3Words
                </Form.Label>
                <Col sm={fieldSpan}>
                  <InputGroup hasValidation>
                    <InputGroup.Text>{"///"}</InputGroup.Text>
                    <Form.Control
                      type={"text"}
                      name="contactAddressWhat3Words"
                      value={values.contactAddressWhat3Words}
                      onChange={handleChange}
                      isInvalid={!!errors.contactAddressWhat3Words}
                    />
                    <Button
                      href={
                        "https://what3words.com/" +
                        values.contactAddressWhat3Words
                      }
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faExternalLinkAlt} /> View
                    </Button>
                    <Form.Control.Feedback type="invalid">
                      {errors.contactAddressWhat3Words}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Col>
              </Form.Group>
              {status && (
                <Alert variant={alertVariant} className="mt-3">
                  {status}
                </Alert>
              )}
            </Modal.Body>
            <Modal.Footer>
              <div className="form-group">
                <Button
                  variant="primary"
                  disabled={isSubmitting}
                  type="submit"
                  className="me-2"
                >
                  {isSubmitting ? (
                    <LoadingSpinner text="Adding contact..." />
                  ) : (
                    "Add contact"
                  )}
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                  Cancel
                </Button>
              </div>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export { AddCustomerContactModel };
