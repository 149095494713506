import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, FieldAttributes } from "formik";
import React from "react";
import Select, { components } from "react-select";

interface SelectProps extends FieldAttributes<any> {
  placeholders: { value: string; label: string }[];
  isMulti?: boolean;
}
const Divider = () => <div style={{ margin: "2px 4px" }} className="vr"></div>;

const CustomMultiValueRemove: React.FC = (props: any) => (
  <components.MultiValueRemove {...props}>
    <FontAwesomeIcon
      icon={faXmark}
      className="me-1"
      style={{ color: "darkgray" }}
    />
  </components.MultiValueRemove>
);

const CustomMultiValueContainer: React.FC = (props: any) => (
  <div style={{ display: "flex", alignItems: "center" }}>
    {props.children}
    <Divider />
  </div>
);

const HHTSelect: React.FC<SelectProps> = ({
  placeholders,
  name,
  values,
  setFieldValue,
  isMulti = true,
  ...rest
}) => {

  return (
    <div>
      <Select
        isClearable={false}
        onChange={(selected:any) => setFieldValue(name, selected.label)}
        value={{
          label: values[name],
          value: values[name],
        }}
        options={placeholders}
        isMulti={isMulti}
        components={{
          MultiValueContainer: CustomMultiValueContainer,
          MultiValueRemove: CustomMultiValueRemove,
        }}
      />
      <ErrorMessage name={name} component="div" className="invalid-feedback" />
    </div>
  );
};

export default HHTSelect;
