import dayjs from "dayjs";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Form, Image, Modal, Row, Stack } from "react-bootstrap";
import Select from "react-select";
import * as Yup from "yup";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { DocumentLibrary } from "../constants/DocumentLibraryFolderId";
import { IDocument } from "../customTypings/Document";
import { documentService } from "../services";
import { customerService } from "../services/customer.service";
import { ReactSelectBootstrapStyle } from "../styles/ReactSelectBootstrapStyle";
import HHTField from "./HHTField";

type Props = {
    show: boolean;
    onClose: (updated: boolean) => void;
    item: any;
    title: string;
    buttonTitle?: string;
    properties?: any;
    callback: (data: any) => Promise<any>;
};

const HHTEditModal: React.FC<Props> = ({
    show,
    onClose,
    item,
    title,
    buttonTitle,
    properties,
    callback,
}) => {
    const [alertVariant, setAlertVariant] = useState("danger");
    const [allCustomers, setAllCustomers] = useState<any[]>();
    const [documents, setDocuments] = useState<IDocument[]>([]);
    const [customerId, setCustomerId] = useState<string>("");
    const handleClose = () => onClose(false);
    useEffect(() => {
        Promise.all([customerService.getAllListItems(), documentService.getDocuments(DocumentLibrary.folderId)]).then((response) => {
            setAllCustomers(response[0]);
            const documents = response[1].results
                .filter((d: any) => d.entityId === item.id)
                .map((d: IDocument) => {
                    const dateAdded = dayjs(d.dateAdded);
                    return {
                        ...d,
                        dateAdded: dateAdded.format("DD/MM/YYYY HH:mm")
                    };
                })
            setDocuments(documents);
            setCustomerId(item.customerId);
        });
    }, [item]);

    const handleDownloadDocument = (document: IDocument) => {
        window.open(
            "/api/document/download/" + document.id,
            "_blank",
            "noopener,noreferrer"
        );
    };

    const generateValidationSchema = () => {
        const schemaObject: {
            [key: string]: Yup.StringSchema<string | undefined>;
        } = {};

        Object.keys(item)
            .filter((field) => field !== "id" && !properties[field]?.readOnly)
            .forEach((field) => {
                let fieldSchema;
                if (properties[field]?.type === "email") {
                    fieldSchema = Yup.string()
                        .required(`${field} is required`)
                        .email("must be a valid email");
                } else {
                    fieldSchema = Yup.string().required(`${field} is required`);
                }

                schemaObject[field] = fieldSchema;
            });
        return Yup.object().shape(schemaObject);
    };
    const listIconStyle = { width: "2rem", height: "2rem" };

    return (
        <Modal centered show={show} keyboard={false} onHide={handleClose} size="lg">
            <Formik
                initialValues={{ ...item }}
                validationSchema={generateValidationSchema()}
                onSubmit={(values, { setStatus, setSubmitting, setFieldError }) => {
                    setStatus();
                    setSubmitting(false);
                    callback(values).then(
                        (response) => {
                            setSubmitting(false);
                            if (response.status !== "Failure") {
                                onClose(true);
                            } else {
                                setAlertVariant("danger");
                                setStatus(response.message);
                            }
                        },
                        (error) => {
                            setAlertVariant("danger");
                            setStatus(error);
                            setSubmitting(false);
                        }
                    );
                }}
            >
                {({
                    errors,
                    status,
                    touched,
                    isSubmitting,
                    handleSubmit,
                    values,
                    handleChange,
                    setFieldValue,
                }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>{title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <>
                                {Object.keys(item)
                                    .filter((field) => field !== "id")
                                    .map((field) => {
                                        return (
                                            <Form.Group
                                                as={Row}
                                                className="mb-3"
                                                controlId={field}
                                                key={field}
                                            >
                                                <Form.Label column xs={3}>
                                                    {properties[field].name}
                                                </Form.Label>
                                                <Col xs={9}>
                                                    {properties[field].name === "Customer" ? (
                                                        <Select
                                                            styles={ReactSelectBootstrapStyle}
                                                            className="stateManagedSelect"
                                                            onChange={(item) => {
                                                                setFieldValue(
                                                                    "customerId",
                                                                    item.value
                                                                )
                                                                setCustomerId(item.value)
                                                            }
                                                            }
                                                            value={(allCustomers ?? []).find(customer => customer.value === customerId)}
                                                            options={allCustomers}
                                                        />
                                                    ) : properties[field].name === "Body" ? (
                                                        <Form.Control
                                                            type={properties[field].type}
                                                            name={field}
                                                            as="textarea"
                                                            rows={6}
                                                            value={values.body}
                                                            onChange={(event) => setFieldValue("body", event.target.value)}
                                                        />

                                                    ) : (
                                                        <HHTField
                                                            type={properties[field].type}
                                                            name={field}
                                                            errors={errors}
                                                            touched={touched}
                                                            values={values}
                                                            handleChange={handleChange}
                                                            style={{ ...properties[field].style }}
                                                            readOnly={properties[field].readOnly}
                                                        />
                                                    )}
                                                </Col>
                                            </Form.Group>
                                        );
                                    })}
                                <div className="mt-2">
                                    {documents.length > 0 && (
                                        <Card>
                                            <Card.Header as="h5">Uploaded Files</Card.Header>
                                            <Card.Body>
                                                {documents &&
                                                    documents.map((document) => (
                                                        <Button
                                                            key={document.id}
                                                            className="mb-1 border-0 text-start"
                                                            onClick={() => handleDownloadDocument(document)}
                                                        >
                                                            <Row>
                                                                <Col xs={6}>
                                                                    <Stack direction="horizontal" gap={2}>
                                                                        <Image
                                                                            src={`/api/thumbnail/${document.documentThumbnailId}`}
                                                                            style={listIconStyle}
                                                                            className="image-contain"
                                                                        />
                                                                        {document.fileName}
                                                                    </Stack>
                                                                </Col>
                                                            </Row>
                                                        </Button>
                                                    ))}
                                            </Card.Body>
                                        </Card>
                                    )}
                                </div>
                                {status && (
                                    <Alert variant={alertVariant} className="mt-3">
                                        {status}
                                    </Alert>
                                )}
                            </>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="form-group">
                                <Button
                                    variant="primary"
                                    disabled={isSubmitting}
                                    type="submit"
                                    className="me-2"
                                >
                                    {isSubmitting ? <LoadingSpinner text={title} /> : buttonTitle}
                                </Button>
                                <Button variant="secondary" onClick={handleClose}>
                                    Cancel
                                </Button>
                            </div>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export { HHTEditModal };
