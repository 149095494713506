import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { ErrorMessage, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { taskService, userService } from "../../services";
import { ReactSelectBootstrapStyle } from "../../styles/ReactSelectBootstrapStyle";
dayjs.extend(utc);


type Props = {
    show: boolean;
    onClose: (added: boolean) => void;
    taskId?: string;
};

const ReAssignTask: React.FC<Props> = ({ show, onClose, taskId }) => {
    const [alertVariant, setAlertVariant] = useState("danger");
    const [allUsers, setAllUsers] = useState<any[]>();
    const [currentTask, setCurrentTask] = useState<any>();

    useEffect(() => {
        getTask();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getTask = () => {
        if (taskId) {
            taskService.get(taskId).then((task) => {
                setCurrentTask(task);
            });
        }
    }

    const handleClose = () => onClose(false);

    useEffect(() => {
        Promise.all([userService.getAllListItems()]).then((response) => {
            setAllUsers(response[0]);
        });
    }, []);

    return (
        <Modal centered show={show} keyboard={false} onHide={handleClose}>
            <Formik
                initialValues={{
                    userId: "",
                }}
                onSubmit={(values, { setStatus, setSubmitting, setFieldError }) => {
                    setStatus();
                    setSubmitting(false);
                    taskService.edit({ ...currentTask, userId: values.userId }).then(
                        (response) => {
                            setSubmitting(false);
                            if (response.status !== "Failure") {
                                onClose(true);
                            } else {
                                setAlertVariant("danger");
                                setStatus(response.message);
                            }
                        },
                        (error) => {
                            setAlertVariant("danger");
                            if (error.status === 400) {
                                setStatus(error.title);
                                setFieldError("productId", error.errors.ProductId);
                                setFieldError("customerId", error.errors.CustomerId);
                            } else {
                                setStatus(error);
                            }
                            setSubmitting(false);
                        }
                    );

                }}
            >
                {
                    ({ errors, status, touched, isSubmitting, handleSubmit, values, handleChange, setFieldValue }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Header closeButton>
                                <Modal.Title>ReAssign Task</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form.Group as={Row} className="mb-3" controlId="taskUserId">
                                    <Form.Label column sm={3}>
                                        Personnel
                                    </Form.Label>
                                    <Col sm={9}>
                                        <Select
                                            styles={ReactSelectBootstrapStyle}
                                            className="stateManagedSelect"
                                            onChange={(value) => setFieldValue("userId", value == null ? "" : value.value)}
                                            isClearable
                                            options={allUsers}
                                        />
                                        <ErrorMessage name="userId" component="div" className="invalid-feedback" />
                                    </Col>
                                </Form.Group>

                                {status && (
                                    <Alert variant={alertVariant} className="mt-3">
                                        {status}
                                    </Alert>
                                )}
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="form-group">
                                    <Button variant="primary" disabled={isSubmitting} type="submit" className="me-2">
                                        {isSubmitting ? <LoadingSpinner text="ReAssigning Task..." /> : "ReAssign Task"}
                                    </Button>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Cancel
                                    </Button>
                                </div>
                            </Modal.Footer>
                        </Form>
                    )
                }
            </Formik>
        </Modal>
    );
};

export { ReAssignTask };
