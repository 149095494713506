import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAtom } from "@dbeining/react-atom";
import { Alert, Button, ListGroup, Modal } from "react-bootstrap";
import { userAtom } from "../../atoms/userAtom";
import { IIdentity } from "../../customTypings/Identity";
import { accountService, authenticationService } from "../../services";

type Props = {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<any>>;
};

const SwitchOrganisation: React.FC<Props> = ({ show, setShow }) => {
    let navigate = useNavigate();
    const [userIdentities, setUserIdentities] = useState<IIdentity[]>();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const currentUser = useAtom(userAtom);

    const handleClose = () => setShow(false);

    function switchUser(id: string) {
        authenticationService.switchuser(id).then(
            () => {
                handleClose();
                navigate("/", { state: { message: "Ok" } });
            },
            (error) => {
                if (error.status === 400) {
                    setErrorMessage("Account switch failed.");
                } else {
                    setErrorMessage(error);
                }
            }
        );
    }

    useEffect(() => {
        accountService
            .getAllIdentities()
            .then((identities: IIdentity[]) => setUserIdentities(identities));
    }, []);

    return (
        <Modal
            centered
            show={show}
            keyboard={false}
            onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Organisations
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {userIdentities && (
                    <ListGroup>
                        {userIdentities.map((identity) => (
                            <ListGroup.Item
                                className="d-flex justify-content-between align-items-start"
                                key={identity.id}
                                action
                                as={Button}
                                disabled={
                                    currentUser?.identity.id === identity.id
                                }
                                onClick={() => {
                                    switchUser(identity.id);
                                }}
                            >
                                <div>{identity.organisation.displayName}</div>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                )}
                {errorMessage && (
                    <Alert variant="danger" className="mt-3">
                        {errorMessage}
                    </Alert>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export { SwitchOrganisation };
