import { updateAccessDeniedStateAtom } from '../atoms/accessDeniedAtom';
import { authenticationService } from '../services';

export function handleResponse(response: { text: () => Promise<any>; ok: any; status: number; statusText: any; }) {
    return response.text().then((text: string) => {
        let data = null;
        let hasData = false;
        try {
            data = text && JSON.parse(text);
            hasData = true;
        }
        catch {}
        
        if (!response.ok) {
            if ([403].indexOf(response.status) !== -1) {
                // if 403 Forbidden
                updateAccessDeniedStateAtom(true)
            }
            if ([401].indexOf(response.status) !== -1) {
                // auto logout if 401 Unauthorized response returned from api
                authenticationService.logout();
            }
            if (response.status === 400) {
                if(data && data.message)
                    return Promise.reject(data.message);
                return Promise.reject(hasData ? data : text);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return hasData ? data : text; 
    });
}