import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { TimesheetListPage } from "../Timesheet/TimesheetListPage";

const TimesheetPage = () => {
    return (
        <Container fluid>
            <Helmet>
                <title>Timesheets</title>
            </Helmet>
            <Row>
                <Col>
                    <h1 className="my-3">Timesheets</h1>
                    <TimesheetListPage />
                </Col>
            </Row>
        </Container>
    );
};

export { TimesheetPage };
