import { ErrorMessage, Field, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";

import { IIdentity } from "@/customTypings/Identity";
import { useAtom } from "@dbeining/react-atom";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { updateUserStateAtom, userAtom } from "../../atoms/userAtom";
import { IUser } from "../../customTypings/user";
import { clone } from "../../helpers/utils";
import { userService } from "../../services";
import { Avatar } from "../Avatar";
import { LoadingSpinner } from "../LoadingSpinner";

type Props = {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<any>>;
    user: IIdentity;
    setUserInteraction?: React.Dispatch<React.SetStateAction<any>>;
};

const UserDisplayNameEdit: React.FC<Props> = ({
    show,
    setShow,
    user,
    setUserInteraction,
}) => {
    const [alertVariant, setAlertVariant] = useState("danger");

    const handleClose = () => setShow(false);
    const currentUserAtom = useAtom(userAtom);

    return (
        <Modal centered show={show} keyboard={false} onHide={handleClose}>
            <Formik
                initialValues={{
                    displayName: user.displayName ?? "",
                }}
                validationSchema={Yup.object().shape({
                    displayName: Yup.string().required("Username is required"),
                })}
                onSubmit={(values, { setStatus, setSubmitting }) => {
                    setStatus();
                    userService
                        .updateDisplayName(user.id, values.displayName)
                        .then(
                            (response) => {
                                setSubmitting(false);
                                if (response.status !== "Failure") {
                                    const copy = clone<IUser>(
                                        currentUserAtom as IUser
                                    );
                                    copy.identity.displayName =
                                        values.displayName;
                                    updateUserStateAtom(copy);
                                    if (setUserInteraction !== undefined) {
                                        setUserInteraction(true);
                                    }
                                    handleClose();
                                } else {
                                    setAlertVariant("danger");
                                    setStatus(response.message);
                                }
                            },
                            (error) => {
                                setAlertVariant("danger");
                                if (error.status === 400) {
                                    setStatus(error.title);
                                } else {
                                    setStatus(error);
                                }
                                setSubmitting(false);
                            }
                        );
                }}
            >
                {({ errors, touched, status, isSubmitting }) => (
                    <Form>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Display Name</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <h5 className="mb-3">
                                <Avatar identity={user} />
                            </h5>

                            <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="displayName"
                            >
                                <Form.Label column sm={3}>
                                    Display Name
                                </Form.Label>
                                <Col sm={9}>
                                    <Field
                                        name="displayName"
                                        type="text"
                                        autoFocus
                                        className={
                                            "form-control" +
                                            (errors.displayName &&
                                            touched.displayName
                                                ? " is-invalid"
                                                : "")
                                        }
                                    />
                                    <ErrorMessage
                                        name="displayName"
                                        component="div"
                                        className="invalid-feedback"
                                    />
                                </Col>
                            </Form.Group>
                            {status && (
                                <Alert variant={alertVariant} className="mt-3">
                                    {status}
                                </Alert>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="form-group">
                                <Button
                                    variant="primary"
                                    disabled={isSubmitting}
                                    type="submit"
                                    className="me-2"
                                >
                                    {isSubmitting ? (
                                        <LoadingSpinner text="Updating..." />
                                    ) : (
                                        "Update"
                                    )}
                                </Button>
                                <Button
                                    variant="secondary"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export { UserDisplayNameEdit };
