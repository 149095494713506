import { ITask } from "@/customTypings/Task";
import { authHeader, handleResponse } from "../helpers";
import { ITaskCategory } from "@/customTypings/TaskCategory";
import { ITaskType } from "@/customTypings/TaskType";

export const taskService = {
  getAllListItems,
  getAllListTaskTypeItems,
  add,
  get,
  edit,
  complete,
  addTaskCategory,
  addTaskType,
  getTaskCategories,
  fullTask,
  deleteTaskType,
  editTaskType,
  editTaskCategory,
  getAll
};

function getAllListItems() {
  const requestOptions = { method: "POST", headers: authHeader() };
  return fetch(`api/task/alllistitems`, requestOptions).then(handleResponse);
}

function getAllListTaskTypeItems() {
  const requestOptions = { method: "POST", headers: authHeader() };
  return fetch(`api/task/alllisttasktypeitems`, requestOptions).then(
    handleResponse
  );
}

function add(task: ITask) {
  const requestOptions: RequestInit = {
    method: "POST",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify(task),
  };
  return fetch(`api/task/add`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function get(taskId: string) {
  const requestOptions: RequestInit = {
    method: "GET",
    credentials: "include",
    headers: authHeader(),
  };
  return fetch(`api/task/get/${taskId}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function edit(task: ITask) {
  const requestOptions: RequestInit = {
    method: "POST",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify(task),
  };
  return fetch(`api/task/edit`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function complete(id: string) {
  const requestOptions: RequestInit = {
    method: "POST",
    credentials: "include",
    headers: authHeader(),
  };
  return fetch(`api/task/complete?id=${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function addTaskCategory(taskCategory: ITaskCategory) {
  const requestOptions: RequestInit = {
    method: "POST",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify(taskCategory),
  };
  return fetch(`api/task/createtaskcategory`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function getTaskCategories() {
  const requestOptions: RequestInit = {
    method: "POST",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filter: { name: null },
      offset: 0,
      count: 25,
      sortBy: "name",
      sortOrder: 0,
    }),
  };
  return fetch(`api/task/taskcategories`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function addTaskType(taskType: ITaskType) {
  const requestOptions: RequestInit = {
    method: "POST",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify(taskType),
  };
  return fetch(`api/task/createtasktype`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function fullTask(id: string) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(`api/task/fulltask?id=${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function deleteTaskType(id: string | undefined) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  return fetch(`api/task/deletetasktype?id=${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function editTaskType(taskType: ITaskType) {
  const requestOptions: RequestInit = {
    method: "PATCH",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify(taskType),
  };
  return fetch(`api/task/updatetasktype`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function editTaskCategory(taskCategory: ITaskCategory) {
  const requestOptions: RequestInit = {
    method: "PATCH",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify(taskCategory),
  };
  return fetch(`api/task/updatetaskcategory`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}
function getAll() {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',
    };

    return fetch(`api/task/alltasks`, requestOptions)
        .then(handleResponse);
}
