import { faPen } from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb } from "react-bootstrap";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { ColumnRender, FilterType, IColumn, IFilter, IPagedListAction, IPagedViewResponse } from "../../components/PagedList/Declarations";
import { PagedList } from "../../components/PagedList/PagedList";

const FormListPage = () => {
    const navigate = useNavigate();
    // Sample leads data
    const formsData: IPagedViewResponse = {
        offset: 0,
        count: 25,
        total: 6,
        results: [
            { name: "Site Visit Form" },
            { name: "Delivery/Installation Form" },
            { name: "Spa Fresh Service Report Form" },
            { name: "Annual Service Report Form" },
            { name: "Hot Tub Order Form" },
            { name: "Endless Pools Order Form" }
        ] as any
    }

    return (
        <>
        <Container fluid>
            <Row>
                <Col>
                    <Helmet>
                        <title>Forms</title>
                    </Helmet>

                    <Breadcrumb>
                        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/settings" }}>
                            Settings
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Forms</Breadcrumb.Item>
                    </Breadcrumb>

                    <h1 className="my-3">Forms</h1>
                    <PagedList
                        columns={[
                            {
                                member: "name",
                                renderAs: ColumnRender.text,
                                title: "Name",
                                width: 200,
                            } as IColumn,
                        ]}
                        itemActions={[
                            {
                                text: "Complete",
                                onClick: (item) => {
                                    if (item.name === "Site Visit Form") {
                                        navigate('/siteVisitForm');
                                    }
                                    else if (item.name === 'Delivery/Installation Form') {
                                        navigate('/deliveryOrInstallationForm');
                                    }
                                    else if (item.name === "Hot Tub Order Form") {
                                        navigate('/hotTubOrderForm');
                                    }
                                    else if (item.name === "Endless Pools Order Form") {
                                        navigate('/endlessPoolsOrderForm');
                                    }
                                    else if (item.name === "Spa Fresh Service Report Form") {
                                        navigate('/spaFreshReportForm');
                                    }
                                    else if (item.name === "Annual Service Report Form") {
                                        navigate('/annualServiceReportForm');
                                    }
                                },
                                primary: true,
                                icon: faPen
                            } as IPagedListAction,
                        ]}
                        filters={[
                            {
                                title: "Name",
                                member: "name",
                                type: FilterType.text,
                            } as IFilter,
                        ]}
                        defaultSortBy="name"
                        rowFormatter={(item: any) => {
                            return null;
                        }}
                        persistanceKey="LeadListPage"
                        items={formsData}
                        getUrl={""}
                    />
                </Col>
            </Row>
            </Container>
        </>
    );
};

export { FormListPage };
