import { useEffect, useState } from 'react';

import { invitationService } from '../../services';

import { Button, Container, ListGroup } from 'react-bootstrap';
import { AddNewUser, InvitationAdmin } from '../../components';
import { IInvitation } from '../../customTypings/Invitation';

const AdminPage = () => {
  const [userInvitations, setUserInvitations] = useState<IInvitation[] | null>(null)
  const [showInviteUser, setShowInviteUser] = useState(false)
  
  const updateUsers = ()=>{
    invitationService.getAll().then(invitations => setUserInvitations(invitations));
  };

  useEffect(() => {
    updateUsers()
  }, []);

  const handleClose = () => {
    setShowInviteUser(false);
  }

  return (
      <Container fluid>
          <h1>Admin</h1>
          <p>This page can only be accessed by administrators.</p>
          <div>
              All users from secure (admin only) api end point:
  
              {userInvitations &&
                <ListGroup>
                  {userInvitations.map((invitation: IInvitation) =>
                    <ListGroup.Item className="d-flex justify-content-between align-items-start" key={invitation.tokenId}>
                      <InvitationAdmin invitation={invitation} onUpdate={updateUsers}/>
                    </ListGroup.Item>
                  )}
                </ListGroup>
              }
          </div>
          
          <Button 
            className="mt-2"
            variant="success"
            disabled={showInviteUser}
            onClick={() => setShowInviteUser(true)}>
              Invite User
          </Button>
          <AddNewUser show={showInviteUser} onClose={handleClose} onAdded={updateUsers} />
      </Container>
  );
}

export { AdminPage };
