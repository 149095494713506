import { usePDF } from "react-to-pdf";
import "../../styles/form.css";

type Props = {
    currentForm: any;
};

const AnnualAndSpaPdfs: React.FC<Props> = ({ currentForm }) => {
    const { toPDF, targetRef } = usePDF({ filename: "page.pdf" });

    const annualServiceChecks = [
        "Visual plumbing check",
        "Clean and buff spa shel",
        "Test jet functions",
        "Clean headrests(if fitted)",
        "Test topside diverter/air valves(if fitted)",
        "Clean and refit filter(s)",
        "Test ozone output(if fitted)",
        "Clean outside of cabinet & steps",
        "Visual check of pump and heater seals",
        "Vacuum inside cabinet(where access allows)",
        "Test all electrical components",
        "Clean cover lifter(if fitted)",
        "Visually check circuit board",
        "Clean cover and treat with UV protection",
        "Test RCD & isolator(if fitted)",
        "Refill hot tub to correct level",
        "Complete bio film pipe clean",
        "Add sanitizer",
        "Drain rinse and wet vacuum spa and jets",
        "Balance pH level",
    ];

    const spaServiceChecks = ['Complete bio film pipe clean', 'Add sanitizer',
        'Drain rinse and wet vacuum spa and jets', 'Balance pH level',
        'Clean and refit filter(s)', 'Check heater and activated',
        'Clean headrests(if fitted)', 'Clean outside of cabinet & steps',
        'Refill hot tub to correct level', 'Clean cover and treat with UV protection']
    const serviceChecks = (currentForm?.name === "Annual Service Report Form") ? annualServiceChecks : spaServiceChecks;
    const currentFormServiceChecks = currentForm?.serviceChecks?.split(",");
    return (
        <div>
            <button onClick={() => toPDF()}>Download PDF</button>
            <div ref={targetRef} className="download-pdf p-2 m-auto">
                <div>
                    <div className="d-flex">
                        <img className="logo" src="/happy-hot-tubs-logo.svg" alt="" />
                        <div className="heading" style={{ width: "100%" }}>
                            <strong><h3 style={{ color: "#4A9CE8" }} >{currentForm?.name.substring(0, currentForm?.name.lastIndexOf(" Form"))}</h3></strong>
                        </div>
                    </div>
                    <div className="form-table-container">
                        <strong>Customer Details</strong>
                        <table className="form-table">
                            <tbody>
                                <tr>
                                    <td className="form-th">Customer Name</td>
                                    <td className="form-td" style={{ width: "200px" }}>{currentForm?.customerName}</td>
                                    <td className="form-th">Postcode</td>
                                    <td className="form-td" style={{ width: "200px" }}>
                                        {currentForm?.customerContactPostcode}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="form-table-container">
                        <strong>Pre-service chemical check</strong>
                        <table className="form-table" border={2}>
                            <tbody>
                                <tr>
                                    <td className="form-th">Sanitizer</td>
                                    <td className="form-td" style={{ width: "200px" }}>{currentForm?.sanitiser}</td>
                                    <td className="form-th">pH Level</td>
                                    <td className="form-td" style={{ width: "200px" }}>{currentForm?.phLevel}</td>
                                </tr>
                                <tr>
                                    <td className="form-th">Appearance of water</td>
                                    <td className="form-td" style={{ width: "150px" }} colSpan={3}>
                                        <div className="d-flex">
                                            <div
                                                className={`circle ${currentForm?.waterAppearance.toLowerCase()}`}
                                            ></div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="form-th">Recommendations</td>
                                    <td className="form-td" style={{ width: "150px" }} colSpan={3}>
                                        {currentForm?.chemicalRecommendation}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="form-table-container">
                        <strong>Service Checks</strong>
                        <table className="form-table">
                            <tbody>
                                {serviceChecks.map((check, index) => {
                                    if (index % 2 === 0) {
                                        const nextCheck = serviceChecks[index + 1];
                                        return (
                                            <tr key={index}>
                                                <td className="form-td" style={{ width: "5%" }}>
                                                    <input
                                                        checked={currentFormServiceChecks?.includes(check)}
                                                        type="checkbox"
                                                    />
                                                </td>
                                                <td className="form-td" style={{ width: "45%" }}>{check}</td>
                                                <td className="form-td" style={{ width: "5%" }}>
                                                    <input
                                                        checked={currentFormServiceChecks?.includes(nextCheck)}
                                                        type="checkbox"
                                                    />
                                                </td>
                                                <td className="form-td" style={{ width: "45%" }}>{nextCheck}</td>
                                            </tr>
                                        );
                                    }
                                    return null;
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="mb-3">
                        Any comments/recommendations.............................................................................................................................................................................
                        ...................................................................................................................................................................................................................................
                        ...................................................................................................................................................................................................................................
                    </div>
                    <div className="form-table-container">
                        <strong>Accessory Checks</strong>
                        <table className="mb-3 form-table">
                            <thead>
                                <th className="form-th" style={{ width: "20%" }}>Item</th>
                                <th className="form-th" style={{ width: "20%" }}>Condition</th>
                                <th className="form-th" style={{ width: "60%" }}>Comments/Recommendations</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="form-td">Filters</td>
                                    <td className="form-td">
                                        <div className="d-flex ">
                                            <div
                                                className={`circle ${currentForm?.filterConditon.toLowerCase()}`}
                                            ></div>
                                        </div>
                                    </td>
                                    <td className="form-td">{currentForm?.filterRecommendation}</td>
                                </tr>
                                <tr>
                                    <td className="form-td">Covers</td>
                                    <td className="form-td">
                                        <div className="d-flex ">
                                            <div
                                                className={`circle ${currentForm?.coverConditon.toLowerCase()}`}
                                            ></div>
                                        </div>
                                    </td>
                                    <td className="form-td">{currentForm?.coverRecommendation}</td>
                                </tr>
                                <tr>
                                    <td className="form-td">Cover Lifters (if fitted)</td>
                                    <td className="form-td">
                                        <div className="d-flex ">
                                            <div
                                                className={`circle ${currentForm?.coverLifterConditon.toLowerCase()}`}
                                            ></div>
                                        </div>
                                    </td>
                                    <td className="form-td">{currentForm?.coverLifterRecommendation}</td>
                                </tr>
                                <tr>
                                    <td className="form-td">Headrests (if fitted)</td>
                                    <td className="form-td">
                                        <div className="d-flex ">
                                            <div
                                                className={`circle ${currentForm?.headrestConditon.toLowerCase()}`}
                                            ></div>
                                        </div>
                                    </td>
                                    <td className="form-td">{currentForm?.headrestRecommendation}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="d-flex mb-3">
                        <div>Quality for lasting care plan</div>
                        <span style={{ marginRight: '10px' }}></span>
                        <input type="checkbox" checked={currentForm?.qualifyForLastingCarePlan} />&nbsp;
                        <span style={{ marginRight: '10px' }}> Yes</span>
                        <input type="checkbox" checked={!currentForm?.qualifyForLastingCarePlan} />&nbsp;
                        No
                    </div>
                    <div className="form-table-container">
                        <table className="form-table" border={2}>
                            <tbody>
                                <tr>
                                    <td className="form-th" style={{ width: "15%" }}>Engineer Name</td>
                                    <td className="form-td" style={{ width: "25%" }}>{currentForm?.visitEngineerName}</td>
                                    <td className="form-th" style={{ width: "20%" }}>Engineer Signature</td>
                                    <td className="form-td" style={{ width: "18%" }}></td>
                                    <td className="form-th" style={{ width: "9%" }}>Date</td>
                                    <td className="form-td" style={{ width: "13%" }}>{currentForm?.visitDate}</td>
                                </tr>
                                <tr>
                                    <td className="form-th" style={{ width: "15%" }}>Customer Name</td>
                                    <td className="form-td" style={{ width: "25%" }}>{currentForm?.customerFullName}</td>
                                    <td className="form-th" style={{ width: "20%" }}>Customer Signature</td>
                                    <td className="form-td" style={{ width: "18%" }}>
                                        <img height={35} width={150} src={currentForm?.customerSignature} alt="signature" />
                                    </td>
                                    <td className="form-th" style={{ width: "9%" }}>Date</td>
                                    <td className="form-td" style={{ width: "13%" }}></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="heading" style={{ width: "100%" }}>
                        <strong><h3 style={{ color: "#4A9CE8" }}><a href="https://www.happyhottubs.co.uk">www.happyhottubs.co.uk</a></h3></strong>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AnnualAndSpaPdfs;
