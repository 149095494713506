const placeholders = {
    Task: {
        "{TaskName}": { description: "Task name", type: "both" },
        "{CustomerName}": { description: "Customer name", type: "both" },
        "{ContactName}": { description: "Contact name", type: "both" },
        "{TaskType}": { description: "Task type", type: "both" },
        "{TaskURL}": { description: "Task url", type: "both" },
        "{PersonnelList}": { description: "Personnel list", type: "both" },
        "{Actioner}": { description: "Actioner", type: "both" },
        "{CreationDate}": { description: "CreationDate", type: "both" },
        "{CompletionDate}": { description: "CompletionDate", type: "completion" },
        "{DeletionDate}": { description: "DeletionDate", type: "deletion" },
        "{DueDate}": { description: "Due Date", type: "both" },
        "{Location}": { description: "Location", type: "both" },
        "{RecursiveInfoIfRecursive}": { description: "Recursive Info (If Recursive)", type: "both" },
        "{ProductInfoIfRelated}": { description: "Product Info (If related)", type: "both" },
    },
};

const TaskCompletionPlaceholders = Object.entries(placeholders.Task)
    .filter(([key, value]) => value.type === "both" || value.type === "completion")
    .reduce((obj, [key, value]) => {
        obj[key] = value.description;
        return obj;
    }, {} as { [key: string]: string });

const TaskDeletionPlaceholders = Object.entries(placeholders.Task)
    .filter(([key, value]) => value.type === "both" || value.type === "deletion")
    .reduce((obj, [key, value]) => {
        obj[key] = value.description;
        return obj;
    }, {} as { [key: string]: string });

const TaskCreationPlaceholders = Object.entries(placeholders.Task)
    .filter(([key, value]) => value.type === "both")
    .reduce((obj, [key, value]) => {
        obj[key] = value.description;
        return obj;
    }, {} as { [key: string]: string });

export const EventCreatePlaceholders = {
    "{EventName}": "Event name",
    "{EventType}": "Event type",
    "{PersonnelList}": "Personnel list",
    "{StartTime}": "Start time",
    "{EndTime}": "End time",
    "{Actioner}": "Actioner",
    "{CreationDate}": "Creation date",
    "{Location}": "Location",
    "{RecursiveInfoIfRecursive}": "Recursive info (if recursive)",
    "{TaskNameIfRelated}": "Task name (if related)",
    "{TaskURLIfRelated}": "Task URL (if related)",
};

export const EventReSchedulePlaceholders = {
    "{EventName}": "Event name",
    "{EventType}": "Event type",
    "{PersonnelList}": "Personnel list",
    "{OldStartTime}": "Old Start time",
    "{OldEndTime}": "Old End time",
    "{NewStartTime}": "New Start time",
    "{NewEndTime}": "New End time",
    "{Actioner}": "Actioner",
    "{CreationDate}": "Creation date",
    "{ReScheduleDate}": "Reschedule date",
    "{Location}": "Location",
    "{SingleEventOrSeriesIfRecursive}": "Single event or series(if recursive)",
    "{OldRecursiveInfoIfRecursive}": "Old Recursive info (if recursive)",
    "{NewRecursiveInfoIfRecursive}": "New Recursive info (if recursive)",
    "{TaskNameIfRelated}": "Task name (if related)",
    "{TaskURLIfRelated}": "Task URL (if related)",
};

export const EventDeletePlaceholders = {
    "{EventName}": "Event name",
    "{EventType}": "Event type",
    "{PersonnelList}": "Personnel list",
    "{StartTime}": "Start time",
    "{EndTime}": "End time",
    "{Actioner}": "Actioner",
    "{CreationDate}": "Creation date",
    "{CancellationDate}": "Cancellation date",
    "{Location}": "Location",
    "{SingleEventOrSeriesIfRecursive}": "Single event or series(if recursive)",
    "{RecursiveInfoIfRecursive}": "Recursive info (if recursive)",
    "{TaskNameIfRelated}": "Task name (if related)",
    "{TaskURLIfRelated}": "Task URL (if related)",
};

const SiteVisitSignedPlaceholders = {
    Header: {
        Action: {
            "{TimeCompleted}": "Time Completed",
        },
        Task: {
            "{TaskName}": "Name",
            "{TaskURL}": "URL",
        },
    },
    Placeholders: {
        Customer: {
            "{CustomerName}": "Customer name",
            "{CustomerContactName}": "Customer contact name",
            "{CustomerAddress}": "Customer address",
            "{CustomerHomeTel}": "Customer home telephone",
            "{CustomerMobileTel}": "Customer mobile telephone",
            "{CustomerWorkTel}": "Customer work telephone",
            "{CustomerOtherTel}": "Customer other telephone",
            "{CustomerEmail}": "Customer email",
        },
        Product: {
            "{ProductMake}": "Product make",
            "{ProductModel}": "Product model",
            "{ProductColour}": "Product colour",
            "{ProductCabinet}": "Product cabinet",
            "{ProductSerialNumber}": "Product serial number",
            "{ProductDeliveryDate}": "Product delivery date",
            "{ProductFilterCode}": "Product filter code",
            "{ProductFreshWater}": "Product fresh water",
            "{ProductBromine}": "Product bromine",
            "{ProductWarranty}": "Product warranty",
            "{ProductComments}": "Product comments",
        },
        ServicePlan: {
            "{ServicePlanHPWOCare}": "Service plan HPWO Care",
            "{ServicePlanHPWSilk}": "Service plan HPW Silk",
            "{ServicePlanNoPumpOSB}": "Service plan No Pump OSB",
            "{ServicePlanLC}": "Service plan LC",
            "{ServicePlanLCLite}": "Service plan LC Lite",
            "{ServicePlanLPC}": "Service plan LPC",
            "{ServicePlanGold}": "Service plan Gold",
            "{ServicePlanUltimate}": "Service plan Ultimate",
            "{ServicePlanEPWellness}": "Service plan EP Wellness",
            "{ServicePlanEPWellnessPlus}": "Service plan EP Wellness Plus",
        },
        Visit: {
            "{VisitType}": "Visit type",
            "{VisitDetails}": "Visit details",
            "{VisitScheduledBy}": "Visit scheduled by",
            "{VisitListOfProductsAndOrAccessoriesSold}":
                "Visit list of products and/or accessories sold",
            "{VisitActionTakenByEngineerAndNotes}":
                "Visit action taken by engineer and notes",
            "{FollowUpVisitRequired}": "Follow-up visit required",
            "{VisitDateBooked}": "Visit date booked",
            "{VisitPaymentMethod}": "Visit payment method",
            "{VisitEngineerName}": "Visit engineer name",
            "{VisitDate}": "Visit date",
        },
    },
};

const SiteVisitPlaceholders = {
    Header: {
        Action: {
            "{SigningURL}": "Signing URL",
        },
    },

    Placeholders: {
        Customer: {
            "{CustomerName}": "Name",
            "{CustomerContactName}": "ContactName",
            "{CustomerAddress}": "Address",
            "{CustomerHomeTel}": "Home telephone",
            "{CustomerMobileTel}": "Mobile telephone",
            "{CustomerWorkTel}": "Work telephone",
            "{CustomerOtherTel}": "Other telephone",
            "{CustomerEmail}": "Email",
        },
        Product: {
            "{ProductMake}": "Make",
            "{ProductModel}": "Model",
            "{ProductColour}": "Colour",
            "{ProductCabinet}": "Cabinet",
            "{ProductSerialNumber}": "Serial number",
            "{ProductDeliveryDate}": "Delivery date",
            "{ProductFilterCode}": "Filter code",
            "{ProductFreshWater}": "Fresh water",
            "{ProductBromine}": "Bromine",
            "{ProductWarranty}": "Warranty",
            "{ProductComments}": "Comments",
        },
        ServicePlan: {
            "{ServicePlanHPWOCare}": "HPWO Care",
            "{ServicePlanHPWSilk}": "HPW Silk",
            "{ServicePlanNoPumpOSB}": "No Pump OSB",
            "{ServicePlanLC}": "LC",
            "{ServicePlanLCLite}": "LC Lite",
            "{ServicePlanLPC}": "LPC",
            "{ServicePlanGold}": "Gold",
            "{ServicePlanUltimate}": "Ultimate",
            "{ServicePlanEPWellness}": "EP Wellness",
            "{ServicePlanEPWellnessPlus}": "EP Wellness Plus",
        },
        Visit: {
            "{VisitType}": "Type",
            "{VisitDetails}": "Details",
            "{VisitScheduledBy}": "Scheduled by",
            "{VisitListOfProductsAndOrAccessoriesSold}":
                "List of products and/or accessories sold",
            "{VisitActionTakenByEngineerAndNotes}":
                "Action taken by engineer and notes",
            "{FollowUpVisitRequired}": "Follow-up visit required",
            "{VisitDateBooked}": "Date booked",
            "{VisitPaymentMethod}": "Payment method",
            "{VisitEngineerName}": "Engineer name",
            "{VisitDate}": "Date",
            "{CustomerVisitComment}": "Customer visit comment",
        },
    },
};

const DeliveryInstallationFormPlaceholders = {
    "{SigningURL}": "Signing URL",
    "{CustomerName}": "Customer name",
    "{CustomerContactName}": "Customer contact name",
    "{CustomerContactTitle}": "Customer contact title",
    "{CustomerAddress}": "Customer address",
    "{CustomerHomeTel}": "Customer home telephone",
    "{CustomerMobileTel}": "Customer mobile telephone",
    "{CustomerWorkTel}": "Customer work telephone",
    "{CustomerOtherTel}": "Customer other telephone",
    "{CustomerEmail}": "Customer email",
    "{AccessNotes}": "Access notes",
    "{ProductMake}": "Product make",
    "{ProductModel}": "Product model",
    "{ProductSerialNumber}": "Product serial number",
    "{ProductColour}": "Product colour",
    "{ProductCabinet}": "Product cabinet",
    "{ProductCondition}": "Product condition",
    "{ProductComments}": "Product comments",
    "{OrderNumber}": "Order number",
    "{SalesPersonName}": "Salesperson name",
    "{Balance}": "Balance",
    "{PaymentMethod}": "Payment method",
    "{DateBalancePaid}": "Date balance paid",
    "{FinanceAgreementName}": "Finance agreement name",
    "{ServicePlanHPWOCare}": "Service plan HPWO Care",
    "{ServicePlanHPWSilk}": "Service plan HPW Silk",
    "{ServicePlanNoPumpOSB}": "Service plan No Pump OSB",
    "{ServicePlanLC}": "Service plan LC",
    "{ServicePlanLCLite}": "Service plan LC Lite",
    "{ServicePlanLPC}": "Service plan LPC",
    "{ServicePlanGold}": "Service plan Gold",
    "{ServicePlanUltimate}": "Service plan Ultimate",
    "{ServicePlanEPWellness}": "Service plan EP Wellness",
    "{ServicePlanEPWellnessPlus}": "Service plan EP Wellness Plus",
    "{ExtrasList}": "Extras list",
    "{EngineerNameList}": "Engineer name list",
    "{EngineerDate}": "Engineer date",
    "{AccessClear}": "Access clear",
    "{AccessComments}": "Access comments",
    "{VisitComments}": "Visit comments",
};

const DeliveryInstallationSignedPlaceholders = {
    "{TimeCompleted}": "Time completed",
    "{TaskName}": "Task name",
    "{TaskURL}": "Task URL",
    "{CustomerName}": "Customer name",
    "{CustomerContactTitle}": "Customer contact title",
    "{CustomerContactName}": "Customer contact name",
    "{CustomerAddress}": "Customer address",
    "{CustomerHomeTel}": "Customer home telephone",
    "{CustomerMobileTel}": "Customer mobile telephone",
    "{CustomerWorkTel}": "Customer work telephone",
    "{CustomerOtherTel}": "Customer other telephone",
    "{CustomerEmail}": "Customer email",
    "{AccessNotes}": "Access notes",
    "{OrderNumber}": "Order number",
    "{SalesPersonName}": "Salesperson name",
    "{Balance}": "Balance",
    "{PaymentMethod}": "Payment method",
    "{DateBalancePaid}": "Date balance paid",
    "{FinanceAgreementName}": "Finance agreement name",
    "{ProductMake}": "Product make",
    "{ProductModel}": "Product model",
    "{ProductSerialNumber}": "Product serial number",
    "{ProductColour}": "Product colour",
    "{ProductCabinet}": "Product cabinet",
    "{ProductCondition}": "Product condition",
    "{ProductComments}": "Product comments",
    "{ServicePlanHPWOCare}": "Service plan HPWO Care",
    "{ServicePlanHPWSilk}": "Service plan HPW Silk",
    "{ServicePlanNoPumpOSB}": "Service plan No Pump OSB",
    "{ServicePlanLC}": "Service plan LC",
    "{ServicePlanLCLite}": "Service plan LC Lite",
    "{ServicePlanLPC}": "Service plan LPC",
    "{ServicePlanGold}": "Service plan Gold",
    "{ServicePlanUltimate}": "Service plan Ultimate",
    "{ServicePlanEPWellness}": "Service plan EP Wellness",
    "{ServicePlanEPWellnessPlus}": "Service plan EP Wellness Plus",
    "{ExtrasList}": "Extras list",
    "{EngineerNameList}": "Engineer name list",
    "{EngineerDate}": "Engineer date",
    "{AccessClear}": "Access clear",
    "{AccessComments}": "Access comments",
    "{CustomerVisitComment}": "Customer visit comment",
};

const HotTubFormPlaceholders = {
    "{SigningURL}": "Signing URL",
    "{OrderDate}": "Order date",
    "{OrderNumber}": "Order number",
    "{OrderLocation}": "Order location",
    "{COFSent}": "COF sent",
    "{COFLink}": "COF link",
    "{Subtotal}": "Subtotal",
    "{OrderTotal}": "Order total",
    "{DepositAmount}": "Deposit amount",
    "{DepositMethods}": "Deposit methods",
    "{Balance}": "Balance",
    "{FundingChoice}": "Funding choice",
    "{PartExchangeValueToBeDeducted}": "Part exchange value to be deducted",
    "{SalesPersonName}": "Salesperson name",
    "{SalesPersonEmail}": "Salesperson email",
    "{Comments}": "Comments",
    "{CustomerName}": "Customer name",
    "{CustomerContactTitle}": "Customer contact title",
    "{CustomerContactName}": "Customer contact name",
    "{CustomerAddress}": "Customer address",
    "{CustomerHomeTel}": "Customer home telephone",
    "{CustomerMobileTel}": "Customer mobile telephone",
    "{CustomerEmail}": "Customer email",
    "{CustomerReferredBy}": "Customer referred by",
    "{ProductCollection}": "Product collection",
    "{ProductModel}": "Product model",
    "{ProductShellColour}": "Product shell colour",
    "{ProductCabinet}": "Product cabinet",
    "{ProductPrice}": "Product price",
    "{ModelToBePartExchanged}": "Model to be part exchanged",
    "{AdditionalPackage}": "Additional package",
    "{AccessoryList}": "Accessory list",
    "{DeliveryMethod}": "Delivery method",
    "{ElectricalSupply}": "Electrical supply",
    "{PreferredDeliveryDate}": "Preferred delivery date",
    "{AccessDescription}": "Access description",
    "{SiteVisitFormSent}": "Site visit form sent",
};

const HotTubFormSignedPlaceholders = {
    "{TimeCompleted}": "Time completed",
    "{DeclarationAgreed}": "Declaration agreed",
    "{GDPRConsented}": "GDPR consented",
    "{ReceivesNewsletter}": "Receives newsletter",
    "{TaskName}": "Task name",
    "{TaskURL}": "Task URL",
    "{OrderDate}": "Order date",
    "{OrderNumber}": "Order number",
    "{OrderLocation}": "Order location",
    "{COFSent}": "COF sent",
    "{COFLink}": "COF link",
    "{Subtotal}": "Subtotal",
    "{OrderTotal}": "Order total",
    "{DepositAmount}": "Deposit amount",
    "{DepositMethods}": "Deposit methods",
    "{Balance}": "Balance",
    "{FundingChoice}": "Funding choice",
    "{PartExchangeValueToBeDeducted}": "Part exchange value to be deducted",
    "{SalesPersonName}": "Salesperson name",
    "{SalesPersonEmail}": "Salesperson email",
    "{Comments}": "Comments",
    "{CustomerName}": "Customer name",
    "{CustomerContactTitle}": "Customer contact title",
    "{CustomerContactName}": "Customer contact name",
    "{CustomerAddress}": "Customer address",
    "{CustomerHomeTel}": "Customer home telephone",
    "{CustomerMobileTel}": "Customer mobile telephone",
    "{CustomerEmail}": "Customer email",
    "{CustomerReferredBy}": "Customer referred by",
    "{ProductCollection}": "Product collection",
    "{ProductModel}": "Product model",
    "{ProductShellColour}": "Product shell colour",
    "{ProductCabinet}": "Product cabinet",
    "{ProductPrice}": "Product price",
    "{ModelToBePartExchanged}": "Model to be part exchanged",
    "{AdditionalPackage}": "Additional package",
    "{AccessoryList}": "Accessory list",
    "{DeliveryMethod}": "Delivery method",
    "{ElectricalSupply}": "Electrical supply",
    "{PreferredDeliveryDate}": "Preferred delivery date",
    "{AccessDescription}": "Access description",
    "{SiteVisitFormSent}": "Site visit form sent",
};

const EndlessPoolFormPlaceholders = {
    "{SigningURL}": "Signing URL",
    "{OrderDate}": "Order date",
    "{OrderNumber}": "Order number",
    "{OrderLocation}": "Order location",
    "{COFSent}": "COF sent",
    "{COFLink}": "COF link",
    "{Subtotal}": "Subtotal",
    "{OrderTotal}": "Order total",
    "{DepositAmount}": "Deposit amount",
    "{DepositMethods}": "Deposit methods",
    "{Balance}": "Balance",
    "{FundingChoice}": "Funding choice",
    "{PartExchangeValueToBeDeducted}": "Part exchange value to be deducted",
    "{SalesPersonName}": "Salesperson name",
    "{SalesPersonEmail}": "Salesperson email",
    "{Comments}": "Comments",
    "{CustomerName}": "Customer name",
    "{CustomerContactTitle}": "Customer contact title",
    "{CustomerContactName}": "Customer contact name",
    "{CustomerAddress}": "Customer address",
    "{CustomerHomeTel}": "Customer home telephone",
    "{CustomerMobileTel}": "Customer mobile telephone",
    "{CustomerEmail}": "Customer email",
    "{CustomerReferredBy}": "Customer referred by",
    "{ProductModel}": "Product model",
    "{ProductShellColour}": "Product shell colour",
    "{ProductCabinet}": "Product cabinet",
    "{ProductPrice}": "Product price",
    "{ModelToBePartExchanged}": "Model to be part exchanged",
    "{Features}": "Features",
    "{CoverOptions}": "Cover options",
    "{AccessoryList}": "Accessory list",
    "{ServicePlan}": "Service plan",
    "{DeliveryMethod}": "Delivery method",
    "{ElectricalSupply}": "Electrical supply",
    "{ElectricianRequired}": "Electrician required",
    "{PreferredDeliveryDate}": "Preferred delivery date",
    "{AccessDescription}": "Access description",
    "{SiteVisitFormSent}": "Site visit form sent",
};

const EndlessPoolFormSignedPlaceholders = {
    "{TimeCompleted}": "Time completed",
    "{DeclarationAgreed}": "Declaration agreed",
    "{GDPRConsented}": "GDPR consented",
    "{ReceivesNewsletter}": "Receives newsletter",
    "{TaskName}": "Task name",
    "{TaskURL}": "Task URL",
    "{OrderDate}": "Order date",
    "{OrderNumber}": "Order number",
    "{OrderLocation}": "Order location",
    "{COFSent}": "COF sent",
    "{COFLink}": "COF link",
    "{Subtotal}": "Subtotal",
    "{OrderTotal}": "Order total",
    "{DepositAmount}": "Deposit amount",
    "{DepositMethods}": "Deposit methods",
    "{Balance}": "Balance",
    "{FundingChoice}": "Funding choice",
    "{PartExchangeValueToBeDeducted}": "Part exchange value to be deducted",
    "{SalesPersonName}": "Salesperson name",
    "{SalesPersonEmail}": "Salesperson email",
    "{Comments}": "Comments",
    "{CustomerName}": "Customer name",
    "{CustomerContactTitle}": "Customer contact title",
    "{CustomerContactName}": "Customer contact name",
    "{CustomerAddress}": "Customer address",
    "{CustomerHomeTel}": "Customer home telephone",
    "{CustomerMobileTel}": "Customer mobile telephone",
    "{CustomerEmail}": "Customer email",
    "{CustomerReferredBy}": "Customer referred by",
    "{ProductModel}": "Product model",
    "{ProductShellColour}": "Product shell colour",
    "{ProductCabinet}": "Product cabinet",
    "{ProductPrice}": "Product price",
    "{ModelToBePartExchanged}": "Model to be part exchanged",
    "{Features}": "Features",
    "{CoverOptions}": "Cover options",
    "{AccessoryList}": "Accessory list",
    "{ServicePlan}": "Service plan",
    "{DeliveryMethod}": "Delivery method",
    "{ElectricalSupply}": "Electrical supply",
    "{ElectricianRequired}": "Electrician required",
    "{PreferredDeliveryDate}": "Preferred delivery date",
    "{AccessDescription}": "Access description",
    "{SiteVisitFormSent}": "Site visit form sent",
};

const SpaFreshReportPlaceholders = {
    "{SigningURL}": "Signing URL",
    "{CustomerName}": "Customer name",
    "{CustomerContactPostcode}": "Customer contact postcode",
    "{Sanitiser}": "Sanitiser",
    "{PHLevel}": "PH level",
    "{WaterAppearance}": "Water appearance",
    "{ChemicalRecommendation}": "Chemical recommendation",
    "{ServiceChecks}": "Service checks",
    "{ServiceRecommendation}": "Service recommendation",
    "{FilterConditon}": "Filter condition",
    "{FilterRecommendation}": "Filter recommendation",
    "{CoverConditon}": "Cover condition",
    "{CoverRecommendation}": "Cover recommendation",
    "{CoverLifterConditon}": "Cover lifter condition",
    "{CoverLifterRecommendation}": "Cover lifter recommendation",
    "{HeadrestConditon}": "Headrest condition",
    "{HeadrestRecommendation}": "Headrest recommendation",
    "{VisitEngineerName}": "Visit engineer name",
    "{VisitEngineerSigned}": "Visit engineer signed",
    "{VisitDate}": "Visit date",
    "{QualifyForLastingCarePlan}": "Qualify for lasting care plan",
};

const SpaFreshReportSignedPlaceholders = {
    "{TimeCompleted}": "Time completed",
    "{TaskName}": "Task name",
    "{TaskURL}": "Task URL",
    "{CustomerName}": "Customer name",
    "{CustomerContactPostcode}": "Customer contact postcode",
    "{Sanitiser}": "Sanitiser",
    "{PHLevel}": "PH level",
    "{WaterAppearance}": "Water appearance",
    "{ChemicalRecommendation}": "Chemical recommendation",
    "{ServiceChecks}": "Service checks",
    "{ServiceRecommendation}": "Service recommendation",
    "{FilterConditon}": "Filter condition",
    "{FilterRecommendation}": "Filter recommendation",
    "{CoverConditon}": "Cover condition",
    "{CoverRecommendation}": "Cover recommendation",
    "{CoverLifterConditon}": "Cover lifter condition",
    "{CoverLifterRecommendation}": "Cover lifter recommendation",
    "{HeadrestConditon}": "Headrest condition",
    "{HeadrestRecommendation}": "Headrest recommendation",
    "{VisitEngineerName}": "Visit engineer name",
    "{VisitEngineerSigned}": "Visit engineer signed",
    "{VisitDate}": "Visit date",
    "{QualifyForLastingCarePlan}": "Qualify for lasting care plan",
};

const AnnualServiceReportPlaceholders = {
    "{SigningURL}": "Signing URL",
    "{CustomerName}": "Customer name",
    "{CustomerContactPostcode}": "Customer contact postcode",
    "{Sanitiser}": "Sanitiser",
    "{PHLevel}": "PH level",
    "{WaterAppearance}": "Water appearance",
    "{ChemicalRecommendation}": "Chemical recommendation",
    "{ServiceChecks}": "Service checks",
    "{ServiceRecommendation}": "Service recommendation",
    "{FilterCondition}": "Filter condition",
    "{FilterRecommendation}": "Filter recommendation",
    "{CoverCondition}": "Cover condition",
    "{CoverRecommendation}": "Cover recommendation",
    "{CoverLifterCondition}": "Cover lifter condition",
    "{CoverLifterRecommendation}": "Cover lifter recommendation",
    "{HeadrestCondition}": "Headrest condition",
    "{HeadrestRecommendation}": "Headrest recommendation",
    "{VisitEngineerName}": "Visit engineer name",
    "{VisitEngineerSigned}": "Visit engineer signed",
    "{VisitDate}": "Visit date",
    "{QualifyForLastingCarePlan}": "Qualify for lasting care plan",
};

const AnnualServiceReportSignedPlaceholders = {
    "{TimeCompleted}": "Time completed",
    "{TaskName}": "Task name",
    "{TaskURL}": "Task URL",
    "{CustomerName}": "Customer name",
    "{CustomerContactPostcode}": "Customer contact postcode",
    "{Sanitiser}": "Sanitiser",
    "{PHLevel}": "PH level",
    "{WaterAppearance}": "Water appearance",
    "{ChemicalRecommendation}": "Chemical recommendation",
    "{ServiceChecks}": "Service checks",
    "{ServiceRecommendation}": "Service recommendation",
    "{FilterCondition}": "Filter condition",
    "{FilterRecommendation}": "Filter recommendation",
    "{CoverCondition}": "Cover condition",
    "{CoverRecommendation}": "Cover recommendation",
    "{CoverLifterCondition}": "Cover lifter condition",
    "{CoverLifterRecommendation}": "Cover lifter recommendation",
    "{HeadrestCondition}": "Headrest condition",
    "{HeadrestRecommendation}": "Headrest recommendation",
    "{VisitEngineerName}": "Visit engineer name",
    "{VisitEngineerSigned}": "Visit engineer signed",
    "{VisitDate}": "Visit date",
    "{QualifyForLastingCarePlan}": "Qualify for lasting care plan",
};
export const EMAIL_TEMPLATE_PLACEHOLDERS: Record<string, any> = {
    "Task creation": TaskCreationPlaceholders,
    "Task completion": TaskCompletionPlaceholders,
    "Task deletion": TaskDeletionPlaceholders,
    "Event creation": EventCreatePlaceholders,
    "Event Reschedule": EventReSchedulePlaceholders,
    "Event cancellation": EventDeletePlaceholders,
    "Site Visit Form for Signing": SiteVisitPlaceholders,
    "Site Visit Form Completion": SiteVisitSignedPlaceholders,
    "Delivery/Installation Form for Signing": DeliveryInstallationFormPlaceholders,
    "Delivery/Installation Form Completion": DeliveryInstallationSignedPlaceholders,
    "Hot Tub Order Form For Signing": HotTubFormPlaceholders,
    "Hot Tub Order Form Completion": HotTubFormSignedPlaceholders,
    "Endless Pools Order Form For Signing": EndlessPoolFormPlaceholders,
    "Endless Pools Order Form Completion": EndlessPoolFormSignedPlaceholders,
    "Spa Fresh Service Report for Signing": SpaFreshReportPlaceholders,
    "Spa Fresh Service Report Completion": SpaFreshReportSignedPlaceholders,
    "Annual Service Report for Signing": AnnualServiceReportPlaceholders,
    "Annual Service Report Completion": AnnualServiceReportSignedPlaceholders,
};


export const customerContact = [
    {
        label: "All Contacts",
        value: "all",
    },
    {
        label: "Main Contact",
        value: "main",
    },
    {
        label: "None",
        value: "none",
    },
];
