import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Card, Col, Stack } from 'react-bootstrap';
import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/esm/Modal';

type Props = {
    columnOptions: IColumnOption[],
    onSave: (columnOptions: IColumnOption[]) => void,
    onClose: ()=> void,
    onReset: ()=> void,
    show: boolean,
}

export interface IColumnOption {
    title: string;
    member: string;
    isVisible: boolean;    
}

export function cloneColumnOptions(columns: IColumnOption[]): IColumnOption[] {
    return JSON.parse(JSON.stringify(columns));
};

export const SelectColumnModal: React.FC<Props> = ({ columnOptions, onSave, onClose, onReset, show }) => {
    const [columns, setColumns] = useState<IColumnOption[]>(cloneColumnOptions(columnOptions));
    
    useEffect(()=> { 
        setColumns(columnOptions);
    }, [columnOptions]);

    const handleSave = () => {
        onClose();
        onSave(cloneColumnOptions(columns));
    };

    const handleReset = () => {
        onClose();
        onReset();
    };
    
    const handleOnChange = (member: string) => {
        const updatedColumns = cloneColumnOptions(columns)
            .map((column, index) => { 
                if(column.member === member)
                    column.isVisible = !column.isVisible;
                return column;
            });
        setColumns(updatedColumns);
    };

    const handleOnDragEnd = (result: any) => {
        if (!result.destination) return;

        const updatedColumns = cloneColumnOptions(columns);
        const [reorderedItem] = updatedColumns.splice(result.source.index, 1);
        updatedColumns.splice(result.destination.index, 0, reorderedItem);
    
        setColumns(updatedColumns);
    };

    return (
        <Modal centered show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Select Columns</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    You can hide or show columns in this list by selecting them below. To change the column order, drag and drop a field.
                </p>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="members-list">
                        {(provided) => (
                            <Stack gap={2} {...provided.droppableProps} ref={provided.innerRef}>
                                { columns.map((column, index) =>
                                    <Draggable key={`${column.member}-${column.title}`} draggableId={`${column.member}-${column.title}`} index={index}>
                                        {(provided) => (
                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                <Card className="p-0">
                                                    <div className="w-100 p-2">
                                                        <FontAwesomeIcon icon={faGripVertical} />
                                                        <label className="ms-3">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                checked={column.isVisible} 
                                                                onChange={()=> handleOnChange(column.member)}
                                                                />
                                                            <span className="ps-2">{column.title}</span>
                                                        </label>
                                                    </div>
                                                </Card>
                                            </div>
                                        )}
                                    </Draggable>
                                )}
                                {provided.placeholder}
                            </Stack>
                        )}
                    </Droppable>
                </DragDropContext>                            
            </Modal.Body>
            <Modal.Footer>
                <Col>
                    <Button variant="outline-dark" onClick={() => handleReset()}>
                        Reset Columns
                    </Button>
                </Col>
                <Col xs="auto">
                    <Button variant="primary" className="me-2" onClick={() => handleSave()}>
                        Save
                    </Button>
                    <Button variant="secondary" onClick={() => onClose()}>
                        Cancel
                    </Button>
                </Col>
            </Modal.Footer>
        </Modal>
    );
}