import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { formService } from "../../services";
import AnnualAndSpaPdfs from "./AnnualAndSpaPdfs";
import DeliveryOrInstallationPdf from './DeliveryOrInstallationPdf';
import HotTubOrderAndEndlessOrderPdfs from './HotTubOrderAndEndlessOrderPdfs';
import SiteVisitPdf from './SiteVisitPdf';

type props ={
    isPublic: boolean,
}
function FormPdf({isPublic}:props) {
    const [currentForm, setCurrentForm] = useState<any>({});
    const params = useParams();
    const formId = params.id;

    useEffect(() => {
        function getForm() {
            if (formId) {
                if (isPublic){
                    formService.getReadableById(formId).then(setCurrentForm)
                }else{
                    formService.getById(formId).then(setCurrentForm);
                }
            }
        }
        getForm();
    }, [formId, isPublic]);
    
    const AnnualOrSpa =  currentForm.name === "Annual Service Report Form" || currentForm.name === "Spa Fresh Service Report Form"
    const SiteVisit = currentForm.name === "Site Visit Form"
    const DeliveryOrInstallation = currentForm.name === "Delivery/Installation Form"
    const HotTubOrEndless = currentForm.name === "Hot Tub Order Form" || currentForm.name === "Endless Pools Order Form"

    return (
        currentForm.signed ?
            (
                <>
                    {AnnualOrSpa && <AnnualAndSpaPdfs currentForm={currentForm} />}
                    {SiteVisit && <SiteVisitPdf currentForm={currentForm} />}
                    {DeliveryOrInstallation && <DeliveryOrInstallationPdf currentForm={currentForm} />}
                    {HotTubOrEndless && <HotTubOrderAndEndlessOrderPdfs currentForm={currentForm} />}
                </>
            )
            :
            <><div>The form is not completed yet, Please complete it first</div></>
    );

}

export default FormPdf;
