import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { PendingCallbackListPage } from "../PendingCallback/PendingCallbackListPage";

const AllPendingCallbacksPage = () => {
    return (
        <Container fluid>
            <Helmet>
                <title>Pending Callbacks</title>
            </Helmet>
            <Row>
                <Col>
                    <h1 className="my-3">Pending Callbacks</h1>
                    <PendingCallbackListPage />
                </Col>
            </Row>
        </Container>
    )
}

export { AllPendingCallbacksPage };
