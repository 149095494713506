import { FieldAttributes } from "formik";
import React, { useEffect, useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";

interface FieldProps extends FieldAttributes<any> {
  placeholders: string[];
  visibleTitle? : boolean
  selected? : string;
}

const HHTDropDown: React.FC<FieldProps> = ({
  placeholders,
  setFieldValue,
  values,
  clickHandler,
  title,
  visibleTitle,
  selected,
  ...rest
}) => {
  useEffect(()=>{
  clickHandler(selected)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const [displayTitle, setDisplayTitle] = useState(title)
  return (
    <div>
      <DropdownButton
        className="ms-auto"
        variant="outline-secondary"
        title={displayTitle}
        id="input-group-dropdown-2"
        align="end"
      >
        {placeholders.map((value,index) => {
          return (
            <Dropdown.Item
              key={value.toString()+index}
              onClick={() => {
                 clickHandler(value)
                  visibleTitle && setDisplayTitle(value)
                }}  
            >
              {value}
            </Dropdown.Item>
          );
        })}
      </DropdownButton>
    </div>
  );
};

export default HHTDropDown;
