import { ICalendarEvent } from "@/customTypings/CalendarEvent";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import bootstrap5Plugin from "@fullcalendar/bootstrap5";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import listPlugin from "@fullcalendar/list";
import momentPlugin from "@fullcalendar/moment";
import timeGridPlugin from "@fullcalendar/timegrid";
import "bootstrap-icons/font/bootstrap-icons.css";
import dayjs from "dayjs";
import { ErrorMessage, Field, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { Button, Form, Modal, Stack } from "react-bootstrap";
import * as Yup from "yup";
import { ICalendarEntry } from "../../customTypings/CalendarEntry";
import { foreColorForBack } from "../../helpers/utils";
import { calendarEventService } from "../../services";
import { LoadingSpinner } from "../LoadingSpinner";
import { EventAdd } from "./EventAdd";
import { EventDetails } from "./EventDetails";
import { EventRemove } from "./EventRemove";
import { EventUpdate } from "./EventUpdate";

type Props = {
  identityIds?: string[];
  initialDate?: Date;
  newEventAllowed?: boolean;
  updateEventAllowed?: boolean;
  setView?: string;
  isTask?: boolean;
  taskId?: string;
  jobId?: string;
  visable?: boolean;
  setColors?: any;
};

export interface IEvent {
  id: string;
  groupId: string;
  title: string;
  start: Date;
  end: Date;
  allDay: boolean;
  color?: string;
  textColor?: string;
  editable: boolean;
  taskId?: string;
  description?: string;
  appointmentTypeId?: string;
  identityId?: string;
  identityName?: string;
  associatedIds?: string[];
  jobId?: string;
}

const EventCalendar: React.FC<Props> = ({
  identityIds = [],
  initialDate = new Date(),
  newEventAllowed = false,
  updateEventAllowed = false,
  setView = "",
  isTask = false,
  taskId,
  jobId,
  visable,
  setColors,
}) => {
  const calendarRef = useRef<any>();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showEventDetails, setShowEventDetails] = useState(false);
  const [showEventAdd, setShowEventAdd] = useState(false);
  const [showEventUpdate, setShowEventUpdate] = useState(false);
  const [showEventRemove, setShowEventRemove] = useState(false);
  const [events, setEvents] = useState<IEvent[]>([]);
  const [currentIdentityIds, setCurrentIdentityIds] = useState(identityIds);
  const [eventAddStart, setEventAddStart] = useState<Date | undefined>(
    undefined
  );
  const [eventAddEnd, setEventAddEnd] = useState<Date | undefined>(undefined);
  const [eventAddIsAllDay, setEventAddIsAllDay] = useState<boolean | undefined>(
    undefined
  );
  const [eventToBeUpdated, setEventToBeUpdated] = useState<IEvent | undefined>(
    undefined
  );

  useEffect(() => {
    if (
      calendarRef.current &&
      !(
        currentIdentityIds.length === identityIds.length &&
        currentIdentityIds.every((value, index) => value === identityIds[index])
      )
    ) {
      setCurrentIdentityIds(identityIds);
      updateEvents();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identityIds]);

  useEffect(() => {
    if (visable && calendarRef.current) {
      calendarRef.current.getApi().updateSize();
    }
  }, [visable]);

  function getAllEntries(object: Record<string, any>) {
    calendarEventService
      .getAllEntries(object)
      .then((events: ICalendarEntry[]) => {
        var allEvents: IEvent[] = [];
        for(let i=0;i<events.length;i++){
          const event = events[i];
          if (setColors) setColors(event);
          allEvents.push({
            id: (event.id ?? "") + (event.identityId ?? ""),
            groupId: event.id ?? "",
            title: event.title ?? "",
            start: event.startDate ?? new Date(),
            end: event.endDate ?? new Date(),
            allDay: event.isAllDay ?? false,
            color: event.color,
            textColor: foreColorForBack(event.color ?? ""),
            editable: updateEventAllowed,
            taskId: event.taskId,
            description: event.description,
            appointmentTypeId: event.appointmentTypeId,
            identityId: event.identityId,
            identityName: event.identityName,
            jobId: event.jobId,
          });
        };
        setEvents(allEvents);
      });
  }

  function updateEvents(start?: Date, end?: Date) {
    var startToUse = start;
    var endToUse = end;

    if (calendarRef.current) {
      startToUse = calendarRef.current.getApi().view.currentStart;
      endToUse = calendarRef.current.getApi().view.currentEnd;
    }
    const filterData: Record<string, any> = {
      fromDate: startToUse,
      toDate: endToUse,
    };
    filterData["identityIds"] = identityIds;
    getAllEntries(filterData);
  }

  return (
    <>
      <FullCalendar
        plugins={[
          dayGridPlugin,
          bootstrap5Plugin,
          timeGridPlugin,
          interactionPlugin,
          listPlugin,
          momentPlugin,
        ]}
        ref={calendarRef}
        height={"80vh"}
        initialView={setView === "" ? "dayGridMonth" : setView}
        initialDate={initialDate}
        themeSystem="bootstrap5"
        fixedWeekCount={false}
        customButtons={{
          goToDateButton: {
            text: "Go to date",
            click: function () {
              setShowDatePicker(true);
            },
          },
          addEventButton: {
            text: "New Diary Entry",
            click: function () {
              setEventAddStart(undefined);
              setEventAddEnd(undefined);
              setEventAddIsAllDay(undefined);
              setShowEventAdd(true);
            },
          },
        }}
        locale="en-GB"
        //timeZone='Europe/London'
        headerToolbar={{
          start:
            (setView === ""
              ? "dayGridMonth,timeGridWeek,timeGridDay,listMonth "
              : "") + "today goToDateButton prevYear,prev,next,nextYear", // will normally be on the left. if RTL, will be on the right
          center: "title",
          end: newEventAllowed ? "addEventButton" : "", // will normally be on the right. if RTL, will be on the left
        }}
        buttonText={{
          today: "Today",
          month: "Month",
          week: "Week",
          day: "Day",
          list: "List",
        }}
        businessHours={{
          // days of week. an array of zero-based day of week integers (0=Sunday)
          daysOfWeek: [1, 2, 3, 4, 5], // Monday - Friday

          startTime: "08:00", // a start time (8am in this example)
          endTime: "18:00", // an end time (6pm in this example)
        }}
        eventSources={[
          {
            events: events,
            id: "main",
          },
        ]}
        //events={events}
        editable={updateEventAllowed}
        eventClick={function (info) {
          //if(updateEventAllowed){
          setEventToBeUpdated({
            id: info.event.id,
            groupId: info.event.groupId,
            title: info.event.title,
            start: info.event.start,
            end: info.event.end,
            allDay: info.event.allDay,
            color: info.event.backgroundColor,
            textColor: info.event.textColor,
            editable: info.el.isContentEditable,
            taskId: info.event.extendedProps.taskId,
            description: info.event.extendedProps.description,
            appointmentTypeId: info.event.extendedProps.appointmentTypeId,
            identityId: info.event.extendedProps.identityId,
            identityName: info.event.extendedProps.identityName,
            associatedIds: calendarRef.current
              .getApi()
              .getEvents()
              .filter((event: any) => event.groupId === info.event.groupId)
              .map((e: any) => e.extendedProps.identityId),
            jobId: info.event.extendedProps.jobId,
          } as IEvent);
          setShowEventDetails(true);
        }}
        selectable={newEventAllowed}
        select={function (selectionInfo) {
          setEventAddStart(dayjs(selectionInfo.start).toDate());
          setEventAddEnd(dayjs(selectionInfo.end).toDate());
          setEventAddIsAllDay(selectionInfo.allDay);
          setShowEventAdd(true);
        }}
        eventChange={function (info) {
          var identityIds = [info.event.extendedProps.identityId] as string[];
          for(let i=0;i<info.relatedEvents.length;i++){
            identityIds.push(info.relatedEvents[i].extendedProps.identityId);
          };

          var end = dayjs(info.event.end);
          if (info.event.end === null) {
            if (info.event.allDay) {
              end = dayjs(info.event.start).add(1, "day");
            } else {
              end = dayjs(info.event.start).add(1, "hour");
            }
          }

          const updatedCalendarEntry: ICalendarEvent = {
            id: info.event.groupId,
            taskId: info.event.extendedProps.taskId,
            title: info.event.title,
            description: info.event.extendedProps.description,
            appointmentTypeId: info.event.extendedProps.appointmentTypeId,
            startDate: dayjs
              .utc(dayjs(info.event.start).format("YYYY-MM-DD HH:mm"))
              .toDate(),
            endDate: dayjs.utc(end.format("YYYY-MM-DD HH:mm")).toDate(),
            isAllDay: info.event.allDay,
            identityIds: identityIds,
            jobId: info.event.extendedProps.jobId,
            color: undefined,
          };
          calendarEventService.update(updatedCalendarEntry).then(
            (response) => {
              if (response.status !== "Failure") {
                updateEvents();
              } else {
                alert(response.message);
              }
            },
            (error) => {
              if (error.status === 400) {
                alert(error.title);
              } else {
                alert(error);
              }
              info.revert();
            }
          );
        }}
        datesSet={function (info) {
          updateEvents(info.start, info.end);
        }}
        eventContent={function (eventInfo) {
          if (eventInfo.view.type === "listMonth") {
            return (
              <Stack direction="horizontal" gap={2}>
                <div className="calendar-identity-column">
                  {eventInfo.event.extendedProps.identityName}
                </div>
                <div>{eventInfo.timeText}</div>
                <b>{eventInfo.event.title}</b>
              </Stack>
            );
          }
        }}
      />
      <EventDetails
        show={showEventDetails}
        onClose={(update) => {
          if (update) {
            setShowEventUpdate(true);
          }
          setShowEventDetails(false);
        }}
        event={eventToBeUpdated}
        updateEventAllowed={updateEventAllowed}
      />
      <EventAdd
        show={showEventAdd}
        onClose={(added) => {
          if (added) {
            if (calendarRef.current) {
              updateEvents();
            }
          }
          setShowEventAdd(false);
        }}
        dateTimeStart={eventAddStart}
        dateTimeEnd={eventAddEnd}
        isAllDay={eventAddIsAllDay}
        identityIds={identityIds}
        isTaskEvent={isTask}
        taskId={taskId}
        jobId={jobId}
      />
      <EventUpdate
        show={showEventUpdate}
        onClose={(updated, removed) => {
          if (removed) {
            setShowEventRemove(true);
          }
          if (updated) {
            if (calendarRef.current) {
              updateEvents();
            }
          }
          setShowEventUpdate(false);
        }}
        eventToBeUpdated={eventToBeUpdated}
        identityIds={eventToBeUpdated?.associatedIds}
      />
      <EventRemove
        show={showEventRemove}
        onClose={(removed) => {
          if (removed) {
            if (calendarRef.current) {
              updateEvents();
            }
          }
          setShowEventRemove(false);
        }}
        eventToBeUpdated={eventToBeUpdated}
      />
      <Modal
        centered
        show={showDatePicker}
        keyboard={false}
        onHide={() => setShowDatePicker(false)}
      >
        <Formik
          initialValues={{
            date: dayjs(
              calendarRef.current
                ? calendarRef.current.getApi().getDate()
                : new Date()
            ).format("YYYY-MM-DD"),
          }}
          validationSchema={Yup.object().shape({
            date: Yup.string().required("Date is required"),
          })}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            setStatus();
            setSubmitting(false);
            if (calendarRef.current) {
              calendarRef.current.getApi().gotoDate(values.date);
            }
            setShowDatePicker(false);
          }}
        >
          {({ values, errors, touched, isSubmitting, handleSubmit }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>Go to date</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group className="mb-3" controlId="validationFormik02">
                  <Field
                    name="date"
                    type="date"
                    className={
                      "form-control" +
                      (errors.date && touched.date ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="date"
                    component="div"
                    className="invalid-feedback"
                  />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <div className="form-group">
                  <Button
                    variant="primary"
                    disabled={isSubmitting}
                    type="submit"
                    className="me-2"
                  >
                    {isSubmitting ? (
                      <LoadingSpinner text="Going..." />
                    ) : (
                      "Go to date"
                    )}
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => setShowDatePicker(false)}
                  >
                    Cancel
                  </Button>
                </div>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export { EventCalendar };
