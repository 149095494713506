import { IRole } from "@/customTypings/role";
import { useAtom } from "@dbeining/react-atom";
import { faFileExport, faPlus } from "@fortawesome/free-solid-svg-icons";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import { useNavigate } from "react-router-dom";
import { userAtom } from "../../atoms/userAtom";
import { ColumnRender, FilterType, IColumn, IFilter, IPagedListAction } from "../../components/PagedList/Declarations";
import { PagedList } from "../../components/PagedList/PagedList";
import { Role } from "../../helpers";

const LeadListPage = () => {
    const navigate = useNavigate();
    const currentUser = useAtom(userAtom);

    return (
        <Container fluid>
            <Row>
                <Col>
                    <h1 className="my-3">Leads</h1>
                    <PagedList
                        columns={[
                            {
                                member: "leadNumber",
                                renderAs: ColumnRender.text,
                                title: "Lead Number",
                                width: 100,
                            } as IColumn,
                            {
                                member: "isBusiness",
                                renderAs: ColumnRender.customRenderer,
                                title: "Type",
                                width: 100,
                                customRenderer: (item) => {
                                    return <td>{item.isBusiness ? "Business" : "Domestic"}</td>;
                                },
                            } as IColumn,
                            {
                                member: "name",
                                renderAs: ColumnRender.text,
                                title: "Name",
                                width: 100,
                            } as IColumn,
                            {
                                member: "address",
                                renderAs: ColumnRender.text,
                                title: "Address",
                                width: 400,
                            } as IColumn,
                            {
                                member: "email",
                                renderAs: ColumnRender.text,
                                title: "Email",
                                width: 100,
                            } as IColumn,
                            {
                                member: "phone",
                                renderAs: ColumnRender.text,
                                title: "Phone",
                                width: 100,
                            } as IColumn,
                        ]}
                        itemActions={[
                            {
                                text: "Detail",
                                onClick: (item) => {
                                    navigate(`/leads/${item.id}`);
                                },
                                primary: true,
                            } as IPagedListAction,
                        ]}
                        headActions={[
                            {
                                icon: faPlus,
                                text: "Add Entry",
                                onClick: () => {
                                    navigate(`/addlead`);
                                },
                            } as IPagedListAction,
                        ]}
                        filters={[
                            {
                                title: "Lead Number",
                                member: "leadNumber",
                                type: FilterType.integer,
                            } as IFilter,
                            {
                                title: "Name",
                                member: "name",
                                type: FilterType.text,
                            } as IFilter,
                            {
                                title: "Address",
                                member: "address",
                                type: FilterType.text,
                            } as IFilter,
                            {
                                title: "Phone",
                                member: "phone",
                                type: FilterType.text,
                            } as IFilter,
                            {
                                title: "Email",
                                member: "email",
                                type: FilterType.text,
                            } as IFilter,
                            {
                                title: "Type",
                                member: "isBusiness",
                                type: FilterType.select,
                                options: { All: null, Business: true, Domestic: false },
                                defaultValue: null,
                            } as IFilter,
                        ]}
                        defaultSortBy="leadNumber"
                        getUrl="api/lead/all"
                        avatarUrl="api/avatar/get"
                        rowFormatter={(item: any) => {
                            return null;
                        }}
                        persistanceKey="LeadListPage"
                        exportUrl={currentUser?.identity.roles.some((role: IRole) => [Role.Admin].indexOf(role.name) >= 0) ? "api/lead/download" : undefined}
                        exportIcon={faFileExport}
                    />
                </Col>
            </Row>
        </Container>
    );
};

export { LeadListPage };
