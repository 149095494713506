import dayjs from "dayjs";
import { taskService } from "../../services";

type MappingProps = {
    body?: string;
    id?: string;
    actioner: string;
};

const mapEmailBody = async ({ body, id, actioner }: MappingProps): Promise<string> => {

    let currentTask: any = {};
    if (id) {
        currentTask = await taskService.fullTask(id);
    }

    if (!body) {
        return "";
    }

    let replacements: Record<string, string | boolean> = {};
    replacements = {
        "{TaskName}": currentTask.name,
        "{CustomerName}": currentTask.customerName,
        "{ContactName}": currentTask.customerName,
        "{TaskType}": currentTask.taskTypeName,
        "{TaskURL}": currentTask.taskURL,
        "{PersonnelList}": currentTask.userName,
        "{Actioner}": actioner,
        "{CreationDate}": currentTask.creationDate,
        "{CompletionDate}": currentTask.completedDate,
        "{DeletionDate}": dayjs(new Date()).format("ddd DD MMM YYYY - HH:mm").toString(),
        "{DueDate}": currentTask.dueDate,
        "{Location}": currentTask.location,
        "{RecursiveInfoIfRecursive}": currentTask.recursiveInterval? `Recurring on ${currentTask.days} every ${currentTask.recursiveInterval} weeks` : "Not Recursive",
        "{ProductInfoIfRelated}": currentTask.productName
    };

    return body.replace(/{TaskName}|{CustomerName}|{ContactName}|{TaskType}|{TaskURL}|{PersonnelList}|{Actioner}|{CreationDate}|{CompletionDate}|{DeletionDate}|{DueDate}|{Location}|{RecursiveInfoIfRecursive}|{ProductInfoIfRelated}/g, (match: any) => replacements[match] || match);
};
export default mapEmailBody;
