import { IProduct } from '@/customTypings/Product';
import { authHeader, handleResponse } from '../helpers';

export const productService = {
    add,
    get,
    edit,
    remove,
    getAll,
    getAllListItems,
};

function add(product: IProduct) {
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(product),
    };
    return fetch(`api/product/add`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });

}

function get(id: string) {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(`api/product/get?id=${id}`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function edit(product: IProduct) {
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(product),
    };
    return fetch(`api/product/edit`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function remove(productId: string) {
    const requestOptions: RequestInit = {
        method: 'DELETE',
        headers: authHeader(),
    };
    return fetch(`api/product/delete?id=${productId}`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function getAll() {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: authHeader(),
        credentials: "include"
    };
    return fetch(`api/product/getall`, requestOptions)
        .then(handleResponse);
}
function getAllListItems() {
    const requestOptions = { method: 'POST', headers: authHeader() };
    return fetch(`api/product/alllistitems`, requestOptions).then(handleResponse);
}
