import { IChecklistDefinitionFull } from '../customTypings/ChecklistDefinitionFull';
import { IChecklistDefinition } from '../customTypings/ChecklistDefinition';
import { IChecklistDefinitionItem } from '../customTypings/ChecklistDefinitionItem';
import { authHeader, handleResponse } from '../helpers';

export const checklistDefinitionService = {
    getAllActive,
    get,
    getItems,
    add,
    addItem,
    edit,
    editItem,
    editFull,
    remove,
    removeItem,
    restore
};

function getAllActive() {
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader(),
    };
    return fetch(`api/checklistdefinition/getallactive`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function get(checklistDefinitionId: string) {
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader(),
    };
    return fetch(`api/checklistdefinition/get?id=${checklistDefinitionId}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function getItems(checklistDefinitionId: string) {
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader(),
    };
    return fetch(`api/checklistdefinitionitem/getbychecklistdefinitionid?checklistdefinitionid=${checklistDefinitionId}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function add(checklistDefinition: IChecklistDefinition) {
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader(),
        body: JSON.stringify(checklistDefinition)
    };
    return fetch(`api/checklistdefinition/add`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function addItem(checklistDefinitionItem: IChecklistDefinitionItem) {
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader(),
        body: JSON.stringify(checklistDefinitionItem)
    };
    return fetch(`api/checklistdefinitionitem/add`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function edit(checklistDefinition: IChecklistDefinition) {
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader(),
        body: JSON.stringify(checklistDefinition)
    };
    return fetch(`api/checklistdefinition/edit`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function editItem(checklistDefinitionItem: IChecklistDefinitionItem) {
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader(),
        body: JSON.stringify(checklistDefinitionItem)
    };
    return fetch(`api/checklistdefinitionitem/edit`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function editFull(checklistDefinitionFull: IChecklistDefinitionFull) {
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader(),
        body: JSON.stringify(checklistDefinitionFull)
    };
    return fetch(`api/checklistdefinition/editfull`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function remove(checklistDefinitionId: string){
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader()
    };
    return fetch(`api/checklistdefinition/delete?id=${checklistDefinitionId}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function removeItem(checklistDefinitionItemId: string){
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader()
    };
    return fetch(`api/checklistdefinitionitem/remove?id=${checklistDefinitionItemId}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function restore(checklistDefinitionId: string){
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader()
    };
    return fetch(`api/checklistdefinition/restore?id=${checklistDefinitionId}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}