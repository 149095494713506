import { IOrderAccessory } from '@/customTypings/OrderAccessory';
import { authHeader, handleResponse } from '../helpers';

export const orderAccessoryService = {
    add,
    get,
    edit,
    getAll,
    remove,
};

function add(orderAccessory: IOrderAccessory) {
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(orderAccessory),
    };

    return fetch(`api/orderaccessory/add`, requestOptions)
        .then(handleResponse);
}

function get(id: string) {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`api/orderaccessory/get?id=${id}`, requestOptions)
        .then(handleResponse);
}

function edit(orderAccessory: IOrderAccessory) {
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(orderAccessory),
    };

    return fetch(`api/orderaccessory/edit`, requestOptions)
        .then(handleResponse);
}

function getAll() {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',
    };

    return fetch(`api/orderaccessory/getall`, requestOptions)
        .then(handleResponse);
}
function remove(orderAccessoryId: string) {
    const requestOptions: RequestInit = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return fetch(`api/orderaccessory/delete/?id=${orderAccessoryId}`, requestOptions)
        .then(handleResponse);
}

