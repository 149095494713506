import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { removeHTMLTagsString } from "../../helpers/utils";
import { communicationLogService } from "../../services/communicationlog.service";
import { HHTEditModal } from "../../shared/HHTEditModal";
import {
    ColumnRender,
    FilterType,
    IColumn,
    IFilter,
    IPagedListAction
} from "../PagedList/Declarations";
import { PagedList } from "../PagedList/PagedList";

type props = {
    myCommunicationLogs: boolean;
};

function CommunicationLog({ myCommunicationLogs }: props) {
    const navigate = useNavigate();
    const [refreshNumber, setRefeshNumber] = useState(0);
    const [show, setShow] = useState(false);
    const [item, setItem] = useState({});
    const columns: IColumn[] = [];
    const filters: IFilter[] = [];
    const headActions: IPagedListAction[] = [];
    
    var staticFilterValues: Record<string, any> = {
        GetAll : !(myCommunicationLogs)
    }
    
    const persistanceKey: string = "";
    const changeItem = (item: any) => {
        setShow(true);
        setItem({
            id: item.id,
            date: new Date(item.date).toLocaleDateString("en-CA"),
            customerId: item.customerId,
            from: item.from,
            to: item.to,
            subject: item.subject,
            fileName: item.fileName,
            body: removeHTMLTagsString(item.body),
        });
    };

    const properties = {
        date: { type: "date", name: "Date" },
        customerId : { type:"dropdown", name: "Customer" },
        from: { type: "email", name: "From" },
        to: { type: "email", name: "To" },
        subject: { type: "text", name: "Subject" },
        fileName: { type: "text", name: "Files", readOnly: true },
        body: {
            type: "text",
            name: "Body",
            style: { height: "200px" },
        },
    };
    const closeModal = () => {
        setShow(false);
        setRefeshNumber((refreshNumber) => refreshNumber + 1);
    };
    filters.push({
        title: "Date/Time",
        member: "date",
        type: FilterType.date,
    } as IFilter);

    filters.push({
        title: "From",
        member: "from",
        type: FilterType.text,
    } as IFilter);

    filters.push({
        title: "To",
        member: "to",
        type: FilterType.text,
    } as IFilter);

    filters.push({
        title: "Subject",
        member: "subject",
        type: FilterType.text,
    } as IFilter);

    filters.push({
        title: "Body",
        member: "body",
        type: FilterType.text,
    } as IFilter);

    columns.push({
        member: "date",
        renderAs: ColumnRender.date,
        title: "Date/Time",
        width: 100,
    } as IColumn);

    columns.push({
        member: "from",
        renderAs: ColumnRender.text,
        title: "From",
        width: 100,
    } as IColumn);

    columns.push({
        member: "to",
        renderAs: ColumnRender.text,
        title: "To",
        width: 100,
    } as IColumn);

    columns.push({
        member: "fileName",
        renderAs: ColumnRender.text,
        title: "Files",
        width: 200,
    } as IColumn);

    columns.push({
        member: "subject",
        renderAs: ColumnRender.text,
        title: "Subject",
        width: 100,
    } as IColumn);

    columns.push({
        member: "body",
        renderAs: ColumnRender.synthesize,
        title: "Body",
        width: 100,
    } as IColumn);

    headActions.push({
        icon: faPlus,
        text: "New Communication Log",
        onClick: () => {
            navigate("/addcommunicationlog");
        },
    } as IPagedListAction);

    return (
        <>
            <Container fluid>
                <Helmet>
                    <title>Task Types</title>
                </Helmet>
                <Row>
                    <Col>
                        <h1 className="my-3">My Communication</h1>
                        <PagedList
                            columns={columns}
                            headActions={headActions}
                            staticFilterValues={staticFilterValues}
                            filters={filters}
                            itemActions={[
                                {
                                    text: "Detail",
                                    onClick: changeItem,
                                    primary: true,
                                } as IPagedListAction,
                            ]}
                            defaultSortBy="date"
                            getUrl="api/communicationlog/communicationlogs"
                            avatarUrl="api/avatar"
                            rowFormatter={(item: any) => {
                                return null;
                            }}
                            persistanceKey={persistanceKey}
                            refreshNumber={refreshNumber}
                        />
                    </Col>
                </Row>
                <HHTEditModal
                    onClose={closeModal}
                    show={show}
                    callback={communicationLogService.update}
                    item={item}
                    title="Edit Communication Log"
                    buttonTitle="Edit"
                    properties={properties}
                />
            </Container>
        </>
    );
}

export default CommunicationLog;
