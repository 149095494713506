import { useAtom } from "@dbeining/react-atom";
import { useEffect, useState } from "react";
import { Alert, Button, Container, ListGroup } from "react-bootstrap";
import { userAtom } from "../../atoms/userAtom";
import { IInvitation } from "../../customTypings/Invitation";
import { invitationService } from "../../services";

function InvitationsPage() {
    const currentUser = useAtom(userAtom);
    const [invites, setInvites] = useState<IInvitation[] | null>(null);
    const [isLoading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    useEffect(() => {
        if (currentUser !== undefined) {
            invitationService
                .getByEmail(currentUser.identity.email)
                .then((tokens) => {
                    setInvites(tokens);
                });
        }
    }, [currentUser]);

    const handleClick = (id: string, accepted: Boolean) => {
        setLoading(true);
        invitationService.response(id, accepted).then(
            (response) => {
                if (response.status !== "Failure") {
                    if (invites !== null) {
                        setInvites(
                            invites.filter(
                                (item: IInvitation) => item.tokenId !== id
                            )
                        );
                    }
                    setLoading(false);
                } else {
                    setErrorMessage(response.message);
                }
            },
            (error) => {
                if (error.status === 400) {
                    setErrorMessage("Invitation remove failed.");
                } else {
                    setErrorMessage(error);
                }
                setLoading(false);
            }
        );
    };

    return (
        <Container fluid>
            <h1 className="my-3">Invitations</h1>
            {invites && (
                <ListGroup>
                    {invites.map((invite) => (
                        <ListGroup.Item
                            className="d-flex justify-content-between align-items-start"
                            key={invite.tokenId}
                        >
                            <div className="ms-2 me-auto">
                                {invite.organisationName}
                            </div>
                            <Button
                                className="me-2"
                                variant="success"
                                disabled={isLoading}
                                onClick={
                                    !isLoading
                                        ? () =>
                                              handleClick(invite.tokenId, true)
                                        : undefined
                                }
                            >
                                Accept
                            </Button>
                            <Button
                                variant="danger"
                                disabled={isLoading}
                                onClick={
                                    !isLoading
                                        ? () =>
                                              handleClick(invite.tokenId, false)
                                        : undefined
                                }
                            >
                                Decline
                            </Button>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            )}
            {errorMessage && (
                <Alert
                    className="mt-2"
                    variant="danger"
                    onClose={() => setErrorMessage(null)}
                    dismissible
                >
                    <Alert.Heading>Error</Alert.Heading>
                    <p>{errorMessage}</p>
                </Alert>
            )}
        </Container>
    );
}

export { InvitationsPage };
