import { IAccessories } from "@/customTypings/Accessories";
import { ErrorMessage, Field, Formik } from "formik";
import React, { useState } from "react";
import { Alert, Button, Col, Form, Modal, Placeholder, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { accessoriesService } from "../../services";

type Props = {
    show: boolean;
    onClose: (updated: boolean) => void;
    currentAccessory?: IAccessories;
};

const EditAccessoryModel: React.FC<Props> = ({ show, onClose, currentAccessory }) => {
    const [alertVariant, setAlertVariant] = useState("danger");

    const handleClose = () => onClose(false);

    return (
        <Modal centered show={show} keyboard={false} onHide={handleClose}>
            {!currentAccessory ? (
                <>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Accessory</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="mb-3">
                            <Col md={3}>Accessory Name</Col>
                            <Col md={9}>
                                <Skeleton width="20rem" height="2rem" />
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Placeholder.Button variant="primary" xs={3} className="me-2" />
                        <Placeholder.Button variant="secondary" xs={2} />
                    </Modal.Footer>
                </>
            ) : (
                <Formik
                    initialValues={{
                            name: currentAccessory.name,
                    }}
                    enableReinitialize

                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        setStatus();
                        setSubmitting(true);
                        const updatedAccessory: IAccessories = {
                            id: currentAccessory.id,
                            name: values.name,
                        };
                        accessoriesService.edit(updatedAccessory).then(
                            (response) => {
                                setSubmitting(false);
                                if (response.status !== "Failure") {
                                    onClose(true);
                                } else {
                                    setAlertVariant("danger");
                                    setStatus(response.message);
                                }
                            },
                            (error) => {
                                setAlertVariant("danger");
                                setStatus(error.title);
                                setSubmitting(false);
                            }
                        );
                    }}
                >
                    {({ errors, status, touched, isSubmitting, handleSubmit, values, handleChange }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Header closeButton>
                                <Modal.Title>Edit Accessory</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form.Group as={Row} className="mb-3" controlId="accessoryName">
                                    <Form.Label column md={3}>
                                        Accessory Name
                                    </Form.Label>
                                        <Col md={9}>
                                            <Field name="name" type="text" className={"form-control" + (errors.name && touched.name ? " is-invalid" : "")} value={values.name} />
                                        <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                    </Col>
                                </Form.Group>
                                {status && (
                                    <Alert variant={alertVariant} className="mt-3">
                                        {status}
                                    </Alert>
                                )}
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="form-group">
                                    <Button variant="primary" disabled={isSubmitting} type="submit" className="me-2">
                                        {isSubmitting ? <LoadingSpinner text="Updating accessory..." /> : "Update accessory"}
                                    </Button>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Cancel
                                    </Button>
                                </div>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            )}
        </Modal>
    );
};

export { EditAccessoryModel };
