import { IContact } from '@/customTypings/Contact';
import { authHeader, handleResponse } from '../helpers';

export const contactService = {
    getAllListItems,
    add
};

function getAllListItems() {
    const requestOptions = { method: 'POST', headers: authHeader() };
    return fetch(`api/contact/alllistitems`, requestOptions).then(handleResponse);
}

function add(contact: IContact) {
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader(),
        body: JSON.stringify(contact)
    };
    return fetch(`api/contact/add`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}