import { ErrorMessage, Field, Formik } from "formik";
import React, { useState } from "react";
import { Alert, Button, Col, Form, Modal, Row, Stack } from "react-bootstrap";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { documentService } from "../../services";
import { LoadingSpinner } from "../LoadingSpinner";

type Props = {
    show: boolean;
    onClose: (added: boolean) => void;
    parentFolderId?: string;
};

const AddFolder: React.FC<Props> = ({ show, onClose, parentFolderId }) => {
    const [alertVariant, setAlertVariant] = useState("danger");

    const handleClose = () => onClose(false);
    const params = useParams();
    const taskId = params.id;
    return (
        <>
            <Modal centered show={show} keyboard={false} onHide={handleClose}>
                <Formik
                    initialValues={{
                        name: "",
                    }}
                    validationSchema={Yup.object().shape({
                        name: Yup.string().required("Folder name is required"),
                    })}
                    onSubmit={(values, { setStatus, setSubmitting, setFieldError }) => {
                        setStatus();
                        documentService.createFolder(parentFolderId, values.name,taskId as string).then(
                            (response) => {
                                setSubmitting(false);
                                if (response.status !== "Failure") {
                                    onClose(true);
                                } else {
                                    setAlertVariant("danger");
                                    setStatus(response.message);
                                }
                            },
                            (error) => {
                                setAlertVariant("danger");
                                if (error.status === 400) {
                                    setStatus(error.title);
                                    setFieldError("name", error.errors.Name);
                                } else {
                                    setStatus(error);
                                }
                                setSubmitting(false);
                            }
                        );
                    }}
                >
                    {({ errors, status, touched, isSubmitting, handleSubmit }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Header closeButton>
                                <Modal.Title>Add Folder</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Stack gap={3}>
                                    <Form.Group as={Row} className="mb-3" controlId="costInput">
                                        <Form.Label column sm={3}>
                                            Folder Name
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field name="name" type="text" autoFocus className={"form-control" + (errors.name && touched.name ? " is-invalid" : "")} />
                                            <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    {status && (
                                        <Alert variant={alertVariant} className="mt-3">
                                            {status}
                                        </Alert>
                                    )}
                                </Stack>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="form-group">
                                    <Button variant="primary" disabled={isSubmitting} type="submit" className="me-2">
                                        {isSubmitting ? <LoadingSpinner text="Adding..." /> : "Add Folder"}
                                    </Button>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Cancel
                                    </Button>
                                </div>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </>
    );
};

export { AddFolder };
