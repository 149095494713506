import { useAtom } from "@dbeining/react-atom";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
    Button,
    Col,
    Container, Form, Image, InputGroup, Row, Stack
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { userAtom } from "../../atoms/userAtom";
import { LogoutModel, PasswordStrength } from "../../components";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { IPasswordComplexityResult } from "../../customTypings/PasswordComplexityResult";
import { accountService, forgotPasswordService } from "../../services";

type Props = {
    id: string;
};

const ResetPasswordPage: React.FC<Props> = ({ id }) => {
    let navigate = useNavigate();
    const [active, setActive] = useState(false);
    const [showLogout, setShowLogout] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [passwordComplexity, setPasswordComplexity] =
        useState<IPasswordComplexityResult>({
            isSuccess: false,
            score: 0,
            messages: [],
        });
    const [userInteraction, setUserInteraction] = useState(false);
    const currentUser = useAtom(userAtom);
    const [email, setEmail] = useState("");

    useEffect(() => {
        setShowLogout(false);
        if (currentUser) {
            forgotPasswordService.isForCurrentUser(id).then(
                (response: boolean) => {
                    setActive(response);
                    if (!response) {
                        setErrorMessage(
                            "you are currently signed in as " +
                                currentUser.identity.email +
                                " do you want to sign out to continue?"
                        );
                        setShowLogout(true);
                    }
                },
                (error) => {
                    setActive(false);
                }
            );
        } else {
            forgotPasswordService.isActive(id).then(
                (response: boolean) => {
                    setActive(response);
                    if (!response) {
                        setErrorMessage("Invalid reset token");
                    }
                },
                (error) => {
                    setErrorMessage(error);
                    setActive(false);
                }
            );
        }
    }, [currentUser, id, userInteraction]);

    function validatePassword(value: string) {
        if (value === "") {
            setPasswordComplexity({ isSuccess: false, score: 0, messages: [] });
        } else {
            accountService
                .passwordComplexityCheck(
                    currentUser
                        ? currentUser.identity.email
                        : email !== ""
                        ? email
                        : null,
                    value
                )
                .then(
                    (result: IPasswordComplexityResult) => {
                        setPasswordComplexity(result);
                    },
                    (error) => {
                        setPasswordComplexity({
                            isSuccess: false,
                            score: 0,
                            messages: [error.errors.Password],
                        });
                    }
                );
        }
    }

    return (
        <Container fluid>
            <Helmet>
                <title>Reset your Password</title>
            </Helmet>
            <Row>
                <Col
                    lg={{ span: 4, offset: 4 }}
                    md={{ span: 6, offset: 3 }}
                    sm={{ span: 6, offset: 3 }}
                >
                    <Image
                        src="/happy-hot-tubs-logo.svg"
                        style={{ height: "5rem" }}
                    />
                </Col>
            </Row>
            <Row>
                <Col
                    lg={{ span: 4, offset: 4 }}
                    md={{ span: 6, offset: 3 }}
                    sm={{ span: 6, offset: 3 }}
                >
                    {active ? (
                        <>
                            <h2>Reset your password</h2>
                            {currentUser && currentUser.identity.email ? (
                                <p>
                                    Enter your new password below to reset your
                                    password.
                                </p>
                            ) : (
                                <p>
                                    Enter your email address and new password
                                    below to reset your password.
                                </p>
                            )}
                            <Formik
                                initialValues={{
                                    email: currentUser
                                        ? currentUser.identity.email
                                        : "",
                                    newPassword: "",
                                    showNewPassword: false,
                                    confirmPassword: "",
                                    showConfirmPassword: false,
                                }}
                                validationSchema={Yup.object().shape({
                                    newPassword: Yup.string().required(
                                        "New Password is required"
                                    ),
                                    confirmPassword: Yup.string().required(
                                        "Confirm Password is required"
                                    ),
                                })}
                                onSubmit={(
                                    values,
                                    { setStatus, setSubmitting, setFieldError }
                                ) => {
                                    setStatus();
                                    forgotPasswordService
                                        .resetPassword(
                                            id,
                                            values.newPassword,
                                            values.confirmPassword
                                        )
                                        .then(
                                            (response) => {
                                                if (
                                                    response.status !==
                                                    "Failure"
                                                ) {
                                                    navigate("/", {
                                                        replace: true,
                                                    });
                                                } else {
                                                    setSubmitting(false);
                                                    setStatus(response.message);
                                                }
                                            },
                                            (error) => {
                                                setSubmitting(false);
                                                if (error.status === 400) {
                                                    setFieldError(
                                                        "newPassword",
                                                        error.errors.newPassword
                                                    );
                                                    setFieldError(
                                                        "confirmPassword",
                                                        error.errors
                                                            .ConfirmPassword
                                                    );
                                                    setStatus(error.title);
                                                } else {
                                                    setStatus(error);
                                                }
                                            }
                                        );
                                }}
                            >
                                {({
                                    values,
                                    handleChange,
                                    errors,
                                    status,
                                    touched,
                                    isSubmitting,
                                    handleSubmit,
                                    setFieldValue,
                                }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Stack gap={3}>
                                            <Form.Group
                                                as={Row}
                                                className="mb-3"
                                                controlId="email"
                                            >
                                                <Form.Label column sm={3}>
                                                    Email address
                                                </Form.Label>
                                                <Col sm={9}>
                                                    <Field
                                                        name="email"
                                                        type="text"
                                                        disabled={currentUser}
                                                        validate={setEmail}
                                                        className={
                                                            "form-control" +
                                                            (errors.email &&
                                                            touched.email
                                                                ? " is-invalid"
                                                                : "")
                                                        }
                                                    />
                                                    <ErrorMessage
                                                        name="email"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group
                                                as={Row}
                                                className="mb-3"
                                                controlId="newPassword"
                                            >
                                                <Form.Label column sm={3}>
                                                    New Password
                                                </Form.Label>
                                                <Col sm={9}>
                                                    <InputGroup hasValidation>
                                                        <Form.Control
                                                            type={
                                                                values.showNewPassword
                                                                    ? "text"
                                                                    : "password"
                                                            }
                                                            name="newPassword"
                                                            value={
                                                                values.newPassword
                                                            }
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                validatePassword(
                                                                    e.target
                                                                        .value
                                                                );
                                                            }}
                                                            isInvalid={
                                                                !!errors.newPassword
                                                            }
                                                        />
                                                        <Button
                                                            variant="outline-secondary"
                                                            onClick={() =>
                                                                setFieldValue(
                                                                    "showNewPassword",
                                                                    !values.showNewPassword
                                                                )
                                                            }
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    values.showNewPassword
                                                                        ? faEyeSlash
                                                                        : faEye
                                                                }
                                                            />
                                                        </Button>
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.newPassword}
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Col>
                                            </Form.Group>
                                            {passwordComplexity && (
                                                <PasswordStrength
                                                    passwordComplexityResult={
                                                        passwordComplexity
                                                    }
                                                />
                                            )}
                                            <Form.Group
                                                as={Row}
                                                className="mb-3"
                                                controlId="confirmPassword"
                                            >
                                                <Form.Label column sm={3}>
                                                    Confirm Password
                                                </Form.Label>
                                                <Col sm={9}>
                                                    <InputGroup hasValidation>
                                                        <Form.Control
                                                            type={
                                                                values.showConfirmPassword
                                                                    ? "text"
                                                                    : "password"
                                                            }
                                                            name="confirmPassword"
                                                            value={
                                                                values.confirmPassword
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                            isInvalid={
                                                                !!errors.confirmPassword
                                                            }
                                                        />
                                                        <Button
                                                            variant="outline-secondary"
                                                            onClick={() =>
                                                                setFieldValue(
                                                                    "showConfirmPassword",
                                                                    !values.showConfirmPassword
                                                                )
                                                            }
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    values.showConfirmPassword
                                                                        ? faEyeSlash
                                                                        : faEye
                                                                }
                                                            />
                                                        </Button>
                                                        <Form.Control.Feedback type="invalid">
                                                            {
                                                                errors.confirmPassword
                                                            }
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Col>
                                            </Form.Group>
                                            <Stack
                                                direction="horizontal"
                                                gap={3}
                                            >
                                                <div className="ms-auto">
                                                    <Button
                                                        variant="success"
                                                        disabled={isSubmitting}
                                                        type="submit"
                                                    >
                                                        {isSubmitting ? (
                                                            <LoadingSpinner text="Resetting password..." />
                                                        ) : (
                                                            "Reset Password"
                                                        )}
                                                    </Button>
                                                </div>
                                            </Stack>
                                            {status && (
                                                <div
                                                    className={
                                                        "alert alert-danger"
                                                    }
                                                >
                                                    {status}
                                                </div>
                                            )}
                                        </Stack>
                                    </Form>
                                )}
                            </Formik>
                        </>
                    ) : (
                        <Stack>
                            <div className="d-flex justify-content-center">
                                {errorMessage}
                            </div>
                            <Button
                                className="mt-2 me-2"
                                variant="link"
                                onClick={() =>
                                    navigate("/signin", {
                                        state: { message: "Ok" },
                                    })
                                }
                            >
                                Go to sign in
                            </Button>
                        </Stack>
                    )}
                    <LogoutModel
                        show={showLogout}
                        errorMessage={errorMessage}
                        onClose={(loggedOut: boolean) =>
                            loggedOut
                                ? setUserInteraction(true)
                                : navigate("/", { replace: true })
                        }
                    />
                </Col>
            </Row>
        </Container>
    );
};

export { ResetPasswordPage };
