import { ErrorMessage, Field, Formik } from "formik";
import React, { useState } from "react";
import { Alert, Button, Col, Form, FormCheck, Modal, Row } from "react-bootstrap";
import { LoadingSpinner } from "../LoadingSpinner";
import { IProduct } from "../../customTypings/Product";
import { productService } from "../../services";

type Props = {
    show: boolean;
    onClose: (added: boolean) => void;
};

const ProductAddModel: React.FC<Props> = ({ show, onClose }) => {
    const [alertVariant, setAlertVariant] = useState("danger");

    const handleClose = () => onClose(false);

    return (
        <Modal centered show={show} keyboard={false} onHide={handleClose}>
            <Formik
                initialValues={{
                    brand: "",
                    model: "",
                    color: "",
                    productCondition: "",
                    make: "",
                    cabinet: "",
                    filterCode: "",
                    warranty: "",
                    freshWater: false,
                    bromine: false,
                    price: 0
                }}
                onSubmit={(values, { setStatus, setSubmitting, setFieldError }) => {
                    setStatus();
                    setSubmitting(false);
                    const newProduct: IProduct = {
                        id: undefined,
                        brand: values.brand,
                        model: values.model,
                        color: values.color,
                        productCondition: values.productCondition,
                        make: values.make,
                        cabinet: values.cabinet,
                        filterCode: values.filterCode,
                        warranty: values.warranty,
                        freshWater: values.freshWater,
                        bromine: values.bromine,
                        price: values.price
                    };
                    productService.add(newProduct).then(
                        (response) => {
                            setSubmitting(false);
                            if (response.status !== "Failure") {
                                onClose(true);
                            } else {
                                setAlertVariant("danger");
                                setStatus(response.message);
                            }
                        },
                        (error) => {
                            setAlertVariant("danger");
                            if (error.status === 400) {
                                setStatus(error.title);
                            } else {
                                setStatus(error);
                            }
                            setSubmitting(false);
                        }
                    );
                }}
            >
                {({ errors, status, touched, isSubmitting, handleSubmit, values, handleChange, setFieldValue }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>New Product</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group as={Row} className="mb-3" controlId="brand">
                                <Form.Label column md={3}>
                                    Brand
                                </Form.Label>
                                <Col md={9}>
                                    <Field name="brand" type="text" className={"form-control" + (errors.brand && touched.brand ? " is-invalid" : "")} value={values.brand} />
                                    <ErrorMessage name="brand" component="div" className="invalid-feedback" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="make">
                                <Form.Label column md={3}>
                                    Make
                                </Form.Label>
                                <Col md={9}>
                                    <Field name="make" type="text" className={"form-control" + (errors.make && touched.make ? " is-invalid" : "")} value={values.make} />
                                    <ErrorMessage name="make" component="div" className="invalid-feedback" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="model">
                                <Form.Label column md={3}>
                                    Model
                                </Form.Label>
                                <Col md={9}>
                                    <Field name="model" type="text" className={"form-control" + (errors.model && touched.model ? " is-invalid" : "")} value={values.model} />
                                    <ErrorMessage name="model" component="div" className="invalid-feedback" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="color">
                                <Form.Label column md={3}>
                                    Color
                                </Form.Label>
                                <Col md={9}>
                                    <Field name="color" type="text" className={"form-control" + (errors.color && touched.color ? " is-invalid" : "")} value={values.color} />
                                    <ErrorMessage name="color" component="div" className="invalid-feedback" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="productCondition">
                                <Form.Label column md={3}>
                                    Condition
                                </Form.Label>
                                <Col md={9}>
                                    <Field name="productCondition" type="text" className={"form-control" + (errors.productCondition && touched.productCondition ? " is-invalid" : "")} value={values.productCondition} />
                                    <ErrorMessage name="productCondition" component="div" className="invalid-feedback" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="cabinet">
                                <Form.Label column md={3}>
                                    Cabinet
                                </Form.Label>
                                <Col md={9}>
                                    <Field name="cabinet" type="text" className={"form-control" + (errors.cabinet && touched.cabinet ? " is-invalid" : "")} value={values.cabinet} />
                                    <ErrorMessage name="cabinet" component="div" className="invalid-feedback" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="filterCode">
                                <Form.Label column md={3}>
                                    FilterCode
                                </Form.Label>
                                <Col md={9}>
                                    <Field name="filterCode" type="text" className={"form-control" + (errors.filterCode && touched.filterCode ? " is-invalid" : "")} value={values.filterCode} />
                                    <ErrorMessage name="filterCode" component="div" className="invalid-feedback" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="warranty">
                                <Form.Label column md={3}>
                                    Warranty
                                </Form.Label>
                                <Col md={9}>
                                        <Field name="warranty" type="text" className={"form-control" + (errors.warranty && touched.warranty ? " is-invalid" : "")} value={values.warranty} />
                                    <ErrorMessage name="warranty" component="div" className="invalid-feedback" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="price">
                                <Form.Label column md={3}>
                                    Price
                                </Form.Label>
                                <Col md={9}>
                                    <Field name="price" type="number" className={"form-control" + (errors.price && touched.price ? " is-invalid" : "")} value={values.price} />
                                    <ErrorMessage name="price" component="div" className="invalid-feedback" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="freshWater">
                                <Form.Label column sm={3}>
                                    FreshWater
                                </Form.Label>
                                <Col sm={9}>
                                    <FormCheck
                                        type="switch"
                                        id="custom-switch"
                                        label=""
                                        checked={values.freshWater}
                                        onChange={handleChange}
                                        name="freshWater"
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="bromine">
                                <Form.Label column sm={3}>
                                    Bromine
                                </Form.Label>
                                <Col sm={9}>
                                    <FormCheck
                                        type="switch"
                                        id="custom-switch"
                                        label=""
                                        checked={values.bromine}
                                        onChange={handleChange}
                                        name="bromine"
                                    />
                                </Col>
                            </Form.Group>

                            {status && (
                                <Alert variant={alertVariant} className="mt-3">
                                    {status}
                                </Alert>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="form-group">
                                <Button variant="primary" disabled={isSubmitting} type="submit" className="me-2">
                                    {isSubmitting ? <LoadingSpinner text="Creating Product..." /> : "Create Product"}
                                </Button>
                                <Button variant="secondary" onClick={handleClose}>
                                    Cancel
                                </Button>
                            </div>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export { ProductAddModel };
