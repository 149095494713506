import { faTrash, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field, ErrorMessage, Formik } from "formik";
import { Row, Col, Form, Alert, Button } from "react-bootstrap";
import SignatureCanvas from 'react-signature-canvas';
import { LoadingSpinner } from "../LoadingSpinner";


type props = {
    isPublic: boolean,
    initialValues:any,
    save:any,
    sign:any,
    setSign:any,
    setUrl:any,
    alertVariant:any,
}

const CustomerSignature = ({isPublic, initialValues, save, sign, setSign, setUrl, alertVariant}:props) => {
    return (
        <Formik
            initialValues={{ ...initialValues }}
            onSubmit={(
                values,
                { setStatus, setSubmitting, setFieldError, resetForm }
            ) => {
                save(values, setStatus, setSubmitting, setFieldError, resetForm);
            }}
        >
            {({ 
                errors,
                status,
                touched,
                isSubmitting,
                handleSubmit,
                values,
                setStatus,
                handleChange,
            }) => (
                <Form onSubmit={handleSubmit}>
                    <div className="row">
                        <h3>CUSTOMER DECLARATION</h3>
                        <div className="col-sm-6">
                            <div className="col-12 mb-3">
                                <Form.Label>
                                    {"Customer Declaration"}
                                    <span style={{ color: 'red' }}>*</span>
                                </Form.Label>
                                <Form.Group className="d-flex justify-content-start align-items-center gap-2" controlId="customerDeclaration">
                                    <Form.Check
                                        type="checkbox"
                                        id="custom-switch-customerDeclaration"
                                        label=""
                                        checked={values.customerDeclaration}
                                        onChange={handleChange}
                                        disabled={!isPublic}
                                        name="customerDeclaration"
                                        required
                                    />
                                    <Form.Label>
                                        I agree with the data provided above and agree to the terms in the Happy Hot Tubs terms and conditions supplied
                                    </Form.Label>
                                </Form.Group>
                            </div>

                            <div className="col-12 mb-3">
                                <Form.Label>
                                    {"GDPR Consent"}
                                    <span style={{ color: 'red' }}>*</span>
                                </Form.Label>
                                <Form.Group className="d-flex justify-content-start align-items-center gap-2" controlId="gdprConsent">
                                    <Form.Check
                                        type="checkbox"
                                        id="custom-switch-gdprConsent"
                                        label=""
                                        checked={values.gdprConsent}
                                        onChange={handleChange}
                                        disabled={!isPublic}
                                        name="gdprConsent"
                                        required
                                    />
                                    <Form.Label>
                                        I agree for my data to be stored and handled, and to be contacted by Happy Hot Tubs about my order
                                    </Form.Label>
                                </Form.Group>
                            </div>

                            <div className="col-12 mb-3">
                                <Form.Label>{"Newsletter"}</Form.Label>
                                <Form.Group className="d-flex justify-content-start align-items-center gap-2" controlId="newsletter">
                                    <Form.Check
                                        type="checkbox"
                                        id="custom-switch-newsletter"
                                        label=""
                                        checked={values.newsletter}
                                        onChange={handleChange}
                                        disabled={!isPublic}
                                        name="newsletter"
                                    />
                                    <Form.Label>
                                        Sign up the Happy Hot Tubs newsletter
                                    </Form.Label>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <Form.Group as={Row} className="mb-3" controlId="customerFullName">
                                <div>
                                    <Form.Label column sm={3}>
                                        <p>
                                            Customer Full Name
                                            <span style={{ color: 'red' }}>*</span>
                                        </p>
                                    </Form.Label>
                                </div>
                                <Col sm={9}>
                                    <Field
                                        name="customerFullName"
                                        type="text"
                                        className={"form-control" + (errors.customerFullName && touched.customerFullName ? " is-invalid" : "")}
                                        disabled={!isPublic}
                                        required
                                    />
                                    <ErrorMessage name="customerFullName" component="div" className="invalid-feedback" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="customerSignature">
                                <div>
                                    <Form.Label column sm={3}>
                                        <p>
                                            Customer Signature
                                            <span style={{ color: 'red' }}>*</span>
                                        </p>
                                    </Form.Label>

                                    <div style={{ position: 'relative', border: '2px solid #51C8B0', width: 460, height: 160 }}>
                                        {!isPublic && <img src={`${initialValues.customerSignature}`} alt='Customer signature' style={{ width: 450, height: 150 }} />}
                                        {isPublic && (
                                            <>
                                                <SignatureCanvas
                                                    canvasProps={{ width: 450, height: 150, className: 'sigCanvas' }}
                                                    ref={(data) => {
                                                        setSign(data);

                                                        // Automatically capture the signature when the user finishes drawing
                                                        if (data) {
                                                            setUrl(data.getTrimmedCanvas().toDataURL('image/png'));
                                                        }
                                                    }}
                                                />
                                                <FontAwesomeIcon
                                                    icon={faTrash}
                                                    style={{ fontSize: '1.5em', color: 'red', position: 'absolute', bottom: 0, left: 0, margin: '8px' }}
                                                    onClick={() => {
                                                        sign.clear();
                                                    }}
                                                />
                                                <FontAwesomeIcon
                                                    icon={faPen}
                                                    style={{ fontSize: '1.5em', color: 'blue', position: 'absolute', bottom: 0, right: 0, margin: '8px' }}
                                                />
                                            </>
                                        )}
                                    </div>
                                </div>
                            </Form.Group>
                        </div>
                    </div>

                    {status && (
                        <Alert variant={alertVariant} className="mt-3">
                            {status}
                        </Alert>
                    )}
                    {isPublic &&
                    <Button
                        variant="info"
                        disabled={isSubmitting}
                        type="submit"
                        className="col-5"
                        style={{ backgroundColor: '#51C8B0', color: 'white' }}
                    >
                        {isSubmitting ? <LoadingSpinner text="Submitting..." /> : "Submit"}
                    </Button>}
                </Form>
            )}
        </Formik>
    );
}

export default CustomerSignature;