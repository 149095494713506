import { IAccessories } from '@/customTypings/Accessories';
import { authHeader, handleResponse } from '../helpers';

export const accessoriesService = {
    add,
    get,
    edit,
    getAll,
    getAllListItems,
};

function add(accessory: IAccessories) {
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(accessory),
    };
    return fetch(`api/accessories/add`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function get(id: string) {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(`api/accessories/get?id=${id}`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function edit(accessory: IAccessories) {
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(accessory),
    };
    return fetch(`api/accessories/edit`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function getAll() {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: authHeader(),
        credentials: "include"
    };
    return fetch(`api/accessories/getall`, requestOptions)
        .then(handleResponse);
}

function getAllListItems() {
    const requestOptions = { method: 'POST', headers: authHeader() };
    return fetch(`api/accessories/alllistitems`, requestOptions).then(handleResponse);
}
