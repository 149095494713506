import React from "react";
import { NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
interface NavDropdownProps {
  title: string;
  links: Record<string, string>;
  show: boolean;
  onClick: () => void;
}

const CustomNavDropdown: React.FC<NavDropdownProps> = ({
  title,
  links,
  show,
  onClick,
}) => {
  return (
    <NavDropdown
      className="nested-dropdown-navbar"
      title={title}
      show={show}
      onClick={onClick}
    >
      {Object.entries(links).map(([name, to]) => (
        <LinkContainer key={name} to={to}>
          <NavDropdown.Item>{name}</NavDropdown.Item>
        </LinkContainer>
      ))}
    </NavDropdown>
  );
};

export default CustomNavDropdown;
