import { useAtom } from "@dbeining/react-atom";
import { faCalendar, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import {
    Alert,
    Button,
    Col,
    Container,
    Row,
    Stack,
    Tab,
    Tabs
} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Helmet } from "react-helmet";
import { Link, useNavigate, useParams } from "react-router-dom";
import { userAtom } from "../../atoms/userAtom";
import { Documents } from "../../components/Documents";
import { Role } from "../../helpers";
import { addTaskToCalender } from "../../helpers/tasks";
import { customerService, emailTemplateService, taskService, userService } from "../../services";
import HHTRow from "../../shared/HHTRow";
import { ChangeRecurringTaskDates } from "./ChangeRecurringTaskDates";
import { ChangeTaskDates } from "./ChangeTaskDates";
import mapEmailBody from "./Mapping";
import { ReAssignTask } from "./ReAssignTask";
import { TaskFormsPage } from "./TaskFormsPage";

const TaskDetailPage = () => {
    let params = useParams();
    const navigate = useNavigate();
    const currentUser = useAtom(userAtom);
    const actioner = `${currentUser?.identity.displayName}: ${currentUser?.identity.email}`;
    const isAdmin = currentUser?.identity.roles.some(
        (role) => role.name === Role.SystemAdmin
    );

    const [task, setTask] = useState<any>();
    const [alertVariant, setAlertVariant] = useState("");
    const [message, setMessage] = useState("");

    const [notes, setNotes] = useState<string>();
    const [showReAssignTask, setShowReAssignTask] = useState(false);
    const [showChangeDates, setShowChangeDates] = useState(false);
    const [showRecurringChangeDates, setShowRecurringChangeDates] =useState(false);
    const [allUsers, setAllUsers] = useState<any[]>();
    const [emailTemplate, setEmailTemplate] = useState<any>({});
    const [taskTemplate, setTaskTemplate] = useState<any>({});
    const [adminEmail, setAdminEmail] = useState("");


    useEffect(() => {
        getFullTask();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        Promise.all([userService.getAdminEmail(), userService.getAllListItems()]).then((response) => {
            setAdminEmail(response[0]);
            setAllUsers(response[1]);
        });
    }, []);

    useEffect(() => {
        emailTemplateService.getAll().then(async (response) => {
            const taskCompletionTemplate = response.find((template: any) => template.name === "Task completion");
            if (taskCompletionTemplate) {
                setEmailTemplate(taskCompletionTemplate);
                setTaskTemplate(await emailTemplateService.getById(taskCompletionTemplate.id));
            } else {
                console.error("Task Completion template not found");
            }
        });
    }, []);
    async function createEmailArray(taskTemplate: any, personalEmail: any, allContacts: any, mainContactEmail: any) {
        let emails = [];

        if (taskTemplate.taskCreator) {
            emails.push(currentUser?.identity.email);
        }
        if (taskTemplate.adminEmail) {
            emails.push(adminEmail);
        }
        if (taskTemplate.personnel) {
            emails.push(personalEmail);
        }
        if (taskTemplate.customerContact === "Main Contact") {
            emails.push(mainContactEmail);
        }
        if (taskTemplate.customerContact === "All Contacts") {
            if (allContacts) {
                allContacts.map((obj: any) => emails.push(obj.email));
            };
        }
        return emails;
    }
    const emailBody = emailTemplate.body;
    const emailSubject = emailTemplate.subject;

    const getFullTask = () => {
        if (params.id) {
            taskService.fullTask(params.id).then((task) => {
                setTask(task);
                setNotes(task.notes);
            });
        }
    };

    const completeTask = () => {
        const response = window.confirm("Do you want to complete this task? ");
        if (response) {
            taskService.edit({ ...task, completedDate: new Date() }).then(
                async (response) => {
                    if (response.status === "Success") {
                        let allContacts = await customerService.getAllContacts(task.customerId);
                        const mainContactEmail = allContacts.find((contact: any) => contact.isPrimaryContact)?.emailAddress;
                        const contacts = allContacts.map((item: any) => ({
                            email: item.emailAddress,
                        }));

                        const personal = allUsers?.find((item: any) => item.value === task.userId)?.label;
                        const personalEmail = personal.split(": ")[1];
                        console.log("allContacts", allContacts);
                        console.log("mainContactEmail", mainContactEmail);
                        console.log("contacts", contacts);
                        console.log("personalEmail", personalEmail);
                        setAlertVariant("success");
                        setMessage("Task has been marked as completed");
                        const receiverEmails = await createEmailArray(taskTemplate, personalEmail, contacts, mainContactEmail);
                        const updatedBody = await mapEmailBody({ body: emailBody, id: task.id, actioner: actioner });
                        const updatedSubject = await mapEmailBody({ body: emailSubject, id: task.id, actioner: actioner });
                        emailTemplateService.sendAutoEmail(updatedSubject, updatedBody, receiverEmails).then(
                            (response) => {
                                setMessage(response.message);
                            });
                    } else {
                        setAlertVariant("danger");
                        setMessage(response.message);
                    }
                },
                (error) => {
                    setAlertVariant("danger");
                    setMessage(error);
                }
            );
        }
    };

    const saveNotes = async () => {
        taskService.edit({ ...task, notes: notes }).then(
            (response) => {
                if (response.status === "Success") {
                    setAlertVariant("success");
                    setMessage("Notes have been updated successfully");
                } else {
                    setAlertVariant("danger");
                    setMessage(response.message);
                }
            },
            (error) => {
                setAlertVariant("danger");
                setMessage(error);
            }
        );
    };

    const viewOrAddToCalendar = async () => {
        if (!task.addToCalendar) {
            addTaskToCalender(task.id).then(
                (response) => {
                    if (response.status === "Success") {
                        taskService.edit({ ...task, addToCalendar: true });
                        setAlertVariant("success");
                        setMessage("Event successfully added to the calendar");
                        setTask({ ...task, addToCalendar: true });
                    } else {
                        setAlertVariant("danger");
                        setMessage(response.message);
                    }
                },
                (error) => {
                    setAlertVariant("danger");
                    setMessage(error);
                }
            );
        } else {
            isAdmin ? navigate(`/calendar`) : navigate(`/mycalendar`);
        }
    };

    const handleReAssignTask = (reAssigned: boolean) => {
        if (reAssigned) {
            setAlertVariant("success");
            setMessage("Task has been re assigned successfully");
            getFullTask();
        }
        setShowReAssignTask(false);
    };

    const handleOpenReAssignTask = () => {
        setShowReAssignTask(true);
    };

    const handleChangeDates = (changed: boolean) => {
        if (changed) {
            setAlertVariant("success");
            setMessage("Dates have been changed successfully");
            getFullTask();
        }
        if (task.days) {
            setShowRecurringChangeDates(false);
        } else setShowChangeDates(false);
    };

    const handleOpenChangeDates = () => {
        if (task.days) {
            setShowRecurringChangeDates(true);
        } else setShowChangeDates(true);
    };

    return (
        <>
            <Container fluid>
                <Helmet>
                    <title>Task Detail</title>
                </Helmet>
                {task && (
                    <>
                        <h1 className="my-3">{task.name ? `${task.name}` : "N/A"} </h1>
                        <div className="d-flex justify-content-between">
                            <Button variant="primary" onClick={viewOrAddToCalendar}>
                                <FontAwesomeIcon
                                    fixedWidth={true}
                                    icon={faCalendar}
                                    className="me-2"
                                />
                                {task.addToCalendar ? "View in Calendar" : " Add to Calendar"}
                            </Button>
                            <Stack gap={3} direction="horizontal">
                                {isAdmin && (
                                    <>
                                        <Button
                                            variant="secondary"
                                            onClick={handleOpenReAssignTask}
                                        >
                                            Reassign
                                        </Button>
                                        <Button variant="secondary" onClick={handleOpenChangeDates}>
                                            Change Dates
                                        </Button>
                                    </>
                                )}
                                <Button
                                    variant="primary"
                                    className="ms-auto"
                                    onClick={completeTask}
                                    disabled={task.completedDate ? true : false}
                                >
                                    <FontAwesomeIcon
                                        fixedWidth={true}
                                        icon={faCheck}
                                        className="me-2"
                                    />
                                    Mark Completed
                                </Button>
                            </Stack>
                        </div>
                        <br />
                        <HHTRow label="Type" value={task.taskTypeName} />
                        <HHTRow label="Product" value={task.productName} />
                        <HHTRow label="Personnel" value={task.userName} />
                        <HHTRow
                            label="Start time"
                            value={dayjs(task.creationDate).format("ddd DD MMM YYYY - HH:mm")}
                        />
                        {task.days && (
                            <p className="text-center">
                                Recurring on {task.days} every {task.recursiveInterval} weeks
                            </p>
                        )}
                        <HHTRow
                            label="End/Due time"
                            value={dayjs(task.dueDate).format("ddd DD MMM YYYY - HH:mm")}
                        />
                        <HHTRow
                            label="Customer"
                            value={
                                isAdmin ? (
                                    <div>
                                        {task.customerName}{" "}
                                        <Link to={`/customers/${task.customerId}`}>{task.customerNumber}</Link>
                                    </div>
                                ) : (
                                    <div>
                                        {task.customerName} {task.customerNumber}
                                    </div>
                                )
                            }
                        />
                        <HHTRow label="Address" value={<a href={`https://maps.google.com/?q=${task.location}`} target="_blank" rel="noreferrer" > {task.location}</a>} />
                        <Row className="mt-5">
                            <Col md={3}>Notes</Col>
                            <Col md={9}>
                                <Form>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="exampleForm.ControlTextarea1"
                                    >
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            value={notes}
                                            onChange={(event) => setNotes(event.target.value)}
                                        />
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                        <Stack direction="horizontal" gap={3}>
                            <div className="ms-auto">
                                <Button type="submit" variant="primary" onClick={saveNotes}>
                                    Save notes
                                </Button>
                            </div>
                        </Stack>
                        <Alert variant={alertVariant} className="mt-3">
                            {message}
                        </Alert>
                    </>
                )}
            </Container>
            <ReAssignTask
                show={showReAssignTask}
                onClose={handleReAssignTask}
                taskId={params.id}
            />
            <ChangeTaskDates
                show={showChangeDates}
                onClose={handleChangeDates}
                taskId={params.id}
            />
            <ChangeRecurringTaskDates
                show={showRecurringChangeDates}
                onClose={handleChangeDates}
                taskId={params.id}
            />
            <Row>
                <Col>
                    <Tabs defaultActiveKey="required" className="mb-3" mountOnEnter>
                        <Tab eventKey="required" title="Documents">
                            <Documents />
                        </Tab>
                        <Tab eventKey="due" title="Forms">
                            <TaskFormsPage
                                taskId={task ? task.id : "#####"}
                            />
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </>
    );
};

export { TaskDetailPage };
