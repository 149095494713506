import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { TaskListPage } from "../Task/TaskListPage";

const AllTasksPage = () => {
    return (
        <Container fluid>
            <Helmet>
                <title>All Tasks</title>
            </Helmet>
            <Row>
                <Col>
                    <h1 className="my-3">All Tasks</h1>
                    <TaskListPage admin/>
                </Col>
            </Row>
        </Container>
    )
}

export { AllTasksPage };
