import { IIdentity } from "@/customTypings/Identity";
import { useAtom } from "@dbeining/react-atom";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik } from "formik";
import React, { useState } from "react";
import {
    Alert,
    Button, Col, Form, InputGroup, Modal, Row, Stack
} from "react-bootstrap";
import * as Yup from "yup";
import { userAtom } from "../../atoms/userAtom";
import { IPasswordComplexityResult } from "../../customTypings/PasswordComplexityResult";
import { accountService } from "../../services";
import { Avatar } from "../Avatar";
import { useFormikSubmitEffect } from "../FormikSubmitEffect";
import { LoadingSpinner } from "../LoadingSpinner";
import { PasswordStrength } from "../PasswordStrength";

type Props = {
    show: boolean;
    user: IIdentity;
    setShow: React.Dispatch<React.SetStateAction<any>>;
    setUserInteraction?: React.Dispatch<React.SetStateAction<any>>;
};

const UserPasswordEdit: React.FC<Props> = ({
    show,
    user,
    setShow,
    setUserInteraction,
}) => {
    const [alertVariant, setAlertVariant] = useState("danger");
    const [passwordComplexity, setPasswordComplexity] =
        useState<IPasswordComplexityResult>({
            isSuccess: false,
            score: 0,
            messages: [],
        });
    const currentUser = useAtom(userAtom);
    const { FormikSubmitEffect, hasSubmitted } = useFormikSubmitEffect();

    const handleClose = () => {
        setShow(false);
        setPasswordComplexity({ isSuccess: false, score: 0, messages: [] });
    };

    function validatePassword(value: string) {
        if (value === "") {
            setPasswordComplexity({ isSuccess: false, score: 0, messages: [] });
        } else {
            accountService
                .passwordComplexityCheck(
                    currentUser?.identity.email ?? "",
                    value
                )
                .then(
                    (result: IPasswordComplexityResult) => {
                        setPasswordComplexity(result);
                    },
                    (error) => {
                        setPasswordComplexity({
                            isSuccess: false,
                            score: 0,
                            messages: [error],
                        });
                    }
                );
        }
    }

    return (
        <Modal
            centered
            show={show}
            keyboard={false}
            onHide={handleClose}
            size="lg"
        >
            <Formik
                validateOnChange={hasSubmitted}
                validateOnBlur={hasSubmitted}
                initialValues={{
                    currentPassword: "",
                    showCurrentPassword: false,
                    newPassword: "",
                    showNewPassword: false,
                    confirmPassword: "",
                    showConfirmPassword: false,
                }}
                validationSchema={Yup.object().shape({
                    currentPassword: Yup.string().required(
                        "Current Password is required"
                    ),
                    newPassword: Yup.string().required(
                        "New Password is required"
                    ),
                    confirmPassword: Yup.string().required(
                        "Confirm Password is required"
                    ),
                })}
                onSubmit={(
                    values,
                    { setStatus, setSubmitting, setFieldError }
                ) => {
                    setStatus();
                    accountService
                        .updatePassword(
                            values.currentPassword,
                            values.newPassword,
                            values.confirmPassword
                        )
                        .then(
                            (response) => {
                                setSubmitting(false);
                                if (response.status !== "Failure") {
                                    if (setUserInteraction !== undefined) {
                                        setUserInteraction(true);
                                    }
                                    handleClose();
                                } else {
                                    setAlertVariant("danger");
                                    setStatus(response.message);
                                }
                            },
                            (error) => {
                                setAlertVariant("danger");
                                if (error.status === 400) {
                                    setFieldError(
                                        "currentPassword",
                                        error.errors.CurrentPassword
                                    );
                                    setFieldError(
                                        "newPassword",
                                        error.errors.NewPassword
                                    );
                                    setFieldError(
                                        "confirmPassword",
                                        error.errors.ConfirmPassword
                                    );
                                    setStatus(error.title);
                                } else {
                                    setStatus(error);
                                }
                                setSubmitting(false);
                            }
                        );
                }}
            >
                {({
                    values,
                    handleChange,
                    errors,
                    touched,
                    status,
                    isSubmitting,
                    submitCount,
                    handleSubmit,
                    setFieldValue,
                }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <FormikSubmitEffect submitCount={submitCount} />
                        <Modal.Header closeButton>
                            <Modal.Title>Change Password</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <h5 className="mb-3">
                                <Avatar identity={user} />
                            </h5>

                            <Stack gap={3}>
                                <Form.Group
                                    as={Row}
                                    className="mb-3"
                                    controlId="currentPassword"
                                >
                                    <Form.Label column sm={3}>
                                        Current Password
                                    </Form.Label>
                                    <Col sm={9}>
                                        <InputGroup hasValidation>
                                            <Form.Control
                                                type={
                                                    values.showCurrentPassword
                                                        ? "text"
                                                        : "password"
                                                }
                                                name="currentPassword"
                                                value={values.currentPassword}
                                                onChange={handleChange}
                                                isInvalid={
                                                    !!errors.currentPassword
                                                }
                                            />
                                            <Button
                                                variant="outline-secondary"
                                                onClick={() =>
                                                    setFieldValue(
                                                        "showCurrentPassword",
                                                        !values.showCurrentPassword
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={
                                                        values.showCurrentPassword
                                                            ? faEyeSlash
                                                            : faEye
                                                    }
                                                />
                                            </Button>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.currentPassword}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Col>
                                </Form.Group>
                                <Form.Group
                                    as={Row}
                                    className="mb-3"
                                    controlId="newPassword"
                                >
                                    <Form.Label column sm={3}>
                                        New Password
                                    </Form.Label>
                                    <Col sm={9}>
                                        <InputGroup hasValidation>
                                            <Form.Control
                                                type={
                                                    values.showNewPassword
                                                        ? "text"
                                                        : "password"
                                                }
                                                name="newPassword"
                                                value={values.newPassword}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    validatePassword(
                                                        e.target.value
                                                    );
                                                }}
                                                isInvalid={!!errors.newPassword}
                                            />
                                            <Button
                                                variant="outline-secondary"
                                                onClick={() =>
                                                    setFieldValue(
                                                        "showNewPassword",
                                                        !values.showNewPassword
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={
                                                        values.showNewPassword
                                                            ? faEyeSlash
                                                            : faEye
                                                    }
                                                />
                                            </Button>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.newPassword}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Col>
                                </Form.Group>
                                {passwordComplexity && (
                                    <PasswordStrength
                                        passwordComplexityResult={
                                            passwordComplexity
                                        }
                                    />
                                )}
                                <Form.Group
                                    as={Row}
                                    className="mb-3"
                                    controlId="confirmPassword"
                                >
                                    <Form.Label column sm={3}>
                                        Confirm Password
                                    </Form.Label>
                                    <Col sm={9}>
                                        <InputGroup hasValidation>
                                            <Form.Control
                                                type={
                                                    values.showConfirmPassword
                                                        ? "text"
                                                        : "password"
                                                }
                                                name="confirmPassword"
                                                value={values.confirmPassword}
                                                onChange={handleChange}
                                                isInvalid={
                                                    !!errors.confirmPassword
                                                }
                                            />
                                            <Button
                                                variant="outline-secondary"
                                                onClick={() =>
                                                    setFieldValue(
                                                        "showConfirmPassword",
                                                        !values.showConfirmPassword
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={
                                                        values.showConfirmPassword
                                                            ? faEyeSlash
                                                            : faEye
                                                    }
                                                />
                                            </Button>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.confirmPassword}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Col>
                                </Form.Group>
                                {status && (
                                    <Alert variant={alertVariant}>
                                        {status}
                                    </Alert>
                                )}
                            </Stack>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="form-group">
                                <Button
                                    variant="primary"
                                    disabled={isSubmitting}
                                    type="submit"
                                    className="me-2"
                                >
                                    {isSubmitting ? (
                                        <LoadingSpinner text="Updating..." />
                                    ) : (
                                        "Update"
                                    )}
                                </Button>
                                <Button
                                    variant="secondary"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export { UserPasswordEdit };
