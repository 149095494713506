import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { ErrorMessage, Field } from "formik";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { ReactSelectBootstrapStyle } from "../../styles/ReactSelectBootstrapStyle";
dayjs.extend(utc);


type Props = {
    show: boolean;
    values: any,
    errors: any,
    touched: any,
    handleChange:any
};

const RecurringAddTask: React.FC<Props> = ({ show, values, errors, touched, handleChange }) => {
    const days = ["Monday", "Tuesday", "Wednesday","Thursday", "Friday", "Saturday", "Sunday"]

    return (
        <div className="w-100">
            <Form.Group as={Row} className="mb-3" controlId="startTime">
                <Form.Label column sm={3}>
                    Start Time
                </Form.Label>
                <Col sm={3}>
                    <Form.Control type="time"
                        name="startTime"
                        value={values.startTime}
                        className={"form-control" + (errors.startTime && touched.startTime ? " is-invalid" : "")} 
                        onChange={handleChange}
                    />

                    <ErrorMessage name="startTime" component="div" className="invalid-feedback" />
                </Col>
                <Form.Label column sm={3}>
                    Due/End Time
                </Form.Label>
                <Col sm={3}>
                    <Form.Control type="time"
                        name="endTime"
                        value={values.endTime}
                        className={"form-control" + (errors.endTime && touched.endTime ? " is-invalid" : "")}
                        onChange={handleChange}
                    />

                    <ErrorMessage name="endTime" component="div" className="invalid-feedback" />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="days">
                <Form.Label column sm={3}>Day(s)</Form.Label>
                <Col sm={9} className="pt-2">
                    {days.map((day,index)=>
                        <label key={index} className="d-block">
                            <Field type="checkbox" name="days" value={day} className="me-2"
                            />
                            {day}
                        </label>
                    )}
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="duration">
                <Form.Label column sm={3}>
                    Every
                </Form.Label>
                <Col sm={3}>
                    <Form.Control type="number"
                        name="recursiveInterval"
                        value={values.recursiveInterval}
                        className={"form-control" + (errors.recursiveInterval && touched.recursiveInterval ? " is-invalid" : "")}
                        onChange={handleChange}
                    />

                    <ErrorMessage name="recursiveInterval" component="div" className="invalid-feedback" />
                </Col>
               
                    <Col sm={6}>
                    <Select
                        styles={ReactSelectBootstrapStyle}
                        className="stateManagedSelect"
                        onChange={(value) => {
                            console.log(typeof value, value)
                            values["recursiveDuration"] = value
                        }}
                        isClearable
                        options={[{
                            "label": "Week(s)",
                            "value": "Week(s)"
                        }
                        ]}
                        />
                        <ErrorMessage name="recursiveDuration" component="div" className="invalid-feedback" />
                    </Col>
               

            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="commenceDate">
                <Form.Label column sm={3}>
                    After Date
                </Form.Label>
                <Col sm={9}>
                    <Form.Control type="date"
                        name="commenceDate"
                        value={values.commenceDate}
                        className={"form-control" + (errors.commenceDate && touched.commenceDate ? " is-invalid" : "")}
                        onChange={handleChange}
                    />

                    <ErrorMessage name="commenceDate" component="div" className="invalid-feedback" />
                </Col>
               
            </Form.Group>


        </div>



    );
};

export { RecurringAddTask };
