import { ErrorMessage, Field, Formik } from "formik";
import { useEffect, useState } from "react";
import { Alert, Breadcrumb, Button, Col, Container, Form, FormCheck, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { IContactFull } from "../../customTypings/ContactFull";
import { ISpaFreshReportForm } from "../../customTypings/SpaFreshReportForm";
import {
    customerService, emailTemplateService, formService,
    orderService, taskService, userService
} from "../../services";
import { ReactSelectBootstrapStyle } from "../../styles/ReactSelectBootstrapStyle";
import getCustomerEmail from "./Customer";
import mapEmailBody from "./Mapping";
function SpaFreshReportForm() {

    const [alertVariant, setAlertVariant] = useState("danger");

    const [allCustomers, setAllCustomers] = useState<any[]>();
    const [selectedCustomerId, setSelectedCustomerId] = useState("");
    const [selectedCustomer, setSelectedCustomer] = useState<any>({});

    const [allTasks, setAllTasks] = useState<any[]>();
    const [allSelectableTasks, setAllSelectableTasks] = useState<any[]>();
    const [allCustomerTasks, setAllCustomerTasks] = useState<any[]>();
    const [selectedTaskId, setSelectedTaskId] = useState("");
    const [selectedTask, setSelectedTask] = useState<any>({});

    const [allCustomerContacts, setAllCustomerContacts] = useState<IContactFull[]>();
    const [selectedContact, setSelectedContact] = useState<any>({});

    const [emailTemplate, setEmailTemplate] = useState<any>({});

    useEffect(() => {
        Promise.all([orderService.getAll(), customerService.getAllListItems(), userService.getAllListItems(), taskService.getAll(), taskService.getAllListItems()]).then((response) => {
            setAllCustomers(response[1]);
            setAllTasks(response[3]);
            setAllSelectableTasks(response[4]);
        });
    }, []);

    useEffect(() => {
        getCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCustomerId]);

    useEffect(() => {
        if (selectedCustomer && selectedCustomerId) {
            customerService.getAllContacts(selectedCustomerId).then((fullcustomerContacts) => {
                setAllCustomerContacts(fullcustomerContacts);
                if (allTasks && allSelectableTasks) {
                    const filteredArray = allSelectableTasks.filter((item2) =>
                        allTasks.some((item1) => item1.id === item2.value && item1.customerId === selectedCustomerId)
                    );
                    setAllCustomerTasks(filteredArray)
                }
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCustomer]);

    useEffect(() => {
        getTask();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTaskId]);

    useEffect(() => {
        if (allCustomerContacts)
            setSelectedContact(allCustomerContacts.find((contact: any) => contact.isPrimaryContact));
    }, [allCustomerContacts]);

    useEffect(() => {
        emailTemplateService.getAll().then((response) => {
            const spaFreshReportFormForSigningTemplate = response.find((template: any) => template.name === "Spa Fresh Service Report for Signing");
            if (spaFreshReportFormForSigningTemplate) {
                setEmailTemplate(spaFreshReportFormForSigningTemplate);
            } else {
                console.error("Spa Fresh Service Report Form template for Signing not found");
            }
        });
    }, []);
    function getCustomer() {
        if (selectedCustomerId) {
            customerService.getFull(selectedCustomerId).then((customer) => {
                setSelectedCustomer(customer);
            });
        }
    }
    function getTask() {
        if (selectedTaskId) {
            taskService.get(selectedTaskId).then((task) => {
                setSelectedTask(task);
            });
        }
    }

    const navigate = useNavigate();
    const emailBody = emailTemplate.body;

    const initialValues: any = {
        id: "",
        taskId: "",
        signingURL: "",
        customerName: "",
        customerContactPostcode: selectedContact.id ? selectedContact.address.postcode : "",
        sanitiser: "",
        phLevel: "",
        waterAppearance: "",
        chemicalRecommendation: "",
        serviceChecks: [],
        serviceRecommendation: "",
        filterConditon: "",
        filterRecommendation: "",
        coverConditon: "",
        coverRecommendation: "",
        coverLifterConditon: "",
        coverLifterRecommendation: "",
        headrestConditon: "",
        headrestRecommendation: "",
        visitEngineerName: "",
        visitEngineerSigned: false,
        visitDate: null,
        qualifyForLastingCarePlan: false,
        name: "",
        sentTime: null,
        completedTime: null,
        customerDeclaration: false,
        gdprConsent: false,
        newsletter: false,
        customerFullName: "",
        customerSignature: "",
    };

    const save = async (
        values: any,
        setStatus: any,
        setSubmitting: any,
        setFieldError: any,
        resetForm: any
    ) => {
        const updatedSpaFreshReportForm: ISpaFreshReportForm = {
            id: undefined,
            taskId: values.taskId,
            signingURL: undefined,
            taskName: selectedTask.name,
            taskURL: undefined,
            customerName: selectedCustomer.name,
            customerContactPostcode: values.customerContactPostcode,
            customerEmail: selectedCustomer.primaryContact.emailAddress,
            sanitiser: values.sanitiser,
            phLevel: values.phLevel,
            waterAppearance: values.waterAppearance,
            chemicalRecommendation: values.chemicalRecommendation,
            serviceChecks: values.serviceChecks.join(","),
            serviceRecommendation: values.serviceRecommendation,
            filterConditon: values.filterConditon,
            filterRecommendation: values.filterRecommendation,
            coverConditon: values.coverConditon,
            coverRecommendation: values.coverRecommendation,
            coverLifterConditon: values.coverLifterConditon,
            coverLifterRecommendation: values.coverLifterRecommendation,
            headrestConditon: values.headrestConditon,
            headrestRecommendation: values.headrestRecommendation,
            visitEngineerName: values.visitEngineerName,
            visitEngineerSigned: values.visitEngineerSigned,
            visitDate: values.visitDate,
            qualifyForLastingCarePlan: values.qualifyForLastingCarePlan,
            name: "Spa Fresh Service Report Form",
            sentTime: new Date(),
            completedTime: undefined,
            customerDeclaration: false,
            gdprConsent: false,
            newsletter: false,
            customerFullName: "",
            customerSignature: "",
        };
        formService.addSpaFreshReportForm(updatedSpaFreshReportForm).then(
            async (response) => {
                setSubmitting(false);
                if (response.status === "Success") {
                    resetForm({ values: "" });
                    setAlertVariant("success");
                    setStatus("Spa Fresh Report Form has been submitted successfully, You will receive a confirmational email shortly");
                    const updatedBody = await mapEmailBody({ body: emailBody, id: response.id });
                    const receiverEmail = await getCustomerEmail({ id: response.id });
                    emailTemplateService.sendAutoEmail(emailTemplate.subject, updatedBody, [receiverEmail]).then(
                        (response) => {
                            setStatus(response.message);
                        });
                    setTimeout(() => {
                        navigate("/formListPage");
                    }, 2000);
                } else {
                    setAlertVariant("danger");
                    setStatus(response.message);
                }
            },
            (error) => {
                setAlertVariant("danger");
                setStatus(error.title);
            }
        );
    };
    const allConditions = [{ label: 'Good', value: 'Good' }, { label: 'Moderate', value: 'Moderate' },{ label: 'Bad', value: 'Bad' }]
    const serviceChecks = ['Complete bio film pipe clean', 'Add sanitizer',
                           'Drain rinse and wet vacuum spa and jets', 'Balance pH level',
                           'Clean and refit filter(s)', 'Check heater and activated',
                           'Clean headrests(if fitted)', 'Clean outside of cabinet & steps',
                           'Refill hot tub to correct level','Clean cover and treat with UV protection']
    return (
        <>
            <Container fluid>
                <Row>
                    <Col>
                        <Helmet>
                            <title>Spa Fresh Service Report Form</title>
                        </Helmet>

                        <Breadcrumb>
                            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/formListPage" }}>
                                Forms
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>Spa Fresh Service Report Form</Breadcrumb.Item>
                        </Breadcrumb>

                        <h1 className="my-3">Spa Fresh Service Report Form</h1>
                        <Formik
                            initialValues={{ ...initialValues }}
                            enableReinitialize
                            onSubmit={(
                                values,
                                { setStatus, setSubmitting, setFieldError, resetForm }
                            ) => {
                                save(values, setStatus, setSubmitting, setFieldError, resetForm);
                            }}
                        >
                            {({
                                errors,
                                status,
                                touched,
                                isSubmitting,
                                handleSubmit,
                                values,
                                setStatus,
                                handleChange,
                                setFieldValue,
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group as={Row} className="mb-3" controlId="customerName">
                                        <Form.Label column sm={3}>
                                            Name of the customer
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Select
                                                styles={ReactSelectBootstrapStyle}
                                                className="stateManagedSelect"
                                                onChange={(value) => {
                                                    setSelectedCustomerId(value.value);
                                                    setFieldValue("customerName", value == null ? "" : value.label);
                                                }}
                                                required
                                                options={allCustomers}
                                            />
                                            <ErrorMessage name="customerName" component="div" className="invalid-feedback" />

                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="taskId">
                                        <Form.Label column sm={3}>
                                            Associated Task
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Select
                                                styles={ReactSelectBootstrapStyle}
                                                className="stateManagedSelect"
                                                onChange={(value) => {
                                                    setSelectedTaskId(value.value);
                                                    setFieldValue("taskId", value == null ? "" : value.value);
                                                }}
                                                required
                                                options={allCustomerTasks}
                                            />
                                            <ErrorMessage name="taskId" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="customerContactPostcode">
                                        <Form.Label column sm={3}>
                                            Postcode
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field name="customerContactPostcode" type="text" className={"form-control" + (errors.customerContactPostcode && touched.customerContactPostcode ? " is-invalid" : "")} />
                                            <ErrorMessage name="customerContactPostcode" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>


                                    <Form.Group as={Row} className="mb-3" controlId="sanitiser">
                                        <Form.Label column sm={3}>
                                            Sanitizer
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field name="sanitiser" type="text" className={"form-control" + (errors.sanitiser && touched.sanitiser ? " is-invalid" : "")} />
                                            <ErrorMessage name="sanitiser" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="phLevel">
                                        <Form.Label column sm={3}>
                                            pH Level
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field name="phLevel" type="text" className={"form-control" + (errors.phLevel && touched.phLevel ? " is-invalid" : "")} />
                                            <ErrorMessage name="phLevel" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="waterAppearance">
                                        <Form.Label column sm={3}>
                                            Water appearance
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Select
                                                styles={ReactSelectBootstrapStyle}
                                                className="stateManagedSelect"
                                                onChange={(value) => {
                                                    setFieldValue("waterAppearance", value == null ? "" : value.value);
                                                }}
                                                isClearable
                                                options={allConditions}
                                            />
                                            <ErrorMessage name="waterAppearance" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="chemicalRecommendation">
                                        <Form.Label column sm={3}>
                                            Chemical recommendation
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field name="chemicalRecommendation" type="text" className={"form-control" + (errors.chemicalRecommendation && touched.chemicalRecommendation ? " is-invalid" : "")} />
                                            <ErrorMessage name="chemicalRecommendation" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="serviceChecks">
                                        <Form.Label column sm={3}>Service Check(s)</Form.Label>
                                        <Col sm={6} className="pt-2">
                                            <Row>
                                                {serviceChecks.map((method, index) =>
                                                    <Col key={index} sm={6} className="mb-2">
                                                        <label className="d-inline me-3">
                                                            <Field type="checkbox" name="serviceChecks" value={method} className="me-2" />
                                                            {method}
                                                        </label>
                                                    </Col>
                                                )}
                                            </Row>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="serviceRecommendation">
                                        <Form.Label column sm={3}>
                                            Service recommendation
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field name="serviceRecommendation" type="text" className={"form-control" + (errors.serviceRecommendation && touched.serviceRecommendation ? " is-invalid" : "")} />
                                            <ErrorMessage name="serviceRecommendation" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="filterConditon">
                                        <Form.Label column sm={3}>
                                            Filter conditon
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Select
                                                styles={ReactSelectBootstrapStyle}
                                                className="stateManagedSelect"
                                                onChange={(value) => {
                                                    setFieldValue("filterConditon", value == null ? "" : value.value);
                                                }}
                                                isClearable
                                                options={allConditions}
                                            />
                                            <ErrorMessage name="filterConditon" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="filterRecommendation">
                                        <Form.Label column sm={3}>
                                            Filter recommendation
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field name="filterRecommendation" type="text" className={"form-control" + (errors.filterRecommendation && touched.filterRecommendation ? " is-invalid" : "")} />
                                            <ErrorMessage name="filterRecommendation" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="coverConditon">
                                        <Form.Label column sm={3}>
                                            Cover conditon
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Select
                                                styles={ReactSelectBootstrapStyle}
                                                className="stateManagedSelect"
                                                onChange={(value) => {
                                                    setFieldValue("coverConditon", value == null ? "" : value.value);
                                                }}
                                                isClearable
                                                options={allConditions}
                                            />
                                            <ErrorMessage name="coverConditon" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="coverRecommendation">
                                        <Form.Label column sm={3}>
                                            Cover recommendation
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field name="coverRecommendation" type="text" className={"form-control" + (errors.coverRecommendation && touched.coverRecommendation ? " is-invalid" : "")} />
                                            <ErrorMessage name="coverRecommendation" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="coverLifterConditon">
                                        <Form.Label column sm={3}>
                                            Cover Lifter conditon
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Select
                                                styles={ReactSelectBootstrapStyle}
                                                className="stateManagedSelect"
                                                onChange={(value) => {
                                                    setFieldValue("coverLifterConditon", value == null ? "" : value.value);
                                                }}
                                                isClearable
                                                options={allConditions}
                                            />
                                            <ErrorMessage name="coverLifterConditon" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="coverLifterRecommendation">
                                        <Form.Label column sm={3}>
                                            Cover Lifter recommendation
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field name="coverLifterRecommendation" type="text" className={"form-control" + (errors.coverLifterRecommendation && touched.coverLifterRecommendation ? " is-invalid" : "")} />
                                            <ErrorMessage name="coverLifterRecommendation" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>


                                    <Form.Group as={Row} className="mb-3" controlId="headrestConditon">
                                        <Form.Label column sm={3}>
                                            Hardest conditon
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Select
                                                styles={ReactSelectBootstrapStyle}
                                                className="stateManagedSelect"
                                                onChange={(value) => {
                                                    setFieldValue("headrestConditon", value == null ? "" : value.value);
                                                }}
                                                isClearable
                                                options={allConditions}
                                            />
                                            <ErrorMessage name="headrestConditon" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="headrestRecommendation">
                                        <Form.Label column sm={3}>
                                            Hardest recommendation
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field name="headrestRecommendation" type="text" className={"form-control" + (errors.headrestRecommendation && touched.headrestRecommendation ? " is-invalid" : "")} />
                                            <ErrorMessage name="headrestRecommendation" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="visitEngineerName">
                                        <Form.Label column sm={3}>
                                            Engineer name
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field name="visitEngineerName" type="text" className={"form-control" + (errors.visitEngineerName && touched.visitEngineerName ? " is-invalid" : "")} />
                                            <ErrorMessage name="visitEngineerName" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="visitEngineerSigned">
                                        <Form.Label column sm={3}>
                                            Engieer Signed?
                                        </Form.Label>
                                        <Col sm={9}>
                                            <FormCheck
                                                type="switch"
                                                id="custom-switch"
                                                label=""
                                                checked={values.visitEngineerSigned}
                                                onChange={handleChange}
                                                name="visitEngineerSigned"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="visitDate">
                                        <Form.Label column sm={3}>
                                            Date of visit
                                        </Form.Label>
                                        <Col sm={4}>
                                            <Field name="visitDate" type="date" className={"form-control" + (errors.visitDate && touched.visitDate ? " is-invalid" : "")} />
                                            <ErrorMessage name="visitDate" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="qualifyForLastingCarePlan">
                                        <Form.Label column sm={3}>
                                            Qualify for lasting care plan?
                                        </Form.Label>
                                        <Col sm={9}>
                                            <FormCheck
                                                type="switch"
                                                id="custom-switch"
                                                label=""
                                                checked={values.qualifyForLastingCarePlan}
                                                onChange={handleChange}
                                                name="qualifyForLastingCarePlan"
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Button
                                        variant="primary"
                                        disabled={isSubmitting}
                                        type="submit"
                                        className="me-2 d-flex align-items-end ms-auto"
                                    >
                                        {isSubmitting ? <LoadingSpinner text="Saving..." /> : "Complete"}
                                    </Button>

                                    {status && (
                                        <Alert variant={alertVariant} className="mt-3">
                                            {status}
                                        </Alert>
                                    )}
                                </Form>
                            )}
                        </Formik>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default SpaFreshReportForm;