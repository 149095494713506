import { ErrorMessage, Field, Formik } from "formik";
import { useEffect, useState } from "react";
import { Alert, Breadcrumb, Button, Col, Container, Form, FormCheck, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { IContactFull } from "../../customTypings/ContactFull";
import { ISiteVisitForm } from "../../customTypings/SiteVisitForm";
import {
    customerService, emailTemplateService, formService,
    orderService, taskService, userService
} from "../../services";
import { ReactSelectBootstrapStyle } from "../../styles/ReactSelectBootstrapStyle";
import getCustomerEmail from "./Customer";
import mapEmailBody from "./Mapping";
function SiteVisitForm() {
    const navigate = useNavigate();

    const [alertVariant, setAlertVariant] = useState("danger");

    const [allCustomers, setAllCustomers] = useState<any[]>();
    const [selectedCustomerId, setSelectedCustomerId] = useState("");
    const [selectedCustomer, setSelectedCustomer] = useState<any>({});

    const [allUsers, setAllUsers] = useState<any[]>();

    const [allTasks, setAllTasks] = useState<any[]>();
    const [allSelectableTasks, setAllSelectableTasks] = useState<any[]>();
    const [allCustomerTasks, setAllCustomerTasks] = useState<any[]>();
    const [selectedTaskId, setSelectedTaskId] = useState("");
    const [selectedTask, setSelectedTask] = useState<any>({});

    const [allProducts, setAllProducts] = useState<any[]>();
    const [customerProducts, setCustomerProducts] = useState<any[]>();
    const [allCustomerProducts, setAllCustomerProducts] = useState<any[]>();
    const [selectedProductId, setSelectedProductId] = useState("");
    const [selectedProduct, setSelectedProduct] = useState<any>({});

    const [customerContacts, setCustomerContacts] = useState<any[]>();
    const [allCustomerContacts, setAllCustomerContacts] = useState<IContactFull[]>();
    const [selectedContactId, setSelectedContactId] = useState("");
    const [selectedContact, setSelectedContact] = useState<any>({});

    const [emailTemplate, setEmailTemplate] = useState<any>({});

    useEffect(() => {
        Promise.all([orderService.getAll(), customerService.getAllListItems(), userService.getAllListItems(), taskService.getAll(), taskService.getAllListItems()]).then((response) => {
            setAllProducts(response[0]);
            setAllCustomers(response[1]);
            setAllUsers(response[2]);
            setAllTasks(response[3]);
            setAllSelectableTasks(response[4]);
        });
    }, []);

    useEffect(() => {
        getCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCustomerId]);

    useEffect(() => {
        if (selectedCustomer && selectedCustomerId) {
            customerService.getAllContacts(selectedCustomerId).then((fullcustomerContacts) => {
                setAllCustomerContacts(fullcustomerContacts);
                setSelectedContactId(fullcustomerContacts.find((contact: any) => contact.isPrimaryContact)?.id);
                const contacts = fullcustomerContacts.map((item: any) => ({
                    label: `${item.name} (${item.emailAddress})`,
                    value: item.id
                }));
                setCustomerContacts(contacts);
                if (allProducts) {
                    const products = allProducts.filter(item => item.customerId === selectedCustomerId);
                    setCustomerProducts(products);
                    const selectableProducts = products.map((item: any) => ({
                        label: `${item.brand} ${item.model} ${item.color}`,
                        value: item.id
                    }));
                    setAllCustomerProducts(selectableProducts);
                }
                if (allTasks && allSelectableTasks) {
                    const filteredArray = allSelectableTasks.filter((item2) =>
                        allTasks.some((item1) => item1.id === item2.value && item1.customerId === selectedCustomerId)
                    );
                    setAllCustomerTasks(filteredArray)
                }
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCustomer]);

    useEffect(() => {
        getTask();
        getContact();
        getProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTaskId]);

    useEffect(() => {
        emailTemplateService.getAll().then((response) => {
            const siteVisitFormForSigningTemplate = response.find((template: any) => template.name === "Site Visit Form for Signing");
            if (siteVisitFormForSigningTemplate) {
                setEmailTemplate(siteVisitFormForSigningTemplate);
            } else {
                console.error("Site Visit Form template for Signing not found");
            }
        });
    }, []);
    function getCustomer() {
        if (selectedCustomerId) {
            customerService.getFull(selectedCustomerId).then((customer) => {
                setSelectedCustomer(customer);
            });
        }
    }
    function getTask() {
        if (selectedTaskId) {
            taskService.get(selectedTaskId).then((task) => {
                setSelectedTask(task);
            });
        }
    }
    function getContact() {
        if (selectedContactId && allCustomerContacts) {
            setSelectedContact(allCustomerContacts.find(item => item.id === selectedContactId));
        };
    }
    function getProduct() {
        if (selectedProductId && customerProducts) {
            setSelectedProduct(customerProducts.find(item => item.id === selectedProductId));
        };
    }

    const address = selectedContact.address;
    const emailBody = emailTemplate.body;

    const initialValues: any = {
        id: "",
        taskId: "",
        customerName: "",
        customerContactName: "",
        customerAddress: address ? `${address?.line1}, ${address?.line2}, ${address?.line3}, ${address?.town}, ${address?.county}, ${address?.postcode}` : "",
        customerHomeTel: "",
        customerMobileTel: "",
        customerWorkTel: "",
        customerOtherTel: "",
        customerEmail: selectedContact ? selectedContact?.emailAddress : "",
        productMake: "",
        productModel: "",
        productColour: "",
        productCabinet: "",
        productSerialNumber: "",
        productDeliveryDate: null,
        productFilterCode: "",
        productFreshWater: "",
        productBromine: "",
        productWarranty: "",
        productComments: "",
        servicePlanHPWOCare: false,
        servicePlanHPWSilk: false,
        servicePlanNoPumpOSB: false,
        servicePlanLC: false,
        servicePlanLCLite: false,
        servicePlanLPC: false,
        servicePlanGold: false,
        servicePlanUltimate: false,
        servicePlanEPWellness: false,
        servicePlanEPWellnessPlus: false,
        visitType: "",
        visitDetails: "",
        visitScheduledBy: "",
        visitListOfProductsAndOrAccessoriesSold: "",
        visitActionTakenByEngineerAndNotes: "",
        followUpVisitRequired: false,
        visitDateBooked: null,
        visitPaymentMethod: "",
        visitEngineerName: "",
        visitDate: null,
        name: "",
        sentTime: null,
        completedTime: null,
    };

    const save = async (
        values: any,
        setStatus: any,
        setSubmitting: any,
        setFieldError: any,
        resetForm: any
    ) => {
        const updatedSiteVisitForm: ISiteVisitForm = {
            id: undefined,
            taskId: selectedTaskId,
            signingURL: undefined,
            taskName: selectedTask.name,
            taskURL: undefined,
            customerName: selectedCustomer.name,
            customerContactName: selectedContact.name,
            customerAddress: values.customerAddress,
            customerHomeTel: values.customerHomeTel,
            customerMobileTel: values.customerMobileTel,
            customerWorkTel: values.customerWorkTel,
            customerOtherTel: values.customerOtherTel,
            customerEmail: values.customerEmail,
            productMake: selectedProduct.make,
            productModel: selectedProduct.model,
            productColour: selectedProduct.color,
            productCabinet: selectedProduct.cabinet,
            productSerialNumber: selectedProduct.serialNumber,
            productDeliveryDate: selectedProduct.deliveryDate,
            productFilterCode: selectedProduct.filterCode,
            productFreshWater: selectedProduct.freshWater,
            productBromine: selectedProduct.bromine,
            productWarranty: selectedProduct.warranty,
            productComments: values.productComments,
            servicePlanHPWOCare: values.servicePlanHPWOCare,
            servicePlanHPWSilk: values.servicePlanHPWSilk,
            servicePlanNoPumpOSB: values.servicePlanNoPumpOSB,
            servicePlanLC: values.servicePlanLC,
            servicePlanLCLite: values.servicePlanLCLite,
            servicePlanLPC: values.servicePlanLPC,
            servicePlanGold: values.servicePlanGold,
            servicePlanUltimate: values.servicePlanUltimate,
            servicePlanEPWellness: values.servicePlanEPWellness,
            servicePlanEPWellnessPlus: values.servicePlanEPWellnessPlus,
            visitType: values.visitType,
            visitDetails: values.visitDetails,
            visitScheduledBy: values.visitScheduledBy,
            visitListOfProductsAndOrAccessoriesSold: "",
            visitActionTakenByEngineerAndNotes: values.visitActionTakenByEngineerAndNotes,
            followUpVisitRequired: values.followUpVisitRequired,
            visitDateBooked: values.visitDateBooked,
            visitPaymentMethod: values.visitPaymentMethod,
            visitEngineerName: values.visitEngineerName,
            visitDate: values.visitDate,
            name: "Site Visit Form",
            sentTime: new Date(),
            completedTime: undefined,
        };
        formService.addSiteVisitForm(updatedSiteVisitForm).then(
            async (response) => {
                setSubmitting(false);
                if (response.status === "Success") {
                    resetForm({ values: "" });
                    setAlertVariant("success");
                    setStatus("Site Visit Form has been submitted successfully, You will receive a confirmational email shortly");
                    const updatedBody = await mapEmailBody({ body: emailBody, id: response.id });
                    const receiverEmail = await getCustomerEmail({ id: response.id });
                    emailTemplateService.sendAutoEmail(emailTemplate.subject, updatedBody, [receiverEmail]).then(
                        (response) => {
                            setStatus(response.message);
                        });
                    setTimeout(() => {
                        navigate("/formListPage");
                    }, 2000);
                } else {
                    setAlertVariant("danger");
                    setStatus(response.message);
                }
            },
            (error) => {
                setAlertVariant("danger");
                setStatus(error.title);
            }
        );
    };
    return (
        <>
            <Container fluid>
                <Row>
                    <Col>
                        <Helmet>
                            <title>Site Visit Form</title>
                        </Helmet>

                        <Breadcrumb>
                            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/formListPage" }}>
                                Forms
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>Site Visit Form</Breadcrumb.Item>
                        </Breadcrumb>

                        <h1 className="my-3">Site Visit Form</h1>
                        <Formik
                            initialValues={{ ...initialValues }}
                            enableReinitialize
                            onSubmit={(
                                values,
                                { setStatus, setSubmitting, setFieldError, resetForm }
                            ) => {
                                save(values, setStatus, setSubmitting, setFieldError, resetForm);
                            }}
                        >
                            {({
                                errors,
                                status,
                                touched,
                                isSubmitting,
                                handleSubmit,
                                values,
                                setStatus,
                                handleChange,
                                setFieldValue,
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group as={Row} className="mb-3" controlId="customerName">
                                        <Form.Label column sm={3}>
                                            Name of the customer
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Select
                                                styles={ReactSelectBootstrapStyle}
                                                className="stateManagedSelect"
                                                onChange={(value) => {
                                                    setSelectedCustomerId(value.value);
                                                    setFieldValue("customerName", value == null ? "" : value.label);
                                                }}
                                                required
                                                options={allCustomers}
                                            />
                                            <ErrorMessage name="customerName" component="div" className="invalid-feedback" />

                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="taskId">
                                        <Form.Label column sm={3}>
                                            Associated Task
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Select
                                                styles={ReactSelectBootstrapStyle}
                                                className="stateManagedSelect"
                                                onChange={(value) => {
                                                    setSelectedTaskId(value.value);
                                                    setFieldValue("taskId", value == null ? "" : value.value);
                                                }}
                                                required
                                                options={allCustomerTasks}
                                            />
                                            <ErrorMessage name="taskId" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="customerContactName">
                                        <Form.Label column sm={3}>
                                            Name of the main customer contact
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Select
                                                styles={ReactSelectBootstrapStyle}
                                                className="stateManagedSelect"
                                                onChange={(value) => {
                                                    setSelectedContactId(value.value);
                                                    setFieldValue("customerContactName", value == null ? "" : value.label);
                                                }}
                                                options={customerContacts}
                                                value={customerContacts?.find(contact => contact.value === selectedContactId)}
                                            />
                                            <ErrorMessage name="customerContactName" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>


                                    <Form.Group as={Row} className="mb-3" controlId="customerAddress">
                                        <Form.Label column sm={3}>
                                            Inline address summary
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field name="customerAddress" type="text" className={"form-control" + (errors.customerAddress && touched.customerAddress ? " is-invalid" : "")} />
                                            <ErrorMessage name="customerAddress" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="customerHomeTel">
                                        <Form.Label column sm={3}>
                                            Home telephone
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field name="customerHomeTel" type="text" className={"form-control" + (errors.customerAddress && touched.customerAddress ? " is-invalid" : "")} />
                                            <ErrorMessage name="customerHomeTel" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="customerMobileTel">
                                        <Form.Label column sm={3}>
                                            Mobile telephone
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field name="customerMobileTel" type="text" className={"form-control" + (errors.customerAddress && touched.customerAddress ? " is-invalid" : "")} />
                                            <ErrorMessage name="customerMobileTel" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="customerWorkTel">
                                        <Form.Label column sm={3}>
                                            Work telephone
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field name="customerWorkTel" type="text" className={"form-control" + (errors.customerAddress && touched.customerAddress ? " is-invalid" : "")} />
                                            <ErrorMessage name="customerWorkTel" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="customerOtherTel">
                                        <Form.Label column sm={3}>
                                            Other telephone
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field name="customerOtherTel" type="text" className={"form-control" + (errors.customerAddress && touched.customerAddress ? " is-invalid" : "")} />
                                            <ErrorMessage name="customerOtherTel" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="customerEmail">
                                        <Form.Label column sm={3}>
                                            Email Address
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field name="customerEmail" type="text" required className={"form-control" + (errors.customerAddress && touched.customerAddress ? " is-invalid" : "")} />
                                            <ErrorMessage name="customerEmail" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="product">
                                        <Form.Label column sm={3}>
                                            Customer Product
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Select
                                                styles={ReactSelectBootstrapStyle}
                                                className="stateManagedSelect"
                                                onChange={(value) => {
                                                    setSelectedProductId(value.value);
                                                    setFieldValue("product", value == null ? "" : value.label);
                                                }}
                                                isClearable
                                                options={allCustomerProducts}
                                            />
                                            <ErrorMessage name="product" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="productComments">
                                        <Form.Label column sm={3}>
                                            Comments about the product
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Form.Control as="textarea" name="productComments"
                                                onChange={handleChange}
                                                className={"form-control" + (errors.productComments && touched.productComments ? " is-invalid" : "")} />
                                            <ErrorMessage name="productComments" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>

                                    <div className="row">
                                        {['HPWOCare', 'HPWSilk', 'NoPumpOSB', 'LC', 'LCLite', 'LPC', 'Gold', 'Ultimate', 'EPWellness', 'EPWellnessPlus'].map((index) => (
                                            <Form.Group className="col-4 d-flex justify-content-start align-items-center gap-2" controlId={`servicePlan${index}`} key={`servicePlan${index}`}>
                                                <Form.Check
                                                    type="switch"
                                                    id="custom-switch"
                                                    label=""
                                                    checked={values[`servicePlan${index}`]}
                                                    onChange={handleChange}
                                                    name={`servicePlan${index}`}
                                                />
                                                <Form.Label>
                                                    ServicePlan{index}
                                                </Form.Label>
                                            </Form.Group>
                                        ))}
                                    </div>
                                    <Form.Group as={Row} className="mb-3" controlId="visitType">
                                        <Form.Label column sm={3}>
                                            Visit type
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field name="visitType" type="text" className={"form-control" + (errors.visitType && touched.visitType ? " is-invalid" : "")} />
                                            <ErrorMessage name="visitType" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="visitDetails">
                                        <Form.Label column sm={3}>
                                            Visit details
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field name="visitDetails" type="text" className={"form-control" + (errors.visitDetails && touched.visitDetails ? " is-invalid" : "")} />
                                            <ErrorMessage name="visitDetails" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="visitScheduledBy">
                                        <Form.Label column sm={3}>
                                            Staff who scheduled the visit
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Select
                                                styles={ReactSelectBootstrapStyle}
                                                className="stateManagedSelect"
                                                onChange={(value) => {
                                                    setFieldValue("visitScheduledBy", value == null ? "" : value.label);
                                                }}
                                                isClearable
                                                options={allUsers}
                                            />
                                            <ErrorMessage name="visitScheduledBy" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    {/*Need to add somwthing here*/}
                                    <Form.Group as={Row} className="mb-3" controlId="visitActionTakenByEngineerAndNotes">
                                        <Form.Label column sm={3}>
                                            Action taken by engineer and notes
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Form.Control as="textarea" name="visitActionTakenByEngineerAndNotes"
                                                onChange={handleChange}
                                                className={"form-control" + (errors.visitActionTakenByEngineerAndNotes && touched.visitActionTakenByEngineerAndNotes ? " is-invalid" : "")} />
                                            <ErrorMessage name="visitActionTakenByEngineerAndNotes" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="followUpVisitRequired">
                                        <Form.Label column sm={3}>
                                            Follow up visit required
                                        </Form.Label>
                                        <Col sm={9}>
                                            <FormCheck
                                                type="switch"
                                                id="custom-switch"
                                                label=""
                                                checked={values.followUpVisitRequired}
                                                onChange={handleChange}
                                                name="followUpVisitRequired"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="visitDateBooked">
                                        <Form.Label column sm={3}>
                                            Date booked
                                        </Form.Label>
                                        <Col sm={4}>
                                            <Field name="visitDateBooked" type="datetime-local" className={"form-control" + (errors.visitDateBooked && touched.visitDateBooked ? " is-invalid" : "")} />
                                            <ErrorMessage name="visitDateBooked" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="visitPaymentMethod">
                                        <Form.Label column sm={3}>
                                            Payment method
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Select
                                                styles={ReactSelectBootstrapStyle}
                                                className="stateManagedSelect"
                                                onChange={(value) => {
                                                    setFieldValue("visitPaymentMethod", value === null ? "" : value.value);
                                                }}
                                                isClearable
                                                options={['cash', 'cheque', 'card'].map((method) => ({ label: method, value: method }))}
                                            />
                                            <ErrorMessage name="visitPaymentMethod" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="visitEngineerName">
                                        <Form.Label column sm={3}>
                                            Engineer name
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field name="visitEngineerName" type="text" className={"form-control" + (errors.visitEngineerName && touched.visitEngineerName ? " is-invalid" : "")} />
                                            <ErrorMessage name="visitEngineerName" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="visitDate">
                                        <Form.Label column sm={3}>
                                            Date of visit
                                        </Form.Label>
                                        <Col sm={4}>
                                            <Field name="visitDate" type="date" className={"form-control" + (errors.visitDate && touched.visitDate ? " is-invalid" : "")} />
                                            <ErrorMessage name="visitDate" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>

                                    <Button
                                        variant="primary"
                                        disabled={isSubmitting}
                                        type="submit"
                                        className="me-2 d-flex align-items-end ms-auto"
                                    >
                                        {isSubmitting ? <LoadingSpinner text="Saving..." /> : "Complete"}
                                    </Button>

                                    {status && (
                                        <Alert variant={alertVariant} className="mt-3">
                                            {status}
                                        </Alert>
                                    )}
                                </Form>
                            )}
                        </Formik>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default SiteVisitForm;