import { formService } from "../../services";

type Props = {
    id?: string;
};

const getCustomerEmail = async ({ id }: Props): Promise<string> => {
    let currentForm: any = {};
    if (id) {
        currentForm = await formService.getById(id);
    }
    return currentForm.customerEmail;
}
export default getCustomerEmail;