import { ErrorMessage, Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
    Alert,
    Button, Col, Form, Image, Modal, Row, Spinner,
    Stack
} from "react-bootstrap";
import * as Yup from "yup";
import { IChecklistDefinition } from "../../customTypings/ChecklistDefinition";
import { IChecklistDefinitionFull } from "../../customTypings/ChecklistDefinitionFull";
import { IChecklistDefinitionItem } from "../../customTypings/ChecklistDefinitionItem";
import { checklistDefinitionService } from "../../services";
import { EditChecklistDefitionItems } from "../EditChecklistDefinitionItems";
import { LoadingSpinner } from "../LoadingSpinner";

type Props = {
    show: boolean;
    onClose: (updated: boolean) => void;
    checklistDefinitionId: string;
};

const EditChecklist: React.FC<Props> = ({
    show,
    onClose,
    checklistDefinitionId,
}) => {
    const [alertVariant, setAlertVariant] = useState("danger");
    const [currentChecklistDefinition, setCurrentChecklistDefinition] =
        useState<IChecklistDefinition>();
    const [
        currentChecklistDefinitionItems,
        setCurrentChecklistDefinitionItems,
    ] = useState<IChecklistDefinitionItem[]>();
    const [checklistDefinitionItems, setChecklistDefinitionItems] =
        useState<IChecklistDefinitionItem[]>();
    const [loading, setLoading] = useState(true);

    const handleClose = () => {
        setChecklistDefinitionItems(currentChecklistDefinitionItems);
        onClose(false);
    };

    useEffect(() => {
        setLoading(true);
        checklistDefinitionService
            .get(checklistDefinitionId)
            .then((checklistDefinition) => {
                setCurrentChecklistDefinition(checklistDefinition);

                checklistDefinitionService
                    .getItems(checklistDefinitionId)
                    .then(
                        (
                            checklistDefinitionitems: IChecklistDefinitionItem[]
                        ) => {
                            setCurrentChecklistDefinitionItems(
                                checklistDefinitionitems
                            );
                            setChecklistDefinitionItems(
                                checklistDefinitionitems
                            );
                            setLoading(false);
                        }
                    );
            });
    }, [checklistDefinitionId]);

    return (
        <Modal centered show={show} keyboard={false} onHide={handleClose}>
            {loading || !currentChecklistDefinition ? (
                <Stack className="justify-content-center mb-5" gap={3}>
                    <Image
                        src="/happy-hot-tubs-logo.svg"
                        className="mx-auto"
                        style={{ height: "5rem" }}
                    />
                    <Spinner
                        animation={"border"}
                        variant="info"
                        className="mx-auto"
                    />
                </Stack>
            ) : (
                <Formik
                    initialValues={{
                        name: currentChecklistDefinition.name,
                        type: currentChecklistDefinition.type,
                    }}
                    validationSchema={Yup.object().shape({
                        name: Yup.string().required("Name is required"),
                    })}
                    onSubmit={(
                        values,
                        { setStatus, setSubmitting, setFieldError }
                    ) => {
                        setStatus();
                        setSubmitting(false);
                        const updatedChecklist: IChecklistDefinitionFull = {
                            id: currentChecklistDefinition.id,
                            name: values.name,
                            type: values.type,
                            isDeleted: currentChecklistDefinition.isDeleted,
                            items: checklistDefinitionItems ?? [],
                        };
                        checklistDefinitionService
                            .editFull(updatedChecklist)
                            .then(
                                (response) => {
                                    setSubmitting(false);
                                    if (response.status !== "Failure") {
                                        setCurrentChecklistDefinitionItems(
                                            checklistDefinitionItems
                                        );
                                        onClose(true);
                                    } else {
                                        setAlertVariant("danger");
                                        setStatus(response.message);
                                    }
                                },
                                (error) => {
                                    setAlertVariant("danger");
                                    if (error.status === 400) {
                                        setStatus(error.title);
                                        setFieldError(
                                            "name",
                                            error.errors.Name
                                        );
                                        setFieldError(
                                            "type",
                                            error.errors.type
                                        );
                                    } else {
                                        setStatus(error);
                                    }
                                    setSubmitting(false);
                                }
                            );
                    }}
                >
                    {({
                        errors,
                        status,
                        touched,
                        isSubmitting,
                        handleSubmit,
                        values,
                        handleChange,
                        setFieldValue,
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Header closeButton>
                                <Modal.Title>Update Checklist</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form.Group
                                    as={Row}
                                    className="mb-3"
                                    controlId="name"
                                >
                                    <Form.Label column sm={3}>
                                        Name
                                    </Form.Label>
                                    <Col sm={9}>
                                        <Field
                                            name="name"
                                            type="text"
                                            className={
                                                "form-control" +
                                                (errors.name && touched.name
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                        />
                                        <ErrorMessage
                                            name="name"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group
                                    as={Row}
                                    className="mb-3"
                                    controlId="type"
                                >
                                    <Form.Label column sm={3}>
                                        Type
                                    </Form.Label>
                                    <Col sm={9}>
                                        <Field
                                            name="type"
                                            type="text"
                                            className={
                                                "form-control" +
                                                (errors.type && touched.type
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                        />
                                        <ErrorMessage
                                            name="type"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </Col>
                                </Form.Group>
                                {currentChecklistDefinitionItems &&
                                    checklistDefinitionItems && (
                                        <Form.Group
                                            className="mb-3"
                                            controlId="checklistItems"
                                        >
                                            <Form.Label>
                                                Checklist Items
                                            </Form.Label>
                                            <EditChecklistDefitionItems
                                                checklistDefinitionItemOptions={
                                                    checklistDefinitionItems
                                                }
                                                onChange={(updatedItems) => {
                                                    setChecklistDefinitionItems(
                                                        updatedItems
                                                    );
                                                }}
                                                onReset={() => {
                                                    setChecklistDefinitionItems(
                                                        currentChecklistDefinitionItems
                                                    );
                                                }}
                                            />
                                            <ErrorMessage
                                                name="type"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </Form.Group>
                                    )}
                                {status && (
                                    <Alert
                                        variant={alertVariant}
                                        className="mt-3"
                                    >
                                        {status}
                                    </Alert>
                                )}
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="form-group">
                                    <Button
                                        variant="primary"
                                        disabled={isSubmitting}
                                        type="submit"
                                        className="me-2"
                                    >
                                        {isSubmitting ? (
                                            <LoadingSpinner text="Updating checklist..." />
                                        ) : (
                                            "Update checklist"
                                        )}
                                    </Button>
                                    <Button
                                        variant="secondary"
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            )}
        </Modal>
    );
};

export { EditChecklist };
