import { usePDF } from "react-to-pdf";
import "../../styles/form.css";

type Props = {
    currentForm: any;
};

const HotTubOrderAndEndlessOrderPdfs: React.FC<Props> = ({ currentForm }) => {
    const { toPDF, targetRef } = usePDF({ filename: `${currentForm.name}.pdf` });
    const paymentMethods = ["Cash", "Cheque", "Card", "BACS"];
    const fundingChoices = ["Full payment prior to delivery", "Finance terms"];
    const hotTubForm = currentForm?.name === "Hot Tub Order Form";
    const endlessForm = currentForm?.name === "Endless Pools Order Form";
    const additionalPackages = [
        "Happy Freeflow Package - £299.00",
        "Happy Silver Package - £399.00",
        "Happy Gold Package - £699.00",
        "Happy Platinum Package - £999",
        "Happy Platinum Package PLUS - £1899.00",
    ];
    const features = ["Bluetooth speaker system", "Treadmill"]
    const coverOptions = ["Hard top cover (Black)  £1249", "Uprite lifter x2  £1198", "Cover Cradle x2  £1198", "End 2 end roll on cover  £4999", "Own Brand Roll on cover  £1999", "Cavana  (depending on size) £13995-£21995"]
    const servicePlans = ["EP Wellness Plan", "EP Wellness PLUS Plan"]
    const accessoryList = currentForm.accessoryList ? currentForm.accessoryList.split(',') : [];
    const deliveryMethods = [
        "Manual delivery & commision (FREE)",
        "Crane/hiab delivery and commission - POA",
    ];

    return (
        <div>
            <button onClick={() => toPDF()}>Download PDF</button>
            <div ref={targetRef} className="pdf-content">
                <div>
                    <div className="d-flex">
                        <img className="logo" src="/happy-hot-tubs-logo.svg" alt="" />
                        <div className="heading" style={{ width: "100%" }}>
                            <h1>{currentForm.name}</h1>
                        </div>
                    </div>
                    <div>
                        <table className="border w-100 mb-3">
                            <tbody>
                                <tr>
                                    <td colSpan={6} className="border-bottom fw-bold text-center">
                                        <h3>Order Details</h3>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="fw-bold" style={{ width: "300px" }}>
                                        Order Number
                                    </td>
                                    <td style={{ width: "200px" }}>{currentForm.orderNumber}</td>
                                    <td style={{ width: "200px" }} className="fw-bold">
                                        Date
                                    </td>
                                    <td style={{ width: "200px" }}>{currentForm.orderDate}</td>
                                </tr>
                                <tr>
                                    <td className="fw-bold">Order Location</td>
                                    <td colSpan={5}>{currentForm.orderLocation}</td>
                                </tr>
                                <tr>
                                    <td className="fw-bold">Cof Sent</td>
                                    <td>{currentForm.cofSent ? "Yes" : "No"}</td>
                                    <td className="fw-bold">Cof Link</td>
                                    <td>{currentForm.cofLink}</td>
                                    <td className="fw-bold">SubTotal</td>
                                    <td style={{ width: "200px" }}>
                                        {currentForm.subtotal.toFixed(2)}/-
                                    </td>
                                </tr>
                                <tr>
                                    <td className="fw-bold">Deposit Amount</td>
                                    <td style={{ width: "200px" }}>
                                        {currentForm.depositAmount.toFixed(2)}/-
                                    </td>
                                    <td className="fw-bold">Balance</td>
                                    <td style={{ width: "200px" }}>
                                        {currentForm.balance.toFixed(2)}/-
                                    </td>
                                    <td className="fw-bold">Dposit Methods</td>
                                    <td style={{ width: "200px" }}>{currentForm.depositMethods}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="border w-100 mb-3">
                            <tbody>
                                <tr>
                                    <td colSpan={4} className="border-bottom fw-bold text-center">
                                        <h3>Customer Details</h3>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="fw-bold">Name</td>
                                    <td style={{ width: "200px" }}>{currentForm.customerName}</td>
                                    <td className="fw-bold">Email</td>
                                    <td style={{ width: "200px" }}>{currentForm.customerEmail}</td>
                                </tr>
                                <tr>
                                    <td className="fw-bold">Address</td>
                                    <td>{currentForm.customerAddress}</td>
                                    <td className="fw-bold">Home Telephone</td>
                                    <td>{currentForm.HomeTel}</td>
                                </tr>
                                <tr>
                                    <td className="fw-bold">Mobile</td>
                                    <td>{currentForm.customerMobileTel}</td>
                                    <td className="fw-bold">Referred By</td>
                                    <td>{currentForm.customerReferredBy}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="mb-3 border w-100">
                            <tbody>
                                <tr>
                                    <td colSpan={6} className="fw-bold border-bottom text-center">
                                        <h3>Product Details</h3>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="fw-bold">Collection</td>
                                    <td style={{ width: "200px" }}>{currentForm.productCollection}</td>
                                    <td className="fw-bold">Model</td>
                                    <td style={{ width: "200px" }}>{currentForm.productModel}</td>
                                    <td style={{ width: "100px" }} className="fw-bold">
                                        Price
                                    </td>
                                    <td style={{ width: "200px" }}>{currentForm.productPrice}</td>
                                </tr>
                                <tr>
                                    <td className="fw-bold">Shell Color</td>
                                    <td>{currentForm.productShellColour}</td>
                                    <td className="fw-bold">Cabinet</td>
                                    <td colSpan={3}>{currentForm.productCabinet}</td>
                                </tr>
                                <tr>
                                    <td className="fw-bold">ModelToBePartExchanged</td>
                                    <td>{currentForm.modelToBePartExchanged}</td>
                                    <td className="fw-bold">Accessories</td>
                                    <td colSpan={3}>{currentForm.accessoryList}</td>
                                </tr>
                                <tr>
                                    <td className="fw-bold">Additional Package</td>
                                    <td colSpan={5}>{currentForm.additionalPackage}</td>
                                </tr>
                                <tr>
                                    <td className="fw-bold">Preferred Delivery Date</td>
                                    <td colSpan={2}>{currentForm.preferredDeliveryDate}</td>
                                    <td className="fw-bold">Electrical Supply</td>
                                    <td>{currentForm.electricalSupply}</td>
                                </tr>
                                <tr>
                                    <td className="fw-bold">Access Description</td>
                                    <td colSpan={5}>{currentForm.accessDescription}</td>
                                </tr>
                                <tr>
                                    <td className="fw-bold">Comments</td>
                                    <td colSpan={5}>{currentForm.comments}</td>
                                </tr>
                            </tbody>
                        </table>
                        {hotTubForm && (<table className="mb-3 border w-100">
                            <tbody>
                                <tr>
                                    <td colSpan={4} className="fw-bold border text-center">
                                        Selected Accessory Packages
                                    </td>
                                </tr>
                                {additionalPackages.map((check, index) => {
                                    if (index % 2 === 0) {
                                        const nextCheck = additionalPackages[index + 1];
                                        return (
                                            <tr key={index}>
                                                <td style={{ width: "3%" }}>
                                                    <input
                                                        checked={currentForm.additionalPackage?.includes(check)}
                                                        type="checkbox"
                                                    />
                                                </td>
                                                <td style={{ width: "47%" }}>{check}</td>
                                                <td style={{ width: "3%" }}>
                                                    {nextCheck && (
                                                        <input
                                                            checked={currentForm.additionalPackage?.includes(nextCheck)}
                                                            type="checkbox"
                                                        />
                                                    )}
                                                </td>
                                                <td style={{ width: "47%" }}>{nextCheck}</td>
                                            </tr>
                                        );
                                    } else if (index === additionalPackages.length - 1) {
                                        return (
                                            <tr key={index}>
                                                <td colSpan={4} style={{ width: "50%" }}>
                                                    <input
                                                        checked={currentForm.additionalPackage?.includes(check)}
                                                        type="checkbox"
                                                    />
                                                    {check}
                                                </td>
                                            </tr>
                                        );
                                    }
                                    return null;
                                })}
                            </tbody>
                        </table>)}
                        {endlessForm && (<table className="mb-3 border w-100">
                            <tbody>
                                <tr>
                                    <td colSpan={4} className="fw-bold border text-center">
                                        Selected Cover Options
                                    </td>
                                </tr>
                                {coverOptions.map((check, index) => {
                                    if (index % 2 === 0) {
                                        const nextCheck = coverOptions[index + 1];
                                        return (
                                            <tr key={index}>
                                                <td style={{ width: "3%" }}>
                                                    <input
                                                        checked={currentForm.coverOptions?.includes(check)}
                                                        type="checkbox"
                                                    />
                                                </td>
                                                <td style={{ width: "47%" }}>{check}</td>
                                                <td style={{ width: "3%" }}>
                                                    {nextCheck && (
                                                        <input
                                                            checked={currentForm.coverOptions?.includes(nextCheck)}
                                                            type="checkbox"
                                                        />
                                                    )}
                                                </td>
                                                <td style={{ width: "47%" }}>{nextCheck}</td>
                                            </tr>
                                        );
                                    }
                                    return null;
                                })}
                            </tbody>
                        </table>)}

                        {endlessForm && (<table className="mb-3 border w-100">
                            <tbody>
                                <tr>
                                    <td colSpan={4} className="fw-bold border text-center">
                                        Features
                                    </td>
                                </tr>
                                {features.map((check, index) => {
                                    if (index % 2 === 0) {
                                        const nextCheck = features[index + 1];
                                        return (
                                            <tr key={index}>
                                                <td style={{ width: "3%" }}>
                                                    <input
                                                        checked={currentForm.features?.includes(check)}
                                                        type="checkbox"
                                                    />
                                                </td>
                                                <td style={{ width: "47%" }}>{check}</td>
                                                <td style={{ width: "3%" }}>
                                                    <input
                                                        checked={currentForm.features?.includes(nextCheck)}
                                                        type="checkbox"
                                                    />
                                                </td>
                                                <td style={{ width: "47%" }}>{nextCheck}</td>
                                            </tr>
                                        );
                                    }
                                    return null;
                                })}
                            </tbody>
                        </table>)}

                        {endlessForm && (<table className="mb-3 border w-100">
                            <tbody>
                                <tr>
                                    <td colSpan={4} className="fw-bold border text-center">
                                        Selected Servicing Options
                                    </td>
                                </tr>
                                {servicePlans.map((check, index) => {
                                    if (index % 2 === 0) {
                                        const nextCheck = servicePlans[index + 1];
                                        return (
                                            <tr key={index}>
                                                <td style={{ width: "3%" }}>
                                                    <input
                                                        checked={currentForm.servicePlan?.includes(check)}
                                                        type="checkbox"
                                                    />
                                                </td>
                                                <td style={{ width: "47%" }}>{check}</td>
                                                <td style={{ width: "3%" }}>
                                                    <input
                                                        checked={currentForm.servicePlan?.includes(nextCheck)}
                                                        type="checkbox"
                                                    />
                                                </td>
                                                <td style={{ width: "47%" }}>{nextCheck}</td>
                                            </tr>
                                        );
                                    }
                                    return null;
                                })}
                            </tbody>
                        </table>)}

                        <table className="mb-3 border w-100">
                            <tbody>
                                <tr>
                                    <td colSpan={4} className="fw-bold border text-center">
                                        Individual Selected Accessories
                                    </td>
                                </tr>
                                {accessoryList.map((check: any, index: any) => {
                                    if (index % 2 === 0) {
                                        const nextCheck = accessoryList[index + 1];
                                        return (
                                            <tr key={index}>
                                                <td style={{ width: "3%" }}>
                                                    <input
                                                        checked={currentForm.accessoryList?.includes(check)}
                                                        type="checkbox"
                                                    />
                                                </td>
                                                <td style={{ width: "47%" }}>{check}</td>
                                                <td>
                                                    {nextCheck && (
                                                        <input
                                                            checked={currentForm.accessoryList?.includes(nextCheck)}
                                                            type="checkbox"
                                                        />
                                                    )}
                                                </td>
                                                <td style={{ width: "200px" }}>{nextCheck}</td>
                                            </tr>
                                        );
                                    } else if (index === accessoryList.length - 1) {
                                        return (
                                            <tr key={index}>
                                                <td colSpan={4}>
                                                    <input
                                                        checked={currentForm.accessoryList?.includes(check)}
                                                        type="checkbox"
                                                    />
                                                    {check}
                                                </td>
                                            </tr>
                                        );
                                    }
                                    return null;
                                })}
                            </tbody>
                        </table>
                        <table className="mb-3 border w-100">
                            <tbody>
                                <tr>
                                    <td colSpan={4} className="fw-bold border-bottom text-center">
                                        Payment Methods
                                    </td>
                                </tr>
                                {paymentMethods.map((check, index) => {
                                    if (index % 2 === 0) {
                                        const nextCheck = paymentMethods[index + 1];
                                        return (
                                            <tr key={index}>
                                                <td style={{ width: "3%" }}>
                                                    <input
                                                        checked={currentForm.depositMethods?.includes(check)}
                                                        type="checkbox"
                                                    />
                                                </td>
                                                <td style={{ width: "47%" }}>{check}</td>
                                                <td style={{ width: "3%" }}>
                                                    <input
                                                        checked={currentForm.depositMethods?.includes(nextCheck)}
                                                        type="checkbox"
                                                    />
                                                </td>
                                                <td style={{ width: "47%" }}>{nextCheck}</td>
                                            </tr>
                                        );
                                    }
                                    return null;
                                })}
                            </tbody>
                        </table>
                        <table className="mb-3 border w-100">
                            <tbody>
                                <tr>
                                    <td colSpan={4} className="fw-bold border text-center">
                                        Funding Choices
                                    </td>
                                </tr>
                                {fundingChoices.map((check, index) => {
                                    if (index % 2 === 0) {
                                        const nextCheck = fundingChoices[index + 1];
                                        return (
                                            <tr key={index}>
                                                <td style={{ width: "3%" }}>
                                                    <input
                                                        checked={currentForm.fundingChoice?.includes(check)}
                                                        type="checkbox"
                                                    />
                                                </td>
                                                <td style={{ width: "47%" }}>{check}</td>
                                                <td style={{ width: "3%" }}>
                                                    <input
                                                        checked={currentForm.fundingChoice?.includes(nextCheck)}
                                                        type="checkbox"
                                                    />
                                                </td>
                                                <td style={{ width: "47%" }}>{nextCheck}</td>
                                            </tr>
                                        );
                                    }
                                    return null;
                                })}
                            </tbody>
                        </table>
                        <table className="mb-3 border w-100">
                            <tbody>
                                <tr>
                                    <td colSpan={4} className="fw-bold border text-center">
                                        Delivery Methods
                                    </td>
                                </tr>
                                {deliveryMethods.map((check, index) => {
                                    if (index % 2 === 0) {
                                        const nextCheck = deliveryMethods[index + 1];
                                        return (
                                            <tr key={index}>
                                                <td style={{ width: "3%" }}>
                                                    <input
                                                        checked={currentForm.deliveryMethod?.includes(check)}
                                                        type="checkbox"
                                                    />
                                                </td>
                                                <td style={{ width: "47%" }}>{check}</td>
                                                <td style={{ width: "3%" }}>
                                                    <input
                                                        checked={currentForm.deliveryMethod?.includes(nextCheck)}
                                                        type="checkbox"
                                                    />
                                                </td>
                                                <td style={{ width: "47%" }}>{nextCheck}</td>
                                            </tr>
                                        );
                                    }
                                    return null;
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default HotTubOrderAndEndlessOrderPdfs;
