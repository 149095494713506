import { IRole } from "@/customTypings/role";
import { useAtom } from "@dbeining/react-atom";
import { faFileExport, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import { useParams } from "react-router-dom";
import { userAtom } from "../../atoms/userAtom";
import { ColumnRender, FilterType, IColumn, IFilter, IPagedListAction } from "../../components/PagedList/Declarations";
import { PagedList } from "../../components/PagedList/PagedList";
import { Role } from "../../helpers";
import { orderAccessoryService } from "../../services";
import { AddNewOrderAccessory } from "./OrderAccessoryAddPage";

const OrderAccessoryListPage = () => {
    const params = useParams();
    const currentUser = useAtom(userAtom);
    const [showAddOrderAccessory, setShowAddOrderAccessory] = useState(false);
    const [refreshNumber, setRefeshNumber] = useState(0);


    const handleAddOrderAccessory = (added: boolean) => {
        if (added) {
            setRefeshNumber((refreshNumber) => refreshNumber + 1);
        }
        setShowAddOrderAccessory(false);
    };
    const handleOpenAddOrderAccessory = () => {
        setShowAddOrderAccessory(true);
    };


    return (
        <>
            <Container fluid>
                <Row>
                    <Col>
                        <h1 className="my-3">Accessories</h1>
                        <PagedList
                            columns={[
                                {
                                    member: "name",
                                    renderAs: ColumnRender.text,
                                    title: "Name",
                                    width: 600,
                                } as IColumn,
                                {
                                    member: "quantity",
                                    renderAs: ColumnRender.text,
                                    title: "Quantity",
                                    width: 600,
                                } as IColumn,
                                
                            ]}
                            itemActions={[
                                {
                                    text: "Remove",
                                    onClick: (item) => {
                                        orderAccessoryService.remove(item.id);
                                        setRefeshNumber((refreshNumber) => refreshNumber + 1);
                                    },
                                    primary: true,
                                } as IPagedListAction,
                            ]}
                            headActions={[
                                {
                                    icon: faPlus,
                                    text: "Add Accessory",
                                    onClick: handleOpenAddOrderAccessory,
                                } as IPagedListAction,
                            ]}
                            filters={[
                                {
                                    title: "Name",
                                    member: "name",
                                    type: FilterType.text,
                                } as IFilter,
                                {
                                    title: "Quantity",
                                    member: "quantity",
                                    type: FilterType.text,
                                } as IFilter,
                            ]}
                            defaultSortBy="name"
                            getUrl={"api/orderaccessory/all/"+params.id}
                            avatarUrl="api/avatar/get"
                            rowFormatter={(item: any) => {
                                return null;
                            }}
                            persistanceKey="OrderListPage"
                            refreshNumber={refreshNumber + 1}
                            exportUrl={currentUser?.identity.roles.some((role: IRole) => [Role.Admin].indexOf(role.name) >= 0) ? "api/order/download" : undefined}
                            exportIcon={faFileExport}
                        />
                    </Col>
                </Row>

            </Container>
            <AddNewOrderAccessory show={showAddOrderAccessory} onClose={handleAddOrderAccessory} orderId={params.id } />
        </>
    );
};

export { OrderAccessoryListPage };

