import { ErrorMessage, Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { IOrderAccessory } from "../../customTypings/OrderAccessory";
import { accessoriesService, orderAccessoryService } from "../../services";
import { ReactSelectBootstrapStyle } from "../../styles/ReactSelectBootstrapStyle";

type Props = {
    show: boolean;
    onClose: (added: boolean) => void;
    orderId?: string;
    accessoryId?: string;
};

const AddNewOrderAccessory: React.FC<Props> = ({ show, onClose, orderId, accessoryId }) => {
    const [alertVariant, setAlertVariant] = useState("danger");
    const [allAccessories, setAllAccessories] = useState<any[]>();

    const handleClose = () => onClose(false);

    useEffect(() => {
        Promise.all([accessoriesService.getAllListItems()]).then((response) => {
            setAllAccessories(response[0]);
        });
    }, []);

    return (
        <Modal centered show={show} keyboard={false} onHide={handleClose}>
            <Formik
                initialValues={{
                    quantity: 0,
                    orderId: orderId ? orderId : "",
                    accessoryId: accessoryId ? accessoryId : "",
                }}
                onSubmit={(values, { setStatus, setSubmitting, setFieldError }) => {
                    setStatus();
                    setSubmitting(false);
                    const newOrderAccessory: IOrderAccessory = {
                        id: undefined,
                        quantity: values.quantity,
                        orderId: values.orderId,
                        accessoryId: values.accessoryId,
                    };
                    orderAccessoryService.add(newOrderAccessory).then(
                        (response) => {
                            setSubmitting(false);
                            if (response.status !== "Failure") {
                                onClose(true);
                            } else {
                                setAlertVariant("danger");
                                setStatus(response.message);
                            }
                        },
                        (error) => {
                            setAlertVariant("danger");
                            if (error.status === 400) {
                                setStatus(error.title);
                                setFieldError("quantity", error.errors.Quantity);
                                setFieldError("orderId", error.errors.OrderId);
                                setFieldError("accessoryId", error.errors.AccessoriesId);
                            } else {
                                setStatus(error);
                            }
                            setSubmitting(false);
                        }
                    );
                }}
            >
                {({ errors, status, touched, isSubmitting, handleSubmit, values, handleChange, setFieldValue }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>New Accessory</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group as={Row} className="mb-3" controlId="accessoryId">
                                <Form.Label column sm={3}>
                                    Accessory
                                </Form.Label>
                                <Col sm={9}>
                                    <Select
                                        styles={ReactSelectBootstrapStyle}
                                        className="stateManagedSelect"
                                        onChange={(value) => setFieldValue("accessoryId", value == null ? "" : value.value)}
                                        isClearable
                                        options={allAccessories}
                                    />
                                    <ErrorMessage name="accessoryId" component="div" className="invalid-feedback" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="accessoryQuantity">
                                <Form.Label column sm={3}>
                                    Quantity
                                </Form.Label>
                                <Col sm={9}>
                                    <Field name="quantity" type="text" className={"form-control" + (errors.quantity && touched.quantity ? " is-invalid" : "")} />
                                    <ErrorMessage name="quantity" component="div" className="invalid-feedback" />
                                </Col>
                            </Form.Group>

                            {status && (
                                <Alert variant={alertVariant} className="mt-3">
                                    {status}
                                </Alert>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="form-group">
                                <Button variant="primary" disabled={isSubmitting} type="submit" className="me-2">
                                    {isSubmitting ? <LoadingSpinner text="Adding accessory..." /> : "Add accessory"}
                                </Button>
                                <Button variant="secondary" onClick={handleClose}>
                                    Cancel
                                </Button>
                            </div>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export { AddNewOrderAccessory };
