import { formService } from "../../services";

type MappingProps = {
    body?: string;
    id?: string;
};

const getDefaultReplacements = (form: any):Record<string, string | boolean> => ({
    "{SigningURL}": form.signingUrl || "",
    "{TimeCompleted}": form.completedTime || "",
    "{TaskName}": form.taskName,
    "{TaskURL}": form.taskUrl,
    "{CustomerName}": form.customerName || "",
    "{CustomerContactName}": form.customerContactName || "",
    "{CustomerAddress}": form.customerAddress || "",
    "{CustomerHomeTel}": form.customerHomeTel || "",
    "{CustomerMobileTel}": form.customerMobileTel || "",
    "{CustomerWorkTel}": form.customerWorkTel || "",
    "{CustomerOtherTel}": form.customerOtherTel || "",
    "{CustomerEmail}": form.customerEmail || "",
    "{ProductMake}": form.productMake || "",
    "{ProductModel}": form.productModel || "",
    "{ProductColour}": form.productColour || "",
    "{ProductCabinet}": form.productCabinet || "",
    "{ProductSerialNumber}": form.productSerialNumber || "",
    "{ProductDeliveryDate}": form.productDeliveryDate || "",
    "{ProductFilterCode}": form.productFilterCode || "",
    "{ProductFreshWater}": form.productFreshWater || "false",
    "{ProductBromine}": form.productBromine || "false",
    "{ProductWarranty}": form.productWarranty || "",
    "{ProductComments}": form.productComments || "",
    "{ServicePlanHPWOCare}": form.servicePlanHPWOCare || "false",
    "{ServicePlanHPWSilk}": form.servicePlanHPWSilk || "false",
    "{ServicePlanNoPumpOSB}": form.servicePlanNoPumpOSB || "false",
    "{ServicePlanLC}": form.servicePlanLC || "false",
    "{ServicePlanLCLite}": form.servicePlanLCLite || "false",
    "{ServicePlanLPC}": form.servicePlanLPC || "false",
    "{ServicePlanGold}": form.servicePlanGold || "false",
    "{ServicePlanUltimate}": form.servicePlanUltimate || "false",
    "{ServicePlanEPWellness}": form.servicePlanEPWellness || "false",
    "{ServicePlanEPWellnessPlus}": form.servicePlanEPWellnessPlus || "false",
});

const getFormSpecificReplacements = (form: any):Record<string, string | boolean> => {
    switch (form.name) {
        case "Site Visit Form":
            return {
                "{VisitType}": form.visitType || "",
                "{VisitDetails}": form.visitDetails || "",
                "{VisitScheduledBy}": form.visitScheduledBy || "",
                "{VisitListOfProductsAndOrAccessoriesSold}": form.visitListOfProductsAndOrAccessoriesSold || "Not Selected",
                "{VisitActionTakenByEngineerAndNotes}": form.visitActionTakenByEngineerAndNotes || "",
                "{FollowUpVisitRequired}": form.followUpVisitRequired || "false",
                "{VisitDateBooked}": form.visitDateBooked || "",
                "{VisitPaymentMethod}": form.visitPaymentMethod || "",
                "{VisitEngineerName}": form.visitEngineerName || "",
                "{VisitDate}": form.visitDate || "",
                "{CustomerVisitComment}": form.visitActionTakenByEngineerAndNotes || "ok"
            };
        case "Delivery/Installation Form":
            return {
                "{CustomerContactTitle}": form.customerContactTitle,
                "{AccessNotes}": form.accessNotes,
                "{OrderNumber}": form.orderNumber,
                "{SalesPersonName}": form.salesPersonName,
                "{Balance}": form.balance,
                "{PaymentMethod}": form.paymentMethod,
                "{DateBalancePaid}": form.dateBalancePaid,
                "{FinanceAgreementName}": form.financeAgreementName,
                "{ProductCondition}": form.productCondition || "Not provided",
                "{ExtrasList}": form.extrasList || "Not Provided",
                "{EngineerNameList}": form.engineerNameList,
                "{EngineerDate}": form.engineerDate,
                "{AccessClear}": form.accessClear,
                "{AccessComments}": form.accessComments,
                "{CustomerVisitComment}": form.visitComments,
            };
        case "Hot Tub Order Form":
            return {
                "{DeclarationAgreed}": form.customerDeclaration || "false",
                "{GDPRConsented}": form.gdprConsent || "false",
                "{ReceivesNewsletter}": form.newsletter || "false",
                "{OrderDate}": form.orderDate,
                "{OrderNumber}": form.orderNumber,
                "{OrderLocation}": form.orderLocation,
                "{COFSent}": form.cofsent || "false",
                "{COFLink}": form.cofLink || "false",
                "{Subtotal}": form.subtotal,
                "{OrderTotal}": form.orderTotal,
                "{DepositAmount}": form.depositAmount,
                "{DepositMethods}": form.depositMethods,
                "{FundingChoice}": form.fundingChoice,
                "{PartExchangeValueToBeDeducted}": form.partExchangeValueToBeDeducted,
                "{SalesPersonEmail}": form.salesPersonEmail,
                "{Comments}": form.comments,
                "{CustomerContactTitle}": form.customerContactTitle || "Mr.",
                "{CustomerReferredBy}": form.customerReferredBy,
                "{ProductCollection}": form.productCollection,
                "{ProductShellColour}": form.productShellColour,
                "{ProductPrice}": form.productPrice,
                "{ModelToBePartExchanged}": form.modelToBePartExchanged,
                "{AdditionalPackage}": form.additionalPackage,
                "{AccessoryList}": form.accessoryList,
                "{DeliveryMethod}": form.deliveryMethod,
                "{ElectricalSupply}": form.electricalSupply,
                "{PreferredDeliveryDate}": form.preferredDeliveryDate,
                "{AccessDescription}": form.accessDescription,
                "{SiteVisitFormSent}": form.siteVisitFormSent || "false",
            };
        case "Endless Pools Order Form":
            return {
                "{DeclarationAgreed}": form.customerDeclaration || "false",
                "{GDPRConsented}": form.gdprConsent || "false",
                "{ReceivesNewsletter}": form.newsletter || "false",
                "{OrderDate}": form.orderDate,
                "{OrderNumber}": form.orderNumber,
                "{OrderLocation}": form.orderLocation,
                "{COFSent}": form.cofsent || "false",
                "{COFLink}": form.cofLink,
                "{Subtotal}": form.subtotal,
                "{OrderTotal}": form.orderTotal,
                "{DepositAmount}": form.depositAmount,
                "{DepositMethods}": form.depositMethods,
                "{FundingChoice}": form.fundingChoice,
                "{PartExchangeValueToBeDeducted}": form.partExchangeValueToBeDeducted,
                "{SalesPersonEmail}": form.salesPersonEmail,
                "{Comments}": form.comments,
                "{CustomerContactTitle}": form.customerContactTitle || "Mr.",
                "{CustomerReferredBy}": form.customerReferredBy,
                "{ProductShellColour}": form.productShellColour,
                "{ProductPrice}": form.productPrice,
                "{ModelToBePartExchanged}": form.modelToBePartExchanged,
                "{Features}": form.features,
                "{CoverOptions}": form.coverOptions,
                "{ServicePlan}": form.servicePlan,
                "{DeliveryMethod}": form.deliveryMethod,
                "{ElectricalSupply}": form.electricalSupply,
                "{ElectricianRequired}": form.electricianRequired || "false",
                "{PreferredDeliveryDate}": form.preferredDeliveryDate,
                "{AccessDescription}": form.accessDescription,
                "{SiteVisitFormSent}": form.siteVisitFormSent || "false",
            };
        case "Spa Fresh Service Report Form":
            return {
                "{CustomerContactPostcode}": form.customerContactPostcode,
                "{Sanitiser}": form.sanitiser,
                "{PHLevel}": form.phLevel,
                "{WaterAppearance}": form.waterAppearance,
                "{ChemicalRecommendation}": form.chemicalRecommendation,
                "{ServiceChecks}": form.serviceChecks,
                "{ServiceRecommendation}": form.serviceRecommendation,
                "{FilterConditon}": form.filterConditon,
                "{FilterRecommendation}": form.filterRecommendation,
                "{CoverConditon}": form.coverConditon,
                "{CoverRecommendation}": form.coverRecommendation,
                "{CoverLifterConditon}": form.coverLifterConditon,
                "{CoverLifterRecommendation}": form.coverLifterRecommendation,
                "{HeadrestConditon}": form.headrestConditon,
                "{HeadrestRecommendation}": form.headrestRecommendation,
                "{VisitEngineerSigned}": form.visitEngineerSigned || "false",
                "{QualifyForLastingCarePlan}": form.qualifyForLastingCarePlan || "false",
            };
        case "Annual Service Report Form":
            return {
                "{CustomerContactPostcode}": form.customerContactPostcode,
                "{Sanitiser}": form.sanitiser,
                "{PHLevel}": form.phLevel,
                "{WaterAppearance}": form.waterAppearance,
                "{ChemicalRecommendation}": form.chemicalRecommendation,
                "{ServiceChecks}": form.serviceChecks,
                "{ServiceRecommendation}": form.serviceRecommendation,
                "{FilterConditon}": form.filterConditon,
                "{FilterRecommendation}": form.filterRecommendation,
                "{CoverConditon}": form.coverConditon,
                "{CoverRecommendation}": form.coverRecommendation,
                "{CoverLifterConditon}": form.coverLifterConditon,
                "{CoverLifterRecommendation}": form.coverLifterRecommendation,
                "{HeadrestConditon}": form.headrestConditon,
                "{HeadrestRecommendation}": form.headrestRecommendation,
                "{VisitEngineerSigned}": form.visitEngineerSigned || "false",
                "{QualifyForLastingCarePlan}": form.qualifyForLastingCarePlan || "false",
            };
        default:
            return {};
    }
};

const mapEmailBody = async ({ body, id }: MappingProps): Promise<string> => {
    let currentForm: any = {};
    if (id) {
        currentForm = await formService.getById(id);
    }

    if (!body) {
        return "";
    }
    
    
    const replacements: Record<string, string | boolean> = {
        ...getDefaultReplacements(currentForm),
        ...getFormSpecificReplacements(currentForm),
    };


    return body.replaceAll(/{SigningURL}|{TimeCompleted}|{TaskName}|{TaskURL}|{CustomerName}|{CustomerContactName}|{CustomerContactTitle}|{CustomerAddress}|{CustomerHomeTel}|{CustomerMobileTel}|{CustomerWorkTel}|{CustomerOtherTel}|{CustomerEmail}|{ProductMake}|{ProductModel}|{ProductColour}|{ProductCabinet}|{ProductSerialNumber}|{ProductDeliveryDate}|{ProductFilterCode}|{ProductFreshWater}|{ProductBromine}|{ProductWarranty}|{ProductComments}|{ServicePlanHPWOCare}|{ServicePlanHPWSilk}|{ServicePlanNoPumpOSB}|{ServicePlanLC}|{ServicePlanLCLite}|{ServicePlanLPC}|{ServicePlanGold}|{ServicePlanUltimate}|{ServicePlanEPWellness}|{ServicePlanEPWellnessPlus}|{VisitType}|{VisitDetails}|{VisitScheduledBy}|{VisitListOfProductsAndOrAccessoriesSold}|{VisitActionTakenByEngineerAndNotes}|{FollowUpVisitRequired}|{VisitDateBooked}|{VisitPaymentMethod}|{VisitEngineerName}|{VisitDate}|{CustomerVisitComment}|{AccessNotes}|{OrderNumber}|{SalesPersonName}|{Balance}|{PaymentMethod}|{DateBalancePaid}|{FinanceAgreementName}|{ProductCondition}|{ExtrasList}|{EngineerNameList}|{EngineerDate}|{AccessClear}|{AccessComments}|{DeclarationAgreed}|{GDPRConsented}|{ReceivesNewsletter}|{OrderDate}|{OrderLocation}|{COFSent}|{COFLink}|{Subtotal}|{OrderTotal}|{DepositAmount}|{DepositMethods}|{FundingChoice}|{PartExchangeValueToBeDeducted}|{SalesPersonEmail}|{CustomerReferredBy}|{ProductCollection}|{ProductShellColour}|{ModelToBePartExchanged}|{CoverOptions}|{AccessoryList}|{DeliveryMethod}|{ElectricalSupply}|{PreferredDeliveryDate}|{SiteVisitFormSent}|{Sanitiser}|{PHLevel}|{WaterAppearance}|{ChemicalRecommendation}|{ServiceChecks}|{FilterConditon}|{FilterRecommendation}|{CoverConditon}|{CoverRecommendation}|{CoverLifterConditon}|{CoverLifterRecommendation}|{HeadrestConditon}|{HeadrestRecommendation}|{QualifyForLastingCarePlan}|{ElectricianRequired}/g, (match: any) => replacements[match] || match);
};
export default mapEmailBody;
