import { useEffect, useState } from 'react';

import { organisationService } from '../../services';

import { useAtom } from '@dbeining/react-atom';
import { Button, Container, ListGroup, ToggleButton } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { userAtom } from '../../atoms/userAtom';
import { AddNewOrganisation, Organisation } from '../../components';
import { IOrganisation } from '../../customTypings/Organisation';
import { IRole } from '../../customTypings/role';
import { Role } from '../../helpers';

const OrganisationsPage = () => {
  const currentUser = useAtom(userAtom)
  const [organisations, setOrganisations] = useState<IOrganisation[] | null>(null);
  const [showCreateOrganisation, setShowCreateOrganisation] = useState(false);
  const [userInteraction, setUserInteraction] = useState(true)
  const [checkedDeleted, setCheckedDeleted] = useState<boolean>(false);

  useEffect(() => {
    //if(userInteraction)
    //{
      organisationService.getAll(null,null,checkedDeleted).then(organisations => setOrganisations(organisations))
    //}
    setUserInteraction(false)
  }, [userInteraction, checkedDeleted]);

  return (
      <Container fluid>
          <Helmet>
            <title>Organisations</title>
          </Helmet>
          <h1>Organisations</h1>
          <div>
              All organisations from api end point:
              <ToggleButton
                className="mb-2"
                id="toggle-check"
                type="checkbox"
                variant="outline-primary"
                checked={checkedDeleted}
                value="1"
                onChange={(e) => setCheckedDeleted(e.currentTarget.checked)}
              >
                Deleted
              </ToggleButton>
  
              {organisations &&
                <ListGroup>
                  {organisations.map(organisation =>
                    <ListGroup.Item className="d-flex justify-content-between align-items-start" key={organisation.id}>
                      <Organisation organisation={organisation} setUserInteraction={setUserInteraction}/>
                    </ListGroup.Item>
                  )}
                </ListGroup>
              }
          
              {currentUser && currentUser.identity.roles.some((role: IRole) => role.name === Role.SystemAdmin) &&
              <>
                <Button 
                  className="mt-2"
                  variant="success"
                  disabled={showCreateOrganisation}
                  onClick={() => setShowCreateOrganisation(true)}>
                    Add Organisation
                </Button>
                <AddNewOrganisation show={showCreateOrganisation} setShow={setShowCreateOrganisation} />
              </>}
          </div>
      </Container>
  );
}

export { OrganisationsPage };
