import { ITwoFACodeResult } from '@/customTypings/TwoFACodeResult';
import { useAtom } from "@dbeining/react-atom";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { Alert, Button, Col, Container, Form, Image, Row, Spinner, Stack } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import { useNavigate } from "react-router-dom";
import VerificationInput from "react-verification-input";
import { updateUserStateAtom, userAtom } from "../../atoms/userAtom";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { IUser } from "../../customTypings/user";
import { clone } from "../../helpers/utils";
import { authenticationService } from "../../services";

const TwoFASetupPage = () => {
    let navigate = useNavigate();
    const [qRCode, setQRCode] = useState<ITwoFACodeResult | undefined>()
    const [twoFactorQrCode, setTwoFactorQrCode] = useState('')
    const [twoFactorSetupComplete, setTwoFactorSetupComplete] = useState(false)
    const currentUserAtom = useAtom(userAtom)
    const [loading, setLoading] = useState(true)
    const [statusMessage, setStatusMessage] = useState("")

    useEffect(() => {
        generateTwoFAQRCode()
        if(currentUserAtom === undefined ||
            currentUserAtom.advisories === undefined ||
            (currentUserAtom.advisories !== undefined && currentUserAtom.advisories?.twoFaSetupRequired === undefined))
        {
            navigate("/", { state: { message: "Ok" } });
        }
        setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function generateTwoFAQRCode() {
        setStatusMessage("");
        authenticationService.generateTwoFAQRCode().then((response: ITwoFACodeResult) => {
            setQRCode(response)
        },
        error => {
            if(error.status === 400){
               setStatusMessage('Account enable failed.');
            } else {
               setStatusMessage(error);
            }
            console.log("2FA", error)
        });
    }

    if (loading) {
        return (
            <Spinner animation={'border'} />
        )
    }

    return (
        <Container fluid>
            <Helmet>
                <title>Two-Factor Authentication Setup</title>
            </Helmet>
            <Row>
                <Col lg={{ span: 6, offset: 3 }} md={{ span: 8, offset: 2 }} sm={{ span: 10, offset: 1 }}>
                    <h2>Two-Factor Authentication Setup</h2>
                    {!twoFactorSetupComplete && <p>Please use an authenticator app (such as Google Authenticator or Microsoft Authenticator) to scan this QR code.</p>}

                    {statusMessage &&
                        <Alert variant="danger">{statusMessage}</Alert>
                    }
                </Col>
            </Row>
            {!twoFactorSetupComplete && <Row>
                <Col lg={{ span: 2, offset: 3 }} md={{ span: 3, offset: 2 }} sm={{ span: 4, offset: 1 }}>
                    {qRCode !== undefined && <Image fluid src={`data:${qRCode.qrCodeContentType};base64,${qRCode.qrCodeContent}`} alt={qRCode.secretKey} height={qRCode.qrCodeHeight} width={qRCode.qrCodeWidth} />}
                </Col>
                <Col lg={{ span: 4 }} md={{ span: 5 }} sm={{ span: 6 }}>
                    {qRCode !== undefined && 
                        <>
                            <p>Or enter this code manualy into your authenticator app of choice:</p> 
                            <pre style={{fontSize: "1.5em"}}>{qRCode.secretKey}</pre>
                        </>
                    }
                </Col>
            </Row>}
            {!twoFactorSetupComplete && <Row>
                <Col lg={{ span: 6, offset: 3 }} md={{ span: 8, offset: 2 }} sm={{ span: 10, offset: 1 }}>
                    <Button 
                        className="mt-2 me-2"
                        variant="danger"
                        onClick={() => generateTwoFAQRCode()}
                        >
                          <FontAwesomeIcon icon={faArrowsRotate}/> Generate new Key/QR Code
                    </Button>
                </Col>
            </Row>}
            <Row>
                <Col lg={{ span: 6, offset: 3 }} md={{ span: 8, offset: 2 }} sm={{ span: 10, offset: 1 }}>
                    {!twoFactorSetupComplete ? qRCode !== undefined && 
                        <Formik
                            initialValues={{
                            
                            }}
                            onSubmit={async (_, { setStatus, setSubmitting }) => {
                                setStatus();
                                await authenticationService.updateTwoFASecretKey(qRCode.secretKey, twoFactorQrCode).then(() => {
                                    setTwoFactorSetupComplete(true)
                                    const copy = clone<IUser>(currentUserAtom as IUser);
                                    copy.advisories = undefined;
                                    updateUserStateAtom(copy)
                                    setSubmitting(false)
                                },
                                error => {
                                    setSubmitting(false);
                                    if(error.status === 400){
                                        setStatus(error.message);
                                    } else {
                                        setStatus(error);
                                    }
                                });
                            }}
                        >
                            {({ errors, status, touched, isSubmitting, handleSubmit }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Stack gap={3}>
                                        <Form.Group className="mb-3">
                                            <Form.Label htmlFor="validationCode">Validation Code</Form.Label>
                                            <VerificationInput value={twoFactorQrCode} length={6} validChars={'0-9'} placeholder={''} onChange={value => setTwoFactorQrCode(value)} />
                                        </Form.Group>
                                        <div className="ms-auto">
                                            <Button
                                              variant="success"
                                              disabled={isSubmitting}
                                              type="submit"
                                            >
                                                {isSubmitting 
                                                    ?  <LoadingSpinner text="Verifying..."/>
                                                    : "Verify"}
                                            </Button>
                                        </div>
                                        {status &&
                                            <div className={'alert alert-danger'}>{status}</div>
                                        }
                                    </Stack>
                                </Form>
                            )}
                        </Formik>
                        :
                        <Stack gap={3}>
                            <p>Two-Factor setup complete</p>
                            <Button
                              variant="secondary"
                              onClick={() => navigate('/', { state: { message: "Ok" } })}
                            >
                                Go Home
                            </Button>
                        </Stack>
                    }
                </Col>
            </Row>
        </Container>
    )
}

export { TwoFASetupPage };
