import { faPen, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { ColumnRender, FilterType, IColumn, IFilter, IPagedListAction } from "../../components/PagedList/Declarations";
import { PagedList } from "../../components/PagedList/PagedList";
import { IProduct } from "../../customTypings/Product";
import { productService } from "../../services";
import { ProductAddModel } from "../../components/Models/ProductAddModel";
import { ProductEditModel } from "../../components/Models/ProductEditModel";

const ProductListPage = () => {

    const [showEditProduct, setShowEditProduct] = useState(false);
    const [showAddProduct, setShowAddProduct] = useState(false);
    const [refreshNumber, setRefeshNumber] = useState(0);
    const [product, setProduct] = useState<IProduct>();

    const getProduct = (id: string) => {
        productService.get(id).then((res) => {
            setProduct(res);
        });
    }

    const handleAddProduct = (added: boolean) => {
        if (added) {
            setRefeshNumber((refreshNumber) => refreshNumber + 1);
        }
        setShowAddProduct(false);
    };
    const handleOpenAddProduct = () => {
        setShowAddProduct(true);
    };

    const handleEdit = (updated: boolean) => {
        if (updated) {
            setRefeshNumber(refreshNumber + 1);
        }
        setShowEditProduct(false);
    };

    return (
        <>
            <Container fluid>
                <Row>
                    <Col>
                        <Helmet>
                            <title>Products</title>
                        </Helmet>

                        <Breadcrumb>
                            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/settings" }}>
                                Settings
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>Products</Breadcrumb.Item>
                        </Breadcrumb>

                        <h1 className="my-3">Products</h1>
                        <PagedList
                            columns={[
                                {
                                    member: "brand",
                                    renderAs: ColumnRender.text,
                                    title: "Brand",
                                    width: 200,
                                } as IColumn,
                                {
                                    member: "model",
                                    renderAs: ColumnRender.text,
                                    title: "Model",
                                    width: 200,
                                } as IColumn,
                                {
                                    member: "color",
                                    renderAs: ColumnRender.text,
                                    title: "Colour",
                                    width: 200,
                                } as IColumn,
                                {
                                    member: "productCondition",
                                    renderAs: ColumnRender.text,
                                    title: "Condition",
                                    width: 200,
                                } as IColumn,
                                {
                                    member: "price",
                                    renderAs: ColumnRender.text,
                                    title: "Base Price",
                                    width: 200,
                                } as IColumn,
                            ]}
                            itemActions={[
                                {
                                    icon: faPen,
                                    text: "Edit",
                                    onClick: (item) => {
                                        getProduct(item.id);
                                        setShowEditProduct(true);
                                    },
                                } as IPagedListAction,
                            ]}
                            headActions={[
                                {
                                    icon: faPlus,
                                    text: "Add Product",
                                    onClick: handleOpenAddProduct,
                                } as IPagedListAction,
                            ]}
                            filters={[
                                {
                                    title: "Brand",
                                    member: "brand",
                                    type: FilterType.text,
                                } as IFilter,
                                {
                                    title: "Model",
                                    member: "model",
                                    type: FilterType.text,
                                } as IFilter,
                                {
                                    title: "Colour",
                                    member: "color",
                                    type: FilterType.text,
                                } as IFilter,
                                {
                                    title: "Condition",
                                    member: "productCondition",
                                    type: FilterType.text,
                                } as IFilter,
                                {
                                    title: "Base Price",
                                    member: "price",
                                    type: FilterType.text,
                                } as IFilter,
                            ]}
                            defaultSortBy="price"
                            getUrl="api/product/all"
                            avatarUrl="api/avatar/get"
                            refreshNumber={refreshNumber + 1}
                            rowFormatter={(item: any) => {
                                return null;
                            }}
                            persistanceKey="ProductListPage"
                        />
                    </Col>
                </Row>
            </Container>
            <ProductAddModel show={showAddProduct} onClose={handleAddProduct} />
            <ProductEditModel show={showEditProduct} onClose={handleEdit} currentProduct={product} />
        </>
    );
};

export { ProductListPage };
