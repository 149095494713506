import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

export enum ColumnRender {
    text,
    date,
    time,
    dateTime,
    integer,
    decimal,
    identity,
    checkCross,
    yesNo,
    deleted,
    color,
    customRenderer,
    synthesize
}


export enum FilterType {
    text,
    date,
    integer,
    select,
    boolean,
    checkCross,
    multiselect
}

export enum SortOrder {
    ascending,
    descending
}

export interface IColumn {
    title: string;
    member: string;
    canSort?: boolean;
    renderAs: ColumnRender;
    prefix?: string;
    suffix?: string;
    defaultVisible?: boolean;
    width: number;
    customRenderer: (item: any) => React.ReactElement
    default?: string;
}

export type RowFormatterFunction = (item:any) => string | null;

export function ColumnRenderAlignment(renderAs: ColumnRender) {
    switch(renderAs) {
        case ColumnRender.integer:
        case ColumnRender.decimal:
        case ColumnRender.date:
        case ColumnRender.time:
        case ColumnRender.dateTime:
            return "text-end";
        case ColumnRender.yesNo:
        case ColumnRender.checkCross:
        case ColumnRender.deleted:
            return "text-center";
        case ColumnRender.text:
        default:
            return "text-start";
    }
}

export interface IPagedListAction {
    icon?: IconDefinition;
    text: string;
    primary?: boolean;
    startGroup?: boolean;
    onClick: (item: any) => void;
    availableMember?: string;
    availableFliped?: boolean;
    secondaryBtn?: boolean;
}

export interface IFilterValue {
    filter: IFilter;
    value: any;  
}

export interface IRequestValues {
    filter: Record<string, any>;
    offset: number,
    count: number
    sortBy: string,
    sortOrder: SortOrder
}

export interface IPagedViewResponse {
    offset: number;
    count: number;
    total: number;
    results: [];
}

export interface IFilter {
    title: string;
    member: string;
    type: FilterType;
    getUrl?: string;
    options?: any;
    defaultValue: any;
}

export interface IIdentity {
    id: string;
    email: string;
    displayName: string;
    isEnabled: boolean;
    isLocked: boolean;
    avatarRevision: boolean;
}
