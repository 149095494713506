import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { Container, Stack } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { updateAccessDeniedStateAtom } from '../../atoms/accessDeniedAtom';
import { updateUserStateAtomToLocalStorage } from '../../atoms/userAtom';

export default function AccessDeniedPage() {

    useEffect(() => {
        updateUserStateAtomToLocalStorage()
        updateAccessDeniedStateAtom(false)
    }, []);
    
  return (
    <Container fluid className="d-flex align-content-center justify-content-center flex-wrap vh-100">
        <Helmet>
            <title>Access Denied</title>
        </Helmet>

        <Stack gap={3}>
            <FontAwesomeIcon icon={faLock} size="10x"/>
            <div className="d-flex justify-content-center">Access Denied</div>
        </Stack>
    </Container>
  )
}
