import React, { useState } from "react";

import { useAtom } from "@dbeining/react-atom";
import {
    faBan,
    faBuilding,
    faPenToSquare,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Alert,
    Button,
    Col,
    Container,
    Image,
    ListGroup,
    Row,
    Stack
} from "react-bootstrap";
import { userAtom } from "../atoms/userAtom";
import { IOrganisation } from "../customTypings/Organisation";
import { IRole } from "../customTypings/role";
import { Role } from "../helpers";
import { organisationService } from "../services";
import { OrganisationDisplayNameEdit } from "./Models/OrganisationDisplayNameEdit";

type Props = {
    organisation: IOrganisation;
    setUserInteraction?: React.Dispatch<React.SetStateAction<any>>;
};

const Organisation: React.FC<Props> = ({
    organisation,
    setUserInteraction,
}) => {
    const currentUser = useAtom(userAtom);
    const [alertVariant, setAlertVariant] = useState("danger");
    const [statusMessage, setStatusMessage] = useState("");
    const [updating, setUpdating] = useState(false);
    const [showEditDisplayName, setShowEditDisplayName] = useState(false);

    function disableWithId(id: string) {
        setUpdating(true);
        organisationService.disableWithId(id).then(
            (response) => {
                if (response.status !== "Failure") {
                    setAlertVariant("success");
                    setStatusMessage("Organisation disabled.");
                    if (setUserInteraction !== undefined) {
                        setUserInteraction(true);
                    }
                    setUpdating(false);
                } else {
                    setAlertVariant("danger");
                    setStatusMessage(response.message);
                }
            },
            (error) => {
                setAlertVariant("danger");
                if (error.status === 400) {
                    setStatusMessage("Organisation disable failed.");
                } else {
                    setStatusMessage(error);
                }
                setUpdating(false);
            }
        );
    }

    function enableWithId(id: string) {
        setUpdating(true);
        organisationService.enableWithId(id).then(
            (response) => {
                if (response.status !== "Failure") {
                    setAlertVariant("success");
                    setStatusMessage("Organisation enabled.");
                    if (setUserInteraction !== undefined) {
                        setUserInteraction(true);
                    }
                    setUpdating(false);
                } else {
                    setAlertVariant("danger");
                    setStatusMessage(response.message);
                }
            },
            (error) => {
                setAlertVariant("danger");
                if (error.status === 400) {
                    setStatusMessage("Organisation enable failed.");
                } else {
                    setStatusMessage(error);
                }
                setUpdating(false);
            }
        );
    }

    function deleteWithId(id: string) {
        setUpdating(true);
        organisationService.deleteWithId(id).then(
            (response) => {
                if (response.status !== "Failure") {
                    setAlertVariant("success");
                    setStatusMessage("Organisation deleted.");
                    if (setUserInteraction !== undefined) {
                        setUserInteraction(true);
                    }
                    setUpdating(false);
                } else {
                    setAlertVariant("danger");
                    setStatusMessage(response.message);
                }
            },
            (error) => {
                setAlertVariant("danger");
                if (error.status === 400) {
                    setStatusMessage("Organisation delete failed.");
                } else {
                    setStatusMessage(error);
                }
                setUpdating(false);
            }
        );
    }
    return (
        <Container fluid>
            <Row>
                <Col className="justify-content-md-center" md="auto">
                    <Image
                        roundedCircle
                        src={`data:${organisation.logoContentType};base64,${organisation.logoContent}`}
                    />
                </Col>
                <Col>
                    <Stack gap={3}>
                        <ListGroup variant="flush">
                            <ListGroup.Item
                                className="d-flex justify-content-between align-items-start"
                                action
                                onClick={() => setShowEditDisplayName(true)}
                            >
                                <div>
                                    <div>
                                        <FontAwesomeIcon icon={faBuilding} />{" "}
                                        Display Name:
                                    </div>
                                    <div>{organisation.displayName}</div>
                                </div>
                                <FontAwesomeIcon icon={faPenToSquare} />
                            </ListGroup.Item>
                            <OrganisationDisplayNameEdit
                                show={showEditDisplayName}
                                setShow={setShowEditDisplayName}
                                organisationId={organisation.id}
                                displayName={organisation.displayName}
                                setUserInteraction={setUserInteraction}
                            />
                        </ListGroup>
                        {currentUser &&
                            currentUser.identity.roles.some(
                                (role: IRole) => role.name === Role.SystemAdmin
                            ) &&
                            (organisation.isEnabled ? (
                                <Button
                                    className="mt-2 me-2"
                                    variant="danger"
                                    onClick={() =>
                                        disableWithId(organisation.id)
                                    }
                                    disabled={updating}
                                >
                                    Disable <FontAwesomeIcon icon={faBan} />
                                </Button>
                            ) : (
                                <Button
                                    className="mt-2 me-2"
                                    variant="success"
                                    onClick={() =>
                                        enableWithId(organisation.id)
                                    }
                                    disabled={updating}
                                >
                                    Enable
                                </Button>
                            ))}
                        {currentUser &&
                            currentUser.identity.roles.some(
                                (role: IRole) => role.name === Role.SystemAdmin
                            ) && (
                                <Button
                                    className="mt-2 me-2"
                                    variant="danger"
                                    onClick={() =>
                                        deleteWithId(organisation.id)
                                    }
                                    disabled={
                                        updating || organisation.isDeleted
                                    }
                                >
                                    Delete <FontAwesomeIcon icon={faTrash} />
                                </Button>
                            )}
                        {statusMessage && (
                            <Alert
                                variant={alertVariant}
                                onClose={() => setStatusMessage("")}
                                dismissible
                            >
                                {statusMessage}
                            </Alert>
                        )}
                    </Stack>
                </Col>
            </Row>
        </Container>
    );
};

export { Organisation };
