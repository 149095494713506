import { useAtom } from "@dbeining/react-atom";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import { Helmet } from 'react-helmet';
import { userAtom } from "../../atoms/userAtom";
import { PendingCallbackListPage } from "../PendingCallback/PendingCallbackListPage";

const MyPendingCallbacksPage = () => {
    const currentUser = useAtom(userAtom)
    return (
        <Container fluid>
            <Helmet>
                <title>My Pending Callbacks</title>
            </Helmet>
            <Row>
                <Col>
                    <h1 className="my-3">My Pending Callbacks</h1>
                    <PendingCallbackListPage identityId={(currentUser ? currentUser.identity.id : undefined)}/>
                </Col>
            </Row>
        </Container>
    )
}

export { MyPendingCallbacksPage };
