import { ICompany } from "@/customTypings/Company";
import { ErrorMessage, Field, Formik } from "formik";
import { useEffect, useState } from "react";
import {
    Alert, Breadcrumb, Button, Col, Container, Form, Row
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import * as Yup from "yup";
import { companyService } from "../services";
import { LoadingSpinner } from "./LoadingSpinner";

function CompanyInfo() {
  const [alertVariant, setAlertVariant] = useState("danger");
  const [currentCompany, setCurrentCompany] = useState<ICompany>({
    id: "",
    phone: "",
    contactEmail: "",
    line1: "",
    line2: "",
    line3: "",
    town: "",
    country: "",
    postcode: "",
    website: "",
    invitationEmail: "",
  });

  useEffect(() => {
    companyService.getCompany().then((res) => {
      if (res) {
        setCurrentCompany(res);
      }
    });
  }, []);

  const save = (
    values: any,
    setStatus: any,
    setSubmitting: any,
    setFieldError: any
  ) => {
      const updatedCompany: ICompany = {
      id: currentCompany.id ? currentCompany.id : undefined,
      phone: values.phone,
      contactEmail: values.contactEmail,
      line1: values.line1,
      line2: values.line2,
      line3: values.line3,
      town: values.town,
      country: values.country,
      postcode: values.postcode,
      website: values.website,
      invitationEmail: values.invitationEmail,
    };
    const serviceMethod = currentCompany.id
      ? companyService.update
      : companyService.create;
    const updateMessage = currentCompany.id
      ? "Company Info has been updated successfully"
      : "Company Info has been created successfully";

    serviceMethod(updatedCompany).then(
      (response) => {
        setSubmitting(false);
        if (response.status === "Success") {
          setAlertVariant("success");
          setStatus(updateMessage);
        } else {
          setAlertVariant("danger");
          setStatus(response.message);
        }
      },
      (error) => {
        setAlertVariant("danger");
        setStatus(error.title);
        setSubmitting(false);
      }
    );
  };

    const fieldDisplayNames: any = {
        "ContactEmail": "Contact Email",
        "Line1": "Address Line 1",
        "Line2": "Address Line 2",
        "Line3": "Address Line 3",
        "InvitationEmail": "Invitation Email"
    };

  const CompanySchema = Yup.object().shape({
    phone: Yup.string()
      .required("Required")
      .matches(
        /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
        "Invalid phone number"
      ),

    contactEmail: Yup.string()
      .required("Required")
      .email("Invalid email address"),

    line1: Yup.string().required("Required"),
    town: Yup.string().required("Required"),
    country: Yup.string().required("Required"),

    postcode: Yup.string()
      .required("Required"),

    website: Yup.string()
      .required("Required")
      .matches(
        /^(http[s]?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}(\/[a-zA-Z0-9+&@#/%?=~_|!:,.;-]*)?$/,
        "Invalid Website"
      ),

    invitationEmail: Yup.string()
      .required("Required")
      .email("Invalid email address"),
  });

  return (
    <Container fluid>
      <Helmet>
        <title>Company info</title>
      </Helmet>
      <Breadcrumb>
        <Breadcrumb.Item linkProps={{ to: "/settings" }}>
          Settings
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Company info</Breadcrumb.Item>
      </Breadcrumb>
      <h1 className="my-3">Company Info</h1>
      <Formik
        initialValues={currentCompany}
        onSubmit={(values, { setStatus, setSubmitting, setFieldError }) => {
          save(values, setStatus, setSubmitting, setFieldError);
        }}
        validationSchema={CompanySchema}
        enableReinitialize={true}
      >
        {({
          errors,
          status,
          touched,
          isSubmitting,
          handleSubmit,
          values,
          handleChange,
        }) => (
          <Form onSubmit={handleSubmit}>
            {Object.keys(currentCompany)
              .filter((field) => field !== "id")
              .map((field) => {
                return (
                  <Form.Group
                    as={Row}
                    key={field}
                    className="mb-3"
                    controlId={field}
                  >
                    <Form.Label column sm={3}>
                            {fieldDisplayNames[(field.charAt(0).toUpperCase() + field.slice(1))] || (field.charAt(0).toUpperCase() + field.slice(1))}
                    </Form.Label>
                    <Col sm={9}>
                      <Field
                        name={field}
                        onChange={handleChange}
                        type="text"
                        value={values[field as keyof ICompany]}
                        className={`form-control ${
                          errors[field as keyof ICompany] &&
                          touched[field as keyof ICompany]
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                      <ErrorMessage
                        name={field}
                        component="div"
                        className="invalid-feedback"
                      />
                    </Col>
                  </Form.Group>
                );
              })}

            <Button
              variant="primary"
              disabled={isSubmitting}
              type="submit"
              className="me-2 d-flex align-items-end ms-auto"
            >
              {isSubmitting ? (
                <LoadingSpinner
                  text={currentCompany.id ? "Updating... " : "Saving ..."}
                />
              ) : (
                "Save"
              )}
            </Button>

            {status && (
              <Alert variant={alertVariant} className="mt-3">
                {status}
              </Alert>
            )}
          </Form>
        )}
      </Formik>
    </Container>
  );
}

export default CompanyInfo;
