import CustomerSignature from "../../components/Forms/CustomerSignature";
import { ErrorMessage, Field, Formik } from "formik";
import { Alert, Breadcrumb, Col, Container, Form, FormCheck, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

type props = {
    currentForm: any
}

const SiteVisitFormWithData = ({currentForm}:props) => {
    return (
        <>
            <Container fluid>
                <Row>
                    <Col>
                        <Helmet>
                            <title>Site Visit Signing Form Data</title>
                        </Helmet>

                        <Breadcrumb>
                            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/formListPage" }}>
                                Forms
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>Site Visit Signing Form Data</Breadcrumb.Item>
                        </Breadcrumb>

                        <h1 className="my-3">Site Visit Signing Form Data</h1>
                        <Formik
                            initialValues={currentForm}
                            enableReinitialize
                            onSubmit={() => {}}
                        >
                            {({
                                errors,
                                status,
                                touched,
                                isSubmitting,
                                handleSubmit,
                                values,
                                setStatus,
                                handleChange,
                                setFieldValue,
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group as={Row} className="mb-3" controlId="customerName">
                                        <Form.Label column sm={3}>
                                            Name of the customer
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="customerName" type="text" className={"form-control" + (errors.customerAddress && touched.customerAddress ? " is-invalid" : "")} />
                                            <ErrorMessage name="customerName" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="taskId">
                                        <Form.Label column sm={3}>
                                            Associated Task
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="taskId" type="text" className={"form-control" + (errors.taskId && touched.taskId ? " is-invalid" : "")} />
                                            <ErrorMessage name="taskId" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="customerContactName">
                                        <Form.Label column sm={3}>
                                            Name of the main customer contact
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="customerContactName" type="text" className={"form-control" + (errors.customerContactName && touched.customerContactName ? " is-invalid" : "")} />
                                            <ErrorMessage name="customerContactName" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>


                                    <Form.Group as={Row} className="mb-3" controlId="customerAddress">
                                        <Form.Label column sm={3}>
                                            Inline address summary
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="customerAddress" type="text" className={"form-control" + (errors.customerAddress && touched.customerAddress ? " is-invalid" : "")} />
                                            <ErrorMessage name="customerAddress" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="customerHomeTel">
                                        <Form.Label column sm={3}>
                                            Home telephone
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="customerHomeTel" type="text" className={"form-control" + (errors.customerAddress && touched.customerAddress ? " is-invalid" : "")} />
                                            <ErrorMessage name="customerHomeTel" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="customerMobileTel">
                                        <Form.Label column sm={3}>
                                            Mobile telephone
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="customerMobileTel" type="text" className={"form-control" + (errors.customerAddress && touched.customerAddress ? " is-invalid" : "")} />
                                            <ErrorMessage name="customerMobileTel" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="customerWorkTel">
                                        <Form.Label column sm={3}>
                                            Work telephone
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="customerWorkTel" type="text" className={"form-control" + (errors.customerAddress && touched.customerAddress ? " is-invalid" : "")} />
                                            <ErrorMessage name="customerWorkTel" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="customerOtherTel">
                                        <Form.Label column sm={3}>
                                            Other telephone
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="customerOtherTel" type="text" className={"form-control" + (errors.customerAddress && touched.customerAddress ? " is-invalid" : "")} />
                                            <ErrorMessage name="customerOtherTel" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="customerEmail">
                                        <Form.Label column sm={3}>
                                            Email Address
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="customerEmail" type="text" className={"form-control" + (errors.customerAddress && touched.customerAddress ? " is-invalid" : "")} />
                                            <ErrorMessage name="customerEmail" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="productMake">
                                        <Form.Label column sm={3}>
                                            Customer Product
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="productMake" type="text" className={"form-control" + (errors.productMake && touched.productMake ? " is-invalid" : "")}
                                                value={`${currentForm.productMake || ""} ${currentForm.productModel || ""} ${currentForm.productColour || ""}`}
                                            />
                                            <ErrorMessage name="productMake" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="productComments">
                                        <Form.Label column sm={3}>
                                            Comments about the product
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="productComments" type="text" className={"form-control" + (errors.productComments && touched.productComments ? " is-invalid" : "")} />
                                            <ErrorMessage name="productComments" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>

                                    <div className="row">
                                        {['HPWOCare', 'HPWSilk', 'NoPumpOSB', 'LC', 'LCLite', 'LPC', 'Gold', 'Ultimate', 'EPWellness', 'EPWellnessPlus'].map((index) => (
                                            <Form.Group className="col-4 d-flex justify-content-start align-items-center gap-2" controlId={`servicePlan${index}`} key={`servicePlan${index}`}>
                                                <Form.Check
                                                    type="switch"
                                                    id="custom-switch"
                                                    label=""
                                                    checked={values[`servicePlan${index}`]}
                                                    onChange={handleChange}
                                                    name={`servicePlan${index}`}
                                                    style={{ pointerEvents: 'none' }}
                                                />
                                                <Form.Label>
                                                    ServicePlan{index}
                                                </Form.Label>
                                            </Form.Group>
                                        ))}
                                    </div>
                                    <Form.Group as={Row} className="mb-3" controlId="visitType">
                                        <Form.Label column sm={3}>
                                            Visit type
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="visitType" type="text" className={"form-control" + (errors.visitType && touched.visitType ? " is-invalid" : "")} />
                                            <ErrorMessage name="visitType" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="visitDetails">
                                        <Form.Label column sm={3}>
                                            Visit details
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="visitDetails" type="text" className={"form-control" + (errors.visitDetails && touched.visitDetails ? " is-invalid" : "")} />
                                            <ErrorMessage name="visitDetails" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="visitScheduledBy">
                                        <Form.Label column sm={3}>
                                            Staff who scheduled the visit
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="visitScheduledBy" type="text" className={"form-control" + (errors.visitScheduledBy && touched.visitScheduledBy ? " is-invalid" : "")} />
                                            <ErrorMessage name="visitScheduledBy" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    {/*Need to add somwthing here*/}
                                    <Form.Group as={Row} className="mb-3" controlId="visitActionTakenByEngineerAndNotes">
                                        <Form.Label column sm={3}>
                                            Action taken by engineer and notes
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="visitActionTakenByEngineerAndNotes" type="text" className={"form-control" + (errors.visitActionTakenByEngineerAndNotes && touched.visitActionTakenByEngineerAndNotes ? " is-invalid" : "")} />
                                            <ErrorMessage name="visitActionTakenByEngineerAndNotes" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="followUpVisitRequired">
                                        <Form.Label column sm={3}>
                                            Follow up visit required
                                        </Form.Label>
                                        <Col sm={9}>
                                            <FormCheck
                                                type="switch"
                                                id="custom-switch"
                                                label=""
                                                checked={values.followUpVisitRequired}
                                                onChange={handleChange}
                                                name="followUpVisitRequired"
                                                style={{ pointerEvents: 'none' }}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="visitDateBooked">
                                        <Form.Label column sm={3}>
                                            Date booked
                                        </Form.Label>
                                        <Col sm={4}>
                                            <Field readOnly name="visitDateBooked" type="datetime-local" className={"form-control" + (errors.visitDateBooked && touched.visitDateBooked ? " is-invalid" : "")} />
                                            <ErrorMessage name="visitDateBooked" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="visitPaymentMethod">
                                        <Form.Label column sm={3}>
                                            Payment method
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="visitPaymentMethod" type="text" className={"form-control" + (errors.visitPaymentMethod && touched.visitPaymentMethod ? " is-invalid" : "")} />
                                            <ErrorMessage name="visitPaymentMethod" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="visitEngineerName">
                                        <Form.Label column sm={3}>
                                            Engineer name
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field readOnly name="visitEngineerName" type="text" className={"form-control" + (errors.visitEngineerName && touched.visitEngineerName ? " is-invalid" : "")} />
                                            <ErrorMessage name="visitEngineerName" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="visitDate">
                                        <Form.Label column sm={3}>
                                            Date of visit
                                        </Form.Label>
                                        <Col sm={4}>
                                            <Field readOnly name="visitDate" type="text" className={"form-control" + (errors.visitDate && touched.visitDate ? " is-invalid" : "")} />
                                            <ErrorMessage name="visitDate" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    
                                    {status && (
                                        <Alert variant="danger" className="mt-3">
                                            {status}
                                        </Alert>
                                    )}
                                </Form>
                            )}
                        </Formik>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default SiteVisitFormWithData;