import React from 'react';
import { Col, Row } from 'react-bootstrap';
interface ReusableInfoRowProps {
    label: string;
    value: any;
}

const HHTRow: React.FC<ReusableInfoRowProps> = ({ label, value }) => (
    <Row className="mb-3">
        <Col md={3}>{label}</Col>
        <Col>
            <div>{value}</div>
        </Col>
    </Row>
);

export default HHTRow;
