import { IContactFull } from "@/customTypings/ContactFull";
import { faStar as farStar } from "@fortawesome/free-regular-svg-icons";
import { faPenToSquare, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Alert, Card, Col, Row, Stack } from "react-bootstrap";
import { EditGivenCustomerContactModel } from "../../components/Models/EditGivenCustomerContactModel";
import { customerService } from "../../services";

type Props = {
    customerId: string;
    contact: IContactFull;
    isBusiness?: boolean;
    onUpdate: () => void;
};

const CustomerContactDetails: React.FC<Props> = ({ customerId, contact, isBusiness = false, onUpdate }) => {
    const [alertText, setAlertText] = useState<string | undefined>();
    const [showEditCustomerContact, setShowEditCustomerContact] = useState(false);

    const handleSetPrimary = (id: string, customerContactId: string) => {
        setAlertText(undefined);
        customerService.setPrimaryContact(id, customerContactId).then(
            (response) => {
                if (response.status !== "Failure") {
                    onUpdate();
                } else {
                    setAlertText(response.message);
                }
            },
            (error) => {
                if (error.status === 400) {
                    setAlertText(error.title);
                } else {
                    setAlertText(error);
                }
            }
        );
    };

    return (
        <>
            <Card className="bg-light shadow" style={{ height: '300px', width: '400px' }}>
                <Card.Body>
                    <Row>
                        {isBusiness ? (
                            <>
                                <Col md={3}>Name</Col>
                                <Col>
                                    <div>{contact.name}</div>
                                </Col>
                            </>
                        ) : (
                            <>
                                <Col md={3}>Email</Col>
                                <Col>
                                    <div>{contact.emailAddress}</div>
                                </Col>
                            </>
                        )}
                        <Col md="auto">
                            <Stack direction="horizontal" gap={3}>
                                <FontAwesomeIcon
                                    icon={contact.isPrimaryContact ? faStar : farStar}
                                    className="onclick-hover"
                                    onClick={() => {
                                        handleSetPrimary(customerId, contact.customerContactId ?? "");
                                    }}
                                />
                                <FontAwesomeIcon
                                    icon={faPenToSquare}
                                    className="onclick-hover"
                                    onClick={() => {
                                        setShowEditCustomerContact(true);
                                    }}
                                />
                            </Stack>
                        </Col>
                    </Row>
                    {isBusiness && (
                        <>
                            <Row>
                                <Col md={3}>Email</Col>
                                <Col>
                                    <div>{contact.emailAddress}</div>
                                </Col>
                            </Row>
                        </>
                    )}
                    <Row>
                        <Col md={3}>Phone</Col>
                        <Col>
                            <div>{contact.phoneNumber}</div>
                        </Col>
                    </Row>
                    {contact.address && (
                        <Row>
                            <Col md={3}>Address</Col>
                            <Col>
                                {contact.address.latitude && contact.address.longitude ? (
                                    <a href={"https://maps.google.com/?q=" + contact.address.latitude + "," + contact.address.longitude} target="_blank" rel="noreferrer">
                                        {contact.address.line1}
                                        <br />
                                        {contact.address.line2 && (
                                            <>
                                                {contact.address.line2}
                                                <br />
                                            </>
                                        )}
                                        {contact.address.line3 && (
                                            <>
                                                {contact.address.line3}
                                                <br />
                                            </>
                                        )}
                                        {contact.address.town}
                                        <br />
                                        {contact.address.county}
                                        <br />
                                        {contact.address.postcode}
                                    </a>
                                ) : (
                                    <div>
                                        {contact.address.line1}
                                        <br />
                                        {contact.address.line2 && (
                                            <>
                                                {contact.address.line2}
                                                <br />
                                            </>
                                        )}
                                        {contact.address.line3 && (
                                            <>
                                                {contact.address.line3}
                                                <br />
                                            </>
                                        )}
                                        {contact.address.town}
                                        <br />
                                        {contact.address.county}
                                        <br />
                                        {contact.address.postcode}
                                    </div>
                                )}
                            </Col>
                        </Row>
                    )}
                    {/*{contact.address && (*/}
                    {/*    //<Row>*/}
                    {/*    //    <Col md={3}>What3Words</Col>*/}
                    {/*    //    <Col>*/}
                    {/*    //        <a href={"https://what3words.com/" + contact.address.what3words} target="_blank">*/}
                    {/*    //            ///{contact.address.what3words}*/}
                    {/*    //        </a>*/}
                    {/*    //    </Col>*/}
                    {/*    //</Row>*/}
                    {/*)}*/}
                    {alertText && (
                        <Alert variant={"danger"} className="mt-3" onClose={() => setAlertText(undefined)} dismissible>
                            {alertText}
                        </Alert>
                    )}
                </Card.Body>
            </Card>
            <EditGivenCustomerContactModel
                show={showEditCustomerContact}
                customerId={customerId}
                contactFull={contact}
                onClose={(updated) => {
                    if (updated) {
                        onUpdate();
                    }
                    setShowEditCustomerContact(false);
                }}
            />
        </>
    );
};

export { CustomerContactDetails };
