import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { ErrorMessage, Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { taskService } from "../../services";
import { ReactSelectBootstrapStyle } from "../../styles/ReactSelectBootstrapStyle";
dayjs.extend(utc);


type Props = {
    show: boolean;
    onClose: (added: boolean) => void;
    taskId?: string;
};

const ChangeRecurringTaskDates: React.FC<Props> = ({ show, onClose, taskId }) => {
    const [alertVariant, setAlertVariant] = useState("danger");
    const [currentTask, setCurrentTask] = useState<any>();

    useEffect(() => {
        getTask();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getTask = () => {
        if (taskId) {
            taskService.get(taskId).then((task) => {
                setCurrentTask(task);
            });
        }
    }

    const handleClose = () => onClose(false);
    const days = ["Monday", "Tuesday", "Wednesday","Thursday", "Friday", "Saturday", "Sunday"]

    return (
        <Modal centered show={show} keyboard={false} onHide={handleClose}>
            <Formik
                initialValues={{
                    creationDate: dayjs(new Date()).format("YYYY-MM-DD"),
                    dueDate: dayjs(new Date()).format("YYYY-MM-DD"),
                    startTime: "",
                    endTime: "",
                    days: [],
                    recursiveInterval: undefined,
                    recursiveDuration: undefined,
                    commenceDate: undefined,
                }}
                onSubmit={(values, { setStatus, setSubmitting, setFieldError }) => {
                    setStatus();
                    setSubmitting(false);
                    taskService.edit({
                        ...currentTask,
                        creationDate: dayjs.utc(values.creationDate + " " + values.startTime).toDate(),
                        dueDate: dayjs.utc(values.dueDate + " " + values.endTime).toDate(),
                        days: values.days.join(","),
                        recursiveInterval: values.recursiveInterval,
                        recursiveDuration: values.recursiveDuration ? values.recursiveDuration["value"] : undefined,
                        commenceDate: values.commenceDate
                    }).then(
                        (response) => {
                            setSubmitting(false);
                            if (response.status !== "Failure") {
                                onClose(true);
                            } else {
                                setAlertVariant("danger");
                                setStatus(response.message);
                            }
                        },
                        (error) => {
                            setAlertVariant("danger");
                            if (error.status === 400) {
                                setStatus(error.title);
                                setFieldError("productId", error.errors.ProductId);
                                setFieldError("customerId", error.errors.CustomerId);
                            } else {
                                setStatus(error);
                            }
                            setSubmitting(false);
                        }
                    );

                }}
            >
                {
                    ({ errors, status, touched, isSubmitting, handleSubmit, values, handleChange, setFieldValue }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Header closeButton>
                                <Modal.Title>ReAssign Task</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form.Group as={Row} className="mb-3" controlId="startTime">
                                    <Form.Label column sm={3}>
                                        Start Time
                                    </Form.Label>
                                    <Col sm={3}>
                                        <Form.Control type="time"
                                            name="startTime"
                                            value={values.startTime}
                                            className={"form-control" + (errors.startTime && touched.startTime ? " is-invalid" : "")}
                                            onChange={handleChange}
                                        />

                                        <ErrorMessage name="startTime" component="div" className="invalid-feedback" />
                                    </Col>
                                    <Form.Label column sm={3}>
                                        Due/End Time
                                    </Form.Label>
                                    <Col sm={3}>
                                        <Form.Control type="time"
                                            name="endTime"
                                            value={values.endTime}
                                            className={"form-control" + (errors.endTime && touched.endTime ? " is-invalid" : "")}
                                            onChange={handleChange}
                                        />

                                        <ErrorMessage name="endTime" component="div" className="invalid-feedback" />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="days">
                                    <Form.Label column sm={3}>Day(s)</Form.Label>
                                    <Col sm={9} className="pt-2">
                                        {days.map((day, index) =>
                                            <label key={index} className="d-block">
                                                <Field type="checkbox" name="days" value={day} className="me-2"
                                                />
                                                {day}
                                            </label>
                                        )}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="duration">
                                    <Form.Label column sm={3}>
                                        Every
                                    </Form.Label>
                                    <Col sm={3}>
                                        <Form.Control type="number"
                                            name="recursiveInterval"
                                            value={values.recursiveInterval}
                                            className={"form-control" + (errors.recursiveInterval && touched.recursiveInterval ? " is-invalid" : "")}
                                            onChange={handleChange}
                                        />

                                        <ErrorMessage name="recursiveInterval" component="div" className="invalid-feedback" />
                                    </Col>

                                    <Col sm={6}>
                                        <Select
                                            styles={ReactSelectBootstrapStyle}
                                            className="stateManagedSelect"
                                            onChange={(value) => {
                                                console.log(typeof value, value)
                                                values["recursiveDuration"] = value
                                            }}
                                            isClearable
                                            options={[{
                                                "label": "Week(s)",
                                                "value": "Week(s)"
                                            }
                                            ]}
                                        />
                                        <ErrorMessage name="recursiveDuration" component="div" className="invalid-feedback" />
                                    </Col>


                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="commenceDate">
                                    <Form.Label column sm={3}>
                                        After Date
                                    </Form.Label>
                                    <Col sm={9}>
                                        <Form.Control type="date"
                                            name="commenceDate"
                                            value={values.commenceDate}
                                            className={"form-control" + (errors.commenceDate && touched.commenceDate ? " is-invalid" : "")}
                                            onChange={handleChange}
                                        />

                                        <ErrorMessage name="commenceDate" component="div" className="invalid-feedback" />
                                    </Col>

                                </Form.Group>

                                {status && (
                                    <Alert variant={alertVariant} className="mt-3">
                                        {status}
                                    </Alert>
                                )}
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="form-group">
                                    <Button variant="primary" disabled={isSubmitting} type="submit" className="me-2">
                                        {isSubmitting ? <LoadingSpinner text="Changing Recurring Dates..." /> : "Change Recurring Dates"}
                                    </Button>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Cancel
                                    </Button>
                                </div>
                            </Modal.Footer>
                        </Form>
                    )
                }
            </Formik>
        </Modal>
    );
};

export { ChangeRecurringTaskDates };
