import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { userService } from "../../services";

import { IIdentity } from "@/customTypings/Identity";
import {
    Alert,
    Breadcrumb,
    Button,
    Col,
    Container,
    Form,
    Row,
    Stack
} from "react-bootstrap";
import {
    UserColorEdit, UserDisplayNameEdit,
    UserPasswordEdit, UserRoleEdit
} from "../../components";

import { useAtom } from "@dbeining/react-atom";
import {
    faBan,
    faPenToSquare, faUnlock
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";
import { userAtom } from "../../atoms/userAtom";
import { Avatar } from "../../components/Avatar";
import { AvatarSkeleton } from "../../components/AvatarSkeleton";

const UserPage = () => {
    let params = useParams();
    const [user, setUser] = useState<IIdentity | null>(null);
    const [showEditRoles, setShowEditRoles] = useState(false);
    const [showEditDisplayName, setShowEditDisplayName] = useState(false);
    const [showEditColor, setShowEditColor] = useState(false);
    const [showEditPassword, setShowEditPassword] = useState(false);
    const [userInteraction, setUserInteraction] = useState(false);
    const [alertVariant, setAlertVariant] = useState("danger");
    const [statusMessage, setStatusMessage] = useState("");
    const [updating, setUpdating] = useState(false);
    const currentUser = useAtom(userAtom);
    const [color, setColor] = useState("");

    useEffect(() => {
        userService
            .getById(params.userId ?? "")
            .then((userFromApi) => setUser(userFromApi));
        userService
            .getColor(params.userId ?? "")
            .then((color) => setColor(color));
    }, [params.userId]);

    useEffect(() => {
        if (userInteraction) {
            userService
                .getById(params.userId ?? "")
                .then((userFromApi) => setUser(userFromApi));
            userService
                .getColor(params.userId ?? "")
                .then((color) => setColor(color));
        }
        setUserInteraction(false);
    }, [params.userId, userInteraction]);

    const showServiceSuccessResponse = (message: string) => {
        setAlertVariant("success");
        setStatusMessage(message);
        setUserInteraction(true);
        setUpdating(false);
    };

    const showServiceFailureResponse = (
        error: any,
        fallbackMessage: string
    ) => {
        setAlertVariant("danger");
        if (error.status === 400) {
            setStatusMessage(fallbackMessage);
        } else {
            setStatusMessage(error);
        }
        setUpdating(false);
    };

    function disableAndEndAllSessionsForUserWithId(id: string) {
        setUpdating(true);
        userService.disableAndEndAllSessionsForUserWithId(id).then(
            (response) => {
                if (response.status !== "Failure") {
                    showServiceSuccessResponse("Account disabled.");
                } else {
                    showServiceFailureResponse(
                        response.message,
                        "Account disable failed."
                    );
                }
            },
            (error) => {
                showServiceFailureResponse(error, "Account disable failed.");
            }
        );
    }

    function enableUserWithId(id: string) {
        setUpdating(true);
        userService.enableUserWithId(id).then(
            (response) => {
                if (response.status !== "Failure") {
                    showServiceSuccessResponse("Account enabled.");
                } else {
                    showServiceFailureResponse(
                        response.message,
                        "Account enabled failed."
                    );
                }
            },
            (error) => {
                showServiceFailureResponse(error, "Account enable failed.");
            }
        );
    }

    function resetLockedOutUserWithId(id: string) {
        setUpdating(true);
        userService.resetLockedOutUserWithId(id).then(
            (response) => {
                if (response.status !== "Failure") {
                    showServiceSuccessResponse("Account unlocked.");
                } else {
                    showServiceFailureResponse(
                        response.message,
                        "Account unlock failed."
                    );
                }
            },
            (error) => {
                showServiceFailureResponse(error, "Account unlock failed.");
            }
        );
    }

    function updateTwoFAIsRequired(id: string, isRequired: boolean) {
        setUpdating(true);
        userService.updateTwoFAIsRequired(id, isRequired).then(
            (response) => {
                if (response.status !== "Failure") {
                    showServiceSuccessResponse(
                        `Two factor authentication ${
                            isRequired ? "en" : "dis"
                        }abled.`
                    );
                } else {
                    showServiceFailureResponse(
                        response.message,
                        `Two factor authentication ${
                            isRequired ? "en" : "dis"
                        }able failed.`
                    );
                }
            },
            (error) => {
                showServiceFailureResponse(
                    error,
                    `Two factor authentication ${
                        isRequired ? "en" : "dis"
                    }able failed.`
                );
            }
        );
    }

    function resetTwoFactorSecretKey(id: string) {
        setUpdating(true);
        userService.resetTwoFactorSecretKey(id).then(
            (response) => {
                if (response.status !== "Failure") {
                    showServiceSuccessResponse(
                        "Two factor authentication reset."
                    );
                } else {
                    showServiceFailureResponse(
                        response.message,
                        "Two factor authentication reset failed."
                    );
                }
            },
            (error) => {
                showServiceFailureResponse(
                    error,
                    "Two factor authentication reset failed."
                );
            }
        );
    }

    return (
        <Container fluid>
            <Helmet>
                <title>{`User ${user?.displayName}`}</title>
            </Helmet>

            <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/settings" }}>
                    Settings
                </Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/users" }}>
                    Users
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                    {user ? user.displayName : <Skeleton width="6em" />}
                </Breadcrumb.Item>
            </Breadcrumb>

            <Row className="mb-3">
                <h1>
                    {user && <Avatar identity={user} size={1.5} />}
                    {!user && <AvatarSkeleton size={1.5} />}
                </h1>
            </Row>
            <Row>
                {user && (
                    <Stack gap={2} direction="horizontal" className="mb-3">
                        {user.isEnabled ? (
                            <Button
                                variant="danger"
                                onClick={() =>
                                    disableAndEndAllSessionsForUserWithId(
                                        user.id
                                    )
                                }
                                disabled={
                                    updating ||
                                    user.id === currentUser?.identity.id
                                }
                            >
                                <FontAwesomeIcon
                                    icon={faBan}
                                    className="me-1"
                                />{" "}
                                Disable User
                            </Button>
                        ) : (
                            <Button
                                className="me-2"
                                variant="success"
                                onClick={() => enableUserWithId(user.id)}
                                disabled={updating}
                            >
                                Enable User
                            </Button>
                        )}
                        {user.isLocked && (
                            <Button
                                variant="success"
                                onClick={() =>
                                    resetLockedOutUserWithId(user.id)
                                }
                                disabled={updating}
                            >
                                <FontAwesomeIcon
                                    icon={faUnlock}
                                    className="me-1"
                                />{" "}
                                Unlock
                            </Button>
                        )}
                        {!user.twoFAIsEnabled && (
                            <Button
                                variant="success"
                                onClick={() =>
                                    updateTwoFAIsRequired(user.id, true)
                                }
                                disabled={updating}
                            >
                                Enable 2FA
                            </Button>
                        )}
                        {user.twoFAIsEnabled && (
                            <Button
                                variant="danger"
                                onClick={() =>
                                    updateTwoFAIsRequired(user.id, false)
                                }
                                disabled={updating}
                            >
                                Disable 2FA
                            </Button>
                        )}
                        <Button
                            variant="danger"
                            onClick={() => resetTwoFactorSecretKey(user.id)}
                            disabled={updating}
                        >
                            Reset 2FA
                        </Button>
                    </Stack>
                )}
                {!user && (
                    <Stack gap={2} direction="horizontal" className="mb-3">
                        <Skeleton width="6rem" height="38px" />
                        <Skeleton width="6rem" height="38px" />
                        <Skeleton width="6rem" height="38px" />
                    </Stack>
                )}
            </Row>

            {statusMessage && (
                <Alert
                    variant={alertVariant}
                    onClose={() => setStatusMessage("")}
                    dismissible
                >
                    {statusMessage}
                </Alert>
            )}

            <Card className="shadow">
                <Card.Body>
                    <Row>
                        <Col lg={3} md={4} sm={12}>
                            <Form.Label>Email</Form.Label>
                        </Col>
                        <Col>
                            <Form.Label>
                                {user ? user.email : <Skeleton width="14em" />}
                            </Form.Label>
                        </Col>
                    </Row>

                    <hr />

                    <Row>
                        <Col lg={3} md={4} sm={12}>
                            <Form.Label>Display name</Form.Label>
                        </Col>
                        <Col>
                            <Form.Label>
                                {user ? (
                                    user.displayName
                                ) : (
                                    <Skeleton width="14em" />
                                )}
                            </Form.Label>
                        </Col>
                        {user && (
                            <Col xs="auto">
                                <Button
                                    variant="outline-secondary"
                                    className="border-0"
                                    onClick={() => setShowEditDisplayName(true)}
                                    title="Edit display name"
                                >
                                    <FontAwesomeIcon icon={faPenToSquare} />
                                </Button>
                            </Col>
                        )}
                    </Row>

                    <hr />

                    <Row>
                        <Col lg={3} md={4} sm={12}>
                            <Form.Label>Organisation</Form.Label>
                        </Col>
                        <Col>
                            <Form.Label>
                                {" "}
                                {user ? (
                                    user.organisation.displayName
                                ) : (
                                    <Skeleton width="14em" />
                                )}
                            </Form.Label>
                        </Col>
                    </Row>

                    <hr />

                    <Row>
                        <Col lg={3} md={4} sm={12}>
                            <Form.Label>Colour</Form.Label>
                        </Col>
                        <Col>
                            {user ? (
                                <div
                                    style={{
                                        border: "solid 1px black",
                                        backgroundColor: color,
                                        height: "1.5em",
                                        width: "6em",
                                    }}
                                ></div>
                            ) : (
                                <Skeleton width="6em" height="1.5em" />
                            )}
                        </Col>
                        {user && (
                            <Col xs="auto">
                                <Button
                                    variant="outline-secondary"
                                    className="border-0"
                                    onClick={() => setShowEditColor(true)}
                                    title="Edit colour"
                                >
                                    <FontAwesomeIcon icon={faPenToSquare} />
                                </Button>
                            </Col>
                        )}
                    </Row>

                    <hr />

                    <Row className="mb-2">
                        <Col lg={3} md={4} sm={12}>
                            <Form.Label>Roles</Form.Label>
                        </Col>
                        <Col>
                            <Form.Label>
                                {user ? (
                                    user.roles.map((userRole) => (
                                        <div key={userRole.id}>
                                            {userRole.name}
                                        </div>
                                    ))
                                ) : (
                                    <Skeleton width="12em" count={6} />
                                )}
                            </Form.Label>
                        </Col>
                        {user && user?.id !== currentUser?.identity.id && (
                            <Col xs="auto">
                                <Button
                                    variant="outline-secondary"
                                    className="border-0"
                                    onClick={() => setShowEditRoles(true)}
                                    title="Edit roles"
                                >
                                    <FontAwesomeIcon icon={faPenToSquare} />
                                </Button>
                            </Col>
                        )}
                    </Row>

                    <hr />

                    <Row className="mb-2">
                        <Col lg={3} md={4} sm={12}>
                            Password
                        </Col>
                        <Col>
                            <Form.Label>
                                {user ? "••••••••" : <Skeleton width="8em" />}
                            </Form.Label>
                        </Col>
                        {user && user.id === currentUser?.identity.id && (
                            <Col xs="auto">
                                <Button
                                    variant="outline-secondary"
                                    className="border-0"
                                    onClick={() => setShowEditPassword(true)}
                                    title="Edit password"
                                >
                                    <FontAwesomeIcon icon={faPenToSquare} />
                                </Button>
                            </Col>
                        )}
                    </Row>
                </Card.Body>
            </Card>

            {user && (
                <>
                    <UserDisplayNameEdit
                        show={showEditDisplayName}
                        setShow={setShowEditDisplayName}
                        user={user}
                        setUserInteraction={setUserInteraction}
                    />
                    <UserRoleEdit
                        show={showEditRoles}
                        setShow={setShowEditRoles}
                        user={user}
                        setUserInteraction={setUserInteraction}
                    />
                    <UserColorEdit
                        show={showEditColor}
                        setShow={setShowEditColor}
                        user={user}
                        color={color}
                        setUserInteraction={setUserInteraction}
                    />
                    <UserPasswordEdit
                        show={showEditPassword}
                        setShow={setShowEditPassword}
                        user={user}
                        setUserInteraction={setUserInteraction}
                    />
                </>
            )}
        </Container>
    );
};

export { UserPage };
