import { ISiteVisitForm } from '@/customTypings/SiteVisitForm';
import { IDeliveryOrInstallationForm } from '@/customTypings/DeliveryOrInstallationForm';
import { IHotTubOrderForm } from '@/customTypings/HotTubOrderForm';
import { IEndlessPoolsOrderForm } from '@/customTypings/EndlessPoolsOrderForm';
import { ISpaFreshReportForm } from '@/customTypings/SpaFreshReportForm';
import { IAnnualServiceReportForm } from '@/customTypings/AnnualServiceReportForm';
import { authHeader, handleResponse } from '../helpers';

export const formService = {
    addSiteVisitForm,
    addDeliveryOrInstallationForm,
    addEndlessPoolsOrderForm,
    addHotTubOrderForm,
    addSpaFreshReportForm,
    addAnnualServiceReportForm,
    getAll,
    getById,
    getSignableById,
    getReadableById,
    signForm,
};

function addSiteVisitForm(siteVisitForm: ISiteVisitForm) {
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(siteVisitForm),
    };
    return fetch(`api/form/addSiteVisitForm`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function addDeliveryOrInstallationForm(deliveryOrInstallationForm: IDeliveryOrInstallationForm) {
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(deliveryOrInstallationForm),
    };
    return fetch(`api/form/addDeliveryOrInstallationForm`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function addEndlessPoolsOrderForm(endlessPoolsOrderForm: IEndlessPoolsOrderForm) {
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(endlessPoolsOrderForm),
    };
    return fetch(`api/form/addEndlessPoolsOrderForm`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}
function addHotTubOrderForm(hotTubOrderForm: IHotTubOrderForm) {
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(hotTubOrderForm),
    };
    return fetch(`api/form/addHotTubOrderForm`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function addSpaFreshReportForm(spaFreshReportForm: ISpaFreshReportForm) {
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(spaFreshReportForm),
    };
    return fetch(`api/form/addSpaFreshReportForm`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function addAnnualServiceReportForm(annualServiceReportForm: IAnnualServiceReportForm) {
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(annualServiceReportForm),
    };
    return fetch(`api/form/addAnnualServiceReportForm`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function getAll() {
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: authHeader(),
        credentials: "include"
    };
    return fetch(`api/form/all`, requestOptions)
        .then(handleResponse);
}

function getById(id: string) {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`api/form/getbyid?id=${id}`, requestOptions)
        .then(handleResponse);
}

function getSignableById(id: string) {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`api/form/getsignablebyid?id=${id}`, requestOptions)
        .then(handleResponse);
}

function getReadableById(id: string) {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`api/form/getreadablebyid?id=${id}`, requestOptions)
        .then(handleResponse);
}

function signForm(obj: any) {
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(obj),
    };

    return fetch(`api/form/signForm`, requestOptions)
        .then(handleResponse);
}