import { useAtom } from "@dbeining/react-atom";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { userAtom } from "../../atoms/userAtom";
import { IValueAndLabel } from "../../customTypings/ValueAndLabel";
import { calendarEventService, emailTemplateService, userService } from "../../services";
import { LoadingSpinner } from "../LoadingSpinner";
import { IEvent } from "./EventCalendar";
import mapEmailBody from "./Mapping";


type Props = {
    show: boolean;
    onClose: (added: boolean) => void;
    eventToBeUpdated?: IEvent;
};

const EventRemove: React.FC<Props> = ({ show, onClose, eventToBeUpdated }) => {
    const currentUser = useAtom(userAtom);
    const actioner = `${currentUser?.identity.displayName}: ${currentUser?.identity.email}`;

    const [allIdentities, setAllIdentities] = useState<IValueAndLabel[]>();
    const [emailTemplate, setEmailTemplate] = useState<any>({});
    const [eventTemplate, setEventTemplate] = useState<any>({});

    const [alertVariant, setAlertVariant] = useState("danger");
    const handleClose = () => onClose(false);

    useEffect(() => {
        userService
            .getAllListItems()
            .then((identities) => setAllIdentities(identities));
    }, []);

    useEffect(() => {
        emailTemplateService.getAll().then(async (response) => {
            const eventCancellationTemplate = response.find((template: any) => template.name === "Event cancellation");
            if (eventCancellationTemplate) {
                setEmailTemplate(eventCancellationTemplate);
                setEventTemplate(await emailTemplateService.getById(eventCancellationTemplate.id));
            } else {
                console.error("Event Cancellation template not found");
            }
        });
    }, []);

    async function createEmailArray(eventTemplate: any, personals: any) {
        let emails = [];

        if (eventTemplate.eventCreator) {
            emails.push(currentUser?.identity.email);
        }
        if (eventTemplate.adminEmail) {
            emails.push("");
        }
        if (eventTemplate.personnel) {
            if (personals) {
                personals.map((obj: any) => emails.push(obj.email));
            };
        }

        return emails;
    }
    const emailBody = emailTemplate.body;
    const emailSubject = emailTemplate.subject;

    return (
        <Modal centered show={show} keyboard={false} onHide={handleClose}>
            <Formik
                initialValues={{
                    isRemoveAll: false,
                }}
                onSubmit={async (values, { setStatus, setSubmitting }) => {
                    setStatus();
                    setSubmitting(true);
                    const personals = allIdentities?.filter(user => eventToBeUpdated?.associatedIds?.includes(user.value)).map((item: any) => ({
                        email: item?.label.split(": ")[1],
                    }));
                    const receiverEmails = await createEmailArray(eventTemplate, personals) as string[];
                    const updatedBody = await mapEmailBody({ body: emailBody, id: eventToBeUpdated?.groupId ?? "", actioner: actioner });
                    const updatedSubject = await mapEmailBody({ body: emailSubject, id: eventToBeUpdated?.groupId ?? "", actioner: actioner });
                    await calendarEventService
                        .remove(
                            eventToBeUpdated?.groupId ?? "",
                            values.isRemoveAll
                                ? undefined
                                : eventToBeUpdated?.identityId
                        )
                        .then(
                            async (response) => {
                                setSubmitting(false);
                                if (response.status !== "Failure") {
                                    emailTemplateService.sendAutoEmail(updatedSubject, updatedBody, receiverEmails).then(
                                        (response) => {
                                            setStatus(response.message);
                                        });
                                    onClose(true);
                                } else {
                                    setAlertVariant("danger");
                                    setStatus(response.message);
                                }
                            },
                            (error) => {
                                setAlertVariant("danger");
                                if (error.status === 400) {
                                    setStatus(error.title);
                                } else {
                                    setStatus(error);
                                }
                                setSubmitting(false);
                            }
                        );
                }}
            >
                {({
                    values,
                    status,
                    errors,
                    touched,
                    isSubmitting,
                    handleSubmit,
                    setFieldValue,
                }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>Remove Event</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group
                                className="mb-3"
                                controlId="removeCheckbox"
                            >
                                <Form.Check
                                    type="checkbox"
                                    name="isRemoveAll"
                                    className={
                                        "mt-1 form-check" +
                                        (errors.isRemoveAll &&
                                        touched.isRemoveAll
                                            ? " is-invalid"
                                            : "")
                                    }
                                    defaultChecked={values.isRemoveAll.valueOf()}
                                    onChange={(e) =>
                                        setFieldValue(
                                            "isRemoveAll",
                                            e.target.checked == null
                                                ? false
                                                : e.target.checked
                                        )
                                    }
                                    label="Remove this event for all attendees?"
                                />
                                <ErrorMessage
                                    name="isAllDay"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </Form.Group>
                            {values.isRemoveAll && (
                                <Alert variant="info" className="mb-0">
                                    <FontAwesomeIcon
                                        className="me-2"
                                        icon={faInfoCircle}
                                        size="lg"
                                    />{" "}
                                    Please be aware this will remove the event
                                    from all attendees calendars.
                                </Alert>
                            )}
                            {status && (
                                <Alert variant={alertVariant} className="mt-3">
                                    {status}
                                </Alert>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="form-group">
                                <Button
                                    variant="danger"
                                    disabled={isSubmitting}
                                    type="submit"
                                    className="me-2"
                                >
                                    {isSubmitting ? (
                                        <LoadingSpinner text="Removing event..." />
                                    ) : (
                                        "Remove event"
                                    )}
                                </Button>
                                <Button
                                    variant="secondary"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export { EventRemove };
