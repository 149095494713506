import {
    Col, Container,
    Dropdown, DropdownButton, Row
} from "react-bootstrap";

interface SiteVisitFormProps {
  data: Record<string, any>;
  clickHandler: (value: string) => void;
}

const PlaceholderList: React.FC<SiteVisitFormProps> = ({
  data,
  clickHandler,
}) => {
  return (Object.entries(data) as any).map(
    (placeholder: string, index: number) => (
      <Dropdown.Item
        style={{ margin: "0px", padding: "0px" }}
        key={index}
        onClick={() => clickHandler(placeholder[0])}
      >
        {placeholder[1]}
      </Dropdown.Item>
    )
  );
};

interface PlaceholderArrayItem {
  0: string;
  1: {
    [key: string]: string;
  };
}

type PlaceholderArray = PlaceholderArrayItem[];

const SiteVisitFormModal: React.FC<SiteVisitFormProps> = ({
  clickHandler,
  data,
}) => {
  const modifiedPlaceholders: PlaceholderArray = Object.entries(
    data.Placeholders
  );
  const modifiedHeaders: PlaceholderArray = Object.entries(data.Header);

  const containerStyle = {
    width: "600px",
    height: "700px",
    overflow: "hidden",
  };

  return (
    <DropdownButton
      className="ms-auto"
      variant="outline-secondary"
      title="Insert Placeholders"
      id="input-group-dropdown-2"
      align="end"
      style={{ zIndex: 2000 }}
    >
      <Container style={containerStyle}>
        <Row style={{ borderBottom: "1px solid" }}>
          {modifiedHeaders.length >= 1 && (
            <Col xs={6}>
              <h5>
                <strong> {modifiedHeaders[0][0]}</strong>
              </h5>
              <PlaceholderList
                data={modifiedHeaders[0][1]}
                clickHandler={clickHandler}
              />
            </Col>
          )}

          {modifiedHeaders.length >= 2 && (
            <Col xs={6} style={{ borderLeft: "1px solid" }}>
              <h5>
                <strong> {modifiedHeaders[1][0]}</strong>
              </h5>
              <PlaceholderList
                data={modifiedHeaders[1][1]}
                clickHandler={clickHandler}
              />
            </Col>
          )}
        </Row>

        <Row style={{ height: "100%" }}>
          {[...Array(2)].map((_, index) => (
            <Col xs={6} style={{ borderRight: "1px solid" }} key={index}>
              <Row style={{ height: "40%" }}>
                <div>
                  <h5>
                    <strong> {modifiedPlaceholders[index * 2][0]} </strong>
                  </h5>
                  <PlaceholderList
                    data={modifiedPlaceholders[index * 2][1]}
                    clickHandler={clickHandler}
                  />
                </div>
              </Row>
              <Row
                style={{
                  height: "60%",
                  borderTop: "1px solid",
                }}
              >
                <div>
                  <h5>
                    <strong> {modifiedPlaceholders[index * 2 + 1][0]} </strong>
                  </h5>
                  <PlaceholderList
                    data={modifiedPlaceholders[index * 2 + 1][1]}
                    clickHandler={clickHandler}
                  />
                </div>
              </Row>
            </Col>
          ))}
        </Row>
      </Container>
    </DropdownButton>
  );
};

export default SiteVisitFormModal;
