import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Documents } from "../../components/Documents";

const DocumentLibraryPage = () => {
    return (
        <Container fluid>
            <Helmet>
                <title>Document Library</title>
            </Helmet>
            <h1>Document Library</h1>
            <Documents baseFolder />
        </Container>
    );
};

export { DocumentLibraryPage };
