import { ILead } from "@/customTypings/Lead";
import { ErrorMessage, Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Modal, Placeholder, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import { leadService } from "../../services";
import { LoadingSpinner } from "../LoadingSpinner";

type Props = {
    show: boolean;
    onClose: (updated: boolean) => void;
    leadId: string;
};

const EditLeadModel: React.FC<Props> = ({ show, onClose, leadId }) => {
    const [alertVariant, setAlertVariant] = useState("danger");
    const [currentLead, setCurrentLead] = useState<ILead>();
    const [loading, setLoading] = useState(true);

    const leadTypes = [
        { name: "Domestic", value: false },
        { name: "Business or organisation", value: true },
    ];

    const handleClose = () => onClose(false);

    useEffect(() => {
        if (show) {
            setLoading(true);
            leadService.get(leadId).then((lead) => {
                setCurrentLead(lead);
                setLoading(false);
            });
        }
    }, [leadId, show]);

    const labelSpan = 3;
    const fieldSpan = 9;

    return (
        <Modal centered show={show} keyboard={false} onHide={handleClose}>
            {loading || !currentLead ? (
                <>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Lead</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="mb-3">
                            <Col md={labelSpan}>Lead Type</Col>
                            <Col md={fieldSpan}>
                                <Skeleton width="20rem" height="2rem" />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={labelSpan}>Lead Name</Col>
                            <Col md={fieldSpan}>
                                <Skeleton width="20rem" height="2rem" />
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Placeholder.Button variant="primary" xs={3} className="me-2" />
                        <Placeholder.Button variant="secondary" xs={2} />
                    </Modal.Footer>
                </>
            ) : (
                <Formik
                    initialValues={{
                        name: currentLead.name ?? "",
                        isBusiness: currentLead.isBusiness.toString(),
                    }}
                    validationSchema={Yup.object().shape({
                        isBusiness: Yup.boolean(),
                        name: Yup.string().when("isBusiness", {
                            is: true,
                            then: Yup.string().required("Lead Name is required"),
                        }),
                    })}
                    onSubmit={(values, { setStatus, setSubmitting, setFieldError }) => {
                        setStatus();
                        setSubmitting(true);
                        const isBusiness = values.isBusiness === true.toString();
                        const updatedLead: ILead = {
                            id: currentLead.id,
                            name: values.name,
                            isBusiness: isBusiness,
                            leadNumber: currentLead.leadNumber,
                            notes: currentLead.notes,
                            dateOfInquiry: currentLead.dateOfInquiry
                        };
                        leadService.edit(updatedLead).then(
                            (response) => {
                                setSubmitting(false);
                                if (response.status !== "Failure") {
                                    onClose(true);
                                } else {
                                    setAlertVariant("danger");
                                    setStatus(response.message);
                                }
                            },
                            (error) => {
                                setAlertVariant("danger");
                                if (error.status === 400) {
                                    setStatus(error.title);
                                    setFieldError("name", error.errors.Name);
                                    setFieldError("isBusiness", error.errors.isBusiness);
                                    setFieldError("isSchemeOrganisation", error.errors.isSchemeOrganisation);
                                } else {
                                    setStatus(error);
                                }
                                setSubmitting(false);
                            }
                        );
                    }}
                >
                    {({ errors, status, touched, isSubmitting, handleSubmit, values, handleChange, setFieldValue }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Header closeButton>
                                <Modal.Title>Edit Lead</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form.Group as={Row} className="mb-3" controlId="leadTypeRadios">
                                    <Form.Label column sm={3}>
                                        Lead Type
                                    </Form.Label>
                                    <Col sm={9} className="pt-2">
                                        {leadTypes &&
                                            leadTypes.map((leadType) => (
                                                <label key={uuidv4()} className="d-block">
                                                    <Field type="radio" name="isBusiness" value={leadType.value.toString()} className="me-2" />
                                                    {leadType.name}
                                                </label>
                                            ))}
                                    </Col>
                                </Form.Group>
                                {(
                                    <>
                                        <Form.Group as={Row} className="mb-3" controlId="leadName">
                                            <Form.Label column sm={3}>
                                                Lead Name
                                            </Form.Label>
                                            <Col sm={9}>
                                                <Field name="name" type="text" className={"form-control" + (errors.name && touched.name ? " is-invalid" : "")} />
                                                <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                            </Col>
                                            </Form.Group>
                                        </>
                                )}
                                {status && (
                                    <Alert variant={alertVariant} className="mt-3">
                                        {status}
                                    </Alert>
                                )}
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="form-group">
                                    <Button variant="primary" disabled={isSubmitting} type="submit" className="me-2">
                                        {isSubmitting ? <LoadingSpinner text="Updating lead..." /> : "Update lead"}
                                    </Button>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Cancel
                                    </Button>
                                </div>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            )}
        </Modal>
    );
};

export { EditLeadModel };
