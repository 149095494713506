import { IRole } from "@/customTypings/role";
import { useAtom } from "@dbeining/react-atom";
import { faFileExport, faPlus } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import { useNavigate } from "react-router-dom";
import { userAtom } from "../../atoms/userAtom";
import { ColumnRender, FilterType, IColumn, IFilter, IPagedListAction } from "../../components/PagedList/Declarations";
import { PagedList } from "../../components/PagedList/PagedList";
import { Role } from "../../helpers";
import { AddOrderModel } from "../../components/Models/AddOrderModel";

type Props = {
    customerId: string | undefined;
    customerNumber: string | undefined;
};

const CustomerProductsPage: React.FC<Props> = ({ customerId, customerNumber }) => {
    const navigate = useNavigate();
    const currentUser = useAtom(userAtom);
    const [showAddOrder, setShowAddOrder] = useState(false);
    const [refreshNumber, setRefeshNumber] = useState(0);


    const handleAddOrder = (added: boolean) => {
        if (added) {
            setRefeshNumber((refreshNumber) => refreshNumber + 1);
        }
        setShowAddOrder(false);
    };
    const handleOpenAddOrder = () => {
        setShowAddOrder(true);
    };


    return (
        <>
            <Container fluid>
                <Row>
                    <Col>
                        <PagedList
                            columns={[
                                {
                                    member: "serialNumber",
                                    renderAs: ColumnRender.text,
                                    title: "Serial No",
                                    width: 150,
                                } as IColumn,
                                {
                                    member: "brand",
                                    renderAs: ColumnRender.text,
                                    title: "Brand",
                                    width: 150,
                                } as IColumn,
                                {
                                    member: "model",
                                    renderAs: ColumnRender.text,
                                    title: "Model",
                                    width: 150,
                                } as IColumn,
                                {
                                    member: "color",
                                    renderAs: ColumnRender.text,
                                    title: "Colour",
                                    width: 150,
                                } as IColumn,
                                {
                                    member: "deliveryDate",
                                    renderAs: ColumnRender.date,
                                    title: "Delivery Date",
                                    width: 150,
                                } as IColumn,
                            ]}
                            itemActions={[
                                {
                                    text: "Detail",
                                    onClick: (item) => {
                                        navigate(`/orders/${item.id}`);
                                    },
                                    primary: true,
                                } as IPagedListAction,
                            ]}
                            headActions={[
                                {
                                    icon: faPlus,
                                    text: "Add Product",
                                    onClick: handleOpenAddOrder,
                                } as IPagedListAction,
                            ]}
                            filters={[
                                {
                                    title: "Serial Number",
                                    member: "serialNumber",
                                    type: FilterType.text,
                                } as IFilter,
                                {
                                    title: "Brand",
                                    member: "brand",
                                    type: FilterType.text,
                                } as IFilter,
                                {
                                    title: "Model",
                                    member: "model",
                                    type: FilterType.text,
                                } as IFilter,
                                {
                                    title: "Colour",
                                    member: "color",
                                    type: FilterType.text,
                                } as IFilter,
                                {
                                    title: "Delivery Date",
                                    member: "deliveryDate",
                                    type: FilterType.text,
                                } as IFilter,
                            ]}
                            defaultSortBy="customerNumber"
                            getUrl="api/order/all"
                            avatarUrl="api/avatar/get"
                            rowFormatter={(item: any) => {
                                return null;
                            }}
                            persistanceKey="OrderListPage"
                            refreshNumber={refreshNumber + 1}
                            exportUrl={currentUser?.identity.roles.some((role: IRole) => [Role.Admin].indexOf(role.name) >= 0) ? "api/order/download" : undefined}
                            exportIcon={faFileExport}
                            staticFilterValues={{ customerNumber }}
                        />
                    </Col>
                </Row>

            </Container>
            <AddOrderModel show={showAddOrder} onClose={handleAddOrder} customerId={customerId} />
        </>
    );
};

export { CustomerProductsPage };
